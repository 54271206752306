/**
 * ENUM to define which page is currently open for the client
 */
export enum CurrentPage {
  AGB,
  Privacy,
  Imprint,
  DASHBOARD_ADMIN_HOME,
  CONFIGURATION_ADMIN,
  CONFIGURATION_ADMIN_PROVIDER,
  DASHBOARD_PROVIDER_HOME,
  PROVIDER_DETAIL_CONFIGURATION,
  PROVIDER_LUNCHEDIT,
  PROVIDER_LUNCHOVERVIEW,
  ADMIN_PROFILE_PAGE,
  ADMIN_VERSION,
  ADMIN_EVENT,
  ADMIN_ACTION,
  ADMIN_ACTIVITY,
  ADMIN_LUNCHEDIT,
  ADMIN_LUNCHOVERVIEW,
  PROVIDER_ACTIVITY,
  PROVIDER_PROFILE_PAGE,
  MAILBOX_ADMIN,
  MAILBOX_PROVIDER,
}
