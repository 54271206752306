import StockOne from "../../assets/images/stock/stock-1.png";
import StockTwo from "../../assets/images/stock/stock-2.png";
import StockThree from "../../assets/images/stock/stock-3.png";
import StockFour from "../../assets/images/stock/stock-4.png";
import "./StockImage.style.scss";
import { StockImageProps } from "./StockImage.types";
// Import event/eatAndDrink stocks
import StockEventEat1 from "../../assets/images/stock/event/eatDrink/stock-1.png";
import StockEventEat10 from "../../assets/images/stock/event/eatDrink/stock-10.png";
import StockEventEat11 from "../../assets/images/stock/event/eatDrink/stock-11.png";
import StockEventEat12 from "../../assets/images/stock/event/eatDrink/stock-12.png";
import StockEventEat13 from "../../assets/images/stock/event/eatDrink/stock-13.png";
import StockEventEat14 from "../../assets/images/stock/event/eatDrink/stock-14.png";
import StockEventEat2 from "../../assets/images/stock/event/eatDrink/stock-2.png";
import StockEventEat3 from "../../assets/images/stock/event/eatDrink/stock-3.png";
import StockEventEat4 from "../../assets/images/stock/event/eatDrink/stock-4.png";
import StockEventEat5 from "../../assets/images/stock/event/eatDrink/stock-5.png";
import StockEventEat6 from "../../assets/images/stock/event/eatDrink/stock-6.png";
import StockEventEat7 from "../../assets/images/stock/event/eatDrink/stock-7.png";
import StockEventEat8 from "../../assets/images/stock/event/eatDrink/stock-8.png";
import StockEventEat9 from "../../assets/images/stock/event/eatDrink/stock-9.png";
// Import event/experience stocks
import StockEventExp1 from "../../assets/images/stock/event/experience/stock-1.png";
import StockEventExp10 from "../../assets/images/stock/event/experience/stock-10.png";
import StockEventExp11 from "../../assets/images/stock/event/experience/stock-11.png";
import StockEventExp12 from "../../assets/images/stock/event/experience/stock-12.png";
import StockEventExp13 from "../../assets/images/stock/event/experience/stock-13.png";
import StockEventExp14 from "../../assets/images/stock/event/experience/stock-14.png";
import StockEventExp15 from "../../assets/images/stock/event/experience/stock-15.png";
import StockEventExp16 from "../../assets/images/stock/event/experience/stock-16.png";
import StockEventExp17 from "../../assets/images/stock/event/experience/stock-17.png";
import StockEventExp18 from "../../assets/images/stock/event/experience/stock-18.png";
import StockEventExp19 from "../../assets/images/stock/event/experience/stock-19.png";
import StockEventExp2 from "../../assets/images/stock/event/experience/stock-2.png";
import StockEventExp20 from "../../assets/images/stock/event/experience/stock-20.png";
import StockEventExp21 from "../../assets/images/stock/event/experience/stock-21.png";
import StockEventExp22 from "../../assets/images/stock/event/experience/stock-22.png";
import StockEventExp23 from "../../assets/images/stock/event/experience/stock-23.png";
import StockEventExp24 from "../../assets/images/stock/event/experience/stock-24.png";
import StockEventExp25 from "../../assets/images/stock/event/experience/stock-25.png";
import StockEventExp26 from "../../assets/images/stock/event/experience/stock-26.png";
import StockEventExp27 from "../../assets/images/stock/event/experience/stock-27.png";
import StockEventExp28 from "../../assets/images/stock/event/experience/stock-28.png";
import StockEventExp29 from "../../assets/images/stock/event/experience/stock-29.png";
import StockEventExp3 from "../../assets/images/stock/event/experience/stock-3.png";
import StockEventExp30 from "../../assets/images/stock/event/experience/stock-30.png";
import StockEventExp31 from "../../assets/images/stock/event/experience/stock-31.png";
import StockEventExp32 from "../../assets/images/stock/event/experience/stock-32.png";
import StockEventExp33 from "../../assets/images/stock/event/experience/stock-33.png";
import StockEventExp34 from "../../assets/images/stock/event/experience/stock-34.png";
import StockEventExp35 from "../../assets/images/stock/event/experience/stock-35.png";
import StockEventExp36 from "../../assets/images/stock/event/experience/stock-36.png";
import StockEventExp37 from "../../assets/images/stock/event/experience/stock-37.png";
import StockEventExp38 from "../../assets/images/stock/event/experience/stock-38.png";
import StockEventExp39 from "../../assets/images/stock/event/experience/stock-39.png";
import StockEventExp4 from "../../assets/images/stock/event/experience/stock-4.png";
import StockEventExp5 from "../../assets/images/stock/event/experience/stock-5.png";
import StockEventExp6 from "../../assets/images/stock/event/experience/stock-6.png";
import StockEventExp7 from "../../assets/images/stock/event/experience/stock-7.png";
import StockEventExp8 from "../../assets/images/stock/event/experience/stock-8.png";
import StockEventExp9 from "../../assets/images/stock/event/experience/stock-9.png";
// Import event/shopping stocks
import StockEventShop1 from "../../assets/images/stock/event/shopping/stock-1.png";
import StockEventShop10 from "../../assets/images/stock/event/shopping/stock-10.png";
import StockEventShop11 from "../../assets/images/stock/event/shopping/stock-11.png";
import StockEventShop12 from "../../assets/images/stock/event/shopping/stock-12.png";
import StockEventShop13 from "../../assets/images/stock/event/shopping/stock-13.png";
import StockEventShop14 from "../../assets/images/stock/event/shopping/stock-14.png";
import StockEventShop15 from "../../assets/images/stock/event/shopping/stock-15.png";
import StockEventShop16 from "../../assets/images/stock/event/shopping/stock-16.png";
import StockEventShop17 from "../../assets/images/stock/event/shopping/stock-17.png";
import StockEventShop18 from "../../assets/images/stock/event/shopping/stock-18.png";
import StockEventShop19 from "../../assets/images/stock/event/shopping/stock-19.png";
import StockEventShop2 from "../../assets/images/stock/event/shopping/stock-2.png";
import StockEventShop20 from "../../assets/images/stock/event/shopping/stock-20.png";
import StockEventShop21 from "../../assets/images/stock/event/shopping/stock-21.png";
import StockEventShop22 from "../../assets/images/stock/event/shopping/stock-22.png";
import StockEventShop23 from "../../assets/images/stock/event/shopping/stock-23.png";
import StockEventShop3 from "../../assets/images/stock/event/shopping/stock-3.png";
import StockEventShop4 from "../../assets/images/stock/event/shopping/stock-4.png";
import StockEventShop5 from "../../assets/images/stock/event/shopping/stock-5.png";
import StockEventShop6 from "../../assets/images/stock/event/shopping/stock-6.png";
import StockEventShop7 from "../../assets/images/stock/event/shopping/stock-7.png";
import StockEventShop8 from "../../assets/images/stock/event/shopping/stock-8.png";
import StockEventShop9 from "../../assets/images/stock/event/shopping/stock-9.png";
// Import action/eatAndDrink stocks
import StockActionEat1 from "../../assets/images/stock/action/eatDrink/stock-1.png";
import StockActionEat10 from "../../assets/images/stock/action/eatDrink/stock-10.png";
import StockActionEat11 from "../../assets/images/stock/action/eatDrink/stock-11.png";
import StockActionEat12 from "../../assets/images/stock/action/eatDrink/stock-12.png";
import StockActionEat13 from "../../assets/images/stock/action/eatDrink/stock-13.png";
import StockActionEat14 from "../../assets/images/stock/action/eatDrink/stock-14.png";
import StockActionEat15 from "../../assets/images/stock/action/eatDrink/stock-15.png";
import StockActionEat16 from "../../assets/images/stock/action/eatDrink/stock-16.png";
import StockActionEat17 from "../../assets/images/stock/action/eatDrink/stock-17.png";
import StockActionEat2 from "../../assets/images/stock/action/eatDrink/stock-2.png";
import StockActionEat3 from "../../assets/images/stock/action/eatDrink/stock-3.png";
import StockActionEat4 from "../../assets/images/stock/action/eatDrink/stock-4.png";
import StockActionEat5 from "../../assets/images/stock/action/eatDrink/stock-5.png";
import StockActionEat6 from "../../assets/images/stock/action/eatDrink/stock-6.png";
import StockActionEat7 from "../../assets/images/stock/action/eatDrink/stock-7.png";
import StockActionEat8 from "../../assets/images/stock/action/eatDrink/stock-8.png";
import StockActionEat9 from "../../assets/images/stock/action/eatDrink/stock-9.png";
// Import action/experience stocks
import StockActionExp1 from "../../assets/images/stock/action/experience/stock-1.png";
import StockActionExp10 from "../../assets/images/stock/action/experience/stock-10.png";
import StockActionExp11 from "../../assets/images/stock/action/experience/stock-11.png";
import StockActionExp12 from "../../assets/images/stock/action/experience/stock-12.png";
import StockActionExp13 from "../../assets/images/stock/action/experience/stock-13.png";
import StockActionExp14 from "../../assets/images/stock/action/experience/stock-14.png";
import StockActionExp15 from "../../assets/images/stock/action/experience/stock-15.png";
import StockActionExp16 from "../../assets/images/stock/action/experience/stock-16.png";
import StockActionExp17 from "../../assets/images/stock/action/experience/stock-17.png";
import StockActionExp18 from "../../assets/images/stock/action/experience/stock-18.png";
import StockActionExp19 from "../../assets/images/stock/action/experience/stock-19.png";
import StockActionExp2 from "../../assets/images/stock/action/experience/stock-2.png";
import StockActionExp20 from "../../assets/images/stock/action/experience/stock-20.png";
import StockActionExp21 from "../../assets/images/stock/action/experience/stock-21.png";
import StockActionExp22 from "../../assets/images/stock/action/experience/stock-22.png";
import StockActionExp23 from "../../assets/images/stock/action/experience/stock-23.png";
import StockActionExp24 from "../../assets/images/stock/action/experience/stock-24.png";
import StockActionExp25 from "../../assets/images/stock/action/experience/stock-25.png";
import StockActionExp26 from "../../assets/images/stock/action/experience/stock-26.png";
import StockActionExp27 from "../../assets/images/stock/action/experience/stock-27.png";
import StockActionExp28 from "../../assets/images/stock/action/experience/stock-28.png";
import StockActionExp29 from "../../assets/images/stock/action/experience/stock-29.png";
import StockActionExp3 from "../../assets/images/stock/action/experience/stock-3.png";
import StockActionExp30 from "../../assets/images/stock/action/experience/stock-30.png";
import StockActionExp31 from "../../assets/images/stock/action/experience/stock-31.png";
import StockActionExp32 from "../../assets/images/stock/action/experience/stock-32.png";
import StockActionExp33 from "../../assets/images/stock/action/experience/stock-33.png";
import StockActionExp34 from "../../assets/images/stock/action/experience/stock-34.png";
import StockActionExp35 from "../../assets/images/stock/action/experience/stock-35.png";
import StockActionExp36 from "../../assets/images/stock/action/experience/stock-36.png";
import StockActionExp37 from "../../assets/images/stock/action/experience/stock-37.png";
import StockActionExp38 from "../../assets/images/stock/action/experience/stock-38.png";
import StockActionExp4 from "../../assets/images/stock/action/experience/stock-4.png";
import StockActionExp5 from "../../assets/images/stock/action/experience/stock-5.png";
import StockActionExp6 from "../../assets/images/stock/action/experience/stock-6.png";
import StockActionExp7 from "../../assets/images/stock/action/experience/stock-7.png";
import StockActionExp8 from "../../assets/images/stock/action/experience/stock-8.png";
import StockActionExp9 from "../../assets/images/stock/action/experience/stock-9.png";
// Import action/shopping stocks
import {
  FullImageSlideShowComponent,
  generateNotification,
  NotificationTypes,
  ReactImageGalleryItem,
} from "deinestadtliebt-component-library";
import { useTranslation } from "react-i18next";
import StockActionShop1 from "../../assets/images/stock/action/shopping/stock-1.png";
import StockActionShop10 from "../../assets/images/stock/action/shopping/stock-10.png";
import StockActionShop11 from "../../assets/images/stock/action/shopping/stock-11.png";
import StockActionShop12 from "../../assets/images/stock/action/shopping/stock-12.png";
import StockActionShop13 from "../../assets/images/stock/action/shopping/stock-13.png";
import StockActionShop14 from "../../assets/images/stock/action/shopping/stock-14.png";
import StockActionShop15 from "../../assets/images/stock/action/shopping/stock-15.png";
import StockActionShop16 from "../../assets/images/stock/action/shopping/stock-16.png";
import StockActionShop17 from "../../assets/images/stock/action/shopping/stock-17.png";
import StockActionShop18 from "../../assets/images/stock/action/shopping/stock-18.png";
import StockActionShop19 from "../../assets/images/stock/action/shopping/stock-19.png";
import StockActionShop2 from "../../assets/images/stock/action/shopping/stock-2.png";
import StockActionShop20 from "../../assets/images/stock/action/shopping/stock-20.png";
import StockActionShop21 from "../../assets/images/stock/action/shopping/stock-21.png";
import StockActionShop22 from "../../assets/images/stock/action/shopping/stock-22.png";
import StockActionShop23 from "../../assets/images/stock/action/shopping/stock-23.png";
import StockActionShop24 from "../../assets/images/stock/action/shopping/stock-24.png";
import StockActionShop25 from "../../assets/images/stock/action/shopping/stock-25.png";
import StockActionShop26 from "../../assets/images/stock/action/shopping/stock-26.png";
import StockActionShop27 from "../../assets/images/stock/action/shopping/stock-27.png";
import StockActionShop28 from "../../assets/images/stock/action/shopping/stock-28.png";
import StockActionShop29 from "../../assets/images/stock/action/shopping/stock-29.png";
import StockActionShop3 from "../../assets/images/stock/action/shopping/stock-3.png";
import StockActionShop30 from "../../assets/images/stock/action/shopping/stock-30.png";
import StockActionShop31 from "../../assets/images/stock/action/shopping/stock-31.png";
import StockActionShop32 from "../../assets/images/stock/action/shopping/stock-32.png";
import StockActionShop33 from "../../assets/images/stock/action/shopping/stock-33.png";
import StockActionShop4 from "../../assets/images/stock/action/shopping/stock-4.png";
import StockActionShop5 from "../../assets/images/stock/action/shopping/stock-5.png";
import StockActionShop6 from "../../assets/images/stock/action/shopping/stock-6.png";
import StockActionShop7 from "../../assets/images/stock/action/shopping/stock-7.png";
import StockActionShop8 from "../../assets/images/stock/action/shopping/stock-8.png";
import StockActionShop9 from "../../assets/images/stock/action/shopping/stock-9.png";
import { ActivityType } from "../../utils/activity/Activity.types";
import { Category } from "../../utils/user/User.types";

const StockImage: React.FC<StockImageProps> = ({
  setSelectedFile,
  setSelectedPreviewFile,
  showTitle = false,
  category,
  activityType,
}) => {
  const { t } = useTranslation();

  /**
   * Helper to set selected image as file
   * @param src source of the selected image
   */
  const setImageToFile = (src: string): void => {
    cropImageOneOne(src);
    fetch(src)
      .then((response) => response.blob())
      .then(async (blob: Blob) => {
        if (setSelectedPreviewFile) {
          setSelectedPreviewFile(new File([blob], "stock-image.png"));
        }
        generateNotification(
          NotificationTypes.SUCCESS,
          t("notification.title.success.changesSuccessful"),
          t("notification.content.success.stockImage")
        );
      });
  };

  /**
   * Method to determine the correct list of stock-photos to be used in component
   * @returns list of stock-images according to type and category of activity
   */
  const generateCorrectStockImageList = (): ReactImageGalleryItem[] => {
    switch (activityType) {
      case ActivityType.ACTION:
        switch (category) {
          case Category.EAT_DRINK:
            return [
              { original: StockActionEat1 },
              { original: StockActionEat2 },
              { original: StockActionEat3 },
              { original: StockActionEat4 },
              { original: StockActionEat5 },
              { original: StockActionEat6 },
              { original: StockActionEat7 },
              { original: StockActionEat8 },
              { original: StockActionEat9 },
              { original: StockActionEat10 },
              { original: StockActionEat11 },
              { original: StockActionEat12 },
              { original: StockActionEat13 },
              { original: StockActionEat14 },
              { original: StockActionEat15 },
              { original: StockActionEat16 },
              { original: StockActionEat17 },
            ];
          case Category.EXPERIENCE:
            return [
              { original: StockActionExp1 },
              { original: StockActionExp2 },
              { original: StockActionExp3 },
              { original: StockActionExp4 },
              { original: StockActionExp5 },
              { original: StockActionExp6 },
              { original: StockActionExp7 },
              { original: StockActionExp8 },
              { original: StockActionExp9 },
              { original: StockActionExp10 },
              { original: StockActionExp11 },
              { original: StockActionExp12 },
              { original: StockActionExp13 },
              { original: StockActionExp14 },
              { original: StockActionExp15 },
              { original: StockActionExp16 },
              { original: StockActionExp17 },
              { original: StockActionExp18 },
              { original: StockActionExp19 },
              { original: StockActionExp20 },
              { original: StockActionExp21 },
              { original: StockActionExp22 },
              { original: StockActionExp23 },
              { original: StockActionExp24 },
              { original: StockActionExp25 },
              { original: StockActionExp26 },
              { original: StockActionExp27 },
              { original: StockActionExp28 },
              { original: StockActionExp29 },
              { original: StockActionExp30 },
              { original: StockActionExp31 },
              { original: StockActionExp32 },
              { original: StockActionExp33 },
              { original: StockActionExp34 },
              { original: StockActionExp35 },
              { original: StockActionExp36 },
              { original: StockActionExp37 },
              { original: StockActionExp38 },
            ];
          case Category.SHOPPING:
            return [
              { original: StockActionShop1 },
              { original: StockActionShop2 },
              { original: StockActionShop3 },
              { original: StockActionShop4 },
              { original: StockActionShop5 },
              { original: StockActionShop6 },
              { original: StockActionShop7 },
              { original: StockActionShop8 },
              { original: StockActionShop9 },
              { original: StockActionShop10 },
              { original: StockActionShop11 },
              { original: StockActionShop12 },
              { original: StockActionShop13 },
              { original: StockActionShop14 },
              { original: StockActionShop15 },
              { original: StockActionShop16 },
              { original: StockActionShop17 },
              { original: StockActionShop18 },
              { original: StockActionShop19 },
              { original: StockActionShop20 },
              { original: StockActionShop21 },
              { original: StockActionShop22 },
              { original: StockActionShop23 },
              { original: StockActionShop24 },
              { original: StockActionShop25 },
              { original: StockActionShop26 },
              { original: StockActionShop27 },
              { original: StockActionShop28 },
              { original: StockActionShop29 },
              { original: StockActionShop30 },
              { original: StockActionShop31 },
              { original: StockActionShop32 },
              { original: StockActionShop33 },
            ];
          default:
        }
      //eslint-disable-next-line
      case ActivityType.EVENT:
        switch (category) {
          case Category.EAT_DRINK:
            return [
              { original: StockEventEat1 },
              { original: StockEventEat2 },
              { original: StockEventEat3 },
              { original: StockEventEat4 },
              { original: StockEventEat5 },
              { original: StockEventEat6 },
              { original: StockEventEat7 },
              { original: StockEventEat8 },
              { original: StockEventEat9 },
              { original: StockEventEat10 },
              { original: StockEventEat11 },
              { original: StockEventEat12 },
              { original: StockEventEat13 },
              { original: StockEventEat14 },
            ];
          case Category.EXPERIENCE:
            return [
              { original: StockEventExp1 },
              { original: StockEventExp2 },
              { original: StockEventExp3 },
              { original: StockEventExp4 },
              { original: StockEventExp5 },
              { original: StockEventExp6 },
              { original: StockEventExp7 },
              { original: StockEventExp8 },
              { original: StockEventExp9 },
              { original: StockEventExp10 },
              { original: StockEventExp11 },
              { original: StockEventExp12 },
              { original: StockEventExp13 },
              { original: StockEventExp14 },
              { original: StockEventExp15 },
              { original: StockEventExp16 },
              { original: StockEventExp17 },
              { original: StockEventExp18 },
              { original: StockEventExp19 },
              { original: StockEventExp20 },
              { original: StockEventExp21 },
              { original: StockEventExp22 },
              { original: StockEventExp23 },
              { original: StockEventExp24 },
              { original: StockEventExp25 },
              { original: StockEventExp26 },
              { original: StockEventExp27 },
              { original: StockEventExp28 },
              { original: StockEventExp29 },
              { original: StockEventExp30 },
              { original: StockEventExp31 },
              { original: StockEventExp32 },
              { original: StockEventExp33 },
              { original: StockEventExp34 },
              { original: StockEventExp35 },
              { original: StockEventExp36 },
              { original: StockEventExp37 },
              { original: StockEventExp38 },
              { original: StockEventExp39 },
            ];
          case Category.SHOPPING:
            return [
              { original: StockEventShop1 },
              { original: StockEventShop2 },
              { original: StockEventShop3 },
              { original: StockEventShop4 },
              { original: StockEventShop5 },
              { original: StockEventShop6 },
              { original: StockEventShop7 },
              { original: StockEventShop8 },
              { original: StockEventShop9 },
              { original: StockEventShop10 },
              { original: StockEventShop11 },
              { original: StockEventShop12 },
              { original: StockEventShop13 },
              { original: StockEventShop14 },
              { original: StockEventShop15 },
              { original: StockEventShop16 },
              { original: StockEventShop17 },
              { original: StockEventShop18 },
              { original: StockEventShop19 },
              { original: StockEventShop20 },
              { original: StockEventShop21 },
              { original: StockEventShop22 },
              { original: StockEventShop23 },
            ];
          default:
        }
      //eslint-disable-next-line
      default:
        return [
          {
            original: StockOne,
          },
          {
            original: StockTwo,
          },
          {
            original: StockThree,
          },
          {
            original: StockFour,
          },
        ];
    }
  };
  /**
   * Helper to convert StockImage to 1:1 ratio
   * @param src of the image to crop
   */
  const cropImageOneOne = (src: string): void => {
    const image: HTMLImageElement = document.createElement("img");
    image.src = src;
    image.onload = () => {
      const canvas = document.createElement("canvas");
      const context = canvas?.getContext("2d");
      canvas.height = image.naturalHeight;
      canvas.width = image.naturalHeight;
      context?.drawImage(
        image,
        (image.naturalWidth - image.naturalHeight) / 2,
        0,
        image.naturalHeight,
        image.naturalHeight,
        0,
        0,
        canvas.height,
        canvas.height
      );
      context?.save();
      context?.restore();
      canvas.toBlob((blob) => {
        setSelectedFile?.(new File([blob!], "stockRatio1To1.png"));
      }, "image/png");
    };
  };
  return (
    <>
      <p>{t("stockImage.title")}</p>
      <div className="stock-image-slideshow-wrapper">
        <FullImageSlideShowComponent
          height={200}
          onImageClick={setImageToFile}
          items={generateCorrectStockImageList()}
        />
      </div>
    </>
  );
};

export default StockImage;
