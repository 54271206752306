import { InputComponent } from "deinestadtliebt-component-library";
import { useTranslation } from "react-i18next";
import NewOpeningTimeBox from "../newopeningtimebox/NewOpeningTimeBox";
import ProviderImageEdit from "../providerimageedit/ProviderImageEdit";
import WysiwygComponent from "../wysiwyg/WysiwygComponent";
import "./ProviderProfileConfig.styles.scss";
import { ProviderProfileConfigProps } from "./ProviderProfileConfig.types";
import { useContext, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../pages/App";
import { UserRole } from "../../utils/user/User.types";

const ProviderProfileConfig: React.FC<ProviderProfileConfigProps> = ({
  provider,
  setProvider,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { hash: locationHash } = useLocation();
  const specialOpeningTimesElement = useRef<HTMLParagraphElement>(null);
  const { user } = useContext(UserContext);

  /**
   *  This effect handles the scroll behavior when the user
   *  was redirected to the special opening times.
   *  It shows the special opening times centered on the viewport.
   */
  useEffect(() => {
    if (
      specialOpeningTimesElement.current &&
      locationHash === "#specialOpeningTimes"
    ) {
      specialOpeningTimesElement.current.scrollIntoView({ block: "center" });
    }
  }, [locationHash, specialOpeningTimesElement]);

  return (
    <div className="provider-config--body">
      <div className="provider-config--body-base">
        <div className="provider-config--body-base-left">
          <div className="provider-config--body-base-left--name">
            <InputComponent
              disabled={isLoading}
              isFrameless
              fontSize="1.7em"
              placeholder={t("dashboard.provider.config.placeholder.name")}
              value={provider.name}
              onChange={(updateProviderName) =>
                setProvider({
                  ...provider,
                  name: updateProviderName,
                })
              }
            />
          </div>

          <div className="provider-config--body-base-left--location">
            <p>{t("dashboard.provider.config.headers.address")}</p>
            <div className="provider-config--body-base-left--location-street">
              <InputComponent
                disabled={isLoading}
                isFrameless
                placeholder={t(
                  "dashboard.provider.config.placeholder.locationStreet"
                )}
                value={provider.location.street}
                onChange={(updateProviderStreet) =>
                  setProvider({
                    ...provider,
                    location: {
                      ...provider.location,
                      street: updateProviderStreet,
                    },
                  })
                }
              />
            </div>
            <div className="provider-config--body-base-left--location-zip">
              <InputComponent
                disabled={isLoading}
                isFrameless
                placeholder={t(
                  "dashboard.provider.config.placeholder.locationZip"
                )}
                value={provider.location.zipCode}
                onChange={(updateProviderZipCode) =>
                  setProvider({
                    ...provider,
                    location: {
                      ...provider.location,
                      zipCode: updateProviderZipCode,
                    },
                  })
                }
              />
            </div>
            <div className="provider-config--body-base-left--location-city">
              <InputComponent
                disabled={isLoading}
                isFrameless
                placeholder={t(
                  "dashboard.provider.config.placeholder.locationCity"
                )}
                value={provider.location.city}
                onChange={(updateProviderCity) =>
                  setProvider({
                    ...provider,
                    location: {
                      ...provider.location,
                      city: updateProviderCity,
                    },
                  })
                }
              />
            </div>
          </div>
          <div className="provider-config--body-base-left--description">
            <p>{t("dashboard.provider.config.headers.description")}</p>
            <InputComponent
              disabled={isLoading}
              isFrameless
              type="multiLine"
              signCap={500}
              placeholder={t(
                "dashboard.provider.config.placeholder.description"
              )}
              value={provider.description || ""}
              backgroundColor="#f4f4f4"
              onChange={(updateProviderDescription) =>
                setProvider({
                  ...provider,
                  description: updateProviderDescription,
                })
              }
            />
          </div>
        </div>

        <ProviderImageEdit provider={provider} setProvider={setProvider} />
      </div>
      <div className="provider-config--body-base-left--displayDescription">
        <WysiwygComponent
          providerId={provider.id!}
          disabled={isLoading}
          isFrameless
          signCap={3000}
          placeholder={t(
            "dashboard.provider.config.placeholder.displayDescription"
          )}
          value={`<div>${provider.displayDescription || ""}</div>`}
          backgroundColor="#f4f4f4"
          onChange={(updateProviderDescription) =>
            setProvider({
              ...provider,
              displayDescription: updateProviderDescription,
            })
          }
        />
      </div>
      <div className="provider-config--body-detail">
        <div className="provider-config--body-detail--openingTimes">
          <NewOpeningTimeBox
            timeCollection={provider.openingHours?.standard}
            setTimeCollection={(standard) => {
              setProvider({
                ...provider,
                openingHours: { ...provider.openingHours, standard },
              });
            }}
            standard
            showSpecialOpeningButton={user && user.role === UserRole.PROVIDER}
          />
        </div>
        <div className="provider-config--body-detail--contact">
          <div className="provider-config--body-detail--contact-phoneNumber">
            <p>{t("dashboard.provider.config.headers.phone")}</p>
            <InputComponent
              disabled={isLoading}
              isFrameless
              fontSize="1.0em"
              placeholder={t("dashboard.provider.config.placeholder.phone")}
              value={provider.publicPhoneNumber || ""}
              onChange={(updateProviderPhoneNumber) =>
                setProvider({
                  ...provider,
                  publicPhoneNumber: updateProviderPhoneNumber,
                })
              }
            />
            <div className="provider-config--body-detail--contact-socialMedia-whatsapp">
              <InputComponent
                disabled={isLoading}
                isFrameless
                fontSize="1.0em"
                placeholder={t(
                  "dashboard.provider.config.placeholder.whatsapp"
                )}
                value={provider.socialMedia.whatsapp || ""}
                onChange={(updateProviderWhatsapp) =>
                  setProvider({
                    ...provider,
                    socialMedia: {
                      ...provider.socialMedia,
                      whatsapp: updateProviderWhatsapp,
                    },
                  })
                }
              />
            </div>
          </div>

          <div className="provider-config--body-detail--contact-publicMail">
            <p>{t("dashboard.provider.config.headers.publicMail")}</p>
            <InputComponent
              disabled={isLoading}
              isFrameless
              fontSize="1.0em"
              placeholder={t(
                "dashboard.provider.config.placeholder.publicMail"
              )}
              value={provider.publicMail}
              onChange={(updateProviderPublicMail) =>
                setProvider({
                  ...provider,
                  publicMail: updateProviderPublicMail,
                })
              }
            />
          </div>
          <div className="provider-config--body-detail--contact-phoneNumber">
            <p>{t("dashboard.provider.config.headers.website")}</p>
            <InputComponent
              disabled={isLoading}
              isFrameless
              type="url"
              fontSize="1.0em"
              placeholder={t("dashboard.provider.config.placeholder.website")}
              value={provider.publicWebsite || ""}
              onChange={(updatedProviderWebsite) =>
                setProvider({
                  ...provider,
                  publicWebsite: updatedProviderWebsite,
                })
              }
            />
          </div>
          <div className="provider-config--body-detail--contact-socialMedia">
            <p>{t("dashboard.provider.config.headers.socialMedia")}</p>
            <div className="provider-config--body-detail--contact-socialMedia-facebook">
              <InputComponent
                disabled={isLoading}
                isFrameless
                fontSize="1.0em"
                placeholder={t(
                  "dashboard.provider.config.placeholder.facebook"
                )}
                value={provider.socialMedia.facebook || ""}
                onChange={(updateProviderFacebook) =>
                  setProvider({
                    ...provider,
                    socialMedia: {
                      ...provider.socialMedia,
                      facebook: updateProviderFacebook,
                    },
                  })
                }
              />
            </div>
            <div className="provider-config--body-detail--contact-socialMedia-instagram">
              <InputComponent
                disabled={isLoading}
                isFrameless
                fontSize="1.0em"
                placeholder={t(
                  "dashboard.provider.config.placeholder.instagram"
                )}
                value={provider.socialMedia.instagram || ""}
                onChange={(updateProviderInstagram) =>
                  setProvider({
                    ...provider,
                    socialMedia: {
                      ...provider.socialMedia,
                      instagram: updateProviderInstagram,
                    },
                  })
                }
              />
            </div>
            <div className="provider-config--body-detail--contact-socialMedia-linkedIn">
              <InputComponent
                disabled={isLoading}
                isFrameless
                fontSize="1.0em"
                placeholder={t(
                  "dashboard.provider.config.placeholder.linkedIn"
                )}
                value={provider.socialMedia.linkedIn || ""}
                onChange={(updateProviderLinkedIn) =>
                  setProvider({
                    ...provider,
                    socialMedia: {
                      ...provider.socialMedia,
                      linkedIn: updateProviderLinkedIn,
                    },
                  })
                }
              />
            </div>
            <div className="provider-config--body-detail--contact-socialMedia-youtube">
              <InputComponent
                disabled={isLoading}
                isFrameless
                fontSize="1.0em"
                placeholder={t("dashboard.provider.config.placeholder.youtube")}
                value={provider.socialMedia.youtube || ""}
                onChange={(updateProviderYoutube) =>
                  setProvider({
                    ...provider,
                    socialMedia: {
                      ...provider.socialMedia,
                      youtube: updateProviderYoutube,
                    },
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderProfileConfig;
