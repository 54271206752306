import { AxiosInstance } from "axios";
import {
  generateNotification,
  NotificationTypes,
  TableRow,
} from "deinestadtliebt-component-library";
import { KeycloakInstance } from "keycloak-js";
import { FileType } from "../appConfig/config.types";
import { validateMail, validateZipCode } from "../InputUtils";
import {
  Admin,
  createEmptyProviderFileCreationRequest,
  createEmptyProviderFileDeleteRequest,
  CustomTag,
  Provider,
  ProviderFileRequest,
  ProviderCustomTagCreationRequest,
  User,
  UserRole,
  Location,
  PriceItem,
  MenuItem,
  CountResponse,
  createEmptyCountResponse,
  SpecialNote,
} from "./User.types";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import { ReactComponent as CancelIcon } from "../../assets/icons/cancel.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";

import { ReactComponent as FacebookIcon } from "../../assets/icons/facebook.svg";
import { ReactComponent as InstagramIcon } from "../../assets/icons/instagram.svg";
import { ReactComponent as WhatsappIcon } from "../../assets/icons/whatsapp.svg";
import { ReactComponent as YoutubeIcon } from "../../assets/icons/youtube.svg";
import { ReactComponent as LinkedInIcon } from "../../assets/icons/linkedin.svg";
import i18n from "../../i18n";

/**
 * API METHOD - to fetch current amount of actions and events for
 * a provider by id
 * @param axios network instance
 * @param providerId id to fetch data for
 * @returns found amount or empty amount
 */
export const fetchCountForActionsAndEvents = async (
  axios: AxiosInstance,
  providerId: string
): Promise<CountResponse> => {
  return axios
    .post("/event/count/", providerId)
    .then((countResp) => countResp.data)
    .catch((exc) => {
      console.error(
        "Error during amount fetching for provider actions/events!",
        exc
      );
      return createEmptyCountResponse();
    });
};

/**
 * API METHOD - to delete provider on the backend
 *
 * @param axios
 * @param providerId of name
 * @returns boolean if 200 er is succeed
 */
export const deleteProviderFromBackend = async (
  axios: AxiosInstance,
  providerId: string
): Promise<boolean> => {
  return axios
    .post("/user/provider/delete/", providerId)
    .then((userResp) => userResp.status === 200)
    .catch((exc) => {
      console.error("Error during provider deletion!", exc);
      return false;
    });
};

/**
 * API METHOD - to get provider via slugId
 *
 * @param axios axios-Instance
 * @param providerSlugId slugId of requested provider
 * @returns provider with the given slugId if existing
 */
export const getProviderBySlugId = async (
  axios: AxiosInstance,
  providerSlugId: string
): Promise<Provider> => {
  return axios
    .get("/user/provider/slugid/", { params: { id: providerSlugId } })
    .then((userResp) => userResp.data)
    .catch((exc) => {
      console.error("Error during provider fetching by slugId!", exc);
      return null;
    });
};

/**
 * API METHOD - fetches all provider from server
 *
 * @param axios
 * @returns array of all provider or empty array
 */
export const fetchAllProvider = async (
  axios: AxiosInstance
): Promise<Provider[]> => {
  return axios
    .get("/user/provider/all/admin/")
    .then((userResp) => userResp.data)
    .catch((exc) => {
      console.error("Error during all provider fetching!", exc);
      return [];
    });
};

/**
 * Helper to retrieve the current serviceId of the logged in user. It is the database id
 *
 * @param keycloak
 * @returns service id as string and role of user
 */
export const fetchKeycloakServiceId = async (
  keycloak: KeycloakInstance
): Promise<{ serviceId: string; role: UserRole }> => {
  return keycloak.loadUserProfile().then((userInfo: any) => {
    let roleServiceIdObject: { serviceId: string; role: UserRole } = {
      role: UserRole.NONE,
      serviceId: userInfo.attributes.serviceId[0],
    };
    if (keycloak.hasRealmRole(UserRole.ADMIN))
      roleServiceIdObject.role = UserRole.ADMIN;
    if (keycloak.hasRealmRole(UserRole.PROVIDER))
      roleServiceIdObject.role = UserRole.PROVIDER;
    return roleServiceIdObject;
  });
};

/**
 * Compares two given strings and returns boolean that represents whether the two string are matching or not.
 *
 * @param value1 string with value of new password
 * @param value2 string with repetition of new password value
 * @returns boolean whether passwords are matching or not
 * @tested
 */
export const stringsMatch = (value1?: string, value2?: string): boolean => {
  if (!value1 || !value2) return false;
  if (value1 !== value2 || !value1) return false;
  if (value1 === value2) {
    return true;
  }
  return false;
};

/**
 * API Method - to fetch the right user to user role
 *
 * @param axios
 * @param serviceId
 * @param role
 * @returns Admin, Provider or null
 */
export const fetchUserById = async (
  axios: AxiosInstance,
  serviceId: string,
  role: UserRole
): Promise<Admin | Provider | null> => {
  let usedUrl: string = "/user";
  switch (role) {
    case UserRole.ADMIN:
      usedUrl = `${usedUrl}/admin/id/`;
      break;
    case UserRole.PROVIDER:
      usedUrl = `${usedUrl}/provider/id/`;
      break;

    default:
      console.error("No valid user role found", role);
      return null;
  }

  return axios
    .get(usedUrl, { params: { id: serviceId } })
    .then((userResp) => userResp.data)
    .catch((exc) => {
      console.error("Error during user fetching!", exc);
      return null;
    });
};

/**
 * Helper to determine if the provider is ready to be updated
 *
 * @param updatedProvider provider object
 * @returns if provider is valid for creation
 * @tested
 */
export const isProviderReadyForUpdate = (
  updatedProvider: Provider
): boolean => {
  if (!updatedProvider) return false;
  return (
    validateMail(updatedProvider.mail) &&
    updatedProvider.category &&
    !!updatedProvider.name &&
    !!updatedProvider.location.street &&
    !!updatedProvider.location.city &&
    !!updatedProvider.location.zipCode &&
    validateZipCode(updatedProvider.location.zipCode) &&
    updatedProvider.username.length > 0 &&
    updatedProvider.description.length > 0 &&
    updatedProvider.displayDescription.length > 0
  );
};

/**
 * Helper to determine if the provider is ready to be created
 *
 * @param newProvider provider object
 * @returns if provider is valid for creation
 * @tested
 */
export const isProviderReadyForCreation = (newProvider: Provider): boolean => {
  if (!newProvider) return false;
  return (
    validateMail(newProvider.mail) &&
    !!newProvider.category &&
    !!newProvider.name &&
    !!newProvider.location.street &&
    !!newProvider.location.city &&
    !!newProvider.location.zipCode &&
    validateZipCode(newProvider.location.zipCode)
  );
};

/**
 * sends a post request to the update-menu-service with a provider-object as body in an attempt to save changed provider-data
 * @param axios axios instance
 * @param updatedProvider provider-Object with new data
 * @returns boolean that determines whether or not the request was successfull (Status 200)
 */
export const updateProviderHolidayData = async (
  axios: AxiosInstance,
  updatedProvider: Provider
): Promise<boolean> => {
  return axios
    .post("/user/provider/holiday/update/", updatedProvider)
    .then((userResp) => userResp.status === 200)
    .catch((exc) => {
      console.error("Error during provider holiday updating!", exc);
      return false;
    });
};

/**
 * sends a post request to the update-menu-service with a provider-object as body in an attempt to save changed provider-data
 * @param axios axios instance
 * @param updatedProvider provider-Object with new data
 * @returns boolean that determines whether or not the request was successfull (Status 200)
 */
export const updateProviderMenuData = async (
  axios: AxiosInstance,
  updatedProvider: Provider
): Promise<boolean> => {
  return axios
    .post("/user/provider/menu/update/", updatedProvider)
    .then((userResp) => userResp.status === 200)
    .catch((exc) => {
      console.error("Error during provider menu updating!", exc);
      return false;
    });
};

/**
 * sends a post request to the update-lunch-service with a provider-object as body in an attempt to save changed provider-data
 * @param axios axios instance
 * @param updatedProvider provider-Object with new data
 * @returns boolean that determines whether or not the request was successfull (Status 200)
 */
export const updateProviderLunchData = async (
  axios: AxiosInstance,
  updatedProvider: Provider
): Promise<boolean> => {
  return axios
    .post("/user/provider/lunch/update/", updatedProvider)
    .then((userResp) => userResp.status === 200)
    .catch((exc) => {
      console.error("Error during provider lunch updating!", exc);
      return false;
    });
};

/**
 * sends a post request to the update-lunch-service with a provider-object as body in an attempt to save changed provider-data
 * @param axios axios instance
 * @param updatedProvider provider-Object with new data
 * @returns boolean that determines whether or not the request was successfull (Status 200)
 */
export const updateProviderLocationData = async (
  axios: AxiosInstance,
  updatedProvider: Provider,
  type: "event" | "booking"
): Promise<boolean> => {
  let usedEndpoint =
    type === "event"
      ? "/user/provider/event/update/location/"
      : "/user/provider/booking/update/location/";
  return axios
    .post(usedEndpoint, updatedProvider)
    .then((userResp) => userResp.status === 200)
    .catch((exc) => {
      console.error(
        `Error during provider location for ${type} updating!`,
        exc
      );
      return false;
    });
};

/**
 * sends a post request to the update-price-service with a provider-object as body in an attempt to save changed provider-data
 * @param axios axios instance
 * @param updatedProvider provider-Object with new data
 * @returns boolean that determines whether or not the request was successfull (Status 200)
 */
export const updateProviderPriceData = async (
  axios: AxiosInstance,
  updatedProvider: Provider
): Promise<boolean> => {
  return axios
    .post("/user/provider/price/update/", updatedProvider)
    .then((userResp) => userResp.status === 200)
    .catch((exc) => {
      console.error("Error during provider price updating!", exc);
      return false;
    });
};

/**
 * sends a post request to the update-price-service with a provider-object as body in an attempt to save changed provider-data
 * @param axios axios instance
 * @param updatedProvider provider-Object with new data
 * @returns boolean that determines whether or not the request was successfull (Status 200)
 */
export const updateProviderCustomPriceDataForActivity = async (
  axios: AxiosInstance,
  updatedProvider: Provider
): Promise<boolean> => {
  return axios
    .post("/user/provider/price/activity/update/", updatedProvider)
    .then((userResp) => userResp.status === 200)
    .catch((exc) => {
      console.error(
        "Error during provider custom price data for activity updating!",
        exc
      );
      return false;
    });
};

/**
 * sends a post request to the update-profile-service with a provider-object as body in an attempt to save changed provider-data
 * @param axios axios instance
 * @param updatedProvider provider-Object with new data
 * @returns boolean that determines whether or not the request was successfull (Status 200)
 */
export const updateProviderProfileData = async (
  axios: AxiosInstance,
  updatedProvider: Provider
): Promise<boolean> => {
  return axios
    .post("/user/provider/update/profile/", updatedProvider)
    .then((userResp) => userResp.status === 200)
    .catch((exc) => {
      console.error("Error during provider updating!", exc);
      return false;
    });
};

/**
 * sends a post request to the update-profile-service with the intention of updating the username, mail-address and password if set
 * @param axios axios instance
 * @param updatedProvider provider-Object with new login-credentail-data
 * @returns boolean that determines whether or not the request was successfull (Status 200)
 */
export const updateProfileLoginCredentials = async (
  axios: AxiosInstance,
  user: Admin | Provider | User
): Promise<boolean> => {
  return axios
    .post(
      user.role === UserRole.PROVIDER
        ? "/user/provider/update/"
        : "/user/admin/update/",
      user
    )
    .then((userResp) => userResp.status === 200)
    .catch((exc) => {
      console.error("Error during provider/admin updating!", exc);
      return false;
    });
};

/**
 * Assigns lat and lon values for given provider object by calling openstreetmap service with lat and lng values of given provider
 * @param axios axios Instance
 * @param updatedProvider provider object with new data
 * @returns provider with newly set lat and lng values
 */
export const assignCoordinatesForAddress = async (
  axios: AxiosInstance,
  updatedProvider: Provider
): Promise<Provider> => {
  return getCoordinatesForLocation(axios, updatedProvider.location).then(
    (data) => {
      updatedProvider.location.lng = data[0];
      updatedProvider.location.lat = data[1];
      return updatedProvider;
    }
  );
};

/**
 * API METHOD - to fetch current coords for location
 * @param axios network instance
 * @param location to fetch the coords for
 * @returns found coords or [0,0]
 */
export const getCoordinatesForLocation = (
  axios: AxiosInstance,
  location: Location
): Promise<[lng: number, lat: number]> => {
  return axios
    .get(
      `https://nominatim.openstreetmap.org/search?` +
        `q=${location.street}+${location.zipCode}` +
        `+${location.city}&format=json`
    )
    .then((data) => [
      parseFloat(data.data[0] !== undefined ? data.data[0].lon : 0),
      parseFloat(data.data[0] !== undefined ? data.data[0].lat : 0),
    ]);
};

/**
 * API METHOD - fetch name for a provider by id
 * @param axios network instance
 * @param providerId id of the provider
 * @returns found name or id in case of error
 */
export const getProvidername = async (
  axios: AxiosInstance,
  providerId: string
): Promise<string> => {
  return axios
    .get("/user/provider/name/", { params: { id: providerId } })
    .then((userResp) => (userResp.data as Provider).name)
    .catch((exc) => {
      console.error("Error during fetching simple name for provider!", exc);
      return providerId;
    });
};

/**
 * API METHOD - fetch name for a provider by id
 * @param axios network instance
 * @param providerSlugId id of the provider
 * @returns found name or id in case of error
 */
export const getProvidernameBySlugId = async (
  axios: AxiosInstance,
  providerSlugId: string
): Promise<string> => {
  return axios
    .get("/user/provider/name/slugid/", { params: { id: providerSlugId } })
    .then((userResp) => (userResp.data as Provider).name)
    .catch((exc) => {
      console.error("Error during fetching simple name for provider!", exc);
      return providerSlugId;
    });
};

/**
 * Checks if any socialMedia link has a value and returns true when at least one property has a value
 * @returns boolean @Tested
 */
export const checkSocialMediaStatus = (provider: Provider): boolean => {
  for (let media in provider.socialMedia) {
    if (provider.socialMedia[media]) return true;
  }
  return false;
};

/**
 * API METHOD - to store a new Provider image on server
 * @param axios network instance
 * @param providerId of provider to update
 * @param providerImage file data as multipart
 * @returns provider to get new id of file item
 */
export const createProviderFile = async (
  axios: AxiosInstance,
  providerId: string,
  providerImage: File,
  type: FileType
): Promise<Provider> => {
  let uploadFormData = new FormData();
  const creationRequestBlob = new Blob(
    [JSON.stringify(createEmptyProviderFileCreationRequest(providerId, type))],
    {
      type: "application/json",
    }
  );
  uploadFormData.append("creationRequest", creationRequestBlob);
  uploadFormData.append("fileData", providerImage);
  return axios
    .post("/user/provider/file/", uploadFormData)
    .then((imageUpload) => imageUpload.data)
    .catch((exc) => {
      console.error("Error during creating new provider file item!", exc);
      return null;
    });
};

/**
 * API METHOD - to upload a main image for a provider with initial image crop
 * @param axios network instance
 * @param providerId id of provider
 * @param providerImage new file data
 * @returns newly updated provider from service for file item
 */
export const updateProviderMainImage = async (
  axios: AxiosInstance,
  providerId: string,
  providerImage: File
): Promise<Provider> => {
  let uploadFormData = new FormData();
  const providerIdBlob = new Blob([providerId], {
    type: "application/json",
  });
  uploadFormData.append("providerId", providerIdBlob);
  uploadFormData.append("fileData", providerImage);
  return axios
    .post("/user/provider/file/update/main/", uploadFormData)
    .then((imageUpload) => imageUpload.data)
    .catch((exc) => {
      console.error(
        "Error during updating provider main image file item!",
        exc
      );
      return null;
    });
};

/**
 * API METHOD - to delete a file item with file on server
 * @param axios network instance
 * @param providerId of provider to update
 * @param filename of file to delete
 * @param type file type to delete
 * @returns success if statuscode is 200, the success is a boolean
 */
export const deleteProviderFile = async (
  axios: AxiosInstance,
  providerId: string,
  filename: string,
  type: FileType
): Promise<boolean> => {
  let deleteRequest: ProviderFileRequest = createEmptyProviderFileDeleteRequest(
    providerId,
    filename,
    type
  );
  return axios
    .post("/user/provider/file/delete/", deleteRequest)
    .then((imageDeletion) => imageDeletion.status === 200)
    .catch((exc) => {
      console.error("Error during creating new provider file item!", exc);
      return false;
    });
};

/**
 * API METHOD - to update an id of a {@link Licence} for a {@link Provider}
 * @param axios network instance
 * @param provider data of the provider
 * @returns true if update is successful with status code 200, false otherwise
 */
export const updateLicenceForProvider = async (
  axios: AxiosInstance,
  provider: Provider
): Promise<boolean> => {
  return axios
    .post("/user/provider/licence/", provider)
    .then((licResp) => licResp.status === 200)
    .catch((exc) => {
      generateNotification(
        NotificationTypes.ERROR,
        i18n.t("notification.title.error.error"),
        i18n.t("notification.content.error.licenceIdUpdate")
      );
      console.error("Error during updating licence id for provider!", exc);
      return false;
    });
};

/**
 * API METHOD - to update the main image of a provider
 * @param axios network instance
 * @param provider updated provider with file list
 * @returns success if statuscode is 200, the success is a boolean
 */
export const updateProviderImage = async (
  axios: AxiosInstance,
  provider: Provider
): Promise<boolean> => {
  return axios
    .post("/user/provider/file/update/", provider)
    .then((imageUpdate) => imageUpdate.status === 200)
    .catch((exc) => {
      console.error("Error during update provider file item!", exc);
      return false;
    });
};

/**
 * Helper to determine level of completion of given providerProfile
 * @param provider provider to be checked
 * @returns percentage of completion
 * @tested
 */
export const getProviderPercentage = (provider: Provider): number => {
  // TODO Speisekarte oder Preisliste
  let returnValue: number = 0;
  if (provider.description) returnValue += 100 / 6;
  if (provider.name) returnValue += 100 / 6;
  if (provider.username) returnValue += 100 / 6;
  if (provider.displayDescription) returnValue += 100 / 6;
  if (provider.publicMail || provider.phoneNumber) returnValue += 100 / 6;
  if (
    provider.location.city &&
    provider.location.street &&
    provider.location.zipCode
  )
    returnValue += 100 / 6;
  return Math.round(returnValue);
};

/**
 * Helper to save file on device
 * @param blob
 * @param fileName
 */
export const saveBlobInFile = (blob: Blob, fileName: string): void => {
  const Url = URL.createObjectURL(blob);
  const createdATagElement = document.createElement("a");
  createdATagElement.setAttribute("download", fileName);
  createdATagElement.href = Url;
  document.body.appendChild(createdATagElement);
  createdATagElement.click();
  createdATagElement.remove();
};

/**
 * API METHOD - to download a file from the server
 * @param fileName ofthe file
 * @param type file type
 * @param axios
 * @returns file binary or null
 */
export const getFile = (
  fileName: string,
  type: FileType,
  axios: AxiosInstance
): Promise<Blob> => {
  return axios
    .get("/config/file/", {
      params: { fileName: fileName, type: type },
      responseType: "blob",
    })
    .then((FileResponse) => FileResponse.data)
    .catch((exc) => {
      console.error("Error during fetching demanded file!", exc);
      return null;
    });
};

/**
 * API METHOD - to create a new customtag on the backend
 * with in app message for an admin
 *
 * @param axios network instance
 * @param customTag to add
 * @param providerId to add for
 * @returns if the custom tag could be created for the provider or not
 */
export const createCustomTagOnBackend = async (
  axios: AxiosInstance,
  customTag: CustomTag,
  providerId: string
): Promise<boolean> => {
  const request: ProviderCustomTagCreationRequest = {
    providerId: providerId,
    tag: customTag.text,
  };
  return axios
    .post("/user/provider/customtag/add/", request)
    .then((customTagResp) => customTagResp.status === 201)
    .catch((exc) => {
      console.error("Error during creating of a custom tag!", exc);
      return false;
    });
};

/**
 * API METHOD - to generate a custom tag on the server
 *
 * @param axios network instance
 * @param provider updated provider
 * @returns true if status code 200 was recieved false otherwise
 */
export const updateCustomTagOnBackend = async (
  axios: AxiosInstance,
  provider: Provider
): Promise<boolean> => {
  return axios
    .post("/user/provider/customtag/update/", provider)
    .then((customTagResp) => customTagResp.status === 200)
    .catch((exc) => {
      console.error("Error during updating of a custom tag!", exc);
      return false;
    });
};

/**
 * Helper to generate table rows for price items
 * @param priceItems list of price items for provider
 * @param onDelete function which deletes the current row
 * @param onDetail function to open a detail popup for update
 * @returns generated table rows
 * @tested
 */
export const generatePriceItemsTableRows = (
  priceItems: PriceItem[],
  onDelete: Function,
  onDetail: Function
): TableRow[] => {
  let currentRows: TableRow[] = [];
  priceItems.forEach((price) => {
    currentRows.push({
      content: [
        price.name,
        `€ ${price.price.toFixed(2)}`,
        <div className="flex-it-center">
          <div
            className={["trash-wrapper", "little-bit-margin-right"].join(" ")}
            onClick={() => onDetail(price)}
          >
            <EditIcon />
          </div>
          <div
            className="trash-wrapper"
            onClick={() => onDelete("delete", undefined, price.id)}
          >
            <TrashIcon />
          </div>
        </div>,
      ],
      id: price.id,
    });
  });
  return currentRows;
};

/**
 * Helper to generate table rows for menu items
 * @param menuItems list of menu items for provider
 * @param onDelete function which deletes the current row
 * @param onDetail function which shows details of menuItems
 * @returns generated table rows
 * @tested
 */
export const generateMenuItemsTableRows = (
  menuItems: MenuItem[],
  onDelete: Function,
  onDetail: Function
): TableRow[] => {
  let currentRows: TableRow[] = [];
  menuItems.forEach((menu) => {
    currentRows.push({
      content: [
        menu.name,
        `€ ${menu.price.toFixed(2)}`,
        <div className="flex-it-center">
          <div
            className={["trash-wrapper", "little-bit-margin-right"].join(" ")}
            onClick={() => onDetail(menu)}
          >
            <EditIcon />
          </div>
          <div
            className="trash-wrapper"
            onClick={() => onDelete("delete", menu.id)}
          >
            <TrashIcon />
          </div>
        </div>,
      ],
      id: menu.id,
    });
  });
  return currentRows;
};

/**
 * Helper to generate table rows for custom tags
 * @param customTags list of custom tags for provider
 * @returns generated table rows
 * @tested
 */
export const generateCustomTagsTableRows = (
  customTags: CustomTag[]
): TableRow[] => {
  let currentRows: TableRow[] = [];
  customTags.forEach((tag, tagIndex) => {
    currentRows.push({
      content: [
        tag.text,
        <div
          className={["table-svg-wrapper", tag.active ? "green" : "red"].join(
            " "
          )}
        >
          {tag.active ? <CheckIcon /> : <CancelIcon />}
        </div>,
      ],
      id: `${tagIndex}`,
    });
  });
  return currentRows;
};

/**
 * Helper to delete a single specialNote from given specialNote[]
 * @param id id of special note to be deleted
 * @param specialNoteArray array to be adjusted
 * @returns specialNote[] without given entry
 */
export const deleteSpecialNote = (
  id: string,
  specialNoteArray: SpecialNote[]
): SpecialNote[] => {
  let localSpecialNotes = [...(specialNoteArray || [])];
  let filteredSpecialNotes = localSpecialNotes.filter(
    (specialNote) => specialNote.id !== id
  );
  return filteredSpecialNotes;
};

/**
 * Method to generate correct tableRows for specialNotes
 * @param provider provider containing specialNotes
 * @param toggleSpecialNoteToggle function to set something true
 * @param setCurrentSpecialNote setter to set currentSpecialNote
 * @param setLocalProvider setter to set localProvider
 * @returns TableRow[] containing information and functionality for specialNotes
 */
export const generateSpecialNoteRows = (
  provider: Provider,
  toggleSpecialNoteToggle: Function,
  setCurrentSpecialNote: Function,
  handleDelete: Function
): TableRow[] => {
  let localTableRows: TableRow[] = [];
  let localSpecialNotes = [...(provider?.specialNotes || [])];
  localSpecialNotes.forEach((specialNote) => {
    let localStartString = "-";
    if (!!specialNote.startDate)
      localStartString = new Date(specialNote.startDate).toLocaleDateString(
        "de"
      );
    let localEndString = "-";
    if (!!specialNote.endDate)
      localEndString = new Date(specialNote.endDate).toLocaleDateString("de");

    localTableRows.push({
      id: specialNote.id,
      content: [
        <p>{specialNote.title}</p>,
        <p>
          {localStartString}
          {" - "}
          {localEndString}
        </p>,
        <div className="flex-it-center">
          <EditIcon
            onClick={() => {
              setCurrentSpecialNote(specialNote);
              toggleSpecialNoteToggle(true);
            }}
          />
          <div className="red-inside">
            <TrashIcon
              onClick={() => {
                handleDelete(specialNote.id);
              }}
            />
          </div>
        </div>,
      ],
    });
  });
  return localTableRows;
};

/**
 * Helper to return the correct icon for a social media platfrom
 * @param name name of the sicoal media platform
 * @returns icon
 * @tested
 */
export const getCorrectSocialMediaIcon = (name: string): JSX.Element => {
  switch (name) {
    case "facebook":
      return <FacebookIcon />;
    case "instagram":
      return <InstagramIcon />;
    case "linkedIn":
      return <LinkedInIcon />;
    case "whatsapp":
      return <WhatsappIcon />;
    case "youtube":
      return <YoutubeIcon />;
    default:
      return <></>;
  }
};

/**
 * Helper to get correct provider from providerList by slugId
 * @param provList list of Providers to be searched
 * @param slugId slugId of requested provider
 * @returns provider with given slugId or undefined if no matching providers where found
 */
export const getCorrectProviderFromListBySlugId = (
  provList: Provider[],
  slugId: string
): Provider | undefined => {
  return (
    provList.filter((provider) => {
      return provider.slugid === slugId;
    })[0] ?? undefined
  );
};
