/**
 * model of a licence to check
 * user permission
 */
export interface Licence {
  id?: string;
  name: string;
  createDate?: Date;
  eventCreation: boolean;
  actionCreation: boolean;
  menuCreation: boolean;
  jobCreation: boolean;
}

/**
 * Helper to generate empty licence
 * @returns empty licence
 */
export const createEmptyLicence = (): Licence => ({
  actionCreation: false,
  eventCreation: false,
  jobCreation: false,
  menuCreation: false,
  name: "",
});
