import { Action, createEmptyAction } from "../../utils/action/Action.types";
import {
  ActivityTag,
  ActivityType,
  DetailFilter,
  TargetAudience,
} from "../../utils/activity/Activity.types";
import {
  createEmptyEvent,
  createEmptyTempTimelapse,
  Event,
  PriceCategory,
  TempTimelapse,
  TicketType,
} from "../../utils/event/Event.types";
import {
  Category,
  DayOfWeek,
  FileItem,
  Provider,
} from "../../utils/user/User.types";

export interface EventEditBoxComponentProps {
  index: number;
  isDesktop?: boolean;
  provider: Provider;
  activityCompanion: ActivityEditCompanion;
  setActivityCompanion(activityCompanion: ActivityEditCompanion): void;
  isLoading?: boolean;
  isActivityReady?: boolean;
  getActivityReadyAndCreateUpdate?(): void;
  stepCompletion?: boolean;
  isEdit?: boolean;
}

export interface ActivityEditCompanion {
  activityType?: ActivityType;
  action: Action;
  event: Event;
  image?: FileItem;
  previewImage?: FileItem;
  imageString?: string;
  previewImageString?: string;
  flyer?: FileItem;
  flyerString?: string;
  dateEndType: "fix" | "number";
  activeDays: DayOfWeek[];
  displayedDays: DayOfWeek[];
  connectedDates: boolean;
  singleDateLink: boolean;
  actionLink: boolean;
  dateType: "single" | "multi" | "continuous";
  category?: Category;
  startDate?: Date;
  endDate?: Date;
  currentImageFile?: File;
  currentImagePreviewFile?: File;
  currentFlyerFile?: File;
  singleDateObject: TempTimelapse;
  dateList: TempTimelapse[];
  priceCategoryList: PriceCategory[];
  providerId: string;
  mainTag?: ActivityTag;
  detailTags: DetailFilter[];
  targetAudiences: TargetAudience[];
  customTags: string[];
  ticketType: TicketType[];
  visibleFrom?: "now" | "date" | "deadline";
  deadlineType?: "day" | "week" | "month" | "year";
  deadlineAmount?: number;
  onlyVisibleOnSpecificDays?: boolean;
}

/**
 * Constructor to create Empty EventEditCompanion
 * @param event event to be contained in activityCompanion
 * @returns empty activityCompanion with given event
 */
export const createEmptyActivityEditCompanion = (
  event?: Event,
  action?: Action,
  providerId?: string
): ActivityEditCompanion => ({
  action: action || createEmptyAction(),
  event: event || createEmptyEvent(providerId ?? ""),
  dateType: "single",
  connectedDates: false,
  singleDateLink: true,
  actionLink: false,
  priceCategoryList: [],

  activeDays: [],
  displayedDays: [],
  onlyVisibleOnSpecificDays: false,
  singleDateObject: createEmptyTempTimelapse(),
  dateEndType: "fix",
  dateList: [],
  customTags: [],
  detailTags: [],
  providerId: providerId ?? "",
  targetAudiences: [],
  ticketType: [],
});
