import {
  AdminLayoutComponent,
  InfoBoxComponent,
  ProgressbarComponent,
} from "deinestadtliebt-component-library";
import { useContext, useEffect, useState } from "react";
import { ConfigContext } from "../../../pages/App";
import { CurrentPage } from "../../../utils/navigation/Navigation.types";
import { useNavLayout } from "../../../utils/navigation/NavigationUtils";
import { ProviderDashboardProps } from "./ProviderDashboard.types";
import "./ProviderDashboardStyles.scss";
import { ReactComponent as RocketManIcon } from "../../../assets/icons/rocketman.svg";
import { ReactComponent as DiagramIcon } from "../../../assets/icons/diagram.svg";
import { ReactComponent as UserIcon } from "../../../assets/icons/user.svg";
import ClickBox from "../../clickbox/ClickBox";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import {
  CountResponse,
  createEmptyCountResponse,
  createEmptyProvider,
  Provider,
  UserRole,
} from "../../../utils/user/User.types";
import { useAxios } from "../../../utils/AxiosUtil";
import {
  fetchCountForActionsAndEvents,
  fetchUserById,
  getProviderPercentage,
} from "../../../utils/user/UserUtils";
import { getAppropriateGreeting } from "../../../utils/time/TimeUtils";

const ProviderDashboard: React.FC<ProviderDashboardProps> = ({ user }) => {
  const { appConfig } = useContext(ConfigContext);
  const { t } = useTranslation();
  const history = useHistory();
  const axios = useAxios();
  const [localProvider, setLocalProvider] = useState<Provider>(
    createEmptyProvider(false)
  );
  const [countOfEventsAndActions, setCountOfEventsAndActions] =
    useState<CountResponse>(createEmptyCountResponse());

  /**
   * this useEffect sets the provider by fetching it with the given user-id
   */
  useEffect(() => {
    if (!axios || !user) return;
    fetchUserById(axios, user.id!, UserRole.PROVIDER).then((loadedprovider) => {
      setLocalProvider(loadedprovider as Provider);
      fetchCountForActionsAndEvents(
        axios,
        (loadedprovider as Provider).slugid
      ).then((loadedCounts) => {
        setCountOfEventsAndActions(loadedCounts);
      });
    });
    // eslint-disable-next-line
  }, [axios, user]);

  return (
    <AdminLayoutComponent
      {...useNavLayout(CurrentPage.DASHBOARD_PROVIDER_HOME)}
    >
      <div className="dashboard-provider--header" id="top">
        <div
          className="dashboard-provider--header--background"
          style={{ background: appConfig?.highlightColor }}
        />
        <div className="dashboard-provider--header-wrapper">
          <div>
            <p>{getAppropriateGreeting()}</p>
            <p>{user.name}!</p>
          </div>
          <div></div>
        </div>
        <div className="dashboard-provider--header-info-wrapper">
          <InfoBoxComponent
            colors={{ hover: { bgColor: appConfig?.mainColor } }}
            content={
              <>
                <p>
                  {t("dashboard.provider.infoBox.events.text1", {
                    replace: { cityName: appConfig?.cityName },
                  })}
                </p>
                <p>
                  {t("dashboard.provider.infoBox.events.text2", {
                    replace: { number: countOfEventsAndActions.event },
                  })}
                </p>
              </>
            }
            title={t("dashboard.provider.infoBox.events.title")}
            buttonText={t("dashboard.provider.infoBox.events.button")}
            buttonClick={() => history.push("/config/activity")}
            addClick={() =>
              history.push("/config/event/create", {
                providerSlug: localProvider?.slugid,
              })
            }
          />
          <InfoBoxComponent
            colors={{ hover: { bgColor: appConfig?.mainColor } }}
            content={
              <>
                <p>{t("dashboard.provider.infoBox.actions.text1")}</p>
                <p>
                  {t("dashboard.provider.infoBox.actions.text2", {
                    replace: { number: countOfEventsAndActions.action },
                  })}
                </p>
              </>
            }
            title={t("dashboard.provider.infoBox.actions.title")}
            buttonText={t("dashboard.provider.infoBox.actions.button")}
            buttonClick={() => history.push("/config/activity")}
            addClick={() =>
              history.push("/config/event/create", {
                providerSlug: localProvider?.slugid,
              })
            }
          />
          <InfoBoxComponent
            colors={{ hover: { bgColor: appConfig?.mainColor } }}
            content={
              <>
                <ProgressbarComponent
                  value={getProviderPercentage(localProvider)}
                  color={
                    getProviderPercentage(localProvider) === 100
                      ? "green"
                      : "red"
                  }
                />
                <p>
                  {getProviderPercentage(localProvider) === 100
                    ? t("dashboard.provider.infoBox.profile.textFull")
                    : t("dashboard.provider.infoBox.profile.text")}
                </p>
              </>
            }
            buttonText={
              getProviderPercentage(localProvider) === 100
                ? t("dashboard.provider.infoBox.profile.buttonFull")
                : t("dashboard.provider.infoBox.profile.button")
            }
            buttonClick={() => history.push("/config/profile")}
            title={t("dashboard.provider.infoBox.profile.title")}
          />
          <InfoBoxComponent
            colors={{ hover: { bgColor: appConfig?.mainColor } }}
            content={
              <>
                <p>
                  {t("dashboard.provider.infoBox.advertising.text", {
                    replace: { currentAds: 0, maxAds: 1 },
                  })}
                </p>
                <RocketManIcon />
              </>
            }
            title={t("dashboard.provider.infoBox.advertising.title")}
            addClick={() => history.push("/config/advertisement")}
            buttonText={t("dashboard.provider.infoBox.advertising.button")}
            buttonClick={() => history.push("/config/advertisement")}
          />
        </div>
      </div>

      <div className="dashboard-provider--clickbox-wrapper">
        <ClickBox
          icon={<DiagramIcon />}
          onClick={() => history.push("/config/evaluation")}
          text={t("dashboard.provider.clickBox.evaluation")}
        />
        <ClickBox
          icon={<UserIcon />}
          onClick={() => history.push("/config/abo")}
          text={t("dashboard.provider.clickBox.abo")}
        />
      </div>

      <div className="dashboard-provider--back-to-top">
        <a href="#top">{t("dashboard.provider.backToTop")}</a>
      </div>
    </AdminLayoutComponent>
  );
};

export default ProviderDashboard;
