import {
  generateNotification,
  NotificationTypes,
  PopupComponent,
  RetractableComponent,
  TableComponent,
} from "deinestadtliebt-component-library";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TimeFrameCollection } from "../../utils/openinghours/OpeningHours.types";
import { ReactComponent as ImagesIcon } from "../../assets/icons/images.svg";
import { SpecialOpeningTimeEditProps } from "./SpecialOpeningTimeEdit.types";
import { useLocation } from "react-router-dom";
import { createEmptyTimeFrameCollection } from "../../utils/openinghours/OpeningHoursUtils";
import OpeningTimeEdit from "../openingtimeedit/OpeningTimeEdit";
import { generateCustomOpeningTimesTableRows } from "../../utils/time/TimeUtils";
import { updateList } from "../../utils/objects/ListUtils";
import { updateProviderProfileData } from "../../utils/user/UserUtils";
import { useAxios } from "../../utils/AxiosUtil";

const SpecialOpeningTimeEdit: React.FC<SpecialOpeningTimeEditProps> = ({
  selectedProvider,
  setSelectedProvider,
}) => {
  const { t } = useTranslation();

  const { hash: locationHash } = useLocation();
  const [openingTimeToUpdate, setOpeningTimeToUpdate] =
    useState<TimeFrameCollection>();
  const axios = useAxios();
  const [keyOpeningTimeUpdate, setKeyOpeningTimeUpdate] = useState<number>(0);
  const specialOpeningTimesElement = useRef<HTMLParagraphElement>(null);
  const [currentCustomOpeningTime, setCurrentCustomOpeningTime] =
    useState<TimeFrameCollection>(createEmptyTimeFrameCollection());
  const [isLoading, toggleIsLoading] = useState<boolean>(false);

  /**
   * Helper to modify the content of the custom openinghours for the provider
   * @param type action to be done (create, delete, update)
   * @param customTime the timeframecollection to use
   */
  const changeOpeningTimesToProvider = async (
    type: "create" | "delete" | "update",
    customTime: TimeFrameCollection
  ): Promise<void> => {
    toggleIsLoading(true);

    const localCustomHours = updateList(
      customTime,
      selectedProvider.openingHours?.custom || [],
      type
    );

    await updateProviderProfileData(axios, {
      ...selectedProvider,
      openingHours: {
        ...selectedProvider.openingHours,
        custom: localCustomHours,
      },
    }).then((success) => {
      if (success) {
        setSelectedProvider({
          ...selectedProvider,
          openingHours: {
            ...selectedProvider.openingHours,
            custom: localCustomHours,
          },
        });
        setCurrentCustomOpeningTime(createEmptyTimeFrameCollection());
        setOpeningTimeToUpdate(undefined);
        setKeyOpeningTimeUpdate(keyOpeningTimeUpdate + 1);
        generateNotification(
          NotificationTypes.SUCCESS,
          t(
            `notification.title.success.${
              type === "create" ? "creationSuccessful" : "changesSuccessful"
            }`
          ),
          t(`notification.content.success.providerUpdate`)
        );
      } else
        generateNotification(
          NotificationTypes.ERROR,
          t(`notification.title.success.error`),
          t(`notification.content.success.deletion`)
        );
      toggleIsLoading(false);
    });
  };

  return (
    <>
      <RetractableComponent
        title={t("providerConfigurationPage.specialOpeningTimes")}
        type="border"
        icon={<ImagesIcon />}
        open={locationHash === "#specialOpeningTimes"}
      >
        <p ref={specialOpeningTimesElement}>
          {t("providerConfigurationPage.specialOpeningTimesCreate")}
        </p>
        <OpeningTimeEdit
          key={keyOpeningTimeUpdate}
          time={currentCustomOpeningTime}
          isLoading={isLoading}
          setTime={(time) => changeOpeningTimesToProvider("create", time)}
        />

        <TableComponent
          maxPageAmount={20}
          header={t("providerConfigurationPage.tableHeaderCustomOpeningTime", {
            returnObjects: true,
          })}
          rows={generateCustomOpeningTimesTableRows(
            selectedProvider.openingHours?.custom || [],
            (time) => changeOpeningTimesToProvider("delete", time),
            setOpeningTimeToUpdate
          )}
        />
        <PopupComponent
          open={!!openingTimeToUpdate}
          toggleOpen={() => setOpeningTimeToUpdate(undefined)}
        >
          <h2>{t("providerEventsConfigurationPage.updateTitle")}</h2>
          <OpeningTimeEdit
            key={keyOpeningTimeUpdate}
            time={openingTimeToUpdate!}
            isLoading={isLoading}
            setTime={(time) => changeOpeningTimesToProvider("update", time)}
            update
          />
        </PopupComponent>
      </RetractableComponent>
    </>
  );
};

export default SpecialOpeningTimeEdit;
