import { DivIcon, divIcon, LatLngTuple, LeafletMouseEvent, Map } from "leaflet";
import "leaflet/dist/leaflet.css";
import { useEffect, useState } from "react";
import { TileLayer, ZoomControl, MapContainer, Marker } from "react-leaflet";
import { CustomMapComponentProps } from "./CustomMapComponent.types";
import { ReactComponent as MarkerIcon } from "./assets/marker.svg";
import { renderToString } from "react-dom/server";
import "./CustomMapComponentStyles.scss";

const CustomMapComponent: React.FC<CustomMapComponentProps> = ({
  center,
  markerList,
  defaultZoom = 18,
  activateCreation,
  getCreationPosition,
  getZoomChange,
}) => {
  const [curPosition] = useState<LatLngTuple>(center);
  const [localMap, setLocalMap] = useState<Map>();

  /**
   * Helper to create a custom marker on the map
   * @param markerColor color of the middle circle in the marker
   * @returns created custom marker for map
   */
  const createCustomMarker = (markerColor: string): DivIcon => {
    return divIcon({
      className: "map-wrapper-marker",
      html: renderToString(<MarkerIcon style={{ color: markerColor }} />),
    });
  };

  /**
   *  executes getCreationMethod passed into customMapComponent used to create a marker and set coords in appConfig
   * @param evt Method that calls a method passed into the component
   */
  const addPlaceToEvent = (evt: LeafletMouseEvent): void => {
    getCreationPosition?.(evt.latlng);
  };

  /**
   *  executes getCreationMethod passed into customMapComponent used to set zoomLevel on zoomChange
   * @param zoomLevel number that indicates zoomScale
   */
  const operateZoomChange = (): void => {
    if (!localMap) return;
    getZoomChange?.(localMap?.getZoom());
  };

  /**
   * UseEffect to detect doubleClick on map and places marker on position and to detect zoomChange and set appConfig accordingly
   */
  useEffect(() => {
    if (!localMap || !activateCreation) return;
    localMap.on("dblclick", addPlaceToEvent);
    localMap.on("zoom", operateZoomChange);
    return () => {
      localMap.off("dblclick", addPlaceToEvent);
      localMap.off("zoom", operateZoomChange);
    };
    // eslint-disable-next-line
  }, [localMap]);

  return (
    <MapContainer
      doubleClickZoom={false}
      className="map-wrapper"
      center={curPosition ? curPosition : center}
      zoom={defaultZoom}
      minZoom={12}
      zoomControl={false}
      whenCreated={setLocalMap}
    >
      <ZoomControl position="bottomright" />
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      {markerList.map((element, index) => (
        <Marker
          position={[element.location[0], element.location[1]]}
          key={`marker-key-${index}`}
          icon={createCustomMarker(element.color || "#35a85d")}
          title={element.name}
        />
      ))}
    </MapContainer>
  );
};

export default CustomMapComponent;
