import { useKeycloak } from "@react-keycloak/web";
import { LoaderComponent } from "deinestadtliebt-component-library";
import { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { UserContext } from "../pages/App";
import { UserRole } from "./user/User.types";

export const PrivateRoute: React.FC<{
  Page: React.ComponentType<any>;
  path: string;
  allowedUserRoles: UserRole[];
  properties?: Object;
}> = ({ Page, path, allowedUserRoles, properties }) => {
  const { isUserLoaded, user } = useContext(UserContext);
  const { initialized, keycloak } = useKeycloak();

  return (
    <Route path={path} exact>
      {initialized && keycloak && keycloak.authenticated ? (
        isUserLoaded && user ? (
          allowedUserRoles.includes(user.role) ? (
            <Page {...properties} />
          ) : (
            <Redirect to="/" />
          )
        ) : (
          <LoaderComponent isFullscreen />
        )
      ) : (
        <Redirect to="/login" />
      )}
    </Route>
  );
};
