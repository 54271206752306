import dayjs from "dayjs";
import {
  AdminLayoutComponent,
  ButtonComponent,
  NotificationTypes,
  generateNotification,
} from "deinestadtliebt-component-library";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { ReactComponent as LunchIcon } from "../assets/icons/lunch.svg";
import { ReactComponent as CheckIcon } from "../assets/icons/check.svg";
import { LunchEditStep1 } from "../components/luncheditsteps/LunchEditStep1";
import { LunchEditStep2 } from "../components/luncheditsteps/LunchEditStep2";
import "../styles/LunchEditPageStyle.scss";
import { useAxios } from "../utils/AxiosUtil";
import {
  checkIfLunchIsComplete,
  updateLunchItems,
} from "../utils/lunch/LunchUtils";
import { CurrentPage } from "../utils/navigation/Navigation.types";
import { useNavLayout } from "../utils/navigation/NavigationUtils";
import {
  Lunch,
  Provider,
  UserRole,
  createEmptyLunch,
} from "../utils/user/User.types";
import { ConfigContext, UserContext } from "./App";

interface LunchEditPageProps {
  isEdit: boolean;
}
const LunchEditPage: React.FC<LunchEditPageProps> = ({ isEdit }) => {
  const { user, setUser } = useContext(UserContext);
  const axios = useAxios();
  const { appConfig } = useContext(ConfigContext);
  const { t } = useTranslation();
  const location = useLocation<{ provider?: Provider; lunch?: Lunch }>();
  const history = useHistory();
  const [provider, setProvider] = useState<Provider>();
  const [activeStep, setActiveStep] = useState<number>();

  /**
   * Util method to get the last lunch to prefill it into a new Lunch
   * @returns Found lunch
   */
  const lastLunch: Lunch | undefined = useMemo(
    () =>
      provider?.lunchItems.sort((a, b) =>
        dayjs(a.startDate).isBefore(b.startDate) ? 1 : -1
      )[0],
    [provider?.lunchItems]
  );

  const [lunchToEdit, setLunchToEdit] = useState<Lunch>({
    ...createEmptyLunch(),
    startDate: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() + (1 - new Date().getDay())
    ),
    endDate: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() + (7 - new Date().getDay())
    ),
  });

  //useEffect hook to get the last day and time of the newest Lunch object of the provider
  useEffect(() => {
    if (location.state?.lunch) setLunchToEdit(location.state.lunch);
    else if (lastLunch)
      setLunchToEdit({
        ...lunchToEdit,
        days: lastLunch.days,
        startTime: lastLunch.startTime,
        endTime: lastLunch.endTime,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider, location.state, lastLunch]);

  /**
   * Configuration of the looks of a disabled button
   * is handed to the button if it is disabled
   */
  const buttonConfigDisabled = {
    default: {
      bgColor: "lightgray",
      borderColor: "lightgray",
    },
  };

  /**
   * useEffect to set the user as provider if the user is a provider
   * Otherwise check for provider in location state and generate Notification with redirect if both were not present
   */
  useEffect(() => {
    if (user?.role === UserRole.PROVIDER) setProvider(user as Provider);
    else if (location.state?.provider) setProvider(location.state.provider);
    else {
      generateNotification(
        NotificationTypes.WARNING,
        t("notification.title.warning.warning"),
        t("notification.content.warning.noSlugId")
      );
      history.push("/");
    }
  }, [location, history, t, user]);

  /**
   * Helper method to check if all neccessary data is filled in in step 1
   * generates notifications for all missing fields and navigates to step 2 if all data is available
   */
  const navigateToStep2 = (): void => {
    let isRequiredDataPresent: boolean = true;

    if (lunchToEdit.days?.length === 0) {
      generateNotification(
        NotificationTypes.WARNING,
        t("lunchEdit.step.1.notifications.title"),
        t("lunchEdit.step.1.notifications.days")
      );
      isRequiredDataPresent = false;
    }
    if (dayjs(lunchToEdit.startDate).isSame(dayjs(lunchToEdit.endDate))) {
      generateNotification(
        NotificationTypes.WARNING,
        t("lunchEdit.step.1.notifications.title"),
        t("lunchEdit.step.1.notifications.date")
      );
      isRequiredDataPresent = false;
    }
    if (!lunchToEdit.startTime) {
      generateNotification(
        NotificationTypes.WARNING,
        t("lunchEdit.step.1.notifications.title"),
        t("lunchEdit.step.1.notifications.startTime")
      );

      isRequiredDataPresent = false;
    }
    if (!lunchToEdit.endTime) {
      generateNotification(
        NotificationTypes.WARNING,
        t("lunchEdit.step.1.notifications.title"),
        t("lunchEdit.step.1.notifications.endTime")
      );
      isRequiredDataPresent = false;
    }
    if (isRequiredDataPresent) setActiveStep(2);
  };

  /**
   * Helper method to determinate if a update or create should be done
   * @returns string with update or create
   */
  const getCorrectUpdateType = (): "update" | "create" =>
    !location.state?.lunch ? "create" : isEdit ? "update" : "create";
  /*
   * Util method to check if an step is complete and all neccessary data is available
   * @param step step one or two to check
   * @returns boolean if step is complete
   */
  const checkIfStepIsComplete = (step: 1 | 2): boolean => {
    if (step === 1) {
      return lunchToEdit.days.length > 0 &&
        lunchToEdit.startDate &&
        lunchToEdit.endDate &&
        lunchToEdit.startTime &&
        lunchToEdit.endTime
        ? true
        : false;
    } else {
      return lunchToEdit.menuItems.length > 0 ? true : false;
    }
  };
  return (
    <AdminLayoutComponent
      {...useNavLayout(
        user?.role === UserRole.PROVIDER
          ? CurrentPage.PROVIDER_LUNCHEDIT
          : CurrentPage.ADMIN_LUNCHEDIT
      )}
    >
      <div
        className="lunch-edit-page--header"
        style={{ backgroundColor: appConfig?.highlightColor }}
      >
        <p className="lunch-edit-page--header--headline">
          {t("lunchEdit.headline")}
        </p>
      </div>
      <div className="lunch-edit-page--content">
        <div
          className="lunch-edit-page--content--step-wrapper"
          style={{ backgroundColor: appConfig?.highlightColor }}
        >
          <div
            className={`lunch-edit-page--content--step-wrapper--step ${
              checkIfStepIsComplete(1) ? "completed" : ""
            }`}
            onClick={() => setActiveStep(1)}
          >
            {checkIfStepIsComplete(1) ? (
              <CheckIcon className="step-check" />
            ) : (
              <p className="step-number">1</p>
            )}

            <p className="lunch-edit-page--content--step-wrapper--step--headline">
              {t("lunchEdit.stepOneHeadline")}
            </p>
            <p className="lunch-edit-page--content--step-wrapper--step--text">
              {t("lunchEdit.stepOneText")}
            </p>
            {activeStep === 1 && (
              <div className="hide-on-big">
                <LunchEditStep1
                  lunch={lunchToEdit}
                  setLunch={setLunchToEdit}
                  navigateToStep2={navigateToStep2}
                />
              </div>
            )}
          </div>
          <div
            className={`lunch-edit-page--content--step-wrapper--step ${
              checkIfStepIsComplete(2) ? "completed" : ""
            }`}
            onClick={() => navigateToStep2()}
          >
            <p className="lunch-edit-page--content--step-wrapper--step--headline">
              {checkIfStepIsComplete(2) ? (
                <CheckIcon className="step-check" />
              ) : (
                <p className="step-number">2 </p>
              )}
              {t("lunchEdit.stepTwoHeadline")}
            </p>
            <p className="lunch-edit-page--content--step-wrapper--step--text">
              {t("lunchEdit.stepTwoText")}
            </p>
            {activeStep === 2 && provider && (
              <div className="hide-on-big">
                <LunchEditStep2
                  lunch={lunchToEdit}
                  provider={provider}
                  setLunch={setLunchToEdit}
                  setActiveStep={setActiveStep}
                  type={getCorrectUpdateType()}
                />
              </div>
            )}
          </div>
          <div className="lunch-edit-page--content--step-wrapper--button-wrapper">
            {provider && (
              <ButtonComponent
                value={t("lunchEdit.buttonDone")}
                onClick={() =>
                  updateLunchItems(
                    axios,
                    getCorrectUpdateType(),
                    lunchToEdit,
                    provider,
                    setUser,
                    user?.role || UserRole.NONE,
                    (path: string) => history.push(path)
                  )
                }
                disabled={!checkIfLunchIsComplete(lunchToEdit)}
                colors={
                  checkIfLunchIsComplete(lunchToEdit)
                    ? {}
                    : buttonConfigDisabled
                }
              />
            )}
          </div>
        </div>
        <div className="lunch-edit-page--content--content-wrapper">
          {!activeStep && (
            <div className="lunch-edit-page--content--content-wrapper--placeholder">
              <LunchIcon className="lunch-edit-page--content--content-wrapper--placeholder--icon" />
              <p className="lunch-edit-page--content--content-wrapper--placeholder--headline">
                {t("lunchEdit.descriptionHeadline")}
              </p>
              <p className="lunch-edit-page--content--content-wrapper--placeholder--text">
                {t("lunchEdit.descriptionText")}
              </p>
              <div className="lunch-edit-page--content--content-wrapper--nav-button-wrapper">
                <ButtonComponent
                  value={t("lunchEdit.backToStart")}
                  onClick={() => history.push("/")}
                />
                <ButtonComponent
                  value={t("lunchEdit.nextStep")}
                  onClick={() => setActiveStep(1)}
                />
              </div>
            </div>
          )}
          {activeStep === 1 && (
            <LunchEditStep1
              lunch={lunchToEdit}
              setLunch={setLunchToEdit}
              navigateToStep2={navigateToStep2}
            />
          )}
          {activeStep === 2 && provider && (
            <div className="lunch-edit-page--content-content-wrapper--step-wrapper">
              <LunchEditStep2
                lunch={lunchToEdit}
                setLunch={setLunchToEdit}
                provider={provider}
                setActiveStep={setActiveStep}
                type={
                  !location.state?.lunch
                    ? "create"
                    : isEdit
                    ? "update"
                    : "create"
                }
              />
            </div>
          )}
        </div>
      </div>
    </AdminLayoutComponent>
  );
};
export default LunchEditPage;
