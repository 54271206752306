import { TableRow } from "deinestadtliebt-component-library";
import {
  createEmptyHolidayTime,
  createEmptyOpeningTimeItem,
  DayOfWeek,
  HolidayTime,
  Lunch,
  OpeningTime,
  OpeningTimeItem,
  TempSignupConfig,
} from "../user/User.types";
import { CalendarWeeks } from "./Time.types";
import { ReactComponent as OpenIcon } from "../../assets/icons/open.svg";
import { ReactComponent as ClosedIcon } from "../../assets/icons/closed.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import i18n from "../../i18n";
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";
import { TimeFrameCollection } from "../openinghours/OpeningHours.types";
import {
  DayOfWeekArray,
  EventIntervall,
  TempTimelapse,
} from "../event/Event.types";

/**
 * Helper method to get appropriate greeting message
 * @returns string that represents greeting
 * @tested
 */
export const getAppropriateGreeting = (): string => {
  const hourTime = new Date().getHours();
  if (5 <= hourTime && hourTime <= 10) {
    return i18n.t("dashboard.greetings.morning");
  } else if (10 <= hourTime && hourTime <= 18) {
    return i18n.t("dashboard.greetings.normal");
  } else if (18 <= hourTime || hourTime <= 5) {
    return i18n.t("dashboard.greetings.evening");
  } else {
    return i18n.t("dashboard.greetings.normal");
  }
};

/**
 * Method to calculate the amount of full years between two dates
 * @param earlierDate Date
 * @param laterDate Date
 * @returns number of full years
 * @tested
 */
export const getFullYearDiff = (earlierDate: Date, laterDate: Date): number => {
  var diff = (laterDate.getTime() - earlierDate.getTime()) / 1000;
  diff /= 60 * 60 * 24;
  // Increment to include the same date in the future if reached exactly
  diff++;
  return Math.abs(Math.floor(diff / 365.25));
};

/**
 * Helper method to find first/second/third/fourth or last dayOfWeek for given Month
 * @param referenceDate date from which only the month is needed to determine the specific day by given data
 * @param dayNumber number of DayOfWeek (0-6, sun-mon)
 * @param index position of the given day (e.g. 3 if selected third monday of month)
 * @returns Date that fits all given requirements and parameters in the month of the referenceDate
 * @tested
 */
export const getCorrectDatesForDayAndIndexAndGivenMonth = (
  referenceDate: Date,
  dayNumber: number,
  index: number
): Date => {
  let localDayNumber = dayNumber;
  if (dayNumber === 7) {
    localDayNumber = 0;
  }

  const initialMonth = referenceDate.getMonth();
  const localIndex: number = index;
  const specificDayDateArray: Date[] = [];
  referenceDate.setDate(1);
  // function to determine first date with specific weekDay in month
  while (referenceDate.getDay() !== localDayNumber) {
    referenceDate.setDate(referenceDate.getDate() + 1);
  }
  //calculates all instances of given dayOfWeek in given month to later be filtered
  while (referenceDate.getMonth() === initialMonth) {
    specificDayDateArray.push(new Date(referenceDate.getTime()));
    referenceDate.setDate(referenceDate.getDate() + 7);
  }
  //index of 5 means last DayOfWeek in month to avoid potential error e.g. 5th sunday of february but there are only 4 present
  if (localIndex === 5) {
    return specificDayDateArray.slice(-1)[0];
  } else {
    return specificDayDateArray[localIndex - 1];
  }
};

/**
 * Method to generate an array of date with the given data
 * @param startDate first possible date
 * @param endDate last possible date
 * @param dateIntervall indicator what kind of intervall shall be used ("DAILY", "WEEKLY", "MONTHLY", "YEARLY")
 * @param dateRepetititonNumber max number of dates that will be generated
 * @param currentEventTimelapse EventTimelapse that contains information about start- and endTime
 * @param weekRepetitionNumber number of weeks that will pass until the next date is considered for weekly intervall
 * @param weeklyRepititionDay DayOfWeek that is used for unspecific date generation in weekly intervalls
 * @param dynamicDateDayOfWeek DayOfWeek that is used for unspecific date generation in monthly and annual intervalls
 * @param monthlyRepetitionDay number of day of month (e.g. 23 for the 23rd) used for monthly and annual intervalls
 * @param specificDateForIntervall boolean that determine whether a specific date (21st of mai) or dynamic date (first friday of month) is generated
 * @param monthlyRepetitionMonth number of months that will pass between generated dates for monthly intervalls
 * @param dynamicDateDayOfWeekNumber number that will be used to determine which of the available DayOfWeeks in a month is selected (first, second, third, fourth or last)
 * @param yearlyMonth number of month for annual generation
 * @returns TempTimeLapse[] containing all fitting dates that fulfill the given requirements
 */
export const generateDateArray = (
  startDate: Date,
  endDate: Date,
  endDateType: "fix" | "number",
  dateIntervall: EventIntervall,
  dateRepetititonNumber: number,
  currentEventTimelapse: TempTimelapse,
  weekRepetitionNumber: number,
  weeklyRepititionDay: DayOfWeek[],
  dynamicDateDayOfWeek: DayOfWeek,
  monthlyRepetitionDay: number,
  specificDateForIntervall: boolean,
  monthlyRepetitionMonth: number,
  dynamicDateDayOfWeekNumber: number,
  yearlyMonth: number
): TempTimelapse[] => {
  const earliestDate: Date = startDate;
  const latestDate: Date = endDate;
  const dayDifference = Math.ceil(
    Math.abs(latestDate.getTime() - earliestDate.getTime()) /
      (1000 * 60 * 60 * 24)
  );
  let localDateArray: TempTimelapse[] = [];
  const nextFittingDate: Date = new Date(earliestDate);
  switch (dateIntervall) {
    case EventIntervall.DAILY:
      // handling too create date-array for daily intervall
      let dayDiff = 0;
      switch (endDateType) {
        case "fix":
          dayDiff = dayDifference;
          break;
        default:
        case "number":
          dayDiff = dateRepetititonNumber - 1;
          break;
      }
      for (let dayIndex = 0; dayIndex <= dayDiff; dayIndex++) {
        const calculatedDate: Date = new Date(startDate);
        calculatedDate.setDate(earliestDate.getDate() + dayIndex);

        localDateArray.push({
          ...currentEventTimelapse,
          eventDate: calculatedDate,
        });
      }
      break;

    // Handling to create date-array for weekly intervall
    case EventIntervall.WEEKLY:
      let weeklyDateRepetitions = 0;
      switch (endDateType) {
        case "fix":
          weeklyDateRepetitions = dayDifference / (7 * weekRepetitionNumber);
          break;
        default:
        case "number":
          weeklyDateRepetitions = dateRepetititonNumber;
          break;
      }
      const currentDayNumber: number = earliestDate.getDay();
      let nextFittingDateDayNumber: number = 0;
      // eslint-disable-next-line
      DayOfWeekArray.map((day, dayIndex): void => {
        if (weeklyRepititionDay.includes(day as DayOfWeek)) {
          nextFittingDateDayNumber = dayIndex;

          let dayDifferenceToNextDate = 0;
          if (nextFittingDateDayNumber < currentDayNumber) {
            dayDifferenceToNextDate =
              7 + (nextFittingDateDayNumber - currentDayNumber);
          } else
            dayDifferenceToNextDate =
              nextFittingDateDayNumber - currentDayNumber;
          nextFittingDate.setDate(
            earliestDate.getDate() + dayDifferenceToNextDate
          );
          for (let dayIndex = 0; dayIndex < weeklyDateRepetitions; dayIndex++) {
            const calculatedDate: Date = new Date(nextFittingDate);
            calculatedDate.setDate(
              nextFittingDate.getDate() + 7 * weekRepetitionNumber * dayIndex
            );

            const referenceDateForEndCheck = new Date(latestDate);
            referenceDateForEndCheck.setDate(
              referenceDateForEndCheck.getDate() + 1
            );
            if (
              endDateType === "number" ||
              calculatedDate.getTime() <= referenceDateForEndCheck.getTime()
            )
              localDateArray.push({
                ...currentEventTimelapse,
                eventDate: calculatedDate,
              });
          }
        }
      });
      localDateArray.sort(
        (dateA, dateB) =>
          Number(dateA.eventDate!.getTime()) -
          Number(dateB.eventDate!.getTime())
      );
      if (endDateType === "number")
        localDateArray = localDateArray.slice(0, dateRepetititonNumber);
      break;

    //Handling for month-based intervalls
    case EventIntervall.MONTHLY:
      let monthDifference: number = 0;
      const earliestDateDayNumber: number = earliestDate.getDate();
      let nextFittingDateDayNumberForMonth: number = 0;
      // eslint-disable-next-line
      DayOfWeekArray.map((day, dayIndex): void => {
        if (day === dynamicDateDayOfWeek) {
          nextFittingDateDayNumberForMonth = dayIndex;
        }
      });
      nextFittingDate.setDate(monthlyRepetitionDay);
      monthDifference =
        latestDate.getMonth() -
        earliestDate.getMonth() +
        (latestDate.getFullYear() - earliestDate.getFullYear()) * 12;
      if (specificDateForIntervall) {
        //handling for specific date (e.g. 17th of each month)
        let monthlyDateRepetitions = 0;
        switch (endDateType) {
          case "fix":
            if (earliestDateDayNumber > monthlyRepetitionDay) {
              nextFittingDate.setMonth(nextFittingDate.getMonth() + 1);
              monthDifference--;
            }
            monthlyDateRepetitions = monthDifference / monthlyRepetitionMonth;
            break;
          default:
          case "number":
            monthlyDateRepetitions = dateRepetititonNumber - 1;
            break;
        }

        for (
          let repetitionIndex = 0;
          repetitionIndex <= monthlyDateRepetitions;
          repetitionIndex++
        ) {
          const calculatedDate: Date = new Date(nextFittingDate);
          calculatedDate.setMonth(
            nextFittingDate.getMonth() +
              monthlyRepetitionMonth * repetitionIndex
          );
          if (
            calculatedDate.getTime() <= latestDate.getTime() ||
            endDateType === "number"
          )
            localDateArray.push({
              ...currentEventTimelapse,
              eventDate: calculatedDate,
            });
        }
      } else {
        //handling for unspecific date (e.g. every third friday of each month)

        let monthlyDateRepetitions = 0;
        switch (endDateType) {
          case "fix":
            monthlyDateRepetitions = monthDifference;
            break;
          default:
          case "number":
            monthlyDateRepetitions = dateRepetititonNumber;
            break;
        }
        const fullDateArray: Date[] = [];
        for (
          let repetitionIndex = 0;
          repetitionIndex <=
          Math.floor(monthlyDateRepetitions * monthlyRepetitionMonth) + 1;
          repetitionIndex++
        ) {
          const referenceDateForMonth = new Date(earliestDate);
          referenceDateForMonth.setDate(1);
          referenceDateForMonth.setMonth(
            earliestDate.getMonth() + repetitionIndex
          );
          const currentDateForIndex =
            getCorrectDatesForDayAndIndexAndGivenMonth(
              referenceDateForMonth,
              nextFittingDateDayNumberForMonth,
              dynamicDateDayOfWeekNumber
            );
          const referenceDateForEndCheck = new Date(latestDate);
          referenceDateForEndCheck.setDate(
            referenceDateForEndCheck.getDate() + 1
          );
          if (
            currentDateForIndex.getTime() <=
              referenceDateForEndCheck.getTime() ||
            endDateType === "number"
          )
            fullDateArray.push(currentDateForIndex);
        }
        if (
          earliestDate.getTime() > fullDateArray[0].getTime() &&
          endDateType === "fix"
        ) {
          fullDateArray.shift();
        }
        //From here on we have a list with all e.g. first mondays of the next months that are in the range of maxRepetitions and between earliest and latest date
        let fittingDateArray: Date[] = [];
        for (
          let filterIndex: number = 0;
          filterIndex < fullDateArray.length;
          filterIndex += monthlyRepetitionMonth as number
        ) {
          fittingDateArray.push(fullDateArray[filterIndex]);
        }
        fittingDateArray = fittingDateArray.slice(0, monthlyDateRepetitions);
        // eslint-disable-next-line
        fittingDateArray.map((date: Date): void => {
          const localDate: Date = date;
          localDateArray.push({
            ...currentEventTimelapse,
            eventDate: localDate,
          });
        });
      }
      break;
    case EventIntervall.YEARLY:
      let yearlyDateRepetitions = 0;
      switch (endDateType) {
        case "fix":
          yearlyDateRepetitions = getFullYearDiff(earliestDate, latestDate) + 1;
          break;
        default:
        case "number":
          yearlyDateRepetitions = dateRepetititonNumber;
          break;
      }
      if (specificDateForIntervall) {
        const specificDate = new Date(earliestDate);
        specificDate.setDate(monthlyRepetitionDay);
        specificDate.setMonth(yearlyMonth);
        if (specificDate.getTime() < earliestDate.getTime())
          specificDate.setFullYear(specificDate.getFullYear() + 1);
        for (let index: number = 0; index < yearlyDateRepetitions; index++) {
          const localCalculatedDate = new Date(specificDate);
          localCalculatedDate.setFullYear(
            localCalculatedDate.getFullYear() + index
          );
          localDateArray.push({
            ...currentEventTimelapse,
            eventDate: localCalculatedDate,
          });
        }
      } else {
        let nextFittingDateDayNumberForYear: number = 0;
        // eslint-disable-next-line
        DayOfWeekArray.map((day, dayIndex): void => {
          if (day === dynamicDateDayOfWeek) {
            nextFittingDateDayNumberForYear = dayIndex;
          }
        });
        for (let index: number = 0; index < yearlyDateRepetitions; index++) {
          const referenceDate = new Date(earliestDate);
          referenceDate.setDate(1);
          referenceDate.setMonth(yearlyMonth);
          referenceDate.setFullYear(referenceDate.getFullYear() + index);
          const currentDateForIndex =
            getCorrectDatesForDayAndIndexAndGivenMonth(
              referenceDate,
              nextFittingDateDayNumberForYear,
              dynamicDateDayOfWeekNumber
            );
          localDateArray.push({
            ...currentEventTimelapse,
            eventDate: currentDateForIndex,
          });
        }
      }
      break;
  }
  return localDateArray;
};

/**
 * Helper to get calendar weeks for current year
 * @param addYears to toggle the year to calculate the calendar weeks
 * @returns calculated calendar weeks
 * @tested
 */
export const getCalendarWeeks = (
  addYears: number = 0,
  year: number = new Date().getFullYear()
): CalendarWeeks[] => {
  // create prerequirements
  let foundCalendarWeeks: CalendarWeeks[] = [];
  let currentWeekNumber: number = 0;
  let workingDate: Date = new Date(year + addYears, 0, 1);
  while (workingDate.getDay() !== 1) {
    workingDate.setDate(workingDate.getDate() + 1);
  }
  // do the loop for the current whole year
  while (workingDate.getFullYear() === year + addYears) {
    currentWeekNumber++;
    const localStartDate: Date = new Date(workingDate);
    const localEndDate: Date = new Date(
      workingDate.setDate(workingDate.getDate() + 6)
    );
    const newWorkingDate: Date = new Date(
      workingDate.setDate(workingDate.getDate() + 1)
    );
    foundCalendarWeeks.push({
      startDate: localStartDate,
      endDate: localEndDate,
      name: `${currentWeekNumber}`,
    });
    workingDate = newWorkingDate;
  }
  return foundCalendarWeeks;
};

/**
 * Helper to generate table rows for holiday items
 * @param holidayTimes holiday of jobs items for provider
 * @returns generated table rows
 * @tested
 */
export const generateHolidayTimeTableRows = (
  holidayTimes: HolidayTime[],
  deleteHolidayEntry: (index: number) => void
): TableRow[] => {
  let currentRows: TableRow[] = [];
  holidayTimes.forEach((holidayTime, holidayTimeIndex) => {
    currentRows.push({
      content: [
        holidayTime.name,
        new Date(holidayTime.holidayDate).toLocaleDateString("de"),
        holidayTime.open
          ? `${holidayTime.startTime} - ${holidayTime.endTime}`
          : "-",
        <div className="table-svg-wrapper">
          {holidayTime.open ? <OpenIcon /> : <ClosedIcon />}
        </div>,
        <div className="table-svg-wrapper-clickable">
          {holidayTime.open ? (
            <TrashIcon onClick={() => deleteHolidayEntry(holidayTimeIndex)} />
          ) : (
            <></>
          )}
        </div>,
      ],
      id: `${holidayTimeIndex}`,
    });
  });
  return currentRows;
};

/**
 * Method to determine which Action should be executed with the given OpeningTime and then executes the correct operation
 * @param openingTimeToEdit OpeningTime to Create or Update (if type = delete the object is irrelevant but still needs to be provided)
 * @param openingTimes openingTime[] that will be adjusted]
 * @param type type of operation: "delete", "create", "update"
 * @param id id of the openingTIme (needed for deletion or update)
 * @returns OpeningTime[] that is an updated variant of openingTimes
 * @tested
 */
export const updateOpeningTimes = (
  openingTimeToEdit: OpeningTime,
  openingTimes: OpeningTime[],
  type: "create" | "delete" | "update",
  id: string
): OpeningTime[] => {
  switch (type) {
    case "create":
      openingTimes.push(openingTimeToEdit!);
      break;
    case "delete":
      openingTimes = openingTimes.filter((item) => item.id !== id);
      break;
    case "update":
      let foundIndex: number = openingTimes.findIndex(
        (item) => item.id === openingTimeToEdit?.id
      );
      if (foundIndex === -1) return openingTimes;
      openingTimes[foundIndex] = openingTimeToEdit!;
      break;
  }
  return openingTimes;
};

/**
 * Helper to generate table rows for customOpeningTimes items
 * @param customOpeningTimes customOpeningTimes of jobs items for provider
 * @param onDelete function which deletes the current row
 * @returns generated table rows
 * @tested
 */
export const generateCustomOpeningTimesTableRows = (
  customOpeningHours: TimeFrameCollection[],
  onDelete: (customFrames: TimeFrameCollection) => void,
  onDetail: (customFrames: TimeFrameCollection) => void
): TableRow[] => {
  let currentRows: TableRow[] = [];
  for (const customOpeningHour of customOpeningHours) {
    currentRows.push({
      content: [
        customOpeningHour.name || i18n.t("timeUtil.noName"),
        `${
          customOpeningHour.start
            ? new Date(customOpeningHour.start).toLocaleDateString("de")
            : i18n.t("timeUtil.noStartTime")
        } - ${
          customOpeningHour.stop
            ? new Date(customOpeningHour.stop).toLocaleDateString("de")
            : i18n.t("timeUtil.noEndTime")
        }`,
        <div className="flex-it-center">
          <div
            className={["trash-wrapper", "little-bit-margin-right"].join(" ")}
            onClick={() => onDetail(customOpeningHour)}
          >
            <EditIcon />
          </div>
          <div
            className="trash-wrapper"
            onClick={() => onDelete(customOpeningHour)}
          >
            <TrashIcon />
          </div>
        </div>,
      ],
      id: customOpeningHour.id!,
    });
  }
  return currentRows;
};

/**
 * Helper to generate correct time string for a date
 * @param date to render correctly
 * @returns string
 * @tested
 */
export const generateTimeStringForDate = (
  date: Date | undefined,
  altText?: string
): string => (date ? new Date(date).toLocaleDateString("de") : altText || "");

/**
 * Helper to convert a given date to inputcomponent correct date string
 * @param date data to convert
 * @returns converted string
 * @tested
 */
export const convertToDateInputValue = (date?: Date): string =>
  date ? new Date(date).toISOString().split("T")[0] : "";

/**
 * Helper to generate correct utc date for saving it on the database
 * @param date date to convert
 * @returns converted date
 */
export const generatedCorrectUTCDate = (date: Date): Date => {
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
};

/**
 * Helper to compare two dates
 *
 * @param a first date
 * @param b second date
 * @returns if dates are equal boolean
 * @tested
 */
export const areDatesEqual = (a: Date, b: Date): boolean =>
  generatedCorrectUTCDate(new Date(a)).toLocaleDateString() ===
  generatedCorrectUTCDate(new Date(b)).toLocaleDateString();

/**
 * Helper to get correct expression in OpeningTimeItem
 * @param day value to make the siwtch on
 * @returns found expression
 * @tested
 */
export const getCorrectOpeningtimeDay = (
  day: DayOfWeek
): "mon" | "tue" | "wed" | "thu" | "fri" | "sat" | "sun" => {
  switch (day) {
    case DayOfWeek.MONDAY:
      return "mon";
    case DayOfWeek.TUESDAY:
      return "tue";
    case DayOfWeek.WEDNESDAY:
      return "wed";
    case DayOfWeek.THURSDAY:
      return "thu";
    case DayOfWeek.FRIDAY:
      return "fri";
    case DayOfWeek.SATURDAY:
      return "sat";
    case DayOfWeek.SUNDAY:
      return "sun";
  }
};

/**
 * Helper to convert the entered opening times to
 * the database format
 *
 * @param provider object to work on
 * @param openingTimesLocalData  temp. data from signup process
 * @param isSameDayBeakTimeVisible boolean if on same day mode the break times are visible
 * @param differentDaysClosedDays array of DayOfWeek which indicate if the provider is closed
 * @returns opening time object
 */
export const convertSignupOpeningTimesToNormalFormat = (
  openingTime: OpeningTime,
  openingTimesLocalData: TempSignupConfig,
  isSameDayBeakTimeVisible: boolean,
  differentDaysClosedDays: DayOfWeek[],
  noOpeningTimes: boolean
): OpeningTime => {
  // first of all work on the opening times
  // everything same but different but same
  if (openingTimesLocalData.sameDate && !noOpeningTimes) {
    Object.values(DayOfWeek).forEach((day) => {
      let currentOpeningTime: OpeningTimeItem =
        openingTime[getCorrectOpeningtimeDay(day)];
      // fill in data for day
      currentOpeningTime.closed = !openingTimesLocalData.days.includes(day);
      currentOpeningTime.startTime = openingTimesLocalData.startTime;
      currentOpeningTime.endTime =
        openingTimesLocalData.endTime >
        (openingTimesLocalData.breakEndTime || "")
          ? openingTimesLocalData.endTime
          : openingTimesLocalData.breakEndTime!;

      if (isSameDayBeakTimeVisible) {
        openingTime.hasBreakTimes = true;

        if (openingTimesLocalData.breakStartTime)
          currentOpeningTime.startBreakTime =
            openingTimesLocalData.breakStartTime > openingTimesLocalData.endTime
              ? openingTimesLocalData.endTime
              : openingTimesLocalData.breakStartTime;
        if (openingTimesLocalData.breakEndTime)
          currentOpeningTime.endBreakTime =
            openingTimesLocalData.breakEndTime > openingTimesLocalData.endTime
              ? openingTimesLocalData.breakStartTime
              : openingTimesLocalData.breakEndTime;
      } else {
        currentOpeningTime.startBreakTime = undefined;
        currentOpeningTime.endBreakTime = undefined;
      }
      // write it back
      openingTime[getCorrectOpeningtimeDay(day)] = currentOpeningTime;
    });
    // everything different
  } else if (!openingTimesLocalData.sameDate && !noOpeningTimes) {
    Object.values(DayOfWeek).forEach((day) => {
      let currentOpeningTime: OpeningTimeItem = createEmptyOpeningTimeItem(day);
      const currentOpeningTimeCopy: OpeningTimeItem = {
        ...openingTime[getCorrectOpeningtimeDay(day)],
      };
      // fill in data for day
      currentOpeningTime.closed = !openingTimesLocalData.days.includes(day);
      currentOpeningTime.startTime = currentOpeningTimeCopy.startTime;
      currentOpeningTime.endTime =
        currentOpeningTimeCopy.endTime >
        (currentOpeningTimeCopy.endBreakTime || "")
          ? currentOpeningTimeCopy.endTime
          : currentOpeningTimeCopy.endBreakTime!;

      if (differentDaysClosedDays.includes(day)) {
        openingTime.hasBreakTimes = true;

        if (currentOpeningTimeCopy.startBreakTime) {
          currentOpeningTime.startBreakTime =
            currentOpeningTimeCopy.startBreakTime! >
            currentOpeningTimeCopy.endTime
              ? currentOpeningTimeCopy.endTime
              : currentOpeningTimeCopy.startBreakTime!;
        }
        if (currentOpeningTimeCopy.endBreakTime) {
          currentOpeningTime.endBreakTime =
            currentOpeningTimeCopy.endBreakTime! >
            currentOpeningTimeCopy.endTime
              ? currentOpeningTimeCopy.startBreakTime!
              : currentOpeningTimeCopy.endBreakTime;
        }
      } else {
        currentOpeningTime.startBreakTime = undefined;
        currentOpeningTime.endBreakTime = undefined;
      }
      // write it back
      openingTime[getCorrectOpeningtimeDay(day)] = currentOpeningTime;
    });
  }

  return openingTime;
};

/**
 *
 * Helper to convert a time string into a comparable number. if no
 * time and fallback is defined the current time is used.
 *
 * @param time string like 00:00
 * @param fallback optional fallback number if format is invalid
 * @param now optional custom "now" time
 * @returns converted milliseconds
 * @tested
 */
export const parseTimeStringToCompareableNumber = (
  time?: string,
  options: { fallback?: number; now?: Date } = { now: new Date() }
): number => {
  if (time && /^([0-1]?[0-9]|2[0-3]):[0-5]?[0-9]$/.test(time)) {
    return Date.parse(`01/01/1970 ${time}:00`);
  } else {
    if (options?.fallback !== undefined) {
      return options.fallback;
    } else {
      return Date.parse(
        `01/01/1970 ${options?.now?.getHours()}:${options?.now?.getMinutes()}:00`
      );
    }
  }
};
/**
 * Helper to fetch the holidays for a given Bundesland
 * @param selectedBundesland string containing the bundesland to fetch the holidays for
 * @param setCurrentHolidayTimeList setter to set the loaded holidays
 * @param toggleHolidayLoaded setter to toggle-true when holidays are loaded
 */
export const fetchHolidaysForCountry = (
  selectedBundesland: string,
  setCurrentHolidayTimeList: (holidayTimes: HolidayTime[]) => void,
  toggleHolidayLoaded: (toggle: boolean) => void
): void => {
  fetch(
    `https://feiertage-api.de/api/?nur_land=${selectedBundesland}&jahr=${new Date().getFullYear()}`
  )
    .then((response) => response.json())
    .then((data) => {
      let localHolidayList: HolidayTime[] = [];
      Object.keys(data).forEach((currentHoliday) => {
        localHolidayList.push(
          createEmptyHolidayTime(
            currentHoliday,
            new Date(data[currentHoliday].datum)
          )
        );
      });
      setCurrentHolidayTimeList(localHolidayList);
      toggleHolidayLoaded(true);
    });
};

/**
 * CustomSort function that can be passed to the sort() method to sort an array of days
 * @param a dayOfWeek to compare
 * @param b dayOfWeek to compare
 * @returns number for sort function
 */
export const sortDays = (a: DayOfWeek, b: DayOfWeek): number => {
  const order: DayOfWeek[] = [
    DayOfWeek.MONDAY,
    DayOfWeek.TUESDAY,
    DayOfWeek.WEDNESDAY,
    DayOfWeek.THURSDAY,
    DayOfWeek.FRIDAY,
    DayOfWeek.SATURDAY,
    DayOfWeek.SUNDAY,
  ];
  return order.indexOf(a) - order.indexOf(b);
};

/**
 * Util method to get the the week of the year
 * @param date date to get the week for. By default the actual date is used
 * @returns  String with the week of the year
 * @tested
 */
export const getWeekOfYear = (date?: Date): string => {
  const now: Date = date || new Date();
  const firstDayOfYear: Date = new Date(now.getFullYear(), 0, 1);
  const week: number = Math.ceil(
    ((now.getTime() - firstDayOfYear.getTime()) / 86400000 +
      firstDayOfYear.getDay() +
      1) /
      7
  );
  return week.toString();
};

/**
 * Utile method to generate the time range for a lunch in the correct format
 * @param lunch lunch to get the range for
 * @returns string in the format "DD.MM - DD.MM.YYYY"
 */
export const createTimeRangeStringForLunch = (lunch: Lunch): string => {
  const week: string =
    i18n.t("lunchEdit.step.1.calendarWeek") +
    " " +
    getWeekOfYear(new Date(lunch.startDate)) +
    " ";
  const rangeString: string =
    (lunch.startDate
      ? new Date(lunch.startDate)
          .toLocaleDateString("de-DE", {
            day: "2-digit",
            month: "2-digit",
          })
          .slice(0, 6) + " - "
      : i18n.t("eventUtil.noStartTime")) +
    (lunch.endDate
      ? new Date(lunch.endDate).toLocaleDateString("de-DE", {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
        })
      : i18n.t("eventUtil.noEndTime"));
  return week + rangeString;
};
