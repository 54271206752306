export interface OpeningHours {
  standard: TimeFrameCollection;
  custom: TimeFrameCollection[];
}

export interface TimeFrameCollection {
  id?: string;

  start?: Date;
  stop?: Date;

  name?: string;

  frames: TimeFrame[];

  empty: boolean;
  emptyContact: EmptyContact[];
}

export interface TimeFrame {
  open: number;
  close: number;
}

export enum EmptyContact {
  PHONE = "PHONE",
  EMAIL = "EMAIL",
  WHATSAPP = "WHATSAPP",
  SOCIAL_MEDIA = "SOCIAL_MEDIA",
}

export interface TimeFrameInput {
  open: string;
  close: string;
}

export enum WeekDay {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 0,
}

export const WeekDays = [1, 2, 3, 4, 5, 6, 0] as WeekDay[];

export type OpeningType = "normal" | "samedate" | "empty";

export interface LocalModel {
  id?: string;

  start?: string;
  stop?: string;

  name?: string;

  type: OpeningType;

  empty: boolean;
  emptyContact: EmptyContact[];

  selectedDays: WeekDay[];

  sameDayBreaks: boolean;
  sameDay: TimeFrameInput[];

  weekDayBreaks: WeekDay[];
  weekDays: TimeFrameInput[][];
}
