import {
  AdminLayoutComponent,
  ButtonComponent,
  generateNotification,
  InputComponent,
  NotificationTypes,
  PopupComponent,
  RetractableComponent,
  TableComponent,
} from "deinestadtliebt-component-library";
import { CurrentPage } from "../../utils/navigation/Navigation.types";
import { useNavLayout } from "../../utils/navigation/NavigationUtils";
import { ReactComponent as ImagesIcon } from "../../assets/icons/images.svg";
import { ReactComponent as AddIcon } from "../../assets/icons/add.svg";
import { ReactComponent as GlobeIcon } from "../../assets/icons/globe.svg";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow.svg";
import { ReactComponent as CogsIcon } from "../../assets/icons/cogs.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import { ReactComponent as EyeIcon } from "../../assets/icons/eye.svg";
import { ReactComponent as LinkIcon } from "../../assets/icons/externalLink.svg";
import "../../styles/AdminConfiguration.style.scss";
import SlideshowItemEdit from "../../components/slideshowitemedit/SlideshowItemEdit";
import { useContext, useEffect, useState } from "react";
import {
  AppConfig,
  GoodToKnow,
  GoodToKnowType,
  SlideshowItem,
} from "../../utils/appConfig/config.types";
import { ConfigContext } from "../App";
import {
  createCustomGoodToKnowItem,
  createSlideshowItem,
  deleteSlideshowItem,
  generateGoodToKnowTable,
  updateConfig,
  updateSlideshowItem,
  updateSlideshowPositions,
  updateSocialMedia,
  validateSocialMedia,
} from "../../utils/appConfig/configUtils";
import { useAxios } from "../../utils/AxiosUtil";
import { useTranslation } from "react-i18next";
import WysiwygComponent from "../../components/wysiwyg/WysiwygComponent";
import {
  fetchAllLegal,
  updateAdminClientPrivacy,
  updateAdminClientTerms,
  updateClientPrivacy,
  updateClientTerms,
} from "../../utils/legal/LegalUtils";
import { createEmptyLegal, Legal } from "../../utils/legal/Legal.types";
import {
  getCoordinatesForLocation,
  getCorrectSocialMediaIcon,
} from "../../utils/user/UserUtils";
import CustomMapComponent from "../../components/custommap/CustomMapComponent";
import { Location } from "../../utils/user/User.types";
import { LatLng } from "leaflet";
import { ConfirmableButtonComponent } from "../../components/confirmablebutton/ConfirmableButtonComponent";
import LicenceEdit from "../../components/licenceedit/LicenceEdit";
import { createEmptyLicence, Licence } from "../../utils/licence/Licence.types";
import {
  createLicence,
  deleteLicence,
  generateLicencesTableRows,
  getAllLicence,
  updateLicence,
} from "../../utils/licence/LicenceUtils";

interface AdminConfigurationPageProps {}

const AdminConfigurationPage: React.FC<AdminConfigurationPageProps> = () => {
  const { appConfig, setAppConfig } = useContext(ConfigContext);
  const [showAddSlideShowItem, toggleShowAddSlideShowItem] =
    useState<boolean>(false);
  const axios = useAxios();
  const [slideshowVersion, setSlideshowVersion] = useState<number>(0);
  const { t } = useTranslation();
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const [legal, setLegal] = useState<Legal>(createEmptyLegal());
  const [newGoodToKnowName, setNewGoodToKnowName] = useState<string>("");
  const [popupOpen, togglePopup] = useState<boolean>(false);
  const [licenceToEdit, setLicenceToEdit] = useState<Licence>();
  const [licenceToDelete, setLicenceToDelete] = useState<Licence>();
  const [loadedLicences, setLoadedLicences] = useState<Licence[]>([]);
  const [currentSelectedSlideShowItem, setCurrentSelectedSlideShowItem] =
    useState<SlideshowItem>();
  const [localZoomLevel, setLocalZoomLevel] = useState<number>(14);
  const [localAddressConfig, setLocalAddressConfig] = useState<Location>({
    street: "",
    city: "",
    zipCode: "",
    lat: 0.0,
    lng: 0.0,
  });
  const socialMediaNames: string[] = [
    "facebook",
    "instagram",
    "linkedIn",
    "whatsapp",
    "youtube",
  ];

  /**
   * This effect fetch the latest legals from client and adminclient
   */
  useEffect(() => {
    if (axios) {
      Promise.all([fetchAllLegal(axios), getAllLicence(axios)]).then(
        ([legal, lics]) => {
          setLegal(legal);
          setLoadedLicences(lics);
        }
      );
    }
  }, [axios]);

  /**
   * Helper to update client terms on the backend
   */
  const updateClientTermsOnBackend = (): void => {
    toggleLoading(true);
    updateClientTerms(legal.termsClient, axios).then((success) => {
      if (success) {
        generateNotification(
          NotificationTypes.SUCCESS,
          t("notification.title.success.changesSuccessful"),
          t("notification.content.success.termsUpdate")
        );
        toggleLoading(false);
      } else {
        generateNotification(
          NotificationTypes.ERROR,
          t("notification.title.error.changesFailed"),
          t("notification.content.error.termsUpdate")
        );
        toggleLoading(false);
      }
    });
  };

  /**
   * Helper to update admin client terms on the backend
   */
  const updateAdminClientTermsOnBackend = (): void => {
    toggleLoading(true);
    updateAdminClientTerms(legal.termsAdminClient, axios).then((success) => {
      if (success) {
        generateNotification(
          NotificationTypes.SUCCESS,
          t("notification.title.success.changesSuccessful"),
          t("notification.content.success.termsUpdate")
        );
        toggleLoading(false);
      } else {
        generateNotification(
          NotificationTypes.ERROR,
          t("notification.title.error.changesFailed"),
          t("notification.content.error.termsUpdate")
        );
        toggleLoading(false);
      }
    });
  };

  /**
   * Helper to update admin client privacy on the backend
   */
  const updateAdminClientPrivacyOnBackend = () => {
    toggleLoading(true);
    updateAdminClientPrivacy(legal.privacyAdminClient, axios).then(
      (success) => {
        if (success) {
          generateNotification(
            NotificationTypes.SUCCESS,
            t("notification.title.success.changesSuccessful"),
            t("notification.content.success.privacyUpdate")
          );
          toggleLoading(false);
        } else {
          generateNotification(
            NotificationTypes.ERROR,
            t("notification.title.error.changesFailed"),
            t("notification.content.error.privacyUpdate")
          );
          toggleLoading(false);
        }
      }
    );
  };

  /**
   * Helper to update client privacy on the backend
   */
  const updateClientPrivacyOnBackend = () => {
    toggleLoading(true);
    updateClientPrivacy(legal.privacyClient, axios).then((success) => {
      if (success) {
        generateNotification(
          NotificationTypes.SUCCESS,
          t("notification.title.success.changesSuccessful"),
          t("notification.content.success.privacyUpdate")
        );
        toggleLoading(false);
      } else {
        generateNotification(
          NotificationTypes.ERROR,
          t("notification.title.error.changesFailed"),
          t("notification.content.error.privacyUpdate")
        );
        toggleLoading(false);
      }
    });
  };

  /**
   * Helper to delete a slideshow item from the local list
   *
   * @param slideshowItem to remove
   */
  const deleteSlideshowItemFromList = (slideshowItem: SlideshowItem): void => {
    toggleLoading(true);
    deleteSlideshowItem(axios, slideshowItem).then((newSlideshowlist) => {
      setAppConfig({
        ...appConfig!,
        slideshow: newSlideshowlist,
      });
      setSlideshowVersion(slideshowVersion + 1);
      toggleLoading(false);
    });
  };

  /**
   * Helper to create new custom good to know item on backend
   */
  const createNewCustomGoodToKnowItemBackend = (): void => {
    if (isLoading || !newGoodToKnowName) return;
    toggleLoading(true);

    const newGoodToKnowItem: GoodToKnow = {
      fixed: false,
      active: true,
      name:
        newGoodToKnowName.charAt(0).toUpperCase() + newGoodToKnowName.slice(1),
      type: GoodToKnowType.CUSTOM,
    };
    createCustomGoodToKnowItem(axios, newGoodToKnowItem).then((success) => {
      if (success) {
        setAppConfig({
          ...appConfig!,
          goodToKnow: [...(appConfig?.goodToKnow || []), newGoodToKnowItem],
        });
        setNewGoodToKnowName("");
        generateNotification(
          NotificationTypes.SUCCESS,
          t("notification.title.success.changesSuccessful"),
          t("notification.content.success.configChanges")
        );
      } else {
        generateNotification(
          NotificationTypes.ERROR,
          t("notification.title.error.changesFailed"),
          t("notification.content.error.configChanges")
        );
      }
      toggleLoading(false);
    });
  };

  /**
   * Helper to update the config on the backend
   */
  const updateConfigOnBackend = async (): Promise<void> => {
    if (isLoading) return;
    toggleLoading(true);
    await updateConfig(axios, appConfig!).then((success) => {
      if (success) {
        setAppConfig({ ...appConfig! });
        setNewGoodToKnowName("");
        generateNotification(
          NotificationTypes.SUCCESS,
          t("notification.title.success.changesSuccessful"),
          t("notification.content.success.configChanges")
        );
      } else {
        generateNotification(
          NotificationTypes.ERROR,
          t("notification.title.error.changesFailed"),
          t("notification.content.error.configChanges")
        );
      }
      toggleLoading(false);
    });
  };

  /**
   * Helper to update social media on backend
   */
  const updateSocialMediaOnBackend = async (): Promise<void> => {
    if (!validateSocialMedia(appConfig!.socialMedia)) {
      generateNotification(
        NotificationTypes.ERROR,
        t("notification.title.error.missingSocialMedia"),
        t("notification.content.error.missingSocialMedia")
      );
      return;
    }

    toggleLoading(true);
    await updateSocialMedia(axios, appConfig!).then((success) => {
      if (success) {
        generateNotification(
          NotificationTypes.SUCCESS,
          t("notification.title.success.changesSuccessful"),
          t("notification.content.success.socialMediaChanges")
        );
      } else {
        generateNotification(
          NotificationTypes.ERROR,
          t("notification.title.error.changesFailed"),
          t("notification.content.error.socialMediaChanges")
        );
      }
      // setConfirming(undefined);
      toggleLoading(false);
    });
  };

  /**
   * Helper to update the current slideshow item
   *
   * @param slideshowItem which should be created or updated
   * @param slideshowImage optional image file if it is updated
   */
  const uploadNewOrAdjustSlideshowItem = (
    slideshowItem: SlideshowItem,
    slideshowImage?: File,
    mobileSlideshowImage?: File
  ): void => {
    toggleLoading(true);
    let localSlideshowItems = appConfig?.slideshow || [];
    if (slideshowItem.id) {
      updateSlideshowItem(
        axios,
        slideshowItem,
        slideshowImage,
        mobileSlideshowImage
      ).then((success) => {
        if (success) {
          const foundIndex: number = localSlideshowItems.findIndex(
            (item) => item.id === slideshowItem.id
          );
          if (foundIndex !== -1) {
            localSlideshowItems[foundIndex] = slideshowItem;
            setAppConfig({
              ...appConfig!,
              slideshow: localSlideshowItems,
            });
            setSlideshowVersion(slideshowVersion + 1);
          }
          generateNotification(
            NotificationTypes.SUCCESS,
            t("notification.title.success.changesSuccessful"),
            t("notification.content.success.slideshowUpdate")
          );
        } else {
          generateNotification(
            NotificationTypes.ERROR,
            t("notification.title.error.changesFailed"),
            t("notification.content.error.slideshowUpdate")
          );
        }
        toggleLoading(false);
      });
    } else {
      // Create new SlideshowItem
      if (!slideshowImage) {
        generateNotification(
          NotificationTypes.ERROR,
          t("notification.title.error.error"),
          t("notification.content.error.slideshowImageNotDefined")
        );
        toggleLoading(false);
        return;
      }
      createSlideshowItem(
        axios,
        slideshowItem,
        slideshowImage,
        mobileSlideshowImage
      ).then((createdSlideshowItem) => {
        if (createdSlideshowItem) {
          localSlideshowItems.push(createdSlideshowItem);
          setAppConfig({ ...appConfig!, slideshow: localSlideshowItems });
          toggleShowAddSlideShowItem(!showAddSlideShowItem);
          setSlideshowVersion(slideshowVersion + 1);
        }
        toggleLoading(false);
      });
    }
  };

  /**
   * Makes Call to get Coordinates corresponding to the given address and sets localAddressConfig
   */
  const setMapCenter = async (): Promise<void> => {
    await getCoordinatesForLocation(axios, localAddressConfig).then(
      (response) => {
        setLocalAddressConfig({
          ...localAddressConfig!,
          lat: response[1],
          lng: response[0],
        });
      }
    );
  };

  /**
   * Sets localAddressConfig with the given coordinate-pair
   * @param latlng type of LatLng
   */
  const setMarkerForCenter = (latlng: LatLng): void => {
    setLocalAddressConfig({
      ...localAddressConfig,
      lat: latlng.lat,
      lng: latlng.lng,
    });
  };

  /**
   * Sets the zoomLevel when zoom changes
   * @param zoomLevel number indicating zoomScale
   */
  const setZoomLevel = (newZoomLevel: number): void => {
    setLocalZoomLevel(newZoomLevel);
  };

  /**
   * Method to set center coordinates to appConfig and generate Success Notification
   */
  const saveMapCenterLocation = (): void => {
    let localAppConfig: AppConfig = {
      ...appConfig!,
      map: {
        ...appConfig!.map,
        center: [localAddressConfig.lng, localAddressConfig.lat],
        zoom: localZoomLevel,
      },
    };
    updateConfig(axios, localAppConfig).then((success) => {
      if (success) {
        generateNotification(
          NotificationTypes.SUCCESS,
          t("notification.title.success.changesSuccessful"),
          t("notification.content.success.configChanges")
        );
        setAppConfig(localAppConfig);
      } else {
        generateNotification(
          NotificationTypes.ERROR,
          t("notification.title.error.changesFailed"),
          t("notification.content.error.configChanges")
        );
      }
      toggleLoading(false);
    });
  };

  /**
   * Helper to move the slideitem to the correct position
   * @param slideItem item to move up or down
   * @param isUp boolean if the direction is up or down
   */
  const moveSlideItem = (slideItem: SlideshowItem, isUp: boolean): void => {
    toggleLoading(true);
    let localAppConfig: AppConfig = { ...appConfig! };
    let foundIndexToChange: number = localAppConfig.slideshow.findIndex(
      (item) => item.position === slideItem.position
    );
    // slideshow item source not found
    if (foundIndexToChange === -1) {
      generateNotification(
        NotificationTypes.ERROR,
        t("notification.title.error.error"),
        t("notification.content.error.slideshowMovementNotfound")
      );
      return;
    }
    // fetch second item
    let foundSecondIndexToChange: number = -1;
    if (isUp) {
      foundSecondIndexToChange = localAppConfig.slideshow.findIndex(
        (item) => item.position === slideItem.position! - 1
      );
    } else {
      foundSecondIndexToChange = localAppConfig.slideshow.findIndex(
        (item) => item.position === slideItem.position! + 1
      );
    }
    // target slideshow item to change postion too not found
    if (foundSecondIndexToChange === -1) {
      generateNotification(
        NotificationTypes.ERROR,
        t("notification.title.error.error"),
        t("notification.content.error.slideshowSecondMovementNotfound")
      );
      return;
    }
    // check if the item is goind up or down
    if (isUp) {
      localAppConfig.slideshow[foundIndexToChange].position =
        localAppConfig.slideshow[foundIndexToChange].position! - 1;
      localAppConfig.slideshow[foundSecondIndexToChange].position =
        localAppConfig.slideshow[foundSecondIndexToChange].position! + 1;
    } else {
      localAppConfig.slideshow[foundIndexToChange].position =
        localAppConfig.slideshow[foundIndexToChange].position! + 1;
      localAppConfig.slideshow[foundSecondIndexToChange].position =
        localAppConfig.slideshow[foundSecondIndexToChange].position! - 1;
    }
    updateSlideshowPositions(axios, localAppConfig.slideshow).then(
      (success) => {
        if (success) {
          setAppConfig({ ...localAppConfig });
        } else
          generateNotification(
            NotificationTypes.ERROR,
            t("notification.title.error.error"),
            t("notification.content.error.movementError")
          );
        toggleLoading(false);
      }
    );
  };

  /**
   * Helper to handle the move click on a position arrow
   * @param item slideshowitem to move
   * @param isUp is it an up movement
   */
  const handleMoveIconClick = (item: SlideshowItem, isUp: boolean): void => {
    if (isLoading) return;
    if (isUp) {
      if (item.position === 1) {
        generateNotification(
          NotificationTypes.WARNING,
          t("notification.title.error.error"),
          t("notification.content.warning.slideItemFirst")
        );
        return;
      }
    } else {
      if (item.position === appConfig?.slideshow.length) {
        generateNotification(
          NotificationTypes.WARNING,
          t("notification.title.error.error"),
          t("notification.content.warning.slideItemLast")
        );
        return;
      }
    }
    moveSlideItem(item, isUp);
  };

  /**
   * Helper to generate a slideshow entry wrapper
   *
   * @param item slideshow item to display
   * @param itemIndex current index in map of the slideshow item
   * @returns the generated wrapper for render
   */
  const generateSlideshowItemWrapper = (
    item: SlideshowItem,
    itemIndex: number
  ): JSX.Element => (
    <RetractableComponent
      title={
        <div className="slideshow-item-wrapper">
          <div className="slideshow-item-wrapper--index">{itemIndex + 1}</div>
          <div
            className="slideshow-item-wrapper--title"
            style={isSlideActive(item) ? { color: "green" } : { color: "red" }}
          >
            {`${
              item.name || t("adminConfigurationPage.slideshowEntry.noName")
            } (${
              isSlideActive(item)
                ? t("adminConfigurationPage.active")
                : t("adminConfigurationPage.inactive")
            } :: ${item.position})`}
          </div>

          <div className="flex-it">
            <div
              onClick={(evt) => {
                evt.stopPropagation();
                evt.preventDefault();
                handleMoveIconClick(item, true);
              }}
            >
              <ArrowIcon className="slideshow-item-wrapper--arrowUp" />
            </div>
            <div
              onClick={(evt) => {
                evt.stopPropagation();
                evt.preventDefault();
                handleMoveIconClick(item, false);
              }}
            >
              <ArrowIcon className="slideshow-item-wrapper--arrowDown" />
            </div>
          </div>

          <div className="slideshow-item-wrapper--date">
            {item.startDate
              ? new Date(item.startDate).toLocaleDateString("de")
              : t("adminConfigurationPage.slideshowEntry.noStart")}{" "}
            -{" "}
            {item.endDate
              ? new Date(item.endDate).toLocaleDateString("de")
              : t("adminConfigurationPage.slideshowEntry.noEnd")}
          </div>
          <TrashIcon
            className="slideshow-item-wrapper--trash-icon"
            onClick={() => {
              setCurrentSelectedSlideShowItem(item);
              togglePopup(true);
            }}
          />
        </div>
      }
      key={`slideshow-item-${item.id}`}
    >
      <SlideshowItemEdit
        onDelete={deleteSlideshowItemFromList}
        onSubmit={uploadNewOrAdjustSlideshowItem}
        slideshowItemToEdit={item}
        isLoading={isLoading}
      />
    </RetractableComponent>
  );

  /**
   * Helper to determine if a slideshow item is currently active to be displayed
   *
   * @param item curretn slideshow item
   * @returns true if it is valid
   */
  const isSlideActive = (item: SlideshowItem): boolean => {
    let isStartActive: boolean = false;
    let isEndActive: boolean = false;
    let providerActive: boolean = true;
    if (!item.startDate) isStartActive = true;
    else {
      isStartActive = new Date() >= new Date(item.startDate!);
    }
    if (!item.endDate) isEndActive = true;
    else {
      isEndActive = new Date() <= new Date(item.endDate!);
    }
    if (item.providerId) providerActive = !!item.active;
    return isStartActive && isEndActive && providerActive;
  };

  /**
   * useEffect that loads the coordinates for the map from the appConfig if they are set.
   * Otherwise asks for geolocation and sets coordinates either to location or 0,0
   */
  useEffect(() => {
    if (appConfig?.map?.zoom) setLocalZoomLevel(appConfig.map.zoom);

    if (
      appConfig?.map?.center &&
      (appConfig?.map?.center[0] !== 0 || appConfig?.map?.center[1]) !== 0
    ) {
      setLocalAddressConfig({
        ...localAddressConfig,
        lng: appConfig?.map?.center[0],
        lat: appConfig?.map?.center[1],
      });
    } else if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLocalAddressConfig({
          ...localAddressConfig,
          lng: position.coords.longitude,
          lat: position.coords.latitude,
        });
      });
    }
    // eslint-disable-next-line
  }, [appConfig]);

  /**
   * Helper to delete a licence on the backend
   */
  const deleteLicenceButton = (): void => {
    deleteLicence(axios, licenceToDelete!).then((success) => {
      if (success) {
        setLoadedLicences((old: Licence[]) =>
          old.filter((license) => license.id !== licenceToDelete?.id)
        );
        setLicenceToDelete(undefined);
      }
    });
  };

  /**
   * Helper to create or update licence on server
   */
  const createOrUpdateLicence = (): void => {
    if (!licenceToEdit?.id)
      createLicence(axios, licenceToEdit!).then((licence) => {
        if (!licence) return;
        setLoadedLicences([...loadedLicences, licence]);
        setLicenceToEdit(undefined);
      });
    else
      updateLicence(axios, licenceToEdit!).then((licence) => {
        if (!licence) return;
        let foundIndex: number = loadedLicences.findIndex(
          (val) => val.id === licence.id
        );
        if (foundIndex !== -1) {
          let localLicences: Licence[] = [...loadedLicences];
          localLicences[foundIndex] = licence;
          setLoadedLicences([...localLicences]);
          setLicenceToEdit(undefined);
        }
      });
  };

  /**
   * Util method to update the categoryLink of the appConfig
   */
  const handleUpdateCategoryLink = (): void => {
    if (!appConfig) return;
    updateConfig(axios, appConfig).then((success) => {
      if (success) {
        generateNotification(
          NotificationTypes.SUCCESS,
          t("notification.title.success.changesSuccessful"),
          t("notification.content.success.linkUpdate")
        );
      } else {
        generateNotification(
          NotificationTypes.ERROR,
          t("notification.title.error.changesFailed"),
          t("notification.content.error.linkUpdate")
        );
      }
    });
  };

  return (
    <AdminLayoutComponent {...useNavLayout(CurrentPage.CONFIGURATION_ADMIN)}>
      <div
        style={{ background: appConfig?.highlightColor }}
        className="default-page-headline"
      >
        <h1>{t("header.config")}</h1>
      </div>

      <div className="admin-configuration-wrapper default-page-wrapper">
        <RetractableComponent
          title={t("adminConfigurationPage.slideshowTitle")}
          type="border"
          icon={<ImagesIcon />}
        >
          <p>{t("adminConfigurationPage.slideshowIntervall")}</p>
          <div className="flex-it">
            <InputComponent
              isFrameless
              value={appConfig?.slideshowIntervall || 0}
              type="number"
              step="1"
              onChange={(value) =>
                setAppConfig({
                  ...appConfig!,
                  slideshowIntervall: parseInt(value),
                })
              }
            />
            <ConfirmableButtonComponent
              onAccept={updateConfigOnBackend}
              openButton={{
                value: t("adminConfigurationPage.buttons.save"),
                isLoading,
                type: "button",
              }}
            >
              {t("adminConfigurationPage.saveConfiguration")}
            </ConfirmableButtonComponent>
          </div>

          <div key={`slideshow-admin-${slideshowVersion}`}>
            <div>
              {showAddSlideShowItem ? (
                <SlideshowItemEdit
                  isLoading={isLoading}
                  onSubmit={uploadNewOrAdjustSlideshowItem}
                  onClose={() =>
                    toggleShowAddSlideShowItem(!showAddSlideShowItem)
                  }
                />
              ) : (
                <>
                  <div className="slideshow-item-wrapper">
                    <div className="slideshow-item-wrapper--index">#</div>
                    <div className="slideshow-item-wrapper--title">
                      {t("adminConfigurationPage.intern")}
                    </div>
                    <div className="slideshow-item-wrapper--date">
                      {t("adminConfigurationPage.time")}
                    </div>
                    <div className="slideshow-item-wrapper--trash">
                      {t("adminConfigurationPage.delete")}
                    </div>
                  </div>
                  {appConfig?.slideshow
                    .sort(
                      (firstItem: SlideshowItem, secondItem: SlideshowItem) =>
                        firstItem.position! - secondItem.position!
                    )
                    .map((item: SlideshowItem, itemIndex: number) =>
                      generateSlideshowItemWrapper(item, itemIndex)
                    )}
                  <div
                    className="admin-configuration-wrapper--icon-wrapper"
                    onClick={() =>
                      toggleShowAddSlideShowItem(!showAddSlideShowItem)
                    }
                  >
                    {currentSelectedSlideShowItem && (
                      <PopupComponent
                        open={popupOpen}
                        toggleOpen={togglePopup}
                        title={t(
                          "adminConfigurationPage.deleteSlideShowItemTitle"
                        )}
                        bottomButtons={[
                          {
                            value: t("adminConfigurationPage.buttons.close"),
                            onClick: () => togglePopup(false),
                          },
                          {
                            value: t("adminConfigurationPage.buttons.delete"),
                            colors: { default: { bgColor: "red" } },
                            onClick: () => {
                              togglePopup(false);
                              deleteSlideshowItemFromList(
                                currentSelectedSlideShowItem!
                              );
                            },
                          },
                        ]}
                      >
                        <p>
                          {t(
                            "adminConfigurationPage.deleteSlideShowItemContent"
                          )}
                        </p>
                      </PopupComponent>
                    )}

                    <AddIcon className="admin-configuration-wrapper--add-icon" />
                    {t("adminConfigurationPage.addSlideshowItem")}
                  </div>
                </>
              )}
            </div>
          </div>
        </RetractableComponent>

        <RetractableComponent
          title={t("adminConfigurationPage.socialMediaTitle")}
          type="border"
          icon={<GlobeIcon />}
        >
          <p>
            {t("adminConfigurationPage.socialMediaDescription", {
              replace: { cityname: appConfig?.cityName },
            })}
          </p>
          {socialMediaNames.map((name) => (
            <div
              className="social-media-item-config"
              key={`input-name-social-media-${name}`}
            >
              <div>{getCorrectSocialMediaIcon(name)}</div>
              <InputComponent
                value={appConfig?.socialMedia[name] || ""}
                isFrameless
                placeholder={t(
                  `adminConfigurationPage.socialMediaNames.${name}`
                )}
                onChange={(value) =>
                  setAppConfig({
                    ...appConfig!,
                    socialMedia: { ...appConfig!.socialMedia, [name]: value },
                  })
                }
              />
            </div>
          ))}
          <ConfirmableButtonComponent
            onAccept={updateSocialMediaOnBackend}
            openButton={{
              value: t("adminConfigurationPage.buttons.save"),
              isLoading,
              type: "button",
            }}
          >
            {t("adminConfigurationPage.saveConfiguration")}
          </ConfirmableButtonComponent>
        </RetractableComponent>

        <RetractableComponent
          title={t("adminConfigurationPage.globalConfigTitle")}
          type="border"
          icon={<CogsIcon />}
        >
          <p>{t("adminConfigurationPage.platformName")}</p>
          <InputComponent
            value={appConfig?.platformName || ""}
            isFrameless
            placeholder={t("adminConfigurationPage.platformName")}
            onChange={(value) =>
              setAppConfig({
                ...appConfig!,
                platformName: value,
              })
            }
          />
          <p>{t("adminConfigurationPage.cityName")}</p>
          <InputComponent
            value={appConfig?.cityName || ""}
            isFrameless
            placeholder={t("adminConfigurationPage.cityName")}
            onChange={(value) =>
              setAppConfig({
                ...appConfig!,
                cityName: value,
              })
            }
          />
          <p>{t("adminConfigurationPage.color.mainColor")}</p>
          <InputComponent
            value={appConfig?.mainColor || ""}
            isFrameless
            type="color"
            placeholder={t("adminConfigurationPage.color.mainColor")}
            onChange={(value) =>
              setAppConfig({
                ...appConfig!,
                mainColor: value,
              })
            }
          />
          <p>{t("adminConfigurationPage.color.hightlight")}</p>
          <InputComponent
            value={appConfig?.highlightColor || ""}
            isFrameless
            type="color"
            placeholder={t("adminConfigurationPage.color.hightlight")}
            onChange={(value) =>
              setAppConfig({
                ...appConfig!,
                highlightColor: value,
              })
            }
          />
          <p>{t("adminConfigurationPage.color.font")}</p>
          <InputComponent
            value={appConfig?.fontColor || ""}
            type="color"
            isFrameless
            placeholder={t("adminConfigurationPage.color.font")}
            onChange={(value) =>
              setAppConfig({
                ...appConfig!,
                fontColor: value,
              })
            }
          />
          <p>{t("adminConfigurationPage.color.accent")}</p>
          <InputComponent
            value={appConfig?.accentColor || ""}
            type="color"
            isFrameless
            placeholder={t("adminConfigurationPage.color.accent")}
            onChange={(value) =>
              setAppConfig({
                ...appConfig!,
                accentColor: value,
              })
            }
          />
          <ConfirmableButtonComponent
            openButton={{
              value: t("adminConfigurationPage.buttons.save"),
              type: "button",
              isLoading,
            }}
            onAccept={updateConfigOnBackend}
          >
            {t("adminConfigurationPage.saveConfiguration")}
          </ConfirmableButtonComponent>
        </RetractableComponent>

        <RetractableComponent
          title={t("adminConfigurationPage.titleGoodToKnow")}
          type="border"
          icon={<ImagesIcon />}
        >
          <b>{t("adminConfigurationPage.addNewCustomGoodToKnow")}</b>
          <InputComponent
            value={newGoodToKnowName}
            onChange={setNewGoodToKnowName}
          />
          <ButtonComponent
            value={t("adminConfigurationPage.buttons.save")}
            type="button"
            isLoading={isLoading}
            onClick={createNewCustomGoodToKnowItemBackend}
          />

          <TableComponent
            maxPageAmount={20}
            header={t("adminConfigurationPage.tableHeaderGoodToKnow", {
              returnObjects: true,
            })}
            rows={generateGoodToKnowTable(appConfig!)}
          />
        </RetractableComponent>

        <RetractableComponent
          title={t("adminConfigurationPage.terms.client")}
          type="border"
          icon={<CogsIcon />}
        >
          <WysiwygComponent
            signCap={50000}
            value={legal.termsClient}
            onChange={(termsClient) =>
              setLegal({ ...legal, ...{ termsClient } })
            }
          />
          <ButtonComponent
            value={t("adminConfigurationPage.buttons.save")}
            onClick={updateClientTermsOnBackend}
          />
        </RetractableComponent>

        <RetractableComponent
          title={t("adminConfigurationPage.terms.adminClient")}
          type="border"
          icon={<CogsIcon />}
        >
          <WysiwygComponent
            signCap={50000}
            value={legal.termsAdminClient}
            onChange={(termsAdminClient) =>
              setLegal({ ...legal, ...{ termsAdminClient } })
            }
          />
          <ButtonComponent
            value={t("adminConfigurationPage.buttons.save")}
            onClick={updateAdminClientTermsOnBackend}
          />
        </RetractableComponent>

        <RetractableComponent
          title={t("adminConfigurationPage.privacy.client")}
          type="border"
          icon={<CogsIcon />}
        >
          <WysiwygComponent
            signCap={50000}
            value={legal.privacyClient}
            onChange={(privacyClient) =>
              setLegal({ ...legal, ...{ privacyClient } })
            }
          />
          <ButtonComponent
            value={t("adminConfigurationPage.buttons.save")}
            onClick={updateClientPrivacyOnBackend}
          />
        </RetractableComponent>

        <RetractableComponent
          title={t("adminConfigurationPage.privacy.adminClient")}
          type="border"
          icon={<CogsIcon />}
        >
          <WysiwygComponent
            signCap={50000}
            value={legal.privacyAdminClient}
            onChange={(privacyAdminClient) =>
              setLegal({ ...legal, ...{ privacyAdminClient } })
            }
          />
          <ButtonComponent
            value={t("adminConfigurationPage.buttons.save")}
            onClick={updateAdminClientPrivacyOnBackend}
          />
        </RetractableComponent>
        <RetractableComponent
          title={t("adminConfigurationPage.aboutUs.title")}
          icon={<CogsIcon />}
          type="border"
        >
          <InputComponent
            signCap={20}
            value={appConfig?.aboutUs?.title || ""}
            type="text"
            onChange={(title) => {
              if (!appConfig) return;
              setAppConfig({
                ...appConfig,
                aboutUs: {
                  content: appConfig.aboutUs?.content || "",
                  title,
                },
              });
            }}
            placeholder={t("adminConfigurationPage.aboutUs.placeholder")}
          />
          <WysiwygComponent
            signCap={50000}
            value={appConfig?.aboutUs?.content || ""}
            onChange={(content) => {
              if (!appConfig) return;
              setAppConfig({
                ...appConfig,
                aboutUs: { title: appConfig?.aboutUs?.title || "", content },
              });
            }}
          />
          <ConfirmableButtonComponent
            onAccept={updateConfigOnBackend}
            openButton={{
              value: t("adminConfigurationPage.buttons.save"),
              isLoading,
              type: "button",
            }}
          >
            {t("adminConfigurationPage.saveAboutUs")}
          </ConfirmableButtonComponent>
        </RetractableComponent>
        <RetractableComponent
          title={t("adminConfigurationPage.titleMapConfig")}
          type="border"
          icon={<EyeIcon />}
        >
          <div className="admin-configuration-wrapper--map-customization">
            <div className="admin-configuration-wrapper--map-customization-input">
              <p className="admin-configuration-wrapper--map-customization-text">
                {t("adminConfigurationPage.addressSearchTip")}
              </p>
              <div className="flex-it column">
                <p>{t("newSignup.nameAndLocation.street")}</p>
                <InputComponent
                  type="text"
                  isFrameless
                  value={localAddressConfig.street}
                  onChange={(value) =>
                    setLocalAddressConfig({
                      ...localAddressConfig!,
                      street: value,
                    })
                  }
                />
              </div>
              <div className="flex-it">
                <div className="split-width">
                  <p>{t("newSignup.nameAndLocation.zipCode")}</p>
                  <InputComponent
                    isFrameless
                    type="number"
                    max={5}
                    value={localAddressConfig.zipCode}
                    onChange={(value) =>
                      setLocalAddressConfig({
                        ...localAddressConfig!,
                        zipCode: value,
                      })
                    }
                  />
                </div>
                <div className="split-width">
                  <p>{t("newSignup.nameAndLocation.city")}</p>
                  <InputComponent
                    isFrameless
                    value={localAddressConfig.city}
                    onChange={(value) =>
                      setLocalAddressConfig({
                        ...localAddressConfig!,
                        city: value,
                      })
                    }
                  />
                </div>
              </div>
              <ButtonComponent
                value={t("adminConfigurationPage.searchAddress")}
                onClick={() => setMapCenter()}
              />
            </div>
            <p className="admin-configuration-wrapper--map-customization-text">
              {t("adminConfigurationPage.mapConfigTip")}
            </p>
            <div className="admin-configuration-wrapper--map-customization-map">
              <CustomMapComponent
                defaultZoom={localZoomLevel}
                key={`map-key-${localAddressConfig.lat}-${localAddressConfig.lng}`}
                center={[localAddressConfig.lat, localAddressConfig.lng]}
                markerList={[
                  {
                    location: [localAddressConfig.lat, localAddressConfig.lng],
                    name: t("adminConfigurationPage.mapCenter"),
                  },
                ]}
                activateCreation
                getCreationPosition={(lnglat) => setMarkerForCenter(lnglat)}
                getZoomChange={(zoomLevel) => setZoomLevel(zoomLevel)}
              />
              <div className="admin-configuration-wrapper--map-customization-map-coords">
                <p>
                  {t("adminConfigurationPage.latitude", {
                    replace: { lat: localAddressConfig?.lat?.toFixed(4) },
                  })}
                </p>
                <p>
                  {t("adminConfigurationPage.longitude", {
                    replace: { lng: localAddressConfig?.lng?.toFixed(4) },
                  })}
                </p>
                <p>
                  {t("adminConfigurationPage.zoom", {
                    replace: { zoom: localZoomLevel },
                  })}
                </p>
              </div>
            </div>
            <div className="admin-configuration-wrapper--map-customization-save">
              <ButtonComponent
                value={t("adminConfigurationPage.saveLocation")}
                onClick={() => saveMapCenterLocation()}
              />
            </div>
          </div>
        </RetractableComponent>
        <RetractableComponent
          title={t("adminConfigurationPage.categoryHelpLink")}
          type="border"
          icon={<LinkIcon />}
        >
          <InputComponent
            value={appConfig?.categoryHelpLink || ""}
            type="url"
            onChange={(categoryHelpLink) =>
              appConfig && setAppConfig({ ...appConfig, categoryHelpLink })
            }
          />
          <ButtonComponent
            value={t("adminConfigurationPage.buttons.save")}
            onClick={handleUpdateCategoryLink}
          />
        </RetractableComponent>
        {JSON.parse(process.env.REACT_APP_USE_LICENCE!) && (
          <>
            <PopupComponent
              open={!!licenceToEdit}
              toggleOpen={() => setLicenceToEdit(undefined)}
            >
              <LicenceEdit
                licence={licenceToEdit!}
                setLicence={setLicenceToEdit}
                onSave={createOrUpdateLicence}
              />
            </PopupComponent>

            <PopupComponent
              open={!!licenceToDelete}
              toggleOpen={() => setLicenceToDelete(undefined)}
              bottomButtons={[
                {
                  value: t("adminConfigurationPage.buttons.cancel"),
                  type: "button",
                  onClick: () => setLicenceToDelete(undefined),
                },
                {
                  value: t("adminConfigurationPage.buttons.delete"),
                  type: "button",
                  onClick: () => deleteLicenceButton(),
                },
              ]}
            >
              <p>
                {t("adminConfigurationPage.deleteLicence", {
                  replace: { name: licenceToDelete?.name },
                })}
              </p>
            </PopupComponent>

            <RetractableComponent
              title={t("adminConfigurationPage.licenceTitle")}
              type="border"
              icon={<EyeIcon />}
            >
              <div onClick={() => setLicenceToEdit(createEmptyLicence())}>
                <AddIcon className="admin-configuration-wrapper--add-icon" />
              </div>
              <TableComponent
                rows={generateLicencesTableRows(
                  loadedLicences,
                  (lic: Licence) => setLicenceToEdit(lic),
                  (lic: Licence) => setLicenceToDelete(lic)
                )}
                header={t("adminConfigurationPage.licenceTableHeader", {
                  returnObjects: true,
                })}
              />
            </RetractableComponent>
          </>
        )}
      </div>
    </AdminLayoutComponent>
  );
};

export default AdminConfigurationPage;
