import { EventLocationProps } from "./EventLocationEdit.types";
import {
  ButtonComponent,
  generateNotification,
  InputComponent,
  NotificationTypes,
  TableComponent,
} from "deinestadtliebt-component-library";
import { useTranslation } from "react-i18next";
import { useContext, useState } from "react";
import {
  createEmptyEventLocation,
  EventLocation,
  Provider,
  UserRole,
  VVKLocation,
} from "../../utils/user/User.types";
import CustomMapComponent from "../custommap/CustomMapComponent";
import { generateEventLocationableRows } from "../../utils/event/EventUtils";
import {
  getCoordinatesForLocation,
  updateProviderLocationData,
} from "../../utils/user/UserUtils";
import { useAxios } from "../../utils/AxiosUtil";
import { UserContext } from "../../pages/App";

export const EventLocationEdit: React.FC<EventLocationProps> = ({
  isLoading,
  toggleLoading,
  provider,
  setProvider,
  type = "event",
}) => {
  const { t } = useTranslation();
  const [currentWorkingLocation, setCurrentWorkingLocation] = useState<
    EventLocation | VVKLocation
  >(createEmptyEventLocation());
  const [selectedEventLocation, setSelectedEventLocation] = useState<
    EventLocation | VVKLocation
  >({
    id: "",
    location: provider.location,
    name: provider.name,
  });
  const axios = useAxios();
  const { setUser, user } = useContext(UserContext);

  /**
   * Helper to modify the content of the event location items for a provider
   * @param idToRemove if something has to be removed
   */
  const changeEventLocationItems = async (
    idToRemove?: string
  ): Promise<void> => {
    toggleLoading(true);
    // add or remove lunch item
    let localLocationsItems: EventLocation[] | VVKLocation[] =
      type === "event"
        ? [...provider.eventLocationItems]
        : [...provider.advanceBookingOffices];
    if (idToRemove) {
      localLocationsItems = localLocationsItems.filter(
        (item) => item.id !== idToRemove
      );
    } else {
      let loadedCoords: [lng: number, lat: number] =
        await getCoordinatesForLocation(axios, currentWorkingLocation.location);
      localLocationsItems.push({
        ...currentWorkingLocation,
        location: {
          ...currentWorkingLocation.location,
          lng: loadedCoords[0],
          lat: loadedCoords[1],
        },
      });
    }

    const editedProvider: Provider =
      type === "event"
        ? {
            ...provider,
            eventLocationItems: localLocationsItems,
          }
        : {
            ...provider,
            advanceBookingOffices: localLocationsItems,
          };
    updateProviderLocationData(axios, editedProvider, type).then((success) => {
      if (success) {
        setProvider(editedProvider);
        if (user?.role !== UserRole.ADMIN && setUser) setUser(editedProvider);
        setCurrentWorkingLocation(createEmptyEventLocation());
        generateNotification(
          NotificationTypes.SUCCESS,
          t(
            `notification.title.success.${
              idToRemove ? "changesSuccessful" : "creationSuccessful"
            }`
          ),
          t(`notification.content.success.providerUpdate`)
        );
      } else
        generateNotification(
          NotificationTypes.ERROR,
          t(`notification.title.error.changesFailed`),
          t(`notification.content.error.providerUpdate`)
        );
      toggleLoading(false);
    });
  };

  return (
    <div className="flex-it-between event-data-wrapper">
      <div className="half-the-width">
        <InputComponent
          isFrameless
          value={currentWorkingLocation.name}
          onChange={(value) =>
            setCurrentWorkingLocation({
              ...currentWorkingLocation,
              name: value,
            })
          }
          placeholder={t("eventLocationEdit.input.name")}
        />
        <InputComponent
          isFrameless
          value={currentWorkingLocation.location.street}
          onChange={(value) =>
            setCurrentWorkingLocation({
              ...currentWorkingLocation,
              location: {
                ...currentWorkingLocation.location,
                street: value,
              },
            })
          }
          placeholder={t("eventLocationEdit.input.street")}
        />
        <div className="flex-it">
          <InputComponent
            isFrameless
            value={currentWorkingLocation.location.zipCode}
            onChange={(value) =>
              setCurrentWorkingLocation({
                ...currentWorkingLocation,
                location: {
                  ...currentWorkingLocation.location,
                  zipCode: value,
                },
              })
            }
            placeholder={t("eventLocationEdit.input.zipCode")}
          />
          <InputComponent
            isFrameless
            value={currentWorkingLocation.location.city}
            onChange={(value) =>
              setCurrentWorkingLocation({
                ...currentWorkingLocation,
                location: {
                  ...currentWorkingLocation.location,
                  city: value,
                },
              })
            }
            placeholder={t("eventLocationEdit.input.city")}
          />
        </div>
        {type === "booking" && (
          <>
            <InputComponent
              placeholder={t("eventLocationEdit.input.phone")}
              type="tel"
              value={currentWorkingLocation.phone ?? ""}
              isFrameless
              onChange={(phone) =>
                setCurrentWorkingLocation({ ...currentWorkingLocation, phone })
              }
            />
            <InputComponent
              placeholder={t("eventLocationEdit.input.mail")}
              type="email"
              value={currentWorkingLocation.mail ?? ""}
              isFrameless
              onChange={(mail) =>
                setCurrentWorkingLocation({ ...currentWorkingLocation, mail })
              }
            />
            <InputComponent
              placeholder={t("eventLocationEdit.input.page")}
              type="text"
              value={currentWorkingLocation.website ?? ""}
              isFrameless
              onChange={(website) =>
                setCurrentWorkingLocation({
                  ...currentWorkingLocation,
                  website,
                })
              }
            />
          </>
        )}
        <ButtonComponent
          isLoading={isLoading}
          onClick={() => changeEventLocationItems()}
          value={t("eventLocationEdit.button.add")}
        />
        <TableComponent
          header={t("eventLocationEdit.table", { returnObjects: true })}
          maxPageAmount={4}
          rows={generateEventLocationableRows(
            type === "event"
              ? provider.eventLocationItems
              : provider.advanceBookingOffices,
            setSelectedEventLocation,
            changeEventLocationItems
          )}
        />
      </div>
      <div className="half-the-width map-wrapper-container">
        <CustomMapComponent
          key={`map-key-${selectedEventLocation.location.lat}-${selectedEventLocation.location.lng}`}
          center={[
            selectedEventLocation.location.lat,
            selectedEventLocation.location.lng,
          ]}
          markerList={[
            {
              location: [
                selectedEventLocation.location.lat,
                selectedEventLocation.location.lng,
              ],
              name: selectedEventLocation.name,
            },
          ]}
        />
      </div>
    </div>
  );
};
