import { AxiosInstance } from "axios";
import {
  ButtonComponent,
  CheckboxComponent,
  DropdownComponent,
  DropdownOption,
  generateNotification,
  InputComponent,
  NotificationTypes,
  TableComponent,
  TableRow,
} from "deinestadtliebt-component-library";
import {
  DayOfWeekArray,
  Event,
  Eventstate,
  PriceCategory,
  TempTimelapse,
  TicketType,
} from "./Event.types";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import { ReactComponent as CancelIcon } from "../../assets/icons/cancel.svg";
import { ReactComponent as PlayIcon } from "../../assets/icons/play.svg";
import { ReactComponent as EyeIcon } from "../../assets/icons/eye.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";
import { ReactComponent as CopyIcon } from "../../assets/icons/copy.svg";
import i18n from "../../i18n";
import {
  EventLocation,
  IdName,
  PriceItem,
  VVKLocation,
} from "../user/User.types";
import { generateTimeStringForDate } from "../time/TimeUtils";
import { getProviderNameFroIdNameMap } from "../action/ActionUtils";
import React from "react";
import { ActivityEditCompanion } from "../../components/newEventEdit/EventEditBoxComponent.types";
import { DiscountType } from "../action/Action.types";
import { isActivityInTheFuture } from "../activity/ActivityUtils";
import { ActivityType } from "../activity/Activity.types";

/**
 * API METHOD - creates an event with optional image on the server
 * @param axios network instance
 * @param event event which should be created
 * @param eventImage optional file to upload with
 * @param eventFlyer optional flyer for uploading
 * @returns newly created event or null
 */
export const createEvent = async (
  axios: AxiosInstance,
  event: Event,
  eventImage?: File,
  flyerFile?: File,
  currentImagePreviewFile?: File
): Promise<Event> => {
  let uploadFormData = new FormData();
  const eventBlob = new Blob([JSON.stringify(event)], {
    type: "application/json",
  });
  uploadFormData.append("newEvent", eventBlob);
  if (eventImage) uploadFormData.append("fileData", eventImage);
  if (flyerFile) uploadFormData.append("flyerData", flyerFile);
  if (currentImagePreviewFile)
    uploadFormData.append("previewImage", currentImagePreviewFile);
  return axios
    .post("/event/event/", uploadFormData)
    .then((eventResp) => eventResp.data)
    .catch((exc) => {
      console.error("Error during uploading/creating new event!", exc);

      switch (true) {
        case exc.response && exc.response.status === 412:
          generateNotification(
            NotificationTypes.WARNING,
            i18n.t("notification.content.warning.licence"),
            i18n.t("notification.content.warning.licenceEvent")
          );
          break;

        default:
          generateNotification(
            NotificationTypes.ERROR,
            i18n.t("notification.title.error.error"),
            i18n.t("notification.content.error.eventCreation")
          );
          break;
      }

      return null;
    });
};

/**
 * API METHOD - to update an event on the backend
 * @param axios network instance
 * @param event event which should be updated
 * @param eventImage optional image to update for the current event
 * @param eventFlyer optional flyer for uploading
 * @returns success if a statuscode 200 is recieved
 */
export const updateEventOnBackend = async (
  axios: AxiosInstance,
  event: Event,
  eventImage?: File,
  flyerFile?: File,
  currentImagePreviewFileß?: File
): Promise<Event> => {
  let uploadFormData = new FormData();
  const eventBlob = new Blob([JSON.stringify(event)], {
    type: "application/json",
  });
  uploadFormData.append("updateEvent", eventBlob);
  if (eventImage) uploadFormData.append("fileData", eventImage);
  if (flyerFile) uploadFormData.append("flyerData", flyerFile);
  if (currentImagePreviewFileß)
    uploadFormData.append("previewImage", currentImagePreviewFileß);

  return axios
    .post("/event/event/update/", uploadFormData)
    .then((eventResp) => eventResp.data)
    .catch((exc) => {
      console.error("Error during updating event!", exc);
      return null;
    });
};

/**
 * API METHOD - to copy an {@link Event} on the backend
 * @param axios network instance
 * @param event data to copy
 * @returns null in case of error and copyied {@link Event} in body on success
 */
export const copyEvent = async (
  axios: AxiosInstance,
  event: Event
): Promise<Event> => {
  return axios
    .post("/event/event/copy/", event)
    .then((eventResp) => eventResp.data)
    .catch((exc) => {
      console.error("Error during copy event!", exc);
      return null;
    });
};

/**
 *  API METHOD - to fetch all events by a given provider id
 * @param axios network instance
 * @param providerId tid to fetch all events for
 * @returns list of all found events or an empty array
 */
export const fetchAllEventsForProvider = async (
  axios: AxiosInstance,
  providerId: string
): Promise<Event[]> => {
  return axios
    .get("/event/event/provider/all/", { params: { id: providerId } })
    .then((eventResp) => eventResp.data)
    .catch((exc) => {
      console.error("Error during loading all events for provider!", exc);
      return [];
    });
};

/**
 * API METHOD - loads the current Event with the given slugId from the server
 *
 * @param slugId slugId of the requested Event
 * @returns Event with given slugId if existing
 */
export const getEventBySlugId = (
  slugId: string,
  axios: AxiosInstance
): Promise<Event> => {
  return axios
    .get(`/event/event/id/`, {
      params: { id: slugId },
    })
    .then((loadedEvent) => loadedEvent.data)
    .catch(() => {
      console.error("error during fetch of Event");
      return null;
    });
};

/**
 *  API METHOD - to fetch all events
 * @param axios network instance
 * @returns list of all found events or an empty array
 */
export const fetchAllEvents = async (
  axios: AxiosInstance
): Promise<Event[]> => {
  return axios
    .get("/event/event/all/")
    .then((eventResp) => eventResp.data)
    .catch((exc) => {
      console.error("Error during loading all events!", exc);
      return [];
    });
};

/**
 *  API METHOD - to delete an event by id
 * @param axios network instance
 * @param eventId id which should be removed
 * @returns true if a statuscode 200 is recieved, false otherwise
 */
export const deleteEvent = async (
  axios: AxiosInstance,
  eventId: string
): Promise<boolean> => {
  return axios
    .post("/event/event/delete/", eventId)
    .then((eventResp) => eventResp.status === 200)
    .catch((exc) => {
      console.error("Error during deleting event!", exc);
      return false;
    });
};

/**
 * Method to generate Base64String from file-data
 * @param file File to generate base64String from
 * @param setBase64To method used for assigning generated value when reader is successful
 */
export const getBase64ForFile = (
  file: File,
  setBase64To: (base64: string) => void
) => {
  if (!file) return;
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onloadend = function () {
    setBase64To(reader.result as string);
  };
  reader.onerror = function (error) {
    console.error("Error: ", error);
  };
};

/**
 * Method to generate File with given dataUrl and fileName
 * @param dataurl dataUrl containing type and data of file
 * @param filename name of the file to be created
 * @returns File that was generated inside method
 */
export const transformDataURLtoFile = (
  dataUrl: string | undefined,
  filename: string
): File | undefined => {
  if (!dataUrl) return;
  const urlMime: string = dataUrl.split(",")[0];
  const urlData: string = dataUrl.split(",")[1];

  const mimeType = urlMime.match(/:(.*?);/)![1];
  const dataBlobString = atob(urlData);
  let dataBlobStringLength = dataBlobString.length;
  let uInt8Array = new Uint8Array(dataBlobStringLength);
  while (dataBlobStringLength--) {
    uInt8Array[dataBlobStringLength] =
      dataBlobString.charCodeAt(dataBlobStringLength);
  }
  let generatedFile: File = new File([uInt8Array], filename, {
    type: mimeType,
  });
  return generatedFile;
};

/**
 * Method to generate correct JSX.Element containing information about ticke-types
 * @param activityCompanion ActivityEditCompanion object containg info about ticket-types
 * @returns JSX.Element containing active ticket-types
 * @tested
 */
export const generateCorrectStep4ProofString = (
  activityCompanion: ActivityEditCompanion
): JSX.Element => {
  const ticketType = activityCompanion.event.ticketType;
  if (activityCompanion.activityType === ActivityType.EVENT) {
    if (ticketType.includes(TicketType.FREE))
      return <p>{i18n.t("adminEventPage.event.steps.step4.freeEvent")}</p>;
    else {
      let notFreeArray: string[] = [];
      if (ticketType.includes(TicketType.ON_SPOT))
        notFreeArray.push(i18n.t("adminEventPage.event.steps.step4.onSpot"));
      if (ticketType.includes(TicketType.ONLINE))
        notFreeArray.push(i18n.t("adminEventPage.event.steps.step4.online"));
      if (ticketType.includes(TicketType.VVK))
        notFreeArray.push(i18n.t("adminEventPage.event.steps.step4.vvk"));
      return (
        <>
          <p>{i18n.t("adminEventPage.event.steps.step4.ticketTypes")}</p>
          <p>{notFreeArray.join(", ")}</p>
        </>
      );
    }
  } else {
    let localElementArray: JSX.Element[] = [];
    if (activityCompanion.action.discountType === DiscountType.PRICE)
      localElementArray.push(
        <p key={activityCompanion.action.discountPrice}>
          {[
            activityCompanion.action.discountPrice,
            i18n.t("adminEventPage.event.steps.step4.priceDiscountInEuro"),
          ].join("")}
        </p>
      );
    else if (activityCompanion.action.discountType === DiscountType.PERCENT)
      localElementArray.push(
        <p key={activityCompanion.action.discountPrice}>
          {[
            activityCompanion.action.discountPrice,
            i18n.t("adminEventPage.event.steps.step4.priceDiscountInPercent"),
          ].join("")}
        </p>
      );
    if (!!activityCompanion?.action.discountDetails)
      localElementArray.push(
        <p key={activityCompanion.action.discountDetails}>
          {activityCompanion.action.discountDetails}
        </p>
      );
    return <>{localElementArray}</>;
  }
};

/**
 * Method to generate correct JSX.Element containing information about ticke-types
 * @param activityCompanion ActivityEditCompanion object containg info abou ticket-types
 * @returns JSX.Element containing active ticket-types
 * @tested
 */
export const generateCorrectStep3ProofString = (
  activityCompanion: ActivityEditCompanion | undefined
): JSX.Element => {
  if (activityCompanion === undefined) return <></>;
  if (activityCompanion.dateType === "single")
    return (
      <>
        <p>
          {new Date(
            activityCompanion.singleDateObject?.eventDate!
          )?.toLocaleDateString("de")}
        </p>
        <p>
          {activityCompanion?.singleDateObject?.startTime || "00:00"}
          {"-"}
          {activityCompanion?.singleDateObject?.endTime || "00:00"}
        </p>
      </>
    );
  else if (activityCompanion.dateType === "multi")
    return (
      <p>
        {i18n.t("adminEventPage.event.steps.step3.replacementText")}
        {activityCompanion?.dateList.length}
      </p>
    );
  else
    return (
      <>
        <p>
          {new Date(activityCompanion.startDate!)?.toLocaleDateString("de")}
          {"-"}
          {new Date(activityCompanion.endDate!)?.toLocaleDateString("de")}
        </p>
        <p>
          {activityCompanion?.singleDateObject?.startTime || "00:00"}
          {"-"}
          {activityCompanion?.singleDateObject?.endTime || "00:00"}
        </p>
      </>
    );
};

/**
 * Method to generate correct string for step5 proof string
 * @param companion ActivityEditCompanion containing all necessary data
 * @returns JSX.Element depending on data in companion
 */
export const generateStep5ProofString = (
  companion: ActivityEditCompanion | undefined
): JSX.Element => {
  if (companion?.visibleFrom === "now")
    return (
      <>
        <p>{i18n.t("adminEventPage.event.steps.step5.replacementText")}</p>
        <p>{i18n.t("adminEventPage.event.steps.step5.now")}</p>
      </>
    );
  else if (companion?.visibleFrom === "date")
    return (
      <>
        <p>{i18n.t("adminEventPage.event.steps.step5.replacementText")}</p>
        <p>
          {new Date(
            companion?.event?.showDate || new Date()
          )?.toLocaleDateString("de")}
        </p>
      </>
    );
  else if (companion?.visibleFrom === "deadline")
    return (
      <>
        <p>
          {i18n.t("adminEventPage.event.steps.step5.replacementTextAlt")}
          {companion.deadlineAmount +
            " " +
            i18n.t(
              `adminEventPage.event.steps.step5.${companion.deadlineType}s`
            )}
          {i18n.t("adminEventPage.event.steps.step5.before")}
        </p>
      </>
    );
  else return <></>;
};

/**
 * Method to check if every date has a link if selected so or if a singleLink is present
 * @param activityCompanion EventEditCompanion containing the data to check
 * @returns boolean whether criteria are met
 */
const checkIfEveryDateHasLink = (
  activityCompanion: ActivityEditCompanion
): boolean => {
  let allDatesHaveLink: boolean = true;
  if (activityCompanion.singleDateLink) {
    if (activityCompanion?.event?.dateLinkList?.[0]?.length > 0) return true;
    return false;
  } else {
    activityCompanion.event.dateLinkList.forEach((link: string) => {
      if (link.length < 1) allDatesHaveLink = false;
    });
  }
  return allDatesHaveLink;
};

/**
 * Helper to determine if event-part fullfills all requirements for step4
 * @param localActivityCompanion ActvitiyEditCompanion to test
 * @returns boolean that tells if check was complete
 */
const checkEventPriceStatus = (
  localActivityCompanion: ActivityEditCompanion
): boolean => {
  const ticketTypes = localActivityCompanion.event.ticketType;
  if (ticketTypes.includes(TicketType.FREE)) return true;
  if (!checkIfPriceListHasEnabledContent(localActivityCompanion)) return false;
  if (ticketTypes.includes(TicketType.ONLINE))
    if (
      localActivityCompanion.singleDateLink &&
      localActivityCompanion?.event?.dateLinkList?.[0]?.length < 1
    )
      return false;
    else {
      if (!checkIfEveryDateHasLink(localActivityCompanion)) return false;
    }
  if (
    ticketTypes.includes(TicketType.VVK) &&
    localActivityCompanion.event.vvkList.length < 1
  )
    return false;
  return true;
};

/**
 * Method that checks several event-properties and returns whether the correct properties are set depending on th egiven step number
 * @param stepNumber number of the step that shall be checked
 * @returns boolean true when the correct properties are set for given step
 * @tested
 */
export const checkIfStepIsComplete = (
  stepNumber: number,
  localActivityCompanion: ActivityEditCompanion | undefined
): boolean => {
  switch (stepNumber) {
    case 1:
      return (
        !!localActivityCompanion?.activityType &&
        !!localActivityCompanion.category
      );
    case 2:
      return (
        !!localActivityCompanion?.event?.name &&
        localActivityCompanion?.event?.description.length > 8 &&
        !!localActivityCompanion?.event?.location?.city &&
        !!localActivityCompanion?.event?.location?.street &&
        !!localActivityCompanion?.event?.location?.zipCode &&
        localActivityCompanion?.event?.location?.zipCode.length === 5 &&
        !!localActivityCompanion?.image
      );
    case 3:
      if (
        localActivityCompanion?.dateType === "multi" &&
        !!localActivityCompanion?.dateList
      )
        return localActivityCompanion!.dateList.length > 0;
      else if (localActivityCompanion?.dateType === "single")
        return !!localActivityCompanion.singleDateObject.eventDate;
      else if (localActivityCompanion?.dateType === "continuous")
        return (
          !!localActivityCompanion.startDate || !!localActivityCompanion.endDate
        );

      return false;
    case 4:
      if (localActivityCompanion?.activityType === ActivityType.EVENT)
        return checkEventPriceStatus(localActivityCompanion);
      else
        return (
          ((localActivityCompanion?.action.discountType ===
            DiscountType.PRICE ||
            localActivityCompanion?.action.discountType ===
              DiscountType.PERCENT) &&
            !!localActivityCompanion.action.discountPrice) ||
          (localActivityCompanion?.action.discountType ===
            DiscountType.SPECIAL &&
            !!localActivityCompanion.action.discountDetails)
        );
    case 5:
      return (
        localActivityCompanion?.visibleFrom === "now" ||
        localActivityCompanion?.visibleFrom === "deadline" ||
        (localActivityCompanion?.visibleFrom === "date" &&
          !!localActivityCompanion?.event?.showDate)
      );
    default:
      return false;
  }
};

/**
 * Helper to check if previous step is completed
 * @param stepNumber number of the step that is called
 * @param localActivityCompanion object containing all required data
 * @returns
 */
export const checkIfPreviousStepsAreCompleted = (
  stepNumber: number,
  localActivityCompanion: ActivityEditCompanion | undefined
): boolean => {
  switch (stepNumber) {
    case 1:
      return true;
    case 2:
      return checkIfStepIsComplete(1, localActivityCompanion);
    case 3:
      return (
        checkIfStepIsComplete(1, localActivityCompanion) &&
        checkIfStepIsComplete(2, localActivityCompanion)
      );
    case 4:
      return (
        checkIfStepIsComplete(1, localActivityCompanion) &&
        checkIfStepIsComplete(2, localActivityCompanion) &&
        checkIfStepIsComplete(3, localActivityCompanion)
      );
    case 5:
      return (
        checkIfStepIsComplete(1, localActivityCompanion) &&
        checkIfStepIsComplete(2, localActivityCompanion) &&
        checkIfStepIsComplete(3, localActivityCompanion) &&
        checkIfStepIsComplete(4, localActivityCompanion)
      );
    default:
      return false;
  }
};

/**
 * Method to determine whether priceCategoryList has any enabled entries
 * @param activityCompanion EventEditCompanion containing the priceCategoryList
 * @returns boolean true if any priceCategory is enabled
 * @tested
 */
export const checkIfPriceListHasEnabledContent = (
  activityCompanion: ActivityEditCompanion
): boolean => {
  let hasEnabledContent: boolean = false;
  // eslint-disable-next-line
  activityCompanion.priceCategoryList.map((priceCat) => {
    if (priceCat.enabled) hasEnabledContent = true;
  });
  return hasEnabledContent;
};

/**
 * Checks if event will be split into multiple events on creation
 * @param event event to be checked (current event)
 * @returns boolean when event will be split into multiple events
 */
export const checkIfMultipleDatesArePresent = (
  activityCompanion: ActivityEditCompanion
): boolean => {
  if (activityCompanion.dateList.length > 1) return true;
  return false;
};

/**
 * Method togenerate the correct string for deadline input depending on type of deadline
 * @param companion EventEditCompanion containing data about type of deadline
 * @returns string depending on type of deadline
 */
export const generateCorrectDeadlineString = (
  deadlineType: "day" | "week" | "month" | "year" | undefined
): string => {
  switch (deadlineType) {
    default:
      return "";
    case "day":
      return i18n.t("adminEventPage.event.steps.step5.deadlineDaysBefore");
    case "week":
      return i18n.t("adminEventPage.event.steps.step5.deadlineWeeksBefore");
    case "month":
      return i18n.t("adminEventPage.event.steps.step5.deadlineMonthsBefore");
    case "year":
      return i18n.t("adminEventPage.event.steps.step5.deadlineYearsBefore");
  }
};

/**
 * Method that takes a date and cecreases date by intervall times amount given
 * @param date date used as base for calculation
 * @param intervall intervall of change
 * @param amount amount of change
 * @returns adjusted Date
 */
const extendSingleDateByShowDate = (
  date: Date,
  intervall: "day" | "week" | "month" | "year" | undefined,
  amount: number
): Date => {
  let localDate = new Date(date || new Date());
  switch (intervall) {
    default:
    case "day":
      localDate.setDate(localDate.getDate() - amount);
      break;
    case "week":
      localDate.setDate(localDate.getDate() - 7 * amount);
      break;
    case "month":
      localDate.setMonth(localDate.getMonth() - amount);
      break;
    case "year":
      localDate.setFullYear(localDate.getFullYear() - amount);
      break;
  }
  return localDate;
};

/**
 * Method used to assign a value for showDate property of given TempTimelapse
 * @param companion EventEditCompanion containg all data
 * @returns TempTimelapse containg adjusted showDate
 */
export const extendSingleDateObjectWithShowDate = (
  companion: ActivityEditCompanion,
  deadlineType: "day" | "week" | "month" | "year",
  deadlineAmount: number
): TempTimelapse => {
  let localTempTimelapse: TempTimelapse = { ...companion.singleDateObject };
  localTempTimelapse.showDate = extendSingleDateByShowDate(
    localTempTimelapse.eventDate!,
    deadlineType,
    deadlineAmount
  );
  return localTempTimelapse;
};

/**
 * Method to extend the dateList by the showDate property of each element
 * @param companion EventEditCompanion object containingall information
 * @returns TempTimelapse[] with new showDates
 */
export const extendDateList = (
  companion: ActivityEditCompanion
): TempTimelapse[] => {
  let bufferArray: TempTimelapse[] = [...companion.dateList];
  bufferArray.forEach((date: TempTimelapse) => {
    date.showDate = extendSingleDateByShowDate(
      date.eventDate!,
      companion.deadlineType,
      companion.deadlineAmount || 0
    );
  });
  return bufferArray;
};

/**
 * Method to generate TableRow Array containing
 * @param companion EventEditCompanion containing all necessary information
 * @param setCompanion Setter for EventEditCompanion t adjust values
 * @returns TableRow[] containing some info and an InputComponent to adjust showDate of element
 */
export const generateShowDateTableRow = (
  companion: ActivityEditCompanion,
  setCompanion: (companion: ActivityEditCompanion) => void
): TableRow[] => {
  let localTableArray: TableRow[] = [];
  companion.dateList.forEach((date, dateIndex) => {
    localTableArray.push({
      id: date.id,
      content: [
        <p>
          {i18n
            .t(
              `enum.dayOfWeek.${
                DayOfWeekArray[new Date(date!.eventDate!).getDay()]
              }`
            )
            .slice(0, 2)}{" "}
          {new Date(date?.eventDate!)?.toLocaleDateString("de")}
        </p>,
        <p>{i18n.t("adminEventPage.event.steps.step5.visibleFromInput")}</p>,
        <InputComponent
          type="date"
          isFrameless
          value={new Date(date.showDate || new Date())?.toISOString() || ""}
          onChange={(value) => {
            let localDateArray: TempTimelapse[] = [...companion.dateList];
            localDateArray[dateIndex].showDate = value
              ? new Date(value)
              : companion.dateList[dateIndex].showDate;
            setCompanion({ ...companion, dateList: localDateArray });
          }}
        />,
      ],
    });
  });
  return localTableArray;
};

const checkTicketLinkStatus = (
  companion: ActivityEditCompanion,
  index: number = 0
): boolean => {
  return (
    (typeof companion.event.dateLinkList[index] === "string" &&
      companion.event.dateLinkList[index].length < 1) ||
    companion.event.dateLinkList[index] === undefined
  );
};

/**
 * Method to generate the correct input with correct handling depending on companion.discountType
 * @param companion object containing the necessary data
 * @param setCompanion setter of companion-object
 * @param stepCompletion boolean that tells if the current step is completed and has all needed data
 * @returns JSX.Element containing Inputs
 * @tested
 */
export const generateOnlineTicketInputs = (
  activityCompanion: ActivityEditCompanion,
  setactivityCompanion: (activityCompanion: ActivityEditCompanion) => void,
  stepCompletion?: boolean
): JSX.Element => {
  let onlineTicketElement: JSX.Element = <></>;
  if (activityCompanion.event.ticketType.includes(TicketType.ONLINE)) {
    if (checkIfMultipleDatesArePresent(activityCompanion)) {
      const onlineSelectElement = (
        <>
          <div className="input-label">
            {i18n.t("adminEventPage.event.steps.step4.ticketLinkLabel")}
          </div>
          <div className="selected-content--single-link">
            <CheckboxComponent
              checked={activityCompanion.singleDateLink}
              value={i18n.t("adminEventPage.event.steps.step4.singleLink")}
              onCheck={() =>
                setactivityCompanion({
                  ...activityCompanion,
                  singleDateLink: true,
                })
              }
            />
            <CheckboxComponent
              checked={!activityCompanion.singleDateLink}
              value={i18n.t("adminEventPage.event.steps.step4.differentLinks")}
              onCheck={() =>
                setactivityCompanion({
                  ...activityCompanion,
                  singleDateLink: false,
                })
              }
            />
          </div>
        </>
      );
      let onlineInputElement = <></>;

      if (activityCompanion.singleDateLink) {
        onlineInputElement = (
          <>
            <div className="input-label">
              {i18n.t("adminEventPage.event.steps.step4.insertLink")}
              <div className="required">
                {i18n.t("adminEventPage.event.requiredField")}
              </div>
            </div>
            <div className="selected-content--ticket-link-input">
              <InputComponent
                isValid={
                  stepCompletion || !checkTicketLinkStatus(activityCompanion)
                }
                value={activityCompanion.event.dateLinkList[0] ?? ""}
                isFrameless
                type="text"
                onChange={(value) => {
                  let localLinkArray = [
                    ...activityCompanion.event.dateLinkList!,
                  ];
                  localLinkArray[0] = value;
                  setactivityCompanion({
                    ...activityCompanion,
                    event: {
                      ...activityCompanion.event,
                      dateLinkList: localLinkArray,
                    },
                  });
                }}
              />
            </div>
          </>
        );
      } else {
        onlineInputElement = (
          <div className="selected-content--ticket-link-list">
            <TableComponent
              maxPageAmount={5}
              header={i18n.t("adminEventPage.event.dateEdit.linkTableHeader", {
                returnObjects: true,
              })}
              rows={generateEventLinkRows(
                activityCompanion.dateList,
                activityCompanion,
                setactivityCompanion,
                stepCompletion
              )}
            />
          </div>
        );
      }
      onlineTicketElement = (
        <div className="selected-content--ticket-link">
          {onlineSelectElement}
          {onlineInputElement}
        </div>
      );
    } else {
      onlineTicketElement = (
        <>
          <div className="input-label">
            {i18n.t("adminEventPage.event.steps.step4.insertLink")}
            <div className="required">
              {i18n.t("adminEventPage.event.requiredField")}
            </div>
          </div>
          <div className="selected-content--ticket-link-input">
            <InputComponent
              isValid={
                !(!stepCompletion && checkTicketLinkStatus(activityCompanion))
              }
              value={activityCompanion.event.dateLinkList[0] ?? ""}
              isFrameless
              type="text"
              onChange={(value) =>
                setactivityCompanion({
                  ...activityCompanion,
                  event: { ...activityCompanion.event, dateLinkList: [value] },
                })
              }
            />
          </div>
        </>
      );
    }
  }
  return onlineTicketElement;
};

/**
 * Method to generate correct inputs for vvkTickets
 * @param activityCompanion Object that contains user-choices for tickets
 * @param dropdownEventLocations Array of eventLocations (f.e provider-Event-Locations)
 * @param selectedVvkLocationOption EventLocation that is currently selected
 * @param setSelectedVvkLocationOption setter to set currentVvkLocation
 * @param addEntryToVvkList method to add a new entry to vvkList
 * @param deleteEntryFromVvkList method to delete entry from vvkLocationList
 * @returns JSX.ELement containing the correct inputs
 * @tested
 */
export const generateVVKTicketInputs = (
  activityCompanion: ActivityEditCompanion,
  dropdownEventLocations: DropdownOption[],
  selectedVvkLocationOption: string,
  setSelectedVvkLocationOption: (value: string) => void,
  addEntryToVvkList: () => void,
  deleteEntryFromVvkList: (dateIndex: number) => void,
  togglePopupAndSetNewLoc: () => void,
  stepCompletion?: boolean
): JSX.Element => {
  let vvkTicketElement: JSX.Element = <></>;
  if (activityCompanion.event.ticketType.includes(TicketType.VVK)) {
    let vvkListElement = <></>;
    let vvkInputElement: JSX.Element = (
      <>
        <div className="input-label">
          {i18n.t("adminEventPage.event.steps.step4.vvkLocationLabel")}
        </div>
        <div className="selected-content--vvk-input">
          <DropdownComponent
            hideClearIcon
            type="rounded"
            dropdownOptions={dropdownEventLocations}
            selectedOption={selectedVvkLocationOption ?? ""}
            onChange={(value) => {
              setSelectedVvkLocationOption(value);
            }}
          />
          <div
            className={[
              "selected-content--vvk-input-button",
              !stepCompletion &&
                (activityCompanion.event.vvkList!.length < 1 ||
                  activityCompanion.event.vvkList === undefined) &&
                "incomplete-flag",
            ].join(" ")}
          >
            <ButtonComponent
              value={i18n.t("adminEventPage.event.steps.step4.add")}
              onClick={() => {
                if (selectedVvkLocationOption !== "-1") addEntryToVvkList();
                else togglePopupAndSetNewLoc();
              }}
            />
          </div>
        </div>
      </>
    );
    if (activityCompanion.event.vvkList?.length > 0) {
      vvkListElement = (
        <div className="selected-content--vvk-input">
          <TableComponent
            key={
              activityCompanion.event.vvkList![
                activityCompanion.event.vvkList!.length - 1
              ].id
            }
            header={i18n.t("adminEventPage.event.dateEdit.vvkTableHeader", {
              returnObjects: true,
            })}
            rows={generateVvkLocationTableRows(
              activityCompanion.event.vvkList!,
              deleteEntryFromVvkList
            )}
          />
        </div>
      );
    }
    vvkTicketElement = (
      <>
        {vvkInputElement}
        {vvkListElement}
      </>
    );
  }
  return vvkTicketElement;
};

/**
 * Method to generate correct inputs if event is not free
 * @param ticketType Object that contains user-choices for tickets
 * @param priceCategoryDropdownOptions DropdownOption[] containing all options for price-categories
 * @param selectedPriceCategoryDropdown string containing the id of the currently selected dropdown option from above Array
 * @param selectPriceCategory setter to set id of currently selected dropdownOption
 * @param priceCategoryList PriceCategory[] containing all price Categories
 * @param selectedPriceCategory currently selected PriceCategory
 * @param setSelectedPriceCategory setter to set currently selected priceCategory
 * @returns JSX.Element containing all required inputs
 * @tested
 */
export const generateTicketPriceInputs = (
  ticketType: TicketType[],
  priceCategoryDropdownOptions: DropdownOption[],
  selectedPriceCategoryDropdown: string,
  selectPriceCategory: (selectedOption: string) => void,
  priceCategoryList: PriceCategory[],
  selectedPriceCategory: PriceCategory,
  setSelectedPriceCategory: (priceCategory: PriceCategory) => void,
  deleteEntryFromPriceList: (index: number) => void,
  activityCompanion: ActivityEditCompanion,
  stepCompletion?: boolean
): JSX.Element => {
  let priceElement: JSX.Element = <></>;
  if (!ticketType.includes(TicketType.FREE)) {
    const generatedPriceListTableRows = generatePriceListTableRows(
      priceCategoryList,
      deleteEntryFromPriceList
    );
    let priceListElement: JSX.Element = <></>;
    let priceInputElement: JSX.Element = (
      <>
        <div className="input-label">
          {i18n.t("adminEventPage.event.steps.step4.ticketPrice")}
        </div>
        <div className="selected-content--ticket-price">
          <DropdownComponent
            type="rounded"
            dropdownOptions={priceCategoryDropdownOptions}
            selectedOption={selectedPriceCategoryDropdown}
            onChange={selectPriceCategory}
            hideClearIcon
          />
          <div className="segment-grouper">
            <InputComponent
              decimals={2}
              value={selectedPriceCategory.price ?? 0}
              type="number"
              isFrameless
              onChange={(value) =>
                setSelectedPriceCategory({
                  ...selectedPriceCategory,
                  price: parseFloat(value),
                })
              }
            />
            <p>{i18n.t("adminEventPage.event.steps.step4.priceInEuro")}</p>
            <div
              className={[
                "selected-content--ticket-price-button",
                (stepCompletion ||
                  checkIfPriceListHasEnabledContent(activityCompanion)) &&
                  "incomplete-flag",
              ].join(" ")}
            >
              <ButtonComponent
                disabled={selectedPriceCategory.enabled}
                value="+"
                onClick={() =>
                  setSelectedPriceCategory({
                    ...selectedPriceCategory,
                    enabled: true,
                  })
                }
              />
            </div>
          </div>
        </div>
      </>
    );
    if (generatedPriceListTableRows.length > 0) {
      priceListElement = (
        <div className="selected-content--price-list">
          <TableComponent
            maxPageAmount={10}
            header={i18n.t("adminEventPage.event.dateEdit.priceTableHeader", {
              returnObjects: true,
            })}
            rows={generatedPriceListTableRows}
          />
        </div>
      );
    }
    priceElement = (
      <>
        {priceInputElement}
        {priceListElement}
      </>
    );
  }
  return priceElement;
};

/**
 * helper to determine whether default priceCategories or already existing one shall be returned or whether new ones shall be generated from existing priceItems
 * @param activityCompanion EventEditCompanion containing the necessary data
 * @returns PriceCategory[] containing the correct data
 * @tested
 */
export const generateCorrectPriceCategoryList = (
  activityCompanion: ActivityEditCompanion
): PriceCategory[] => {
  if (activityCompanion.priceCategoryList.length > 0)
    return activityCompanion.priceCategoryList;
  if (activityCompanion.event.priceItems.length > 0)
    return transformPriceItemsToCategory(activityCompanion.event.priceItems);
  else {
    return generateDefaultPriceCategories();
  }
};

/**
 * Helper to generate dropdownOptions for corresponding priceCategoryList
 * @param priceCatList PriceCategory[] with entries to generate options for
 * @returns DropdownOption[] with generated options
 * @tested
 */
export const generateCorrectPriceCategoryDropdownList = (
  priceCatList: PriceCategory[]
): DropdownOption[] => {
  let localPriceCatDropdownList: DropdownOption[] = [];
  // eslint-disable-next-line
  priceCatList.map((category: PriceCategory) => {
    localPriceCatDropdownList.push({
      label: category.label,
      value: category.id,
      disabled: false,
    });
  });
  return localPriceCatDropdownList;
};

/**
 * Helper to transform priceItems to PriceCategory to enable edit
 * @param priceItems Arrayo fPriceItems that shall be transformed
 * @returns PriceCategory Array
 * @tested
 */
export const transformPriceItemsToCategory = (
  priceItems: PriceItem[]
): PriceCategory[] => {
  let localPriceCategoryList: PriceCategory[] = [];
  // eslint-disable-next-line
  priceItems.map((item, index) => {
    localPriceCategoryList.push({
      enabled: true,
      id: item.id,
      label: item.name,
      price: item.price,
      description: item.description,
    });
  });

  return localPriceCategoryList;
};

/**
 * Helper to transform PriceCategory to PriceItems to enable edit
 * @param priceItems Array of PriceCategories that shall be transformed
 * @returns PriceItems Array
 * @tested
 */
export const transformCategoryToPriceItems = (
  priceCategories: PriceCategory[]
): PriceItem[] => {
  let localPriceItemList: PriceItem[] = [];
  // eslint-disable-next-line
  priceCategories.map((item) => {
    if (item.enabled)
      localPriceItemList.push({
        link: "",
        id: item.id,
        name: item.label,
        price: item.price ?? 0,
        description: item.description,
      });
  });

  return localPriceItemList;
};

/**
 * Helper to provide default priceCategories
 * @returns PriceCategory[] containg the default priceCategories
 * @tested
 */
export const generateDefaultPriceCategories = (): PriceCategory[] => [
  {
    id: "DEFAULT",
    label: i18n.t("adminEventPage.event.steps.step4.default"),
    price: 0,
    enabled: false,
    description: "",
  },
  {
    id: "ADULT",
    label: i18n.t("adminEventPage.event.steps.step4.adults"),
    price: 0,
    enabled: false,
    description: "",
  },
  {
    id: "KIDS",
    label: i18n.t("adminEventPage.event.steps.step4.kids"),
    price: 0,
    enabled: false,
    description: "",
  },
  {
    id: "STUDENT",
    label: i18n.t("adminEventPage.event.steps.step4.students"),
    price: 0,
    enabled: false,
    description: "",
  },
  {
    id: "REDUCED",
    label: i18n.t("adminEventPage.event.steps.step4.reduced"),
    price: 0,
    enabled: false,
    description: "",
  },
  {
    id: "SENIOR",
    label: i18n.t("adminEventPage.event.steps.step4.seniors"),
    price: 0,
    enabled: false,
    description: "",
  },
  {
    id: "HANDICAPPED",
    label: i18n.t("adminEventPage.event.steps.step4.handicapped"),
    price: 0,
    enabled: false,
    description: "",
  },
];

/**
 * Helper to generate tableRows for generated dates
 * @param dateArray TempTimelapse[]
 * @returns TableRow[] containing info for every generated date
 */
export const generateDateRows = (
  dateArray: TempTimelapse[],
  deleteEntryFromDateArray: (dateIndex: number) => void,
  setDateToEdit: (
    value: React.SetStateAction<TempTimelapse | undefined>
  ) => void,
  setDateToEditIndex: (index: React.SetStateAction<number | undefined>) => void,
  copyEntryFromDateArray: (
    dateIndex: number,
    copiedEntry: TempTimelapse
  ) => void
): TableRow[] => {
  const generateTableRows: TableRow[] = [];
  // eslint-disable-next-line
  dateArray.map((date, dateIndex): void => {
    const localIndex: number = dateIndex;
    generateTableRows.push({
      id: localIndex.toString(),
      content: [
        <p>
          {i18n
            .t(
              `enum.dayOfWeek.${
                DayOfWeekArray[new Date(date!.eventDate!).getDay()]
              }`
            )
            .slice(0, 2)}{" "}
          {new Date(date?.eventDate!)?.toLocaleDateString("de")}
        </p>,
        <p>
          {date?.startTime || "00:00"}
          {" - "}
          {date?.endTime || "00:00"}
        </p>,
        <div className="event-icons">
          <TrashIcon
            onClick={() => deleteEntryFromDateArray(localIndex)}
            title={i18n.t("adminEventPage.event.steps.step3.tooltips.delete")}
          />
          <EditIcon
            title={i18n.t("adminEventPage.event.steps.step3.tooltips.edit")}
            onClick={() => {
              setDateToEdit(dateArray[localIndex]);
              setDateToEditIndex(localIndex);
            }}
          />
          <CopyIcon
            title={i18n.t("adminEventPage.event.steps.step3.tooltips.copy")}
            onClick={() =>
              copyEntryFromDateArray(localIndex, dateArray[localIndex])
            }
          />
        </div>,
      ],
    });
  });
  return generateTableRows;
};

/**
 * Method that generates a string depending on decimals
 * @param price that will be tested and converted
 * @returns generated string containing price
 * @tested
 */
export const generateCorrectPriceString = (price: number): string => {
  if (price.toString() === price.toFixed(0)) return `${price?.toFixed(0)},- €`;
  else {
    return `${price?.toFixed(2)} €`;
  }
};

/**
 * Helper to generate tableRows for selected priceCategories
 * @param pcList list of price-categories
 * @returns TableRow[] containing data for priceCategories
 * @tested
 */
export const generatePriceListTableRows = (
  pcList: PriceCategory[],
  deletePriceListEntry: (index: number) => void
): TableRow[] => {
  let localPriceCategoryTRList: TableRow[] = [];
  // eslint-disable-next-line
  pcList.forEach((priceCategory, index) => {
    if (priceCategory.enabled)
      localPriceCategoryTRList.push({
        id: priceCategory.id,
        content: [
          <p>{priceCategory.label}</p>,
          <p>{generateCorrectPriceString(priceCategory.price ?? 0)}</p>,
          <TrashIcon onClick={() => deletePriceListEntry(index)} />,
        ],
      });
  });
  return localPriceCategoryTRList;
};

/**
 * Helper to generate tableRows for generated dates
 * @param dateArray TempTimelapse[]
 * @returns TableRow[] containing info for every generated date
 */
export const generateVvkLocationTableRows = (
  vvkLocations: EventLocation[],
  deleteEntryFromVvkList: (dateIndex: number) => void
): TableRow[] => {
  const generateTableRows: TableRow[] = [];
  // eslint-disable-next-line
  vvkLocations.map((location, locationIndex): void => {
    const localIndex: number = locationIndex;
    generateTableRows.push({
      id: localIndex.toString(),
      content: [
        <p>{location.name}</p>,
        <p>{location.location.city}</p>,
        <p>{location.location.street}</p>,
        <p>{location.location.zipCode}</p>,
        <div className="event-icons">
          <TrashIcon onClick={() => deleteEntryFromVvkList(localIndex)} />
        </div>,
      ],
    });
  });
  return generateTableRows;
};

/**
 * Helper to generate tableRows for generated dates
 * needs to be adjusted once event is implemented as array of TempTimelapses
 * @param dateArray TempTimelapse[]
 * @returns TableRow[] containing info for every generated date
 */
export const generateEventLinkRows = (
  dateArray: TempTimelapse[],
  activityCompanion: ActivityEditCompanion,
  setactivityCompanion: (activityCompanion: ActivityEditCompanion) => void,
  stepCompletion?: boolean
): TableRow[] => {
  const generateTableRows: TableRow[] = [];
  // eslint-disable-next-line
  dateArray.map((date, dateIndex): void => {
    const localIndex: number = dateIndex;
    generateTableRows.push({
      id: localIndex.toString(),
      content: [
        <p>
          {i18n
            .t(
              `enum.dayOfWeek.${
                DayOfWeekArray[new Date(date.eventDate!).getDay()]
              }`
            )
            ?.slice(0, 2)}{" "}
          {new Date(date?.eventDate!)?.toLocaleDateString("de")}
        </p>,
        <p>
          {date?.startTime || "00:00"}
          {" - "}
          {date?.endTime || "00:00"}
        </p>,
        <div className="link-input">
          <InputComponent
            isValid={
              stepCompletion ||
              !checkTicketLinkStatus(activityCompanion, dateIndex)
            }
            value={activityCompanion.event.dateLinkList?.[dateIndex] ?? ""}
            onChange={(value) => {
              let localLinkList = [...activityCompanion.event.dateLinkList!];
              localLinkList[dateIndex] = value;
              setactivityCompanion({
                ...activityCompanion,
                event: {
                  ...activityCompanion.event,
                  dateLinkList: localLinkList,
                },
              });
            }}
            type="text"
            isFrameless
            placeholder={i18n.t(
              "adminEventPage.event.steps.step4.linkPlaceholder"
            )}
          />
        </div>,
      ],
    });
  });
  return generateTableRows;
};

/**
 * Helper to generate row entries for the event overview table
 * @param events all loaded events
 * @param onUpdate update method for an event
 * @param onDetail on detailbutton click
 * @param onDelete delete method for an event
 * @param showOld boolean to determine if already passed events should be displayed
 * @returns generated table row array
 * @tested
 */
export const generateEventTableRows = (
  events: Event[],
  onUpdate: Function,
  onDetail: Function,
  onDelete: Function,
  showOld: boolean,
  mapIdAndName?: IdName[]
): TableRow[] => {
  let currentRows: TableRow[] = [];
  events
    .filter((item) => {
      if (showOld) return true;
      return isActivityInTheFuture(item);
    })
    .forEach((event) => {
      let isEventFinished: boolean = !isActivityInTheFuture(event);

      let currentProviderName: string = "";
      if (!!mapIdAndName) {
        currentProviderName = getProviderNameFroIdNameMap(
          mapIdAndName,
          event.providerId
        );
      }
      let convertedProviderName: string[] = !!currentProviderName
        ? [currentProviderName]
        : [];

      currentRows.push({
        content: [
          ...convertedProviderName,
          ...[
            event.name,
            generateTimeStringForDate(
              event.activityDates
                ? event.activityDates?.[0]?.date
                : event.eventDateList![0],
              i18n.t("eventUtil.noEventDate")
            ),
            `${event.startTime} - ${event.endTime}`,
            generateTimeStringForDate(
              event.showDate,
              i18n.t("eventUtil.noShowDate")
            ),
            isEventFinished
              ? i18n.t(`providerEventsConfigurationPage.finsihedState`)
              : i18n.t(`enum.eventState.${event.state}`),
            <div className="flex-it-center">
              {isEventFinished || (
                <div
                  className={[
                    "trash-wrapper",
                    "black-color",
                    "little-bit-margin-right",
                    event.state === Eventstate.MOVED
                      ? "deactivated"
                      : undefined,
                  ].join(" ")}
                  onClick={() => {
                    if (event.state !== Eventstate.MOVED)
                      onUpdate({
                        ...event,
                        state:
                          event.state === Eventstate.CANCELLED
                            ? Eventstate.READY
                            : Eventstate.CANCELLED,
                      });
                  }}
                >
                  {event.state === Eventstate.CANCELLED ? (
                    <PlayIcon />
                  ) : (
                    <CancelIcon />
                  )}
                </div>
              )}
              {isEventFinished || (
                <div
                  className={["trash-wrapper", "little-bit-margin-right"].join(
                    " "
                  )}
                  onClick={() => onDetail(event)}
                >
                  <EditIcon />
                </div>
              )}
              <div className="trash-wrapper" onClick={() => onDelete(event.id)}>
                <TrashIcon />
              </div>
            </div>,
          ],
        ],
        id: event.id!,
      });
    });
  return currentRows;
};

/**
 * Helper to generate event location rows
 * @param eventLocations data to be displayed
 * @param onShow function on show button
 * @param onDelete function on delete button
 * @returns generated table rows
 * @tested
 */
export const generateEventLocationableRows = (
  eventLocations: EventLocation[] | VVKLocation[],
  onShow: Function,
  onDelete: Function
): TableRow[] => {
  let currentRows: TableRow[] = [];
  eventLocations.forEach((location) => {
    currentRows.push({
      content: [
        location.name,
        <div>
          <div>{`${location.location.street}`}</div>
          <div>{`${location.location.zipCode} ${location.location.city}`}</div>
          <div>{`${location.location.lat} ${location.location.lng}`}</div>
          {location.phone && <div>{location.phone}</div>}
          {location.mail && <div>{location.mail}</div>}
          {location.website && <div>{location.website}</div>}
        </div>,
        <div className="flex-it-wrap">
          <div
            className="trash-wrapper black-color gap-distance"
            onClick={() => onShow(location)}
          >
            <EyeIcon />
          </div>
          <div
            className="trash-wrapper gap-distance"
            onClick={() => onDelete(location.id)}
          >
            <TrashIcon />
          </div>
        </div>,
      ],
      id: location.id,
    });
  });
  return currentRows;
};

/**
 * Helper to check status of discountPrice
 * @param companion containing all data to check
 * @returns boolean whether all properties are set correctly
 */
export const checkDiscountPriceStatus = (
  companion: ActivityEditCompanion
): boolean => {
  return (
    companion.action.discountType !== DiscountType.SPECIAL &&
    ((typeof companion.action.discountPrice === "number" &&
      companion.action.discountPrice <= 0) ||
      companion.action.discountPrice === undefined)
  );
};

/**
 * Helper to check status of discountDescription
 * @param companion companion containing all needed data for the check
 * @returns boolean whether discountDescription is defined and has a somewhat meaningful value
 */
export const checkDiscountDescriptionStatus = (
  companion: ActivityEditCompanion
): boolean => {
  return (
    companion.action.discountType === DiscountType.SPECIAL &&
    ((typeof companion.action.discountDetails === "string" &&
      (companion.action.discountDetails === "" ||
        companion.action.discountDetails.length < 1)) ||
      companion.action.discountDetails === undefined)
  );
};

/**
 * Helper to determine correct strings for notification depending on missing inputs
 * @param index number of step to be checked
 * @param companion object containg all data for checks
 */
export const generateCorrectNotificationForStep = (
  index: number,
  companion: ActivityEditCompanion
) => {
  let notificationString: string = "";
  let notificationInputsArray: string[] = [];
  switch (index) {
    case 2:
      if (
        typeof companion?.event.name === "string" &&
        companion.event.name.length < 1
      )
        notificationInputsArray.push(
          i18n.t("adminEventPage.event.steps.notifications.name")
        );
      if (
        typeof companion?.event.description === "string" &&
        companion.event.description.length < 1
      )
        notificationInputsArray.push(
          i18n.t("adminEventPage.event.steps.notifications.description")
        );
      if (!companion.imageString || companion.previewImageString)
        notificationInputsArray.push(
          i18n.t("adminEventPage.event.steps.notifications.image")
        );
      if (
        typeof companion?.event.location.city === "string" &&
        companion.event.location.city.length < 1
      )
        notificationInputsArray.push(
          i18n.t("adminEventPage.event.steps.notifications.city")
        );
      if (
        typeof companion?.event.location.street === "string" &&
        companion.event.location.street.length < 1
      )
        notificationInputsArray.push(
          i18n.t("adminEventPage.event.steps.notifications.street")
        );
      if (
        typeof companion?.event.location.zipCode === "string" &&
        companion.event.location.zipCode.length !== 5
      )
        notificationInputsArray.push(
          i18n.t("adminEventPage.event.steps.notifications.zipCode")
        );
      notificationString = `${i18n.t(
        "adminEventPage.event.steps.notifications.plsEnter"
      )}${notificationInputsArray.join(", ")}`;
      break;
    case 1:
      notificationString = i18n.t(
        "adminEventPage.event.steps.notifications.enterTags"
      );
      break;
    case 3:
      notificationString = i18n.t(
        "adminEventPage.event.steps.notifications.createDates"
      );
      break;
    case 4:
      if (companion?.activityType === ActivityType.ACTION) {
        if (checkDiscountDescriptionStatus(companion))
          notificationInputsArray = [
            i18n.t(
              "adminEventPage.event.steps.notifications.discountDescription"
            ),
          ];
        if (checkDiscountPriceStatus(companion))
          notificationInputsArray = [
            i18n.t("adminEventPage.event.steps.notifications.discountPrice"),
          ];
      }
      if (companion?.activityType === ActivityType.EVENT) {
        if (!checkIfPriceListHasEnabledContent(companion))
          notificationInputsArray.push(
            i18n.t("adminEventPage.event.steps.notifications.priceCat")
          );
        if (
          companion.event.ticketType.includes(TicketType.ONLINE) &&
          !checkIfEveryDateHasLink(companion)
        )
          notificationInputsArray.push(
            i18n.t("adminEventPage.event.steps.notifications.ticketLink")
          );
        if (
          companion.event.ticketType.includes(TicketType.VVK) &&
          companion.event.vvkList.length < 1
        )
          notificationInputsArray.push(
            i18n.t("adminEventPage.event.steps.notifications.vvkList")
          );
      }
      notificationString = `${i18n.t(
        "adminEventPage.event.steps.notifications.plsEnter"
      )}${notificationInputsArray.join(", ")}`;
      break;
    case 5:
      break;
    default:
  }

  generateNotification(
    NotificationTypes.WARNING,
    i18n.t("adminEventPage.event.steps.notifications.title"),
    notificationString,
    undefined,
    5000
  );
};

/**
 * Method to generate the correct input with correct handling depending on companion.discountType
 * @param companion object containing the necessary data
 * @param setCompanion setter of companion-object
 * @param stepCompletion boolean that tells if the current step is completed and has all needed data
 * @returns JSX.Element containing Inputs
 */
export const generateCorrectInputsForActionType = (
  companion: ActivityEditCompanion,
  setCompanion: (companion: ActivityEditCompanion) => void,
  stepCompletion?: boolean
): JSX.Element => {
  switch (companion.action.discountType) {
    case DiscountType.PRICE:
      return (
        <>
          <div className="input-label">
            {i18n.t("adminEventPage.event.steps.step4.priceDiscountLabel")}
            <div className="required">
              {i18n.t("adminEventPage.event.requiredField")}
            </div>
          </div>
          <div className="selected-content--discount-input">
            <InputComponent
              isValid={
                !(!stepCompletion && checkDiscountPriceStatus(companion))
              }
              value={companion?.action.discountPrice || 0}
              type="number"
              decimals={2}
              isFrameless
              onChange={(value) =>
                setCompanion({
                  ...companion,
                  action: {
                    ...companion.action,
                    discountPrice: value ? parseFloat(value) : 0,
                  },
                })
              }
            />
            <p>
              {i18n.t("adminEventPage.event.steps.step4.priceDiscountDetail")}
            </p>
          </div>
        </>
      );
    case DiscountType.PERCENT:
      return (
        <>
          <div className="input-label">
            {i18n.t("adminEventPage.event.steps.step4.percentDiscountLabel")}
            <div className="required">
              {i18n.t("adminEventPage.event.requiredField")}
            </div>
          </div>
          <div className="selected-content--discount-input">
            <InputComponent
              isValid={
                !(!stepCompletion && checkDiscountPriceStatus(companion))
              }
              max={100}
              isFrameless
              value={companion?.action.discountPrice ?? 0}
              type="number"
              onChange={(value) => {
                setCompanion({
                  ...companion,
                  action: {
                    ...companion.action,
                    discountPrice: value ? parseFloat(value) : 0,
                  },
                });
              }}
            />
            <p>
              {i18n.t("adminEventPage.event.steps.step4.percentDiscountDetail")}
            </p>
          </div>
        </>
      );
    default:
    case DiscountType.SPECIAL:
      return <></>;
  }
};

/**
 * Helper to update the flyer id of an event
 * @param companion  containing the flyer id
 * @param eventToUpdate  event to be updated
 * @returns  updated event
 */
export const updateFlyerIdFromCompanion = (
  companion: ActivityEditCompanion,
  eventToUpdate: Event
): Event => ({
  ...eventToUpdate,
  flyer: eventToUpdate.flyer
    ? {
        ...eventToUpdate.flyer!,
        id: companion.flyer?.id ?? "",
      }
    : undefined,
});
