import { AdminDashboardProps } from "./AdminDashboard.types";
import {
  AdminLayoutComponent,
  InfoBoxComponent,
} from "deinestadtliebt-component-library";
import { useNavLayout } from "../../../utils/navigation/NavigationUtils";
import { CurrentPage } from "../../../utils/navigation/Navigation.types";
import { useContext } from "react";
import { ConfigContext } from "../../../pages/App";
import "./AdminDashboardStyle.scss";
import { useTranslation } from "react-i18next";
import ClickBox from "../../clickbox/ClickBox";
import { ReactComponent as DiagramIcon } from "../../../assets/icons/diagram.svg";
import { ReactComponent as UserIcon } from "../../../assets/icons/user.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow.svg";
import { getAppropriateGreeting } from "../../../utils/time/TimeUtils";

const AdminDashboard: React.FC<AdminDashboardProps> = ({ user }) => {
  const { appConfig } = useContext(ConfigContext);
  const { t } = useTranslation();

  return (
    <AdminLayoutComponent {...useNavLayout(CurrentPage.DASHBOARD_ADMIN_HOME)}>
      <div
        style={{ background: appConfig?.highlightColor }}
        className="dashboard-admin--header"
      >
        <div className="dashboard-admin--header-wrapper">
          <div>
            <p>{getAppropriateGreeting()}</p>
            <p>{appConfig?.cityName}!</p>
          </div>
          <div></div>
        </div>
        <div className="dashboard-admin--header-info-wrapper">
          <InfoBoxComponent
            colors={{ hover: { bgColor: appConfig?.mainColor } }}
            content={
              <>
                <p>
                  {t("dashboard.admin.infoBox.events.text1", {
                    replace: { cityName: appConfig?.cityName },
                  })}
                </p>
                <p>
                  {t("dashboard.admin.infoBox.events.text2", {
                    replace: { number: 12 },
                  })}
                </p>
              </>
            }
            title={t("dashboard.admin.infoBox.events.title")}
          />
          <InfoBoxComponent
            colors={{ hover: { bgColor: appConfig?.mainColor } }}
            content={
              <>
                <p>{t("dashboard.admin.infoBox.actions.text1")}</p>
                <p>
                  {t("dashboard.admin.infoBox.actions.text2", {
                    replace: { number: 2 },
                  })}
                </p>
              </>
            }
            title={t("dashboard.admin.infoBox.actions.title")}
          />
        </div>
      </div>

      <div className="dashboard-admin--jump-wrapper">
        <div className="dashboard-admin--jump-wrapper--links-wrapper">
          <div className="title">{t("dashboard.admin.jumpWrapper.title")}</div>
          <div className="item" onClick={() => console.log("TODO")}>
            <ArrowIcon />
            <div>{t("dashboard.admin.jumpWrapper.provider")}</div>
          </div>
          <div className="item" onClick={() => console.log("TODO")}>
            <ArrowIcon />
            <div>{t("dashboard.admin.jumpWrapper.invitation")}</div>
          </div>
          <div className="item" onClick={() => console.log("TODO")}>
            <ArrowIcon />
            <div>{t("dashboard.admin.jumpWrapper.new")}</div>
          </div>
        </div>

        <div className="dashboard-admin--jump-wrapper--clickbox-wrapper">
          <ClickBox
            icon={<DiagramIcon />}
            onClick={() => console.log("TODO")}
            text={t("dashboard.admin.clickBox.evaluation")}
          />
          <ClickBox
            icon={<UserIcon />}
            onClick={() => console.log("TODO")}
            text={t("dashboard.admin.clickBox.money")}
          />
          <ClickBox
            icon={<UserIcon />}
            onClick={() => console.log("TODO")}
            text={t("dashboard.admin.clickBox.abo")}
          />
        </div>
      </div>
    </AdminLayoutComponent>
  );
};

export default AdminDashboard;
