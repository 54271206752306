/**
 * model of legal information
 */
export interface Legal {
  termsClient: string;
  termsAdminClient: string;
  privacyClient: string;
  privacyAdminClient: string;
}

/**
 * Helper to generate an empty legal object
 * @returns empty legal object
 */
export const createEmptyLegal = (): Legal => ({
  termsClient: "",
  termsAdminClient: "",
  privacyClient: "",
  privacyAdminClient: "",
});
