import { useKeycloak } from "@react-keycloak/web";
import React, { useState } from "react";
import { useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Helmet from "react-helmet";
import { useAxios } from "../utils/AxiosUtil";
import { PrivateRoute } from "../utils/PrivateRoute";
import { Admin, Provider, UserRole } from "../utils/user/User.types";
import { createEmptyConfig, AppConfig } from "../utils/appConfig/config.types";
import { getAppConfig } from "../utils/appConfig/configUtils";
import { fetchKeycloakServiceId, fetchUserById } from "../utils/user/UserUtils";
import DashboardPage from "./DashboardPage";
import SignupSuccessPage from "./SignupSuccessPage";
import ProviderConfigPage from "./provider/ProviderConfigPage";
import AdminConfigurationPage from "./admin/AdminConfigurationPage";
import ProfilePage from "./ProfilePage";
import {
  generateNotification,
  NotificationTypes,
} from "deinestadtliebt-component-library";
import { useTranslation } from "react-i18next";
import ProviderConfigurationPage from "./provider/ProviderConfigurationPage";
import ProviderEventConfigurationPage from "./provider/ProviderEventConfigurationPage";
import ProviderAdvertisementConfigurationPage from "./provider/ProviderAdvertisementConfigurationPage";
import ProviderEvaluationConfigurationPage from "./provider/ProviderEvaluationConfigurationPage";
import ProviderAboConfigurationPage from "./provider/ProviderAboConfigurationPage";
import MailboxPage from "./MailboxPage";
import AdminProviderOverviewPage from "./admin/AdminProviderOverviewPage";
import AdminVersionPage from "./admin/AdminVersionPage";
import AgbPage from "./AgbPage";
import ImprintPage from "./ImprintPage";
import PrivacyPage from "./PrivacyPage";
import withClearCache from "../ClearCache";
import AdminEventPage from "./admin/AdminEventPage";
import NewSignupPage from "./NewSignupPage";
import AdminAdvertisementPage from "./admin/AdminAdvertisementPage";
import {
  CookieEvents,
  getCookieConsentValue,
} from "../utils/cookies/CookieUtils";
import { ConsentStatus } from "../utils/cookies/Cookie.types";
import ActivityEditPage from "./ActivityEditPage";
import ActivityOverviewPage from "./ActivityOverviewPage";
import LunchOverviewPage from "./LunchOverviewPage";
import LunchEditPage from "./LunchEditPage";

/**
 * this context stores the current loaded provider.
 * Could e Admin or Provider
 */
export const UserContext = React.createContext<{
  user?: Admin | Provider;
  setUser(user: Admin | Provider): void;
  isUserLoaded: boolean;
}>({ setUser: () => {}, isUserLoaded: false });

/**
 * this context saves the current application
 * config loaded from config service
 */
export const ConfigContext = React.createContext<{
  appConfig?: AppConfig;
  setAppConfig(appConfig: AppConfig): void;
}>({ setAppConfig: () => {} });

const App = () => {
  const { keycloak, initialized } = useKeycloak();
  const axios = useAxios();
  const [loadedUser, setLoadedUser] = useState<Admin | Provider>();
  const [isLoadedUserFinished, setIsLoadedUserFinished] =
    useState<boolean>(false);
  const { t } = useTranslation();
  const [consentStatus, setConsentStatus] = useState(ConsentStatus.PENDING);

  const [appConfig, setAppConfig] = useState<AppConfig>(createEmptyConfig());

  // fetch initial keycloak user service id and sets appConfig
  useEffect(() => {
    if (!!axios && !appConfig.id)
      getAppConfig(axios).then((appConfig) => {
        if (appConfig) setAppConfig(appConfig);
      });
    if (initialized && keycloak.authenticated && !!axios) {
      fetchKeycloakServiceId(keycloak).then((roleServiceId) => {
        fetchUserById(axios, roleServiceId.serviceId, roleServiceId.role).then(
          (userResp) => {
            setIsLoadedUserFinished(true);
            if (userResp) setLoadedUser(userResp);
            else {
              generateNotification(
                NotificationTypes.ERROR,
                t("notification.title.error.loadingFailed"),
                t("notification.content.error.loadingUser")
              );
            }
          }
        );
      });
    }
    //eslint-disable-next-line
  }, [keycloak, axios]);

  /**
   * This effect register a listener, listening on cookie consent changes
   * If consent is accepted then loading external tracking script
   */
  useEffect(() => {
    const onChange = (status: ConsentStatus) => {
      setConsentStatus(status);
    };
    setConsentStatus(getCookieConsentValue());
    CookieEvents.on("change", onChange);
    return () => {
      CookieEvents.off("change", onChange);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {t("general.dashboard")}
          {appConfig.platformName ? ` - ${appConfig.platformName}` : ""}
        </title>
        {/* This script is loading a tracker from the specified url in env vars if cookies are accepted */}
        {consentStatus === ConsentStatus.ACCEPTED &&
          process.env.REACT_APP_PAGESENSE_SOURCE && (
            <script src={process.env.REACT_APP_PAGESENSE_SOURCE} async={true} />
          )}
      </Helmet>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact>
            {keycloak && keycloak.authenticated ? (
              <Redirect to="/dashboard" />
            ) : (
              <Redirect to="/signup" />
            )}
          </Route>
          <Route path="/login" exact>
            {keycloak.authenticated ? (
              <Redirect to="/dashboard" />
            ) : (
              () => keycloak.login()
            )}
          </Route>
          {/* Public Routes */}
          <ConfigContext.Provider
            value={{ appConfig: appConfig, setAppConfig: setAppConfig }}
          >
            <Route path="/signup" exact component={NewSignupPage} />
            <Route path="/signup/success" exact component={SignupSuccessPage} />
            <Route path="/agb" exact component={AgbPage} />
            <Route path="/imprint" exact component={ImprintPage} />
            <Route path="/privacy" exact component={PrivacyPage} />
            {/* Secured Routes */}
            <UserContext.Provider
              value={{
                setUser: setLoadedUser,
                user: loadedUser,
                isUserLoaded: isLoadedUserFinished,
              }}
            >
              <PrivateRoute
                path="/mailbox"
                Page={MailboxPage}
                allowedUserRoles={[UserRole.ADMIN, UserRole.PROVIDER]}
              />
              <PrivateRoute
                path="/config/profile"
                Page={ProviderConfigPage}
                allowedUserRoles={[UserRole.PROVIDER]}
              />
              <PrivateRoute
                path="/config/event/edit"
                Page={ActivityEditPage}
                properties={{ isEdit: true }}
                allowedUserRoles={[UserRole.ADMIN, UserRole.PROVIDER]}
              />
              <PrivateRoute
                path="/config/event/create"
                Page={ActivityEditPage}
                properties={{ isEdit: false }}
                allowedUserRoles={[UserRole.ADMIN, UserRole.PROVIDER]}
              />
              <PrivateRoute
                path="/config/events"
                Page={ProviderEventConfigurationPage}
                allowedUserRoles={[UserRole.PROVIDER]}
              />
              <PrivateRoute
                path="/config/activity"
                Page={ActivityOverviewPage}
                properties={{ userRole: UserRole.PROVIDER }}
                allowedUserRoles={[UserRole.PROVIDER]}
              />
              <PrivateRoute
                Page={LunchOverviewPage}
                path="/config/lunch"
                allowedUserRoles={[UserRole.PROVIDER]}
              />
              <PrivateRoute
                Page={LunchEditPage}
                path="/config/lunch/create"
                properties={{ isEdit: false }}
                allowedUserRoles={[UserRole.PROVIDER]}
              />
              <PrivateRoute
                Page={LunchEditPage}
                path="/config/lunch/edit"
                properties={{ isEdit: true }}
                allowedUserRoles={[UserRole.PROVIDER]}
              />
              <PrivateRoute
                path="/config/advertisement"
                Page={ProviderAdvertisementConfigurationPage}
                allowedUserRoles={[UserRole.PROVIDER]}
              />
              <PrivateRoute
                path="/config/evaluation"
                Page={ProviderEvaluationConfigurationPage}
                allowedUserRoles={[UserRole.PROVIDER]}
              />
              <PrivateRoute
                path="/config/abo"
                Page={ProviderAboConfigurationPage}
                allowedUserRoles={[UserRole.PROVIDER]}
              />
              <PrivateRoute
                path="/config"
                Page={ProviderConfigurationPage}
                allowedUserRoles={[UserRole.PROVIDER]}
              />
              <PrivateRoute
                path="/config/provider"
                Page={AdminProviderOverviewPage}
                allowedUserRoles={[UserRole.ADMIN]}
              />
              <PrivateRoute
                Page={DashboardPage}
                path="/dashboard"
                allowedUserRoles={[UserRole.ADMIN, UserRole.PROVIDER]}
              />
              <PrivateRoute
                Page={AdminConfigurationPage}
                path="/admin/config"
                allowedUserRoles={[UserRole.ADMIN]}
              />
              <PrivateRoute
                Page={AdminEventPage}
                path="/admin/event"
                allowedUserRoles={[UserRole.ADMIN]}
              />
              <PrivateRoute
                Page={AdminEventPage}
                path="/admin/acitivityConfig"
                allowedUserRoles={[UserRole.ADMIN]}
              />
              <PrivateRoute
                Page={ActivityOverviewPage}
                path="/admin/activity"
                properties={{ userRole: UserRole.ADMIN }}
                allowedUserRoles={[UserRole.ADMIN]}
              />
              <PrivateRoute
                Page={LunchOverviewPage}
                path="/admin/lunch"
                allowedUserRoles={[UserRole.ADMIN]}
              />
              <PrivateRoute
                Page={LunchEditPage}
                path="/admin/lunch/create"
                properties={{ isEdit: false }}
                allowedUserRoles={[UserRole.ADMIN]}
              />
              <PrivateRoute
                Page={LunchEditPage}
                path="/admin/lunch/edit"
                properties={{ isEdit: true }}
                allowedUserRoles={[UserRole.ADMIN]}
              />
              <PrivateRoute
                Page={AdminAdvertisementPage}
                path="/admin/advertisement"
                allowedUserRoles={[UserRole.ADMIN]}
              />
              <PrivateRoute
                Page={AdminVersionPage}
                path="/admin/version"
                allowedUserRoles={[UserRole.ADMIN]}
              />
              <PrivateRoute
                Page={ProfilePage}
                path="/profile"
                allowedUserRoles={[UserRole.ADMIN, UserRole.PROVIDER]}
              />
            </UserContext.Provider>
          </ConfigContext.Provider>
        </Switch>
      </BrowserRouter>
    </>
  );
};

const ClearCacheComponent = withClearCache(App);

export default ClearCacheComponent;
