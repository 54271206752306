import {
  AdminLayoutComponent,
  DropdownComponent,
  DropdownOption,
  LoaderComponent,
  RetractableComponent,
} from "deinestadtliebt-component-library";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ImagesIcon } from "../../assets/icons/images.svg";
import { EventLocationEdit } from "../../components/eventlocationedit/EventLocationEdit";
import "../../styles/AdminEvent.style.scss";
import { useAxios } from "../../utils/AxiosUtil";
import { fetchAllEvents } from "../../utils/event/EventUtils";
import { CurrentPage } from "../../utils/navigation/Navigation.types";
import { useNavLayout } from "../../utils/navigation/NavigationUtils";
import {
  createEmptyProvider,
  IdName,
  Provider,
} from "../../utils/user/User.types";
import { fetchAllProvider } from "../../utils/user/UserUtils";
import { ConfigContext } from "../App";

interface AdminEventPageProps {}

const AdminEventPage: React.FC<AdminEventPageProps> = () => {
  const { t } = useTranslation();
  const { appConfig } = useContext(ConfigContext);
  const axios = useAxios();
  const [isLoading, toggleLoading] = useState<boolean>(false);

  const [keyCreateEvent, setKeyCreateEvent] = useState<number>(0);
  const [localProvider, setLocalProvider] = useState<Provider>(
    createEmptyProvider(false)
  );

  const [providerList, setProviderList] = useState<Provider[]>([]);

  /**
   * this useeffect fetches all events for the platformm and
   * the correct name for the provider by slugid as well as all the providers for event creation
   */
  useEffect(() => {
    if (!axios) return;

    Promise.all([fetchAllEvents(axios), fetchAllProvider(axios)]).then(
      ([fetchedEvents, providers]) => {
        setProviderList(providers);

        let local: IdName[] = [];
        providers.forEach((provider) => {
          let currentEntry: IdName = {
            id: provider.slugid,
            name: provider.name,
          };
          local.push(currentEntry);
        });
      }
    );
  }, [axios]);

  /**
   * Helper to get dropdown options for providers with a optional filter
   *
   * @param filter optional filter function
   *
   * @returns a list of dropdown options
   */
  const createProviderDropdownEntries = (
    filter: (
      value: Provider,
      index: number,
      array: Provider[]
    ) => boolean = () => true
  ): DropdownOption[] => {
    return providerList.filter(filter).map((provider) => ({
      label: provider.name,
      value: provider.slugid,
    }));
  };

  return (
    <AdminLayoutComponent {...useNavLayout(CurrentPage.ADMIN_EVENT)}>
      {isLoading && <LoaderComponent isFullscreen />}

      <div
        style={{ background: appConfig?.highlightColor }}
        className="default-page-headline"
      >
        <h1>{t("adminConfigPage.title")}</h1>
      </div>

      <div className="admin-configuration-wrapper default-page-wrapper">
        <RetractableComponent
          title={t("adminEventPage.event.location")}
          type="border"
          icon={<ImagesIcon />}
        >
          <p>
            <b>{t("adminEventPage.providerSelection")}</b>
          </p>
          <DropdownComponent
            searchable
            onKeyUp={(key, activeIndex) => {
              if (key.key.toLocaleLowerCase() === "enter") {
                activeIndex && setLocalProvider(providerList[activeIndex]);
              }
            }}
            colors={{
              hover: {
                bgColor: appConfig?.highlightColor,
                fontColor: appConfig?.fontColor,
              },
            }}
            dropdownOptions={createProviderDropdownEntries()}
            selectedOption={localProvider.slugid}
            onChange={(slugId) => {
              setLocalProvider(
                providerList.find((provider) => slugId === provider.slugid) ||
                  createEmptyProvider(false)
              );
              setKeyCreateEvent(keyCreateEvent + 1);
            }}
          />
          {localProvider.slugid && (
            <EventLocationEdit
              provider={localProvider}
              setProvider={setLocalProvider}
              isLoading={isLoading}
              toggleLoading={toggleLoading}
            />
          )}
        </RetractableComponent>
      </div>
    </AdminLayoutComponent>
  );
};

export default AdminEventPage;
