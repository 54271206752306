/**
 * Replace all \r\n (Windows linebreaks <CRLF>) and \r (Unix linebreaks <LF>) with \n <CR>.
 *
 * @tested
 * @param str the string to standardize
 * @returns standardized string, only containing <CR> line breaks
 */
export const standardizeLineBreak = (str: string): string => {
  return str.replace(/\r\n/g, "\n").replace(/\r/g, "\n");
};
