import "./ConfirmableButtonComponent.style.scss";
import {
  ButtonComponent,
  PopupComponent,
} from "deinestadtliebt-component-library";
import { ConfirmableButtonProps } from "./ConfirmableButtonComponent.types";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";

export const ConfirmableButtonComponent: React.FC<ConfirmableButtonProps> = ({
  openButton,
  acceptButton,
  declineButton,
  popup,
  onAccept,
  children,
  className,
}) => {
  const { t } = useTranslation();
  const [isPopup, setPopup] = useState<boolean>(false);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  return (
    <div className={["confirm-popup-component-wrapper", className].join(" ")}>
      <ButtonComponent
        {...{
          ...openButton,
          ...{
            onClick: () => setPopup(true),
          },
        }}
      />
      <PopupComponent
        {...{
          ...popup,
          ...{
            open: isPopup,
            toggleOpen: () => !isSubmitting && setPopup(false),
            bottomButtons: [
              {
                ...{ value: t("popup.confirm.accept") },
                ...acceptButton,
                ...{
                  onClick: async () => {
                    setSubmitting(true);
                    await onAccept();
                    setSubmitting(false);
                    setPopup(false);
                  },
                  disabled: isSubmitting,
                },
              },
              {
                ...{ value: t("popup.confirm.decline") },
                ...declineButton,
                ...{ onClick: () => setPopup(false), disabled: isSubmitting },
              },
            ],
          },
        }}
      >
        {children}
      </PopupComponent>
    </div>
  );
};
