import { AdminLayoutComponent } from "deinestadtliebt-component-library";
import { useEffect, useState } from "react";
import "../styles/AgbPageStyle.scss";
import { useAxios } from "../utils/AxiosUtil";
import { fetchAdminClientPrivacy } from "../utils/legal/LegalUtils";
import { CurrentPage } from "../utils/navigation/Navigation.types";
import { useNavLayout } from "../utils/navigation/NavigationUtils";

interface PrivacyPageProps {}

const PrivacyPage: React.FC<PrivacyPageProps> = () => {
  const axios = useAxios();
  const [privacyAdminClient, setPrivacyAdminClient] = useState<string>("");

  /**
   * This effect fetch the latest privacy from backend and change the state
   */
  useEffect(() => {
    if (axios) {
      fetchAdminClientPrivacy(axios).then(setPrivacyAdminClient);
    }
  }, [axios]);

  return (
    <AdminLayoutComponent {...useNavLayout(CurrentPage.Privacy)}>
      <div
        className="legal-page-wrapper"
        dangerouslySetInnerHTML={{ __html: privacyAdminClient }}
      />
    </AdminLayoutComponent>
  );
};

export default PrivacyPage;
