import { AdminLayoutComponent } from "deinestadtliebt-component-library";
import { CurrentPage } from "../utils/navigation/Navigation.types";
import { useNavLayout } from "../utils/navigation/NavigationUtils";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { ConfigContext, UserContext } from "./App";
import { UserRole } from "../utils/user/User.types";
import "../styles/MailboxStyles.scss";
import { MailboxComponent } from "../components/mailbox/MailboxComponent";

interface MailboxPageProps {}

const MailboxPage: React.FC<MailboxPageProps> = () => {
  const { t } = useTranslation();
  const { appConfig } = useContext(ConfigContext);
  const { user } = useContext(UserContext);

  return (
    <AdminLayoutComponent
      {...useNavLayout(
        user?.role === UserRole.ADMIN
          ? CurrentPage.MAILBOX_ADMIN
          : CurrentPage.MAILBOX_PROVIDER
      )}
    >
      <div
        style={{ background: appConfig?.highlightColor }}
        className="default-page-headline"
      >
        <h1>{t("mailboxPage.title")}</h1>
      </div>
      {user && <MailboxComponent role={user.role} />}
    </AdminLayoutComponent>
  );
};

export default MailboxPage;
