import React from "react";
import "./ProgressBarComponent.styles.scss";
import { ProgressBarComponentProps } from "./ProgressBarComponent.types";

/**
 * Progress bar displaying given progress
 * @param current value of progress
 * @param total value of progress
 * @param backgroundColor color of the back of the progressbar
 * @returns JSX.Element containing styled ProgressBar
 */
const ProgressBarComponent: React.FC<ProgressBarComponentProps> = ({
  current,
  total,
  backgroundColor = "red",
}) => {
  return (
    <div className="progress-bar">
      <div
        style={{
          maxWidth: `${(current / total) * 100}%`,
          backgroundColor: backgroundColor,
        }}
      />
    </div>
  );
};

export default ProgressBarComponent;
