import { AxiosInstance } from "axios";
import { FileItem } from "../user/User.types";
import { getFile, saveBlobInFile } from "../user/UserUtils";

/**
 * Helper to download a file from the config service
 * @param fileItem to download
 * @param axios AxiosInstance
 */
export const downloadFile = (
  fileItem: FileItem,
  axios: AxiosInstance
): void => {
  getFile(fileItem.filename, fileItem.type, axios).then((blob) => {
    if (blob) saveBlobInFile(blob, fileItem.originalFilename);
  });
};
