import { MailboxContentProps } from "./MailboxContent.types";
import { ReactComponent as ReplyIcon } from "../../../assets/icons/reply.svg";
import { ReactComponent as ArchiveIcon } from "../../../assets/icons/archive.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash-can.svg";
import {
  convertInRelativeTimeString,
  getNameFromMember,
} from "../../../utils/mailbox/MailboxUtils";
import { useEffect, useState } from "react";
import { standardizeLineBreak } from "../../../utils/format/StringUtils";
import { Message } from "../../../utils/mailbox/Mailbox.types";
import { useTranslation } from "react-i18next";
import { PopupComponent } from "deinestadtliebt-component-library";

export const MailboxContent: React.FC<MailboxContentProps> = ({
  thread,
  onReply,
  onArchive,
  onDelete,
  onRead,
  userId,
}) => {
  const { t } = useTranslation();
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  /**
   * This effect check whether any message is unread, if so marks them as read.
   */
  useEffect(() => {
    if (
      thread &&
      thread.messages.filter((message) => !message.seenBy.includes(userId))
        .length > 0
    ) {
      onRead();
    }
  }, [thread, userId, onRead]);

  /**
   * This helper sorts the messages by creating time.
   *
   * @returns sorted messages list
   */
  const sortMessages = (): Message[] => {
    if (thread) {
      const tempMessages = [...thread.messages];

      return tempMessages.sort(
        (a, b) => (b.createdAt || 0) - (a.createdAt || 0)
      );
    } else return [];
  };

  return (
    <div className="mailbox-content">
      <div className="mailbox-content-message">
        {thread ? (
          sortMessages().map((message) => (
            <div
              className="mailbox-content-message-entry"
              key={message.createdAt}
            >
              <div className="mailbox-content-message-sender">
                {getNameFromMember(message.sender, thread)}
              </div>
              <div className="mailbox-content-message-subject">
                {thread.subject}
                <div className="mailbox-content-message-time">
                  {convertInRelativeTimeString(new Date(message.createdAt))}
                </div>
              </div>
              <div className="mailbox-content-message-text">
                {standardizeLineBreak(message.content)
                  .split("\n")
                  .map((line, index, array) => (
                    <>
                      {line}
                      {array.length !== index && <br />}
                    </>
                  ))}
              </div>
            </div>
          ))
        ) : (
          <div className="mailbox-content-message-entry">
            <div className="mailbox-content-message-sender">
              {t("mailbox.content.preview")}
            </div>
            <div className="mailbox-content-message-subject">
              {t("mailbox.content.previewEmpty")}
            </div>
          </div>
        )}
      </div>
      {thread && (
        <div className="mailbox-content-footer">
          {onReply && (
            <div className="mailbox-content-footer-action" onClick={onReply}>
              <ReplyIcon />
              <span>{t("mailbox.footer.reply")}</span>
            </div>
          )}
          {onArchive && (
            <div className="mailbox-content-footer-action" onClick={onArchive}>
              <ArchiveIcon />
              <span>{t("mailbox.footer.archive")}</span>
            </div>
          )}
          {onDelete && (
            <div
              className="mailbox-content-footer-action"
              onClick={() => setConfirmDelete(true)}
            >
              <TrashIcon />
              <span>{t("mailbox.footer.delete")}</span>
            </div>
          )}
        </div>
      )}
      <PopupComponent
        open={confirmDelete}
        toggleOpen={() => setConfirmDelete(false)}
        bottomButtons={[
          {
            value: t("mailbox.create.popup.yes"),
            onClick: () => {
              setConfirmDelete(false);
              if (onDelete) onDelete();
            },
          },
          {
            value: t("mailbox.create.popup.no"),
            onClick: () => setConfirmDelete(false),
          },
        ]}
      >
        {t("mailbox.create.popup.confirmDelete")}
      </PopupComponent>
    </div>
  );
};
