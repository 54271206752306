import {
  ActivityTag,
  DateItem,
  DetailFilter,
  TargetAudience,
} from "../activity/Activity.types";
import {
  Category,
  createEmptyLocation,
  DayOfWeek,
  FileItem,
  Location,
  Provider,
} from "../user/User.types";

/**
 * model to save data for different discount types
 */
export enum DiscountType {
  PRICE = "PRICE",
  PERCENT = "PERCENT",
  SPECIAL = "SPECIAL",
}

/**
 * model of an action for a provider
 */
export interface Action {
  id?: string | null;
  slugId?: string;
  version?: number;
  name: string;
  providerId: string;
  createDate?: Date;
  lastUpdate?: Date;
  location: Location;
  startDate?: Date;
  endDate?: Date;
  startTime: string;
  endTime: string;
  activeDays: DayOfWeek[];
  displayedDays: DayOfWeek[];
  actionDates?: Date[];
  mainTag: ActivityTag;
  detailTags: DetailFilter[];
  targetAudiences: TargetAudience[];
  customTags: string[];
  showDate?: Date;
  discountDetails?: string;
  discountType?: DiscountType;
  discountPrice?: number;
  registrationFinishedDate?: Date;
  description: string;
  actionLink?: string;
  actionLinkText?: string;
  image?: FileItem | null;
  previewImage?: FileItem | null;
  flyer?: FileItem | null;
  /**
   * @deprecated
   */
  category?: Category[];
  categoryItem: Category;
  activityDates: DateItem[];
  seriesId?: string;
  canonUrl?: string;
}

/**
 * Helper to generate an empty action
 * @param providerId optional id to set it directly
 * @returns empty action modell
 */
export const createEmptyAction = (provider?: Provider): Action => ({
  name: "",
  activeDays: [],
  displayedDays: [],
  categoryItem: "" as Category,
  description: "",
  endTime: "",
  targetAudiences: [],
  customTags: [],
  location: provider ? provider.location : createEmptyLocation(),
  providerId: provider ? provider.slugid! : "",
  startTime: "",
  mainTag: "" as ActivityTag,
  detailTags: [],
  actionDates: [],
  showDate: new Date(),
  activityDates: [],
});
