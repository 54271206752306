import {
  ButtonComponent,
  generateNotification,
  InputComponent,
  NotificationTypes,
  RetractableComponent,
  TableComponent,
  TagComponent,
} from "deinestadtliebt-component-library";
import { useTranslation } from "react-i18next";
import "./ProviderTagEdit.styles.scss";
import { ProviderTagEditProps } from "./ProviderTagEdit.types";
import { ReactComponent as ImagesIcon } from "../../assets/icons/images.svg";
import {
  Category,
  createEmptyCustomTag,
  CustomTag,
  DetailType,
  MainType,
  TradeAndServiceCategories,
} from "../../utils/user/User.types";
import {
  convertEnumToTag,
  getCorrectAmountOfDetailTypesForCategory,
  getCorrectAmountOfMainTypesForCategory,
  getCorrectDetailTypeForCategory,
  getCorrectMainTypeForCategory,
  getFilteredTags,
} from "../../utils/signup/SignupUtils";
import { useState } from "react";
import {
  createCustomTagOnBackend,
  generateCustomTagsTableRows,
} from "../../utils/user/UserUtils";
import { useAxios } from "../../utils/AxiosUtil";

const ProviderTagEdit: React.FC<ProviderTagEditProps> = ({
  provider,
  setProvider,
}) => {
  const { t } = useTranslation();
  const axios = useAxios();
  const [tradeAndServiceCat, setTradeAndServiceCat] =
    useState<TradeAndServiceCategories>(TradeAndServiceCategories.TRADE);
  const [tradeTags, setTradeTags] = useState<DetailType[]>([]);
  const [serviceTags, setServiceTags] = useState<DetailType[]>([]);
  const [healthTags, setHealthTags] = useState<DetailType[]>([]);
  const [craftTags, setCraftTags] = useState<DetailType[]>([]);
  const [currentCustomTag, setCurrentCustomTag] = useState<CustomTag>(
    createEmptyCustomTag()
  );
  const [isLoading, toggleIsLoading] = useState<boolean>(false);

  /**
   *  Saves currently selected tags into respective useState and then lines them up and saves them into provider
   * @param category type of Categories, determines the switch case
   * @param tags Array of DetailTypes that will be saved
   */
  const setTradeAndServiceTags = (
    category: TradeAndServiceCategories,
    tags: DetailType[]
  ): void => {
    const tagList: DetailType[] = [];
    switch (category) {
      case TradeAndServiceCategories.TRADE:
        setTradeTags(tags);
        break;
      case TradeAndServiceCategories.SERVICE:
        setServiceTags(tags);
        break;
      case TradeAndServiceCategories.HEALTH:
        setHealthTags(tags);
        break;
      case TradeAndServiceCategories.CRAFT:
        setCraftTags(tags);
        break;
    }
    setDetailTypesAndCategory(
      Category.SHOPPING,
      tagList.concat(tradeTags, serviceTags, healthTags, craftTags)
    );
  };

  /**
   * Creates array for selected detail-tags and assigns them to provider-object
   * @param category enum of type Category
   * @param tags  array of tag-objects to be assigned into provider-object
   */
  const setDetailTypesAndCategory = (
    category: Category,
    tags: DetailType[]
  ): void => {
    let selectedDetailTypes: DetailType[] = [];
    tags.forEach((tag) => selectedDetailTypes.push(tag));
    setProvider({
      ...provider,
      detailTypes: selectedDetailTypes,
      category: category,
    });
  };

  /**
   * Helper to create a custom tag on the server
   */
  const createAndOrderCustomTag = (): void => {
    toggleIsLoading(true);
    createCustomTagOnBackend(axios, currentCustomTag, provider.id!).then(
      (success) => {
        if (success) {
          let localCustomTags: CustomTag[] = [...provider.customTags];
          localCustomTags.push(currentCustomTag);
          setProvider({
            ...provider,
            customTags: localCustomTags,
          });
          setCurrentCustomTag(createEmptyCustomTag());
        } else
          generateNotification(
            NotificationTypes.ERROR,
            t("notification.title.error.error"),
            t("notification.content.error.customTag")
          );
        toggleIsLoading(false);
      }
    );
  };

  return (
    <>
      <RetractableComponent
        title={t("providerConfigurationPage.tagsTitle")}
        type="border"
        icon={<ImagesIcon />}
      >
        {Object.values(Category).map(
          (currentCategory, currentCategoryIndex) => (
            <div
              key={`category-rectangle-index-${currentCategoryIndex}-${provider.category}`}
            >
              <p>{t(`enum.category.${currentCategory}`)}</p>
              <TagComponent
                maxSelectAmount={getCorrectAmountOfMainTypesForCategory(
                  currentCategory
                )}
                selected={provider.mainTypes}
                tags={convertEnumToTag(
                  getCorrectMainTypeForCategory(Category[currentCategory]),
                  "MainType"
                )}
                onChange={(tags) => {
                  setProvider({
                    ...provider,
                    mainTypes: [...(tags as MainType[])],
                    category: currentCategory,
                  });
                }}
              />
            </div>
          )
        )}
      </RetractableComponent>

      <RetractableComponent
        title={t("providerConfigurationPage.detailTags")}
        type="border"
        icon={<ImagesIcon />}
      >
        {provider.category === Category.SHOPPING ? (
          <div className="signup-component--right-content--detailTags">
            <div className="signup-component--right-content--detailTags-buttons">
              <ButtonComponent
                className={
                  tradeAndServiceCat === TradeAndServiceCategories.TRADE
                    ? "signup-component--right-content--detailTags-buttons-selected"
                    : ""
                }
                onClick={() =>
                  setTradeAndServiceCat(TradeAndServiceCategories.TRADE)
                }
                value={t("enum.tradeService.TRADE")}
              />
              <ButtonComponent
                className={
                  tradeAndServiceCat === TradeAndServiceCategories.SERVICE
                    ? "signup-component--right-content--detailTags-buttons-selected"
                    : ""
                }
                onClick={() =>
                  setTradeAndServiceCat(TradeAndServiceCategories.SERVICE)
                }
                value={t("enum.tradeService.SERVICE")}
              />
              <ButtonComponent
                className={
                  tradeAndServiceCat === TradeAndServiceCategories.HEALTH
                    ? "signup-component--right-content--detailTags-buttons-selected"
                    : ""
                }
                onClick={() =>
                  setTradeAndServiceCat(TradeAndServiceCategories.HEALTH)
                }
                value={t("enum.tradeService.HEALTH")}
              />
              <ButtonComponent
                className={
                  tradeAndServiceCat === TradeAndServiceCategories.CRAFT
                    ? "signup-component--right-content--detailTags-buttons-selected"
                    : ""
                }
                onClick={() =>
                  setTradeAndServiceCat(TradeAndServiceCategories.CRAFT)
                }
                value={t("enum.tradeService.CRAFT")}
              />
            </div>
            <div className="signup-component--right-content--detailTags-tags">
              <TagComponent
                key={tradeAndServiceCat}
                maxSelectAmount={getCorrectAmountOfDetailTypesForCategory(
                  provider.category
                )}
                selected={provider.detailTypes}
                tags={getFilteredTags(tradeAndServiceCat)}
                onChange={(tags) => {
                  setTradeAndServiceTags(
                    tradeAndServiceCat,
                    tags as DetailType[]
                  );
                }}
              />
            </div>
          </div>
        ) : (
          <TagComponent
            maxSelectAmount={getCorrectAmountOfDetailTypesForCategory(
              provider.category
            )}
            tags={convertEnumToTag(
              getCorrectDetailTypeForCategory(provider.category),
              "DetailType"
            )}
            selected={provider.detailTypes}
            onChange={(tags) =>
              setDetailTypesAndCategory(provider.category, tags as DetailType[])
            }
          />
        )}
      </RetractableComponent>

      <RetractableComponent
        title={t("providerConfigurationPage.customTags")}
        type="border"
        icon={<ImagesIcon />}
      >
        <p>{t("providerConfigurationPage.detailTagsOrder")}</p>
        <InputComponent
          placeholder={t("providerConfigurationPage.inputs.name")}
          value={currentCustomTag.text}
          onChange={(value) =>
            setCurrentCustomTag({ ...currentCustomTag, text: value })
          }
        />
        <ButtonComponent
          value={t("providerConfigurationPage.buttons.save")}
          type="button"
          isLoading={isLoading}
          onClick={() => createAndOrderCustomTag()}
        />
        <div>
          <TableComponent
            maxPageAmount={20}
            header={t("providerConfigurationPage.tableHeaderCustomTag", {
              returnObjects: true,
            })}
            rows={generateCustomTagsTableRows(provider.customTags)}
          />
        </div>
      </RetractableComponent>
    </>
  );
};

export default ProviderTagEdit;
