import { useEffect, useState } from "react";

/**
 * method used to determine whether desktop-content is visible or not
 */
export const useWindowSize = (): boolean => {
  const [desktopVisibility, toggleDesktopVisibility] = useState<boolean>(true);

  useEffect(() => {
    const handleResize = () => {
      if (typeof window !== "undefined" && window.document) {
        let desktopWrap: HTMLElement | null =
          document.getElementById("desktop-wrapper");
        if (desktopWrap === null) return;
        toggleDesktopVisibility(
          window.getComputedStyle(desktopWrap).display !== "none"
        );
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return desktopVisibility;
};
