import {
  Category,
  createEmptyLocation,
  DayOfWeek,
  FileItem,
  Location,
  PriceItem,
  VVKLocation,
} from "../user/User.types";
import { v4 as uuidv4 } from "uuid";
import {
  ActivityTag,
  DateItem,
  DetailFilter,
  TargetAudience,
} from "../activity/Activity.types";

// Array with weekDays to iterate over
export const DayOfWeekArray = [
  "SUNDAY",
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
];

export interface StepClickedDetector {
  step1Clicked: boolean;
  step2Clicked: boolean;
  step3Clicked: boolean;
  step4Clicked: boolean;
  step5Clicked: boolean;
}

export enum EventIntervall {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY",
}

/**
 * model used to save whether a step for event-creation/-edit is complete
 */
export interface SectionValidation {
  nameDescriptionValid: boolean;
  tagSelectionValid: boolean;
  eventDateListValid: boolean;
  ticketsAndPriceValid: boolean;
  releaseValid: boolean;
}

/**
 * model used to determine what kind of ticket is currently selected
 */
export enum TicketType {
  ONLINE = "ONLINE",
  VVK = "VVK",
  ON_SPOT = "ON_SPOT",
  FREE = "FREE",
}

/**
 * model to save data for different price categories
 */
export interface PriceCategory {
  id:
    | "DEFAULT"
    | "ADULT"
    | "KIDS"
    | "REDUCED"
    | "SENIOR"
    | "KIDS"
    | "HANDICAPPED"
    | string;
  label: string;
  price: number | undefined;
  enabled: boolean;
  description: string;
}

/**
 * state of the event
 */
export enum Eventstate {
  READY = "READY",
  MOVED = "MOVED",
  CANCELLED = "CANCELLED",
}
/**
 * model of an event
 */
export interface Event {
  id?: string;
  version?: number;
  slugId?: string;
  providerId: string;
  state: Eventstate;
  providerName?: string;
  createDate?: Date;
  lastUpdate?: Date;
  location: Location;
  name: string;
  eventDate?: Date;
  eventDateList: Date[];
  showDate?: Date;
  movedTo?: Date;
  startTime: string;
  endTime: string;
  link: string;
  category: Category;
  activeDays: DayOfWeek[];
  displayedDays: DayOfWeek[];
  image?: FileItem | null;
  previewImage?: FileItem | null;
  description: string;
  priceItems: PriceItem[];
  mainTag: ActivityTag;
  detailTags: DetailFilter[];
  targetAudiences: TargetAudience[];
  customTags: string[];
  flyer?: FileItem | null;
  startDate?: Date;
  endDate?: Date;
  vvkList: VVKLocation[];
  dateLinkList: string[];
  registration?: boolean;
  registerDate?: Date;
  registerDetail?: string;
  ticketType: TicketType[];
  activityDates: DateItem[];
  seriesId?: string;
  canonUrl?: string;
}

/**
 * Helper to generate an empty event
 * @param providerId id of the provider who creates the event
 * @returns empty provider filled event
 */
export const createEmptyEvent = (providerId: string): Event => ({
  state: Eventstate.READY,
  description: "",
  endTime: "",
  eventDateList: [],
  link: "",
  location: createEmptyLocation(),
  name: "",
  customTags: [],
  targetAudiences: [],
  priceItems: [],
  activeDays: [],
  displayedDays: [],
  ticketType: [],
  mainTag: "" as ActivityTag,
  category: "" as Category,
  detailTags: [],
  providerId: providerId,
  showDate: new Date(),
  startTime: "",
  dateLinkList: [""],
  vvkList: [],
  activityDates: [],
});

/**
 * model for an time lapse for an event
 */
export interface TempTimelapse {
  id: string;
  eventDate?: Date;
  showDate?: Date;
  startTime: string;
  endTime: string;
  link: string;
}

/**
 * Helper to empty temp timelapse
 * @returns empty model
 */
export const createEmptyTempTimelapse = (): TempTimelapse => ({
  id: uuidv4(),
  startTime: "",
  endTime: "",
  link: "",
});
