import {
  AdminLayoutComponent,
  ButtonComponent,
  CheckboxComponent,
  DropdownComponent,
  DropdownOption,
  generateNotification,
  InputComponent,
  LoaderComponent,
  NotificationTypes,
  PopupComponent,
  ProgressbarComponent,
  RetractableComponent,
  TableComponent,
} from "deinestadtliebt-component-library";
import { CurrentPage } from "../../utils/navigation/Navigation.types";
import { useNavLayout } from "../../utils/navigation/NavigationUtils";
import "../../styles/AdminProviderOvierview.style.scss";
import { useContext, useEffect, useState } from "react";
import { useAxios } from "../../utils/AxiosUtil";
import {
  assignCoordinatesForAddress,
  deleteProviderFromBackend,
  fetchAllProvider,
  getProviderPercentage,
  isProviderReadyForUpdate,
  updateCustomTagOnBackend,
  updateLicenceForProvider,
  updateProfileLoginCredentials,
  updateProviderMenuData,
  updateProviderPriceData,
  updateProviderProfileData,
} from "../../utils/user/UserUtils";
import {
  Category,
  CustomTag,
  Provider,
  StatusType,
} from "../../utils/user/User.types";
import { ReactComponent as ImagesIcon } from "../../assets/icons/images.svg";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../App";
import ProviderProfileConfig from "../../components/providerprofileconfig/ProviderProfileConfig";
import ProviderTagEdit from "../../components/providertagedit/ProviderTagEdit";
import { ConfirmableButtonComponent } from "../../components/confirmablebutton/ConfirmableButtonComponent";
import { generateGoodToKnowTable } from "../../utils/appConfig/configUtils";
import MenuEdit from "../../components/menu/MenuEdit";
import PriceListEdit from "../../components/pricelist/PriceListEdit";
import SpecialOpeningTimeEdit from "../../components/specialopeningtimeedit/SpecialOpeningTimeEdit";
import HolidayConfiguration from "../../components/holidayEdit/HolidayEdit";
import SpecialNoteEdit from "../../components/specialnoteedit/SpecialNoteEdit";
import {
  generateDropdownItemsForLicences,
  getAllLicence,
} from "../../utils/licence/LicenceUtils";

interface AdminProviderOverviewPageProps {}

const AdminProviderOverviewPage: React.FC<
  AdminProviderOverviewPageProps
> = () => {
  const { t } = useTranslation();
  const { appConfig } = useContext(ConfigContext);
  const axios = useAxios();
  const [allProvider, setAllProvider] = useState<Provider[]>([]);
  const [filterProviderString, setFilterProviderString] = useState<string>("");
  const history = useHistory();
  const [selectedCategory, setSelectedCategory] = useState<Category[]>(
    Object.values(Category)
  );
  const [selectedProvider, setSelectedProvider] = useState<Provider>();
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const [isGlobalLoading, toggleGlobalLoading] = useState<boolean>(false);
  const [licencesDropdownOptions, setLicencesDropdownOptions] = useState<
    DropdownOption[]
  >([]);

  /**
   * this useEffect fetches all provider
   */
  useEffect(() => {
    if (!!axios) {
      Promise.all([fetchAllProvider(axios), getAllLicence(axios)]).then(
        ([loadedProvider, lics]) => {
          setAllProvider(loadedProvider);
          setLicencesDropdownOptions(generateDropdownItemsForLicences(lics));
        }
      );
    }
  }, [axios]);

  /**
   * Helper to toggle the selectionof a good to know item
   * @param goodToKnowId id of element to toggle
   */
  const toggleGoodToKnowElement = (goodToKnowId: string): void => {
    if (selectedProvider?.goodToKnowItems.includes(goodToKnowId)) {
      setSelectedProvider({
        ...selectedProvider,
        goodToKnowItems: selectedProvider.goodToKnowItems.filter(
          (item) => item !== goodToKnowId
        ),
      });
    } else {
      selectedProvider?.goodToKnowItems.push(goodToKnowId);
      setSelectedProvider({ ...selectedProvider! });
    }
  };

  /**
   * Helper to toggle the disabled state for a provider
   *
   * @param provider to update
   */
  const toggleDisabledStateForProvider = async (
    provider: Provider,
    isProfilLock: boolean
  ): Promise<void> => {
    let newDisabled: boolean = isProfilLock
      ? provider.disabled
      : !provider.disabled;
    let newStatus: StatusType = isProfilLock
      ? provider.status === StatusType.RELEASED
        ? StatusType.LOCKED
        : StatusType.RELEASED
      : provider.status;

    await updateProfileLoginCredentials(axios, {
      ...provider,
      disabled: newDisabled,
      status: newStatus,
    }).then((success) => {
      if (success) {
        const foundIndex: number = allProvider.findIndex(
          (item) => item.id === provider.id
        );
        if (foundIndex !== -1) {
          const localProviderCopy: Provider[] = allProvider;
          localProviderCopy[foundIndex].disabled = newDisabled;
          localProviderCopy[foundIndex].status = newStatus;
          setAllProvider([...localProviderCopy]);
        }
        generateNotification(
          NotificationTypes.SUCCESS,
          t("notification.title.success.changesSuccessful"),
          t("notification.content.success.providerUpdate")
        );
      } else
        generateNotification(
          NotificationTypes.ERROR,
          t("notification.title.error.error"),
          t("notification.content.error.providerUpdate")
        );
    });
  };
  /**
   * Helper to toggle the in/active state of a custom tag for a provider
   * @param currentProvider to update
   * @param currentProviderIndex index in list of all providers
   * @param customTagIndex custom tag index
   */
  const toggleCustomTagForProvider = async (
    currentProvider: Provider,
    currentProviderIndex: number,
    customTagIndex: number
  ): Promise<void> => {
    let localCustomTag: CustomTag[] = [...currentProvider.customTags];
    localCustomTag[customTagIndex].active =
      !localCustomTag[customTagIndex].active;
    await updateCustomTagOnBackend(axios, {
      ...currentProvider,
      customTags: localCustomTag,
    }).then((success) => {
      if (success) {
        allProvider[currentProviderIndex] = {
          ...currentProvider,
          customTags: localCustomTag,
        };
        setAllProvider([...allProvider]);
      } else
        generateNotification(
          NotificationTypes.ERROR,
          t("notification.title.error.error"),
          t("notification.content.error.providerUpdate")
        );
    });
  };

  /**
   * Helper to delete a provider and update the list
   */
  const deleteProvider = async (provider: Provider): Promise<void> => {
    toggleLoading(true);
    await deleteProviderFromBackend(axios, provider.id!).then((success) => {
      if (success) {
        setAllProvider([
          ...allProvider.filter((item) => item.id !== provider?.id!),
        ]);
        generateNotification(
          NotificationTypes.SUCCESS,
          t("notification.title.success.deleteSuccessful"),
          t("notification.content.success.providerUpdate")
        );
      } else {
        generateNotification(
          NotificationTypes.ERROR,
          t("notification.title.error.error"),
          t("notification.content.error.deletion")
        );
      }
    });
    toggleLoading(false);
  };

  /**
   * Applies the changed data to the Provider-Object and sends a post-request to server to overwrite provider-data
   * @returns no return, but sends alert to user whether it was successfull or not
   */
  const adjustCurrentProvider = async (): Promise<void> => {
    if (!isProviderReadyForUpdate(selectedProvider!)) {
      generateNotification(
        NotificationTypes.WARNING,
        t("notification.title.warning.missingFields"),
        t("notification.content.warning.missingFields")
      );
    }

    let foundIndex: number = allProvider.findIndex(
      (item) => item.id === selectedProvider?.id
    );
    if (foundIndex === -1) return;
    toggleLoading(true);

    const menuHasChanged: boolean =
      selectedProvider!.menuItems !== allProvider[foundIndex].menuItems;
    const priceListHasChanged: boolean =
      selectedProvider!.priceItems !== allProvider[foundIndex].priceItems;

    // update provider itself
    assignCoordinatesForAddress(axios, selectedProvider!).then((provider) => {
      updateProviderProfileData(axios, provider).then(async (success) => {
        if (success) {
          if (menuHasChanged) await updateProviderMenuData(axios, provider);
          if (priceListHasChanged)
            await updateProviderPriceData(axios, provider);
          generateNotification(
            NotificationTypes.SUCCESS,
            t("notification.title.success.changesSuccessful"),
            t("notification.content.success.providerUpdate")
          );
          allProvider[foundIndex] = provider!;
          setAllProvider([...allProvider]);
          setSelectedProvider(undefined);
        } else
          generateNotification(
            NotificationTypes.ERROR,
            t("notification.title.error.changesFailed"),
            t("notification.content.error.providerUpdate")
          );
        toggleLoading(false);
      });
    });
  };

  /**
   * Helper to update licence for a selected value of dropdown
   * in provider overview
   * @param provider data to update licence to
   * @param val value of licence id
   */
  const updateLicenceForProviderByValue = (
    provider: Provider,
    val: string
  ): void => {
    toggleGlobalLoading(true);
    let localProvider: Provider = { ...provider };
    localProvider.licenceId = val;
    updateLicenceForProvider(axios, localProvider).then((success) => {
      if (success) {
        let foundIndex = allProvider.findIndex(
          (prov) => prov.id === provider.id
        );
        if (foundIndex === -1) return;
        allProvider[foundIndex].licenceId = val;
        setAllProvider([...allProvider]);
      }
      toggleGlobalLoading(false);
    });
  };

  /**
   * Helper to generate Provider entry in admin list
   * @param provider provider which should be displayes
   * @param currentProviderIndex current index in map for provider
   * @returns generates JSX.Element for provider
   */
  const generateProviderEntry = (
    provider: Provider,
    currentProviderIndex: number
  ): JSX.Element => {
    return (
      <RetractableComponent
        key={`current-provider-wrapper-${provider.id!}`}
        title={
          <div>
            <div>
              {provider.name} ({t(`enum.category.${provider.category}`)})
            </div>
            <div className="flex-it">
              <div>{t("adminProviderOverviewPage.percentageText")}</div>
              <div className="provider-wrapper--progressbar">
                <ProgressbarComponent
                  value={getProviderPercentage(provider)}
                  color={
                    getProviderPercentage(provider) === 100 ? "green" : "red"
                  }
                />
              </div>
              <div>{getProviderPercentage(provider)}%</div>
            </div>
          </div>
        }
        type="border"
        icon={<ImagesIcon />}
      >
        <div className="provider-entry-wrapper">
          <div className="provider-entry-wrapper--headline">
            {t("adminProviderOverviewPage.description")}
          </div>
          <div className="provider-entry-wrapper--content">
            {provider.description}
          </div>
        </div>
        <div className="provider-entry-wrapper">
          <div className="provider-entry-wrapper--headline">
            {t("adminProviderOverviewPage.displayDescription")}
          </div>
          <div className="provider-entry-wrapper--content">
            {provider.displayDescription?.replace(/(<([^>]+)>)/gi, "")}
          </div>
        </div>
        <div className="provider-entry-wrapper">
          <div className="provider-entry-wrapper--headline">
            {t("adminProviderOverviewPage.trafficCounter")}
          </div>
          <div className="provider-entry-wrapper--content">
            {provider.trafficCounter}x
          </div>
        </div>
        <div className="provider-entry-wrapper">
          <div className="provider-entry-wrapper--headline">
            {t("adminProviderOverviewPage.createDate")}
          </div>
          <div className="provider-entry-wrapper--content">
            {provider.createDate
              ? `${new Date(provider.createDate).toLocaleDateString(
                  "de"
                )} ${new Date(provider.createDate).toLocaleTimeString("de")}`
              : t("adminProviderOverviewPage.createDateNotFound")}
          </div>
        </div>
        <div className="provider-entry-wrapper">
          <div className="provider-entry-wrapper--headline">
            {t("adminProviderOverviewPage.location")}
          </div>
          <div className="provider-entry-wrapper--content">
            {`${provider.location.street}, ${provider.location.zipCode} ${provider.location.city} (${provider.location.lat}/${provider.location.lng})`}
          </div>
        </div>
        <div className="provider-entry-wrapper">
          <div className="provider-entry-wrapper--headline">
            {t("adminProviderOverviewPage.tags")}
          </div>
          <div className="provider-entry-wrapper--content flex-it">
            <div className="tags-wrapper">
              {t(`enum.category.${provider.category}`)}
            </div>
            <div className="tags-wrapper">
              {provider.mainTypes.map(
                (currentMainType, currentMainTypeIndex) => (
                  <span key={`main-tag-${currentMainTypeIndex}`}>
                    {t(`enum.mainType.${currentMainType}`)}
                  </span>
                )
              )}
            </div>
            <div className="tags-wrapper">
              {provider.detailTypes.map(
                (currentDetailType, currentDetailTypeIndex) => (
                  <span key={`detail-tag-${currentDetailTypeIndex}`}>
                    {t(`enum.detailType.${currentDetailType}`)}
                  </span>
                )
              )}
            </div>
          </div>
        </div>
        {JSON.parse(process.env.REACT_APP_USE_LICENCE!) && (
          <div className="provider-entry-wrapper licence">
            <div className="provider-entry-wrapper--headline">
              {t("adminProviderOverviewPage.licence")}
            </div>
            <div className="provider-entry-wrapper--content ">
              <DropdownComponent
                dropdownOptions={licencesDropdownOptions}
                onChange={(val) =>
                  updateLicenceForProviderByValue(provider, val)
                }
                selectedOption={provider.licenceId ?? ""}
              />
            </div>
          </div>
        )}
        <div className="provider-entry-wrapper">
          <div className="provider-entry-wrapper--headline">
            {t("adminProviderOverviewPage.customTags")}
          </div>
          <div className="provider-entry-wrapper--content">
            {provider.customTags.length === 0 &&
              t("adminProviderOverviewPage.customTagsNotFound")}
            {provider.customTags.map((customTag, customTagIndex) => (
              <div
                className="flex-it-center"
                key={`custom-tag-${provider.id}-${customTagIndex}`}
              >
                <div>{customTag.text}</div>
                <div>
                  <ButtonComponent
                    onClick={() =>
                      toggleCustomTagForProvider(
                        provider,
                        currentProviderIndex,
                        customTagIndex
                      )
                    }
                    colors={{
                      default: {
                        bgColor: "black",
                        fontColor: "white",
                      },
                    }}
                    size="small"
                    value={t(
                      `adminProviderOverviewPage.buttons.${
                        customTag.active ? "active" : "inactive"
                      }`
                    )}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="provider-entry-wrapper">
          <div className="provider-entry-wrapper--headline">
            {t("adminProviderOverviewPage.files")}
          </div>
          <div className="provider-entry-wrapper--content">
            {t("adminProviderOverviewPage.uploadedFiles", {
              replace: { number: provider.files.length },
            })}
          </div>
        </div>
        <div className="in-one-row">
          <div className="provider-entry-wrapper">
            <div className="provider-entry-wrapper--headline">
              {t("adminProviderOverviewPage.contactPerson")}
            </div>
            <div className="provider-entry-wrapper--content">
              {provider.firstname} {provider.lastname}
            </div>
          </div>
          <div className="provider-entry-wrapper">
            <div className="provider-entry-wrapper--headline">
              {t("adminProviderOverviewPage.phoneNumber")}
            </div>
            <div className="provider-entry-wrapper--content">
              <a href={`tel:${provider.phoneNumber}`}>{provider.phoneNumber}</a>
            </div>
          </div>
          <div className="provider-entry-wrapper">
            <div className="provider-entry-wrapper--headline">
              {t("adminProviderOverviewPage.mail")}
            </div>
            <div className="provider-entry-wrapper--content">
              <a href={`mailto:${provider.mail}`}>{provider.mail}</a>
            </div>
          </div>
          <div className="provider-entry-wrapper">
            <div className="provider-entry-wrapper--headline">
              {t("adminProviderOverviewPage.username")}
            </div>
            <div className="provider-entry-wrapper--content">
              {provider.username}
            </div>
          </div>
        </div>
        <div className="provider-entry-wrapper">
          <div className="provider-entry-wrapper--headline">
            {t("adminProviderOverviewPage.link")}
          </div>
          <div className="provider-entry-wrapper--content">
            <a
              rel="noreferrer noopener"
              target="_blank"
              href={[process.env.REACT_APP_CLIENT_URL!, provider.slugid].join(
                ""
              )}
            >
              {[process.env.REACT_APP_CLIENT_URL!, provider.slugid].join("")}
            </a>
          </div>
        </div>
        <div className="provider-entry-wrapper--buttons">
          <ButtonComponent
            onClick={() => setSelectedProvider(provider)}
            value={t("adminProviderOverviewPage.buttons.providerEdit")}
          />
          <ConfirmableButtonComponent
            openButton={{
              value: provider.disabled
                ? t("adminProviderOverviewPage.buttons.unlock")
                : t("adminProviderOverviewPage.buttons.lock"),
            }}
            onAccept={() => toggleDisabledStateForProvider(provider, false)}
          >
            {provider.disabled
              ? t("adminProviderOverviewPage.popup.unlockProfile", {
                  replace: { name: provider.name },
                })
              : t("adminProviderOverviewPage.popup.lockProfile", {
                  replace: { name: provider.name },
                })}
          </ConfirmableButtonComponent>

          <ConfirmableButtonComponent
            openButton={{
              value:
                provider.status === StatusType.LOCKED
                  ? t("adminProviderOverviewPage.buttons.profileInactive")
                  : t("adminProviderOverviewPage.buttons.profileActive"),
            }}
            onAccept={() => toggleDisabledStateForProvider(provider, true)}
          >
            {provider.status === StatusType.LOCKED
              ? t("adminProviderOverviewPage.popup.showProfile", {
                  replace: { name: provider.name },
                })
              : t("adminProviderOverviewPage.popup.hideProfile", {
                  replace: { name: provider.name },
                })}
          </ConfirmableButtonComponent>

          <ConfirmableButtonComponent
            openButton={{
              value: t("adminProviderOverviewPage.buttons.delete"),
            }}
            acceptButton={{
              value: t("adminProviderOverviewPage.buttons.confirmDelete"),
              isLoading: isLoading,
              type: "button",
              colors: {
                default: {
                  bgColor: "red",
                  borderColor: "red",
                  fontColor: "white",
                },
              },
            }}
            onAccept={() => deleteProvider(provider)}
          >
            {t("adminProviderOverviewPage.deleteProviderInfo", {
              replace: { name: provider.name },
            })}
          </ConfirmableButtonComponent>

          <ButtonComponent
            value={t("adminProviderOverviewPage.buttons.message")}
            onClick={() => history.push(`/mailbox?id=${provider.slugid}`)}
          />
        </div>
      </RetractableComponent>
    );
  };
  return (
    <>
      {isGlobalLoading && <LoaderComponent isFullscreen />}

      <AdminLayoutComponent
        {...useNavLayout(CurrentPage.CONFIGURATION_ADMIN_PROVIDER)}
      >
        <div
          style={{ background: appConfig?.highlightColor }}
          className="default-page-headline"
        >
          <h1>{t("adminProviderOverviewPage.title")}</h1>
        </div>
        <div
          className="default-page-wrapper"
          id="admin-provider-configuration-wrapper"
        >
          <div>
            <InputComponent
              value={filterProviderString}
              onChange={setFilterProviderString}
              placeholder={t("adminProviderOverviewPage.search")}
            />
            <p>{t("adminProviderOverviewPage.info")}</p>
            <div className="flex-it-wrap">
              {Object.values(Category).map((category, categoryIndex) => (
                <CheckboxComponent
                  key={`filter-category-${categoryIndex}`}
                  checked={selectedCategory.includes(category)}
                  onCheck={() => {
                    if (selectedCategory.includes(category)) {
                      setSelectedCategory([
                        ...selectedCategory.filter((item) => item !== category),
                      ]);
                    } else {
                      setSelectedCategory([
                        ...selectedCategory.concat([category]),
                      ]);
                    }
                  }}
                  value={t(`enum.category.${category}`)}
                />
              ))}
            </div>
          </div>

          <div>
            {allProvider
              .filter((item) =>
                item.name.toLowerCase().includes(filterProviderString)
              )
              .filter((item) => selectedCategory.includes(item.category))
              .map((currentProvider, currentProviderIndex) =>
                generateProviderEntry(currentProvider, currentProviderIndex)
              )}
          </div>

          <div className="provider-edit-popup">
            <PopupComponent
              open={!!selectedProvider}
              toggleOpen={() => setSelectedProvider(undefined)}
              bottomButtons={[
                {
                  value: t("adminProviderOverviewPage.buttons.save"),
                  type: "button",
                  isLoading: isLoading,
                  onClick: () => adjustCurrentProvider(),
                },
              ]}
            >
              {selectedProvider ? (
                <>
                  <ProviderProfileConfig
                    provider={selectedProvider!}
                    setProvider={setSelectedProvider}
                  />
                  <ProviderTagEdit
                    provider={selectedProvider!}
                    setProvider={setSelectedProvider}
                  />
                  <RetractableComponent
                    title={t("providerConfigurationPage.titleGoodToKnow")}
                    type="border"
                    icon={<ImagesIcon />}
                  >
                    <TableComponent
                      maxPageAmount={10}
                      header={t(
                        "providerConfigurationPage.tableHeaderGoodToKnow",
                        {
                          returnObjects: true,
                        }
                      )}
                      rows={generateGoodToKnowTable(
                        appConfig!,
                        selectedProvider,
                        toggleGoodToKnowElement
                      )}
                    />
                  </RetractableComponent>
                  {selectedProvider.category === Category.EAT_DRINK ? (
                    <MenuEdit
                      selectedProvider={selectedProvider}
                      setSelectedProvider={setSelectedProvider}
                    />
                  ) : (
                    <PriceListEdit
                      selectedProvider={selectedProvider}
                      setSelectedProvider={setSelectedProvider}
                    />
                  )}

                  <SpecialOpeningTimeEdit
                    selectedProvider={selectedProvider}
                    setSelectedProvider={setSelectedProvider}
                  />
                  <SpecialNoteEdit
                    localProvider={selectedProvider}
                    setLocalProvider={setSelectedProvider}
                  />
                  <RetractableComponent
                    title={t("adminConfigurationPage.holidays")}
                    type="border"
                    icon={<ImagesIcon />}
                  >
                    <HolidayConfiguration
                      provider={selectedProvider}
                      setProvider={setSelectedProvider}
                    />
                  </RetractableComponent>
                </>
              ) : (
                <LoaderComponent />
              )}
            </PopupComponent>
          </div>
        </div>
      </AdminLayoutComponent>
    </>
  );
};

export default AdminProviderOverviewPage;
