import {
  AdminLayoutComponent,
  ButtonComponent,
  generateNotification,
  LoaderComponent,
  NotificationTypes,
  PopupComponent,
} from "deinestadtliebt-component-library";
import { useContext, useEffect } from "react";
import useState from "react-usestateref";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useAxios } from "../utils/AxiosUtil";
import {
  createEmptyEvent,
  Event,
  SectionValidation,
  StepClickedDetector,
} from "../utils/event/Event.types";
import { ReactComponent as CheckIcon } from "../assets/icons/simple-check.svg";
import { ReactComponent as ArrowIcon } from "../assets/icons/arrow.svg";
import {
  checkIfPreviousStepsAreCompleted,
  checkIfStepIsComplete,
  generateCorrectNotificationForStep,
  generateCorrectStep3ProofString,
  generateCorrectStep4ProofString,
  generateStep5ProofString,
  getBase64ForFile,
  getEventBySlugId,
} from "../utils/event/EventUtils";
import { CurrentPage } from "../utils/navigation/Navigation.types";
import { useNavLayout } from "../utils/navigation/NavigationUtils";
import { ConfigContext, UserContext } from "./App";
import "../styles/MailboxStyles.scss";
import "../styles/EventEditStyles.scss";
import EventEditBoxComponent from "../components/newEventEdit/EventEditBoxComponent";
import { Provider, UserRole } from "../utils/user/User.types";
import { getFile, getProviderBySlugId } from "../utils/user/UserUtils";
import { useWindowSize } from "../utils/hooks/useWIndowSize";
import {
  createEmptyActivityEditCompanion,
  ActivityEditCompanion,
} from "../components/newEventEdit/EventEditBoxComponent.types";
import { getCookieConsentValue } from "../utils/cookies/CookieUtils";
import { Action, createEmptyAction } from "../utils/action/Action.types";
import { getActionBySlugId } from "../utils/action/ActionUtils";
import {
  convertLocalActivityToCompanionObject,
  createOrUpdateActivity,
  generateCorrectTagReplacementText,
  saveCompanionInLocalStorage,
} from "../utils/activity/ActivityUtils";
import ProgressBarComponent from "../components/progressbar/ProgressBarComponent";

interface ActivityEditProps {
  isEdit: boolean;
}

const ActivityEditPage: React.FC<ActivityEditProps> = ({ isEdit }) => {
  const location = useLocation<{
    providerSlug: string;
    eventId?: string;
    actionId?: string;
  }>();
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const [providerSlugId, setProviderSlugId] = useState<string>(
    location?.state?.providerSlug ?? ""
  );
  const [eventId, setEventId] = useState<string>(
    location?.state?.eventId ?? ""
  );
  const [actionId, setActionId] = useState<string>(
    location?.state?.actionId ?? ""
  );
  const axios = useAxios();
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { appConfig } = useContext(ConfigContext);
  const [localEvent, setLocalEvent] = useState<Event>();
  const [localAction, setLocalAction] = useState<Action>();
  const [localActivityCompanion, setLocalActivityCompanion] =
    useState<ActivityEditCompanion>();
  const [localProvider, setLocalProvider] = useState<Provider>();
  const [activityEditBoxIndex, setActivityEditBoxIndex] = useState<number>(0);
  const [isActivityReady, setActivityReady] = useState<boolean>(false);
  const [loadPopup, toggleLoadPopup] = useState<boolean>(false);
  const [currentStepCompletion, setCurrentStepCompletion] =
    useState<boolean>(true);
  const [progressPopup, toggleProgressPopup] = useState<boolean>(false);
  const [progressComplete, toggleProgressComplete] = useState<boolean>(false);
  const [
    currentProgressNumber,
    setCurrentProgressNumber,
    currentProgressNumberRef,
  ] = useState<number>(0);
  const [isSameProvider, toggleSameProvider] = useState<boolean>(false);

  /**
   * useEffect helper to load correct event for given eventId if provided
   */
  useEffect(() => {
    if (!axios || !user || !location) return;
    toggleLoading(true);

    let localProviderSlugId: string = location.state?.providerSlug;
    let localEventId = location.state?.eventId ?? "";
    let localActionId = location.state?.actionId ?? "";

    setProviderSlugId(localProviderSlugId);
    setEventId(localEventId);
    setActionId(localActionId);

    switch (user.role) {
      case UserRole.PROVIDER:
        if (!(user as Provider).slugid) {
          generateNotification(
            NotificationTypes.WARNING,
            t("notification.title.warning.warning"),
            t("notification.content.warning.noSlugId")
          );
          history.push("/");
        }
        localProviderSlugId = (user as Provider).slugid;
        setLocalProvider(user as Provider);
        break;

      case UserRole.ADMIN:
        if (!localProviderSlugId) {
          generateNotification(
            NotificationTypes.WARNING,
            t("notification.title.warning.warning"),
            t("notification.content.warning.noProvider")
          );
          history.push("/");
        }
        getProviderBySlugId(axios, localProviderSlugId).then((loadedProvider) =>
          setLocalProvider(loadedProvider)
        );
        break;

      default:
        generateNotification(
          NotificationTypes.WARNING,
          t("notification.title.warning.warning"),
          t("notification.content.warning.noRoll")
        );
        history.push("/");
        break;
    }

    switch (true) {
      case !!localEventId:
        getEventBySlugId(localEventId!, axios).then((loadedEvent) => {
          setLocalEvent(loadedEvent ?? createEmptyEvent(localProviderSlugId));

          setProviderSlugId(loadedEvent.providerId);
          toggleLoadPopup(false);
        });
        break;

      case !!localActionId:
        getActionBySlugId(localActionId!, axios).then((loadedAction) => {
          setLocalAction(loadedAction ?? createEmptyAction(localProvider));
          setLocalEvent({
            ...createEmptyEvent(localProviderSlugId),
            name: loadedAction?.name || "",
            description: loadedAction?.description || "",
          });
          setProviderSlugId(loadedAction.providerId);
          toggleLoadPopup(false);
        });
        break;

      default:
        setLocalEvent(createEmptyEvent(localProviderSlugId));
        break;
    }

    toggleLoading(false);
    //eslint-disable-next-line
  }, [user, eventId, actionId, axios, location]);

  const isDesktopVisible: boolean = useWindowSize();

  const [validSelection, setValidSelection] = useState<SectionValidation>({
    nameDescriptionValid: false,
    tagSelectionValid: false,
    eventDateListValid: false,
    ticketsAndPriceValid: false,
    releaseValid: false,
  });

  const [clickedSteps, setClickedSteps] = useState<StepClickedDetector>({
    step1Clicked: false,
    step2Clicked: false,
    step3Clicked: false,
    step4Clicked: false,
    step5Clicked: false,
  });

  /**
   * Helper to determine if activityCompanion in local storage is from current provider
   */
  useEffect(() => {
    const localCompanion = getInitialactivityCompanion();
    toggleSameProvider(localCompanion.providerId === localProvider?.slugid);
    //eslint-disable-next-line
  }, [localProvider]);

  /**
   * useEffect to check if an activityCompanion is present in localStorage and trigger popup if so
   */
  useEffect(() => {
    if (getCookieConsentValue())
      if (
        !!localStorage.getItem(
          process.env.REACT_APP_ACTIVITY_LOCAL_STORAGE_OBJECT!
        ) &&
        !actionId &&
        !eventId &&
        isSameProvider
      )
        toggleLoadPopup(true);
  }, [location, actionId, eventId, isSameProvider]);

  /**
   * Helper to load EventEditCompanion form localstorage if existing
   * @returns EventEditCompanion if possible or new EventEditCompanion if none is saved
   */
  const getInitialactivityCompanion = (): ActivityEditCompanion => {
    const storedCompanion: string | null = localStorage.getItem(
      process.env.REACT_APP_ACTIVITY_LOCAL_STORAGE_OBJECT!
    );
    if (!!storedCompanion) return JSON.parse(storedCompanion);
    else
      return createEmptyActivityEditCompanion(
        localEvent || createEmptyEvent(providerSlugId),
        localAction || createEmptyAction(),
        providerSlugId
      );
  };

  /**
   * Helper to set when a step is valid, meaning it contains all necessary information
   */
  useEffect(() => {
    let localValidSelection: SectionValidation = {
      tagSelectionValid:
        checkIfStepIsComplete(1, localActivityCompanion) &&
        (clickedSteps.step1Clicked || isEdit),
      nameDescriptionValid:
        (checkIfStepIsComplete(2, localActivityCompanion) &&
          clickedSteps.step2Clicked) ||
        (isEdit && !clickedSteps.step2Clicked),
      eventDateListValid:
        checkIfStepIsComplete(3, localActivityCompanion) &&
        (clickedSteps.step3Clicked || isEdit),
      ticketsAndPriceValid:
        checkIfStepIsComplete(4, localActivityCompanion) &&
        (clickedSteps.step4Clicked || isEdit),
      releaseValid:
        checkIfStepIsComplete(5, localActivityCompanion) &&
        (clickedSteps.step5Clicked || isEdit),
    };
    setValidSelection(localValidSelection);
    setActivityReady(
      localValidSelection.eventDateListValid &&
        localValidSelection.nameDescriptionValid &&
        localValidSelection.releaseValid &&
        localValidSelection.tagSelectionValid &&
        localValidSelection.ticketsAndPriceValid
    );
    // eslint-disable-next-line
  }, [localActivityCompanion, clickedSteps]);

  /**
   * Helper to create new ActivityEditCompanion when localEvent or localAction is set
   */
  useEffect(() => {
    if (!localEvent && !localAction) return;
    setLocalActivityCompanion(
      convertLocalActivityToCompanionObject(
        providerSlugId,
        localEvent,
        localAction
      )
    );
  }, [localEvent, localAction, providerSlugId]);

  /**
   * Helper to load flyerString from server if flyer is present but flyerString is not
   */
  useEffect(() => {
    if (!localActivityCompanion?.flyer || !!localActivityCompanion.flyerString)
      return;
    getFile(
      localActivityCompanion.flyer.filename,
      localActivityCompanion.flyer.type,
      axios
    ).then((fetchedFile) => {
      getBase64ForFile(
        new File(
          [fetchedFile],
          localActivityCompanion.flyer?.originalFilename ?? ""
        ),
        (base64String) =>
          setLocalActivityCompanion({
            ...localActivityCompanion!,
            flyerString: base64String,
            flyer: {
              ...localActivityCompanion?.flyer!,
            },
          })
      );
    });
    //eslint-disable-next-line
  }, [localActivityCompanion?.flyer, axios]);

  /**
   * Method to determine whether a single step with given index is completed
   * @param index of the step to be checked
   * @returns boolean that indiates whether step is completed or not
   */
  const setClickedStepForIndex = (index: number) => {
    switch (index) {
      case 1:
        setClickedSteps({
          ...clickedSteps,
          step1Clicked: true,
        });
        break;
      case 2:
        setClickedSteps({
          ...clickedSteps,
          step2Clicked: true,
        });
        break;
      case 3:
        setClickedSteps({
          ...clickedSteps,
          step3Clicked: true,
        });
        break;
      case 4:
        setClickedSteps({
          ...clickedSteps,
          step4Clicked: true,
        });
        break;
      case 5:
        setClickedSteps({
          ...clickedSteps,
          step5Clicked: true,
        });
        break;
      default:
        return;
    }
  };

  /**
   * Helper to generate correct string for progress-bar comp
   * @returns string depending on progress
   */
  const generateCorrectEncouragmentText = (): string => {
    let maxValue = localActivityCompanion?.connectedDates
      ? 1
      : localActivityCompanion?.dateList.length || 1;
    switch (true) {
      case currentProgressNumber / maxValue === 1:
        return t("adminEventPage.progress.message3");
      case currentProgressNumber / maxValue > 0.5:
        return t("adminEventPage.progress.message2");
      case currentProgressNumber / maxValue < 0.5:
        return t("adminEventPage.progress.message1");
      default:
        return t("adminEventPage.progress.message4");
    }
  };

  /**
   * default create or update activity helper
   */
  const createOrUpdateActivityOnServer = (): void => {
    toggleProgressPopup(true);
    setCurrentProgressNumber(0);
    createOrUpdateActivity(
      axios,
      localActivityCompanion!,
      toggleProgressComplete,
      () => setCurrentProgressNumber(currentProgressNumberRef.current + 1)
    );
  };

  /**
   * Helper to handle clicking on a new step in selection or using buttons to switch step
   * @param index index of step to be selected
   */
  const handleStepClick = (index: number): void => {
    const isCurrentStepFinished: boolean = checkIfStepIsComplete(
      activityEditBoxIndex,
      localActivityCompanion
    );

    switch (true) {
      // finsihing and saving or update step
      case index === 6:
        createOrUpdateActivityOnServer();
        break;

      // checing if steps are completed and
      case isCurrentStepFinished || activityEditBoxIndex < 1:
        if (!checkIfPreviousStepsAreCompleted(index, localActivityCompanion)) {
          generateNotification(
            NotificationTypes.WARNING,
            t("adminEventPage.event.noDataFoundTitle"),
            t("adminEventPage.event.noDataFoundText")
          );
          return;
        }
        if (localActivityCompanion)
          saveCompanionInLocalStorage(localActivityCompanion);
        setClickedStepForIndex(index);
        setActivityEditBoxIndex(index);
        setCurrentStepCompletion(true);
        break;

      // just switching steps
      case index < activityEditBoxIndex:
        setActivityEditBoxIndex(index);
        break;

      // in case we are at the current index just do nothing
      case index === activityEditBoxIndex:
        break;

      // in defautl case generate notification for the user
      default:
        setCurrentStepCompletion(false);
        generateCorrectNotificationForStep(
          activityEditBoxIndex,
          localActivityCompanion!
        );
        break;
    }
  };

  return (
    <AdminLayoutComponent
      {...useNavLayout(
        user?.role === UserRole.PROVIDER
          ? CurrentPage.PROVIDER_PROFILE_PAGE
          : CurrentPage.ADMIN_PROFILE_PAGE
      )}
    >
      <div className="progress-popup">
        <PopupComponent
          title={t("adminEventPage.progress.title")}
          open={progressPopup}
          toggleOpen={() => {}}
          bottomButtons={[
            {
              value: t("adminEventPage.progress.backToOverview"),
              disabled: !progressComplete,
              onClick: () => {
                toggleProgressPopup(false);
                history.goBack();
              },
            },
          ]}
        >
          <ProgressBarComponent
            backgroundColor={appConfig?.highlightColor}
            current={currentProgressNumber}
            total={
              (!localActivityCompanion?.connectedDates
                ? localActivityCompanion?.dateList.length
                : 1) || 1
            }
          />
          <div className="progress-text">
            <p>
              {t("adminEventPage.progress.progress")}
              {`${currentProgressNumber}/${
                (!localActivityCompanion?.connectedDates
                  ? localActivityCompanion?.dateList.length
                  : 1) || 1
              }`}
              {t("adminEventPage.progress.activities")}
            </p>
            <p>{generateCorrectEncouragmentText()}</p>
          </div>
        </PopupComponent>
      </div>
      <div className="activity-edit-load-popup">
        <PopupComponent
          title={t("adminEventPage.event.eventDataFound")}
          open={loadPopup}
          toggleOpen={() => toggleLoadPopup(false)}
          bottomButtons={[
            {
              value: t("adminEventPage.event.reloadEvent"),
              onClick: () => {
                setLocalActivityCompanion(getInitialactivityCompanion());
                toggleLoadPopup(false);
              },
            },
            {
              value: t("adminEventPage.event.createNewEvents"),
              onClick: () => {
                toggleLoadPopup(false);
              },
            },
          ]}
        >
          <p>{t("adminEventPage.event.reloadText")}</p>
        </PopupComponent>
      </div>
      {isLoading && <LoaderComponent isFullscreen />}
      <div className="new-event-edit-wrapper" key={localEvent?.slugId}>
        <div
          className="desktop-header"
          style={{ backgroundColor: appConfig?.highlightColor }}
        >
          <h1>
            {location.state?.eventId
              ? t("adminEventPage.event.editEvent")
              : t("adminEventPage.event.createEvent")}
          </h1>
        </div>
        <div className="new-event-edit-wrapper--top">
          <div className="mobile-wrapper" id="mobile-wrapper">
            {isDesktopVisible || (
              <div className="header">
                <h2 className="header--title">
                  {t("adminEventPage.event.editHeader")}
                </h2>
                <p className="header--text">
                  {t("adminEventPage.event.editText")}
                </p>
              </div>
            )}
            <div
              className="content"
              style={{ background: appConfig?.highlightColor }}
            >
              <div
                className="event-edit-box"
                onClick={() => handleStepClick(1)}
              >
                <div
                  className={[
                    "event-edit-box--counter",
                    validSelection.tagSelectionValid
                      ? "everything-good"
                      : undefined,
                    activityEditBoxIndex === 1 && true
                      ? "selected-event-box"
                      : undefined,
                  ].join(" ")}
                >
                  {validSelection.tagSelectionValid ? <CheckIcon /> : 1}
                </div>
                <div
                  className={[
                    "event-edit-box--content",
                    validSelection.tagSelectionValid
                      ? "everything-good"
                      : undefined,
                  ].join(" ")}
                >
                  <div className="unselected-content">
                    <h2>{t("adminEventPage.event.steps.step2Title")}</h2>
                    {validSelection.tagSelectionValid &&
                    activityEditBoxIndex !== 1 ? (
                      generateCorrectTagReplacementText(
                        localActivityCompanion as ActivityEditCompanion
                      )
                    ) : (
                      <div className="unselected-replacement">
                        <p>{t("adminEventPage.event.steps.step2Text")}</p>
                      </div>
                    )}
                  </div>
                  {activityEditBoxIndex === 1 && !isDesktopVisible && (
                    <EventEditBoxComponent
                      index={1}
                      activityCompanion={localActivityCompanion!}
                      setActivityCompanion={setLocalActivityCompanion}
                      provider={localProvider!}
                      isLoading={isLoading}
                      stepCompletion={currentStepCompletion}
                      isEdit={isEdit}
                    />
                  )}
                </div>
              </div>
              <div
                className="event-edit-box"
                onClick={() => handleStepClick(2)}
              >
                <div
                  className={[
                    "event-edit-box--counter",
                    validSelection.nameDescriptionValid
                      ? "everything-good"
                      : undefined,
                    activityEditBoxIndex === 2 && true
                      ? "selected-event-box"
                      : undefined,
                  ].join(" ")}
                >
                  {validSelection.nameDescriptionValid ? <CheckIcon /> : 2}
                </div>
                <div
                  className={[
                    "event-edit-box--content",
                    validSelection.nameDescriptionValid
                      ? "everything-good"
                      : undefined,
                  ].join(" ")}
                >
                  <div className="unselected-content">
                    <h2>{t("adminEventPage.event.steps.step1Title")}</h2>
                    {validSelection.nameDescriptionValid &&
                    activityEditBoxIndex !== 2 ? (
                      <p>
                        {localActivityCompanion?.event?.name.substring(
                          0,
                          Math.min(
                            localActivityCompanion?.event?.name.length,
                            30
                          )
                        )}
                        <br />
                        {localActivityCompanion?.event?.location.street}
                        <br />
                        {localActivityCompanion?.event?.location.zipCode}{" "}
                        {localActivityCompanion?.event?.location.city}
                      </p>
                    ) : (
                      <div className="unselected-replacement">
                        <div className="unselected-replacement-multiwrapper">
                          <p>
                            <ArrowIcon />
                            {t("adminEventPage.event.steps.step1Text1")}
                          </p>
                          <p>
                            <ArrowIcon />
                            {t("adminEventPage.event.steps.step1Text2")}
                          </p>
                          <p>
                            <ArrowIcon />
                            {t("adminEventPage.event.steps.step1Text3")}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  {activityEditBoxIndex === 2 && !isDesktopVisible && (
                    <EventEditBoxComponent
                      index={2}
                      activityCompanion={localActivityCompanion!}
                      setActivityCompanion={setLocalActivityCompanion}
                      provider={localProvider!}
                      isLoading={isLoading}
                      stepCompletion={currentStepCompletion}
                      isEdit={isEdit}
                    />
                  )}
                </div>
              </div>
              <div
                className="event-edit-box"
                onClick={() => handleStepClick(3)}
              >
                <div
                  className={[
                    "event-edit-box--counter",
                    validSelection.eventDateListValid
                      ? "everything-good"
                      : undefined,
                    activityEditBoxIndex === 3 && true
                      ? "selected-event-box"
                      : undefined,
                  ].join(" ")}
                >
                  {validSelection.eventDateListValid ? <CheckIcon /> : 3}
                </div>
                <div
                  className={[
                    "event-edit-box--content",
                    validSelection.eventDateListValid
                      ? "everything-good"
                      : undefined,
                  ].join(" ")}
                >
                  <div className="unselected-content">
                    <h2>{t("adminEventPage.event.steps.step3Title")}</h2>
                    {validSelection.eventDateListValid &&
                    activityEditBoxIndex !== 3 ? (
                      generateCorrectStep3ProofString(localActivityCompanion)
                    ) : (
                      <div className="unselected-replacement">
                        <p>{t("adminEventPage.event.steps.step3Text")}</p>
                      </div>
                    )}
                  </div>
                  {activityEditBoxIndex === 3 && !isDesktopVisible && (
                    <EventEditBoxComponent
                      index={3}
                      activityCompanion={localActivityCompanion!}
                      setActivityCompanion={setLocalActivityCompanion}
                      provider={localProvider!}
                      isLoading={isLoading}
                      stepCompletion={currentStepCompletion}
                    />
                  )}
                </div>
              </div>
              <div
                className="event-edit-box"
                onClick={() => handleStepClick(4)}
              >
                <div
                  className={[
                    "event-edit-box--counter",
                    validSelection.ticketsAndPriceValid
                      ? "everything-good"
                      : undefined,
                    activityEditBoxIndex === 4 && true
                      ? "selected-event-box"
                      : undefined,
                  ].join(" ")}
                >
                  {validSelection.ticketsAndPriceValid ? <CheckIcon /> : 4}
                </div>
                <div
                  className={[
                    "event-edit-box--content",
                    validSelection.ticketsAndPriceValid
                      ? "everything-good"
                      : undefined,
                  ].join(" ")}
                >
                  <div className="unselected-content">
                    <h2>{t("adminEventPage.event.steps.step4Title")}</h2>
                    {validSelection.ticketsAndPriceValid &&
                    activityEditBoxIndex !== 4 ? (
                      <>
                        {generateCorrectStep4ProofString(
                          localActivityCompanion!
                        )}
                      </>
                    ) : (
                      <div className="unselected-replacement">
                        <div className="unselected-replacement-multiwrapper">
                          <p>
                            <ArrowIcon />
                            {t("adminEventPage.event.steps.step4Text1")}
                          </p>
                          <p>
                            <ArrowIcon />
                            {t("adminEventPage.event.steps.step4Text2")}
                          </p>
                          <p>
                            <ArrowIcon />
                            {t("adminEventPage.event.steps.step4Text3")}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  {activityEditBoxIndex === 4 && !isDesktopVisible && (
                    <EventEditBoxComponent
                      index={4}
                      activityCompanion={localActivityCompanion!}
                      setActivityCompanion={setLocalActivityCompanion}
                      provider={localProvider!}
                      isLoading={isLoading}
                      stepCompletion={currentStepCompletion}
                    />
                  )}
                </div>
              </div>
              <div
                className="event-edit-box"
                onClick={() => handleStepClick(5)}
              >
                <div
                  className={[
                    "event-edit-box--counter",
                    validSelection.releaseValid ? "everything-good" : undefined,
                    activityEditBoxIndex === 5 && true
                      ? "selected-event-box"
                      : undefined,
                  ].join(" ")}
                >
                  {validSelection.releaseValid ? <CheckIcon /> : 5}
                </div>
                <div
                  className={[
                    "event-edit-box--content",
                    validSelection.releaseValid ? "everything-good" : undefined,
                  ].join(" ")}
                >
                  <div className="unselected-content">
                    <h2>{t("adminEventPage.event.steps.step5Title")}</h2>
                    {validSelection.releaseValid &&
                    activityEditBoxIndex !== 5 ? (
                      generateStep5ProofString(localActivityCompanion)
                    ) : (
                      <div className="unselected-replacement">
                        <p>{t("adminEventPage.event.steps.step5Text")}</p>
                      </div>
                    )}
                  </div>
                  {activityEditBoxIndex === 5 && !isDesktopVisible && (
                    <EventEditBoxComponent
                      index={5}
                      activityCompanion={localActivityCompanion!}
                      setActivityCompanion={setLocalActivityCompanion}
                      provider={localProvider!}
                      isLoading={isLoading}
                      stepCompletion={currentStepCompletion}
                    />
                  )}
                </div>
              </div>
              <ButtonComponent
                value={t("adminEventPage.event.saveEvent")}
                isLoading={isLoading}
                disabled={!isActivityReady}
                type="button"
                onClick={createOrUpdateActivityOnServer}
                colors={{ default: { bgColor: "black", fontColor: "white" } }}
              />
            </div>
          </div>

          <div className="desktop-wrapper" id="desktop-wrapper">
            <div className="content-wrapper-aligner">
              <div className="desktop-wrapper--content">
                <>
                  {activityEditBoxIndex === 0 && (
                    <div className="header">
                      <h2 className="header--title">
                        {t("adminEventPage.event.editHeader")}
                      </h2>
                      <p className="header--text">
                        {t("adminEventPage.event.editText")}
                      </p>
                    </div>
                  )}
                  {localProvider && localEvent && (
                    <EventEditBoxComponent
                      provider={localProvider}
                      index={activityEditBoxIndex}
                      activityCompanion={localActivityCompanion!}
                      setActivityCompanion={setLocalActivityCompanion}
                      isDesktop
                      isLoading={isLoading}
                      stepCompletion={currentStepCompletion}
                      isEdit={isEdit}
                    />
                  )}
                </>
              </div>
            </div>
            <div className="event-step-buttons">
              <ButtonComponent
                value={t("adminEventPage.event.previousStep")}
                disabled={activityEditBoxIndex <= 1}
                onClick={() => handleStepClick(activityEditBoxIndex - 1)}
              />
              <ButtonComponent
                disabled={activityEditBoxIndex === 5 && !isActivityReady}
                value={
                  activityEditBoxIndex === 5
                    ? t("adminEventPage.event.saveEvent")
                    : t("adminEventPage.event.nextStep")
                }
                onClick={() => handleStepClick(activityEditBoxIndex + 1)}
              />
            </div>
          </div>
        </div>
      </div>
    </AdminLayoutComponent>
  );
};

export default ActivityEditPage;
