import { AxiosInstance } from "axios";
import dayjs from "dayjs";
import {
  CheckboxComponent,
  NotificationTypes,
  TableRow,
  generateNotification,
} from "deinestadtliebt-component-library";
import { Dispatch } from "react";
import { v4 as uuidv4 } from "uuid";
import { ReactComponent as CopyIcon } from "../../assets/icons/copy.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import i18n from "../../i18n";
import { createTimeRangeStringForLunch } from "../time/TimeUtils";
import {
  Admin,
  Allergens,
  Category,
  DayOfWeek,
  FoodTags,
  Lunch,
  MenuItem,
  Provider,
  UserRole,
} from "../user/User.types";
import { updateProviderLunchData } from "../user/UserUtils";

/**
 * Util method to convert lunch into TableRow
 * @param providers list of providers to display the lunches
 * @param toggleLoading setter for loading state
 * @param showOldLunch boolean if old lunches should be shown
 * @param user to check the role for navigation
 * @param handleDeleteLunch method to delete lunchitem
 * @param navigate navigate function with lunch and provider in state
 * @returns Array of TableRows
 */
export const convertLunchIntoTableRows = (
  providers: Provider[],
  toggleLoading: (value: React.SetStateAction<boolean>) => void,
  showOldLunch: boolean,
  user: Provider | Admin,
  handleDeleteLunch: Dispatch<
    React.SetStateAction<[Provider, Lunch] | undefined>
  >,
  navigate?: (path: string, lunch: Lunch, provider: Provider) => void
): TableRow[] => {
  toggleLoading(false);
  let localProviderList = providers.filter(
    (provider) => provider.category === Category.EAT_DRINK
  );
  let returnValues: TableRow[] = [];
  localProviderList.forEach((prov) => {
    prov.lunchItems
      .filter(
        (lunch) => !(!showOldLunch && new Date(lunch.endDate) <= new Date())
      )
      .forEach((lunch) => {
        returnValues.push({
          id: lunch.id,
          content: [
            prov.name,
            createTimeRangeStringForLunch(lunch),
            `${lunch.menuItems.length} ${i18n.t("eventUtil.menus")}`,
            <div className="flex-it-wrap">
              {lunch.days.map((day, dayIndex) => (
                <div
                  className="gap-distance"
                  key={`table-lunch-entry-menu-${lunch.id}-${dayIndex}`}
                >
                  {i18n.t(`enum.dayOfWeek.${day}`)}
                </div>
              ))}
            </div>,
            <div className="flex-it-center">
              <div
                className="trash-wrapper little-bit-margin-right"
                onClick={() => {
                  if (!navigate) return;
                  navigate(
                    user?.role === UserRole.PROVIDER
                      ? "/config/lunch/edit"
                      : "/admin/lunch/edit",
                    lunch,
                    prov
                  );
                }}
              >
                <EditIcon />
              </div>
              <div
                className="trash-wrapper little-bit-margin-right"
                onClick={() => {
                  if (!navigate) return;
                  navigate(
                    user?.role === UserRole.PROVIDER
                      ? "/config/lunch/create"
                      : "/admin/lunch/create",
                    { ...lunch, id: uuidv4() },
                    prov
                  );
                }}
              >
                <CopyIcon />
              </div>
              <div
                className="trash-wrapper"
                onClick={() => handleDeleteLunch([prov, lunch])}
              >
                <TrashIcon />
              </div>
            </div>,
          ],
        });
      });
  });
  return returnValues;
};

/**
 * Util method to convert Lunches into tableEntries for the archive view
 * @param provider
 * @param setLunch
 * @returns
 */
export const convertLunchIntoTableEntries = (
  provider: Provider,
  setLunch: React.Dispatch<React.SetStateAction<Lunch>>,
  days: DayOfWeek[]
): TableRow[] => {
  return provider.lunchItems
    .sort(
      (a, b) =>
        new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
    )
    .map((lunch: Lunch, index: number) => ({
      id: lunch.id,
      content: [
        <div
          className="lunch-edit-table--date"
          key={`lunch-${lunch.startDate}-${index}`}
        >
          <p>{createTimeRangeStringForLunch(lunch)}</p>
        </div>,
        <div className="lunch-edit-table--items">
          {lunch.menuItems.slice(0, 3).map((item, index) => (
            <p className={`lunch-edit-table--items--${index}`}>{item.name}</p>
          ))}
        </div>,
        <div
          className="lunch-edit-table--copy-wrapper"
          onClick={() =>
            setLunch((prev) => ({
              ...prev,
              menuItems: lunch.menuItems.map((item) => ({
                ...item,
                days: item.days?.filter((it) => days.includes(it)),
              })),
            }))
          }
        >
          <p>{i18n.t("eventUtil.copyLunch")}</p>
        </div>,
      ],
    }));
};

/**
 * Util method to convert menuItems into tablerows
 * @param meals MenuItems to convert
 * @returns Array of tableRows
 */
export const convertMealsIntoTableEntries = (
  lunch: Lunch,
  setLunch: React.Dispatch<React.SetStateAction<Lunch>>,
  setMealToDelete: React.Dispatch<React.SetStateAction<string | undefined>>,
  handleEdit: (meal: MenuItem) => void,
  toogleDayInArray: (meal: MenuItem, day: DayOfWeek) => void,
  setSelectedAllergenTags: React.Dispatch<React.SetStateAction<Allergens[]>>,
  setSelectedCategoryTags: React.Dispatch<React.SetStateAction<FoodTags[]>>
): TableRow[] => {
  return lunch.menuItems.map((meal) => ({
    id: meal.id,
    content: [
      meal.name,
      <div className={"lunch-edit-step-2--tag--day-tag--wrapper"}>
        {sortArrayOfDays(lunch.days).map((day) => (
          <div className="lunch-edit-step-2--day-select-wrapper--day">
            <p className="lunch-edit-step-2--day-select-wrapper--day--label">
              {i18n.t(`enum.dayOfWeekShort.${day}`)}
            </p>
            <CheckboxComponent
              checked={meal.days?.includes(day) || false}
              onCheck={() => toogleDayInArray(meal, day)}
              value=""
            />
          </div>
        ))}
      </div>,
      meal.price.toString() + " €",
      <div className="lunch-edit-step-2--meal-icons">
        <TrashIcon
          className="lunch-edit-step-2--meal-icons--trash"
          width={20}
          onClick={() => setMealToDelete(meal.id)}
        />
        <EditIcon width={20} onClick={() => handleEdit(meal)} />
        <CopyIcon
          width={20}
          onClick={() => {
            setLunch({
              ...lunch,
              menuItems: [...lunch.menuItems, { ...meal, id: uuidv4() }],
            });
            setSelectedAllergenTags(meal.allergens);
            setSelectedCategoryTags(meal.tags);
          }}
        />
      </div>,
    ],
  }));
};

/**
 * Util method to check if all necessary data is attached to a lunch
 * @param lunch to check
 * @returns  boolean if all data is available
 */
export const checkIfLunchIsComplete = (lunch: Lunch): boolean => {
  return (
    lunch.days?.length > 0 &&
    !dayjs(lunch.startDate).isSame(lunch.endDate) &&
    lunch.endTime !== "" &&
    lunch.startTime !== "" &&
    lunch.menuItems.length > 0
  );
};

/**
 * Helper to update a lunch for a given provider.
 *
 * @param type the kind of action to perform (create, update, delete or copy)
 * @param lunch the lunch used for this action
 * @param provider the provider to whom this lunch is assigned
 */
export const updateLunchItems = async (
  axios: AxiosInstance,
  type: "create" | "delete" | "update" | "copy",
  lunch: Lunch,
  provider: Provider,
  setProvider: (provider: Provider) => void,
  userRole: UserRole,
  navigate: (path: string) => void
): Promise<void> => {
  let localLunchItems: Lunch[] = [...provider.lunchItems];
  switch (type) {
    case "copy":
      let localLunch: Lunch = { ...lunch! };
      localLunch.id = uuidv4();
      localLunchItems.push(localLunch);
      break;
    case "create":
      const updatedStartDate: Date = new Date(
        lunch.startDate.getFullYear(),
        lunch.startDate.getMonth(),
        lunch.startDate.getDate() + 1
      );
      const updatedEndDate: Date = new Date(
        lunch.endDate.getFullYear(),
        lunch.endDate.getMonth(),
        lunch.endDate.getDate() + 1
      );
      localLunchItems.push({
        ...lunch!,
        startDate: updatedStartDate,
        endDate: updatedEndDate,
      });
      break;
    case "delete":
      localLunchItems = localLunchItems.filter((item) => item.id !== lunch.id);
      break;
    case "update":
      let foundIndex: number = localLunchItems.findIndex(
        (item) => item.id === lunch.id
      );
      if (foundIndex === -1) return;
      localLunchItems[foundIndex] = lunch!;
      break;
  }

  updateProviderLunchData(axios, {
    ...provider,
    lunchItems: localLunchItems,
  }).then((success) => {
    if (success) {
      if (userRole === UserRole.PROVIDER)
        setProvider({ ...provider, lunchItems: localLunchItems });
      generateNotification(
        NotificationTypes.SUCCESS,
        i18n.t(`notification.title.success.creationSuccessful`),
        i18n.t(`notification.content.success.providerUpdate`)
      );
      navigate(`/${userRole === UserRole.ADMIN ? "admin" : "config"}/lunch`);
    } else
      generateNotification(
        NotificationTypes.ERROR,
        i18n.t(`notification.title.error.changesFailed`),
        i18n.t(`notification.content.error.providerUpdate`)
      );
  });
};

/**
 *  Util method to sort an array of DayOfWeeks
 * @param days array to sort
 * @returns  sorted Array
 */
export const sortArrayOfDays = (days: DayOfWeek[]): DayOfWeek[] => {
  return days.sort(
    (dayOne, dayTwo) =>
      Object.keys(DayOfWeek).indexOf(dayOne) -
      Object.keys(DayOfWeek).indexOf(dayTwo)
  );
};
