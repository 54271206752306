import EventEmitter from "events";
import { ConsentStatus } from "./Cookie.types";

/**
 * creating a event emitter for the app to listen on consent changes
 */
export const CookieEvents = new EventEmitter();

/**
 * helper to retrieve the consent that the user gave for cookies
 * @returns a Consent Status
 */
export const getCookieConsentValue = (): ConsentStatus => {
  if (typeof window === "undefined") return ConsentStatus.PENDING;

  return ConsentStatus[
    (localStorage.getItem("cookieConsent") ||
      "PENDING") as keyof typeof ConsentStatus
  ];
};

/**
 * helper to set cookie consent status
 * @param value new {@link ConsentStatus}
 */
export const setCookieConsentValue = (value: ConsentStatus): void => {
  localStorage.setItem("cookieConsent", value);
  CookieEvents.emit("change", value);
};
