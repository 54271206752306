import {
  ButtonComponent,
  CheckboxComponent,
  DropdownComponent,
  DropdownOption,
  InfoComponent,
  InputComponent,
  TagComponent,
} from "deinestadtliebt-component-library";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { CalendarWeeks } from "../../utils/time/Time.types";
import {
  generatedCorrectUTCDate,
  getCalendarWeeks,
} from "../../utils/time/TimeUtils";
import { DayOfWeek, Lunch } from "../../utils/user/User.types";

interface LunchEditStep1Props {
  lunch: Lunch;
  setLunch: React.Dispatch<React.SetStateAction<Lunch>>;
  navigateToStep2: () => void;
}

export const LunchEditStep1: React.FC<LunchEditStep1Props> = ({
  lunch,
  setLunch,
  navigateToStep2,
}) => {
  const currentCalendarWeeks: CalendarWeeks[] = getCalendarWeeks();
  const nextYearCalendarWeeks: CalendarWeeks[] = getCalendarWeeks(1);

  const { t } = useTranslation();
  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear()
  );
  const history = useHistory();

  // useMemo to store the dropdownOptions for weeks
  const dropdownCalndarWeeks: DropdownOption[] =
    useMemo((): DropdownOption[] => {
      if (selectedYear === new Date().getFullYear())
        return currentCalendarWeeks.map((week) => ({
          label: week.name,
          value: week.name,
        }));
      else
        return nextYearCalendarWeeks.map((week) => ({
          label: week.name,
          value: week.name,
        }));
    }, [currentCalendarWeeks, nextYearCalendarWeeks, selectedYear]);

  //useMemo to store dropdownOptions for this year and next year
  const getDropdownOptionsYear = (): DropdownOption[] => {
    const actualYear: number = new Date().getFullYear();
    const nextYear: Date = new Date();
    nextYear.setFullYear(new Date().getFullYear() + 1);
    return [
      { label: actualYear.toString(), value: actualYear.toString() },
      {
        label: nextYear.getFullYear().toString(),
        value: nextYear.getFullYear().toString(),
      },
    ];
  };

  /**
   * Util method to handle selecting a new option at the dropdown for calendarWeeks
   * @param calendarWeek  selected value of the dropdown
   * @returns  void
   */
  const handleCalendarWeekChange = (
    calendarWeek: string,
    year?: number
  ): void => {
    let foundCalendarWeek: CalendarWeeks | undefined;
    if (
      (!year && selectedYear === new Date().getFullYear()) ||
      (year && year === new Date().getFullYear())
    )
      foundCalendarWeek = currentCalendarWeeks.find(
        (week) => week.name === calendarWeek
      );
    else
      foundCalendarWeek = nextYearCalendarWeeks.find(
        (week) => week.name === calendarWeek
      );

    if (!foundCalendarWeek) return;
    setLunch({
      ...lunch,
      startDate: generatedCorrectUTCDate(foundCalendarWeek.startDate),
      endDate: generatedCorrectUTCDate(foundCalendarWeek.endDate),
    });
  };

  /**
   * Util method to get the selected week for lunch
   * @returns String of the calendar week
   */
  const getSelectedWeek = (): string => {
    if (selectedYear === new Date().getFullYear()) {
      return (
        currentCalendarWeeks.find((week) => {
          return (
            week.startDate.toDateString() ===
            new Date(lunch.startDate).toDateString()
          );
        })?.name || ""
      );
    } else
      return (
        nextYearCalendarWeeks.find((week) => {
          return (
            week.startDate.toDateString() ===
            new Date(lunch.startDate).toDateString()
          );
        })?.name || ""
      );
  };

  /**
   * Util method to update the selected year and set the correct week in the changed year
   * @param year to chenge to
   */
  const handleYearChange = (year: string): void => {
    const selectedWeek: string = getSelectedWeek();
    setSelectedYear(Number(year));
    handleCalendarWeekChange(selectedWeek, Number(year));
  };

  return (
    <div className="lunch-edit-step-1--wrapper">
      <div className="lunch-edit-step-1--week">
        <div className="lunch-edit-step-1--headline--wrapper">
          <p className="lunch-edit-step-1--headline--text">
            {t("lunchEdit.step.1.weekHeadline")}
          </p>
          <InfoComponent
            aligendLeft
            infoText={t("lunchEdit.step.1.weekText")}
          />
        </div>
        <div className="lunch-edit-step-1--dropdown-wrapper">
          <DropdownComponent
            hideClearIcon
            classNameLabel="lunch-edit-step-1--dropdown-label"
            onChange={handleCalendarWeekChange}
            selectedOption={getSelectedWeek()}
            upperLabel={t("lunchEdit.step.1.weekLabel")}
            dropdownOptions={dropdownCalndarWeeks}
          />
          <DropdownComponent
            hideClearIcon
            classNameLabel="lunch-edit-step-1--dropdown-label"
            onChange={(year) => handleYearChange(year)}
            selectedOption={selectedYear.toString()}
            upperLabel={t("lunchEdit.step.1.yearLabel")}
            dropdownOptions={getDropdownOptionsYear()}
          />
        </div>
      </div>
      <div className="lunch-edit-step-1--days">
        <div className="lunch-edit-step-1--headline--wrapper">
          <p className="lunch-edit-step-1--headline--text">
            {t("lunchEdit.step.1.daysHeadline")}
          </p>
          <InfoComponent
            aligendLeft
            infoText={t("lunchEdit.step.1.daysText")}
          />
        </div>

        <TagComponent
          selected={lunch.days}
          tags={Object.values(DayOfWeek).map((day) => ({
            title: t(`enum.dayOfWeek.${day}`),
            value: day,
          }))}
          onChange={(days) => setLunch({ ...lunch, days: days as DayOfWeek[] })}
        />
      </div>
      <div className="lunch-edit-step-1--time">
        <div className="lunch-edit-step-1--headline--wrapper">
          <p className="lunch-edit-step-1--headline--text">
            {t("lunchEdit.step.1.timeHeadline")}
          </p>
          <InfoComponent
            aligendLeft
            infoText={t("lunchEdit.step.1.timeText")}
          />
        </div>
        <div className="lunch-edit-step-1--time-input-wrapper">
          <div className="lunch-edit-step-1--time-input-wrapper--input">
            <p> {t("lunchEdit.step.1.start")}</p>
            <InputComponent
              type="time"
              value={lunch.startTime || "00:00"}
              onChange={(startTime) => setLunch({ ...lunch, startTime })}
              maxHeight={40}
            />
          </div>
          <div className="lunch-edit-step-1--time-input-wrapper--input">
            <p>{t("lunchEdit.step.1.until")}</p>
            <InputComponent
              type="time"
              value={lunch.endTime || "00:00"}
              onChange={(endTime) => setLunch({ ...lunch, endTime })}
              maxHeight={40}
            />
          </div>
          <p className="hide-on-small"> {t("lunchEdit.step.1.clock")}</p>
        </div>
      </div>
      <div className="lunch-edit-step-1--delivery">
        <div className="lunch-edit-step-1--headline--wrapper">
          <p className="lunch-edit-step-1--headline--text">
            {t("lunchEdit.step.1.deliveryHeadline")}
          </p>
          <InfoComponent
            aligendLeft
            infoText={t("lunchEdit.step.1.deliveryText")}
          />
        </div>

        <div className="lunch-edit-step-1--delivery-input-wrapper">
          <CheckboxComponent
            checked={lunch.delivery}
            onCheck={(delivery) => setLunch({ ...lunch, delivery })}
            value={t("lunchEdit.step.1.deliveryCheckbox")}
          />
          <CheckboxComponent
            checked={lunch.pickUp}
            onCheck={(pickUp) => setLunch({ ...lunch, pickUp })}
            value={t("lunchEdit.step.1.pickUpCheckbox")}
          />
        </div>
      </div>
      <div className="lunch-edit-page--content--content-wrapper--nav-button-wrapper">
        <ButtonComponent
          value={t("lunchEdit.backToStart")}
          onClick={() => history.push("/")}
        />
        <ButtonComponent
          value={t("lunchEdit.nextStep")}
          onClick={navigateToStep2}
        />
      </div>
    </div>
  );
};
