import {
  ButtonComponent,
  CheckboxComponent,
  InputComponent,
} from "deinestadtliebt-component-library";
import React, { useEffect, useState } from "react";
import { NewOpeningTimeBoxProps } from "./NewOpeningTimeBox.types";
import { ReactComponent as AddIcon } from "../../assets/icons/add.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import {
  convertToDatabaseModel,
  convertToLocalModel,
  createEmptyTimeFrameCollection,
} from "../../utils/openinghours/OpeningHoursUtils";
import {
  EmptyContact,
  LocalModel,
  TimeFrameCollection,
  WeekDay,
  WeekDays,
} from "../../utils/openinghours/OpeningHours.types";

const NewOpeningTimeBox: React.FC<NewOpeningTimeBoxProps> = ({
  timeCollection,
  setTimeCollection,
  standard,
  register,
  onValidChanged,
  showSpecialOpeningButton,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [time, setTime] = useState<{
    time: TimeFrameCollection;
    new: boolean;
  }>();

  /**
   * This effect setup the time collection. Create a empty if undefined.
   */
  useEffect(() => {
    if (!time) {
      if (timeCollection) {
        setTime({ time: timeCollection, new: false });
      } else {
        setTime({ time: createEmptyTimeFrameCollection(), new: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  return (
    <>
      {time && (
        <TimeBox
          time={time.time}
          setTime={setTimeCollection}
          standard={standard || false}
          register={register || false}
          onValidChanged={onValidChanged}
          isNew={time.new}
        />
      )}
      {showSpecialOpeningButton && (
        <ButtonComponent
          value={t("dashboard.provider.config.gotoSpecialOpeningTimes")}
          onClick={() => {
            history.push("/config#specialOpeningTimes");
          }}
        />
      )}
    </>
  );
};

const TimeBox: React.FC<{
  time: TimeFrameCollection;
  setTime?(time: TimeFrameCollection): void;
  standard: boolean;
  register: boolean;
  onValidChanged?(valid: boolean): void;
  isNew: boolean;
}> = ({ time, setTime, standard, register, onValidChanged, isNew }) => {
  const { t } = useTranslation();

  const [init, setInit] = useState(false);
  const [local, setLocal] = useState<LocalModel>();

  /**
   * This effect make sure that the time model is only loaded once.
   */
  useEffect(() => {
    if (!init) {
      setLocal(convertToLocalModel(time, isNew));
      setInit(true);
    }
  }, [init, time, isNew]);

  /**
   * This effect validate and push updates if local model changed.
   */
  useEffect(() => {
    if (local)
      try {
        const newTime = convertToDatabaseModel(local, standard);
        if (setTime) setTime(newTime);
        if (onValidChanged) onValidChanged(true);
      } catch (err) {
        if (onValidChanged) onValidChanged(false);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [local]);

  /**
   * Helper to update local model
   *
   * @param newLocal a partial localmode to update
   */
  const updateLocal = (newLocal: Partial<LocalModel>) => {
    if (local) setLocal({ ...local, ...newLocal });
  };

  /**
   * Helper to toggle selected day in local model
   * @param day to toggle
   */
  const toggleSelectedDay = (day: WeekDay): void => {
    if (local) {
      const tempLocal = { ...local };
      if (tempLocal.selectedDays.includes(day)) {
        tempLocal.selectedDays = tempLocal.selectedDays.filter(
          (item) => item !== day
        );
        if (tempLocal.weekDayBreaks.includes(day)) {
          tempLocal.weekDayBreaks = tempLocal.weekDayBreaks.filter(
            (item) => item !== day
          );
        }
      } else {
        tempLocal.selectedDays.push(day);
      }
      updateLocal(tempLocal);
    }
  };

  return (
    <>
      {local && (
        <>
          {!standard && (
            <div>
              <InputComponent
                value={local.name || ""}
                placeholder={t(
                  "providerConfigurationPage.inputs.specialOpeningTimesDescription"
                )}
                onChange={(name) => updateLocal({ name })}
              />
              <div className="flex-it">
                <InputComponent
                  type="date"
                  value={local.start || ""}
                  onChange={(start) =>
                    updateLocal({
                      start,
                    })
                  }
                  placeholder={t("providerConfigurationPage.inputs.start")}
                />
                <InputComponent
                  type="date"
                  value={local.stop || ""}
                  onChange={(stop) =>
                    updateLocal({
                      stop,
                    })
                  }
                  placeholder={t("providerConfigurationPage.inputs.end")}
                />
              </div>
            </div>
          )}
          <CheckboxComponent
            checked={local.type === "empty"}
            onCheck={() => {
              if (local.type === "empty") {
                updateLocal({ empty: false, type: "samedate" });
              } else {
                updateLocal({ empty: true, type: "empty" });
              }
            }}
            value={t("newSignup.openingTime.noOpeningtime")}
          />
          {local.type === "empty" && (
            <>
              <p>{t("newSignup.openingTime.messageToUser")}</p>
              {Object.values(EmptyContact).map((contactType, index) => (
                <div key={index}>
                  <CheckboxComponent
                    value={t(`newSignup.emptyContact.${contactType}`)}
                    onCheck={() => {
                      if (local.emptyContact.includes(contactType)) {
                        const index = local.emptyContact.indexOf(contactType);
                        const localEmptyContact = [...local.emptyContact];
                        if (index >= 0) {
                          localEmptyContact.splice(index, 1);
                          updateLocal({ emptyContact: localEmptyContact });
                        }
                      } else {
                        updateLocal({
                          emptyContact: [...local.emptyContact, contactType],
                        });
                      }
                    }}
                    checked={local.emptyContact.includes(contactType)}
                  />
                </div>
              ))}
              {register && (
                <p>{t("newSignup.openingTime.emptyTimeContactNotice")}</p>
              )}
            </>
          )}
          {(local.type === "samedate" || local.type === "normal") && (
            <CheckboxComponent
              checked={local.type === "samedate"}
              onCheck={() => {
                if (local.type === "samedate") {
                  updateLocal({ type: "normal" });
                } else {
                  updateLocal({ type: "samedate" });
                }
              }}
              value={t("newSignup.openingTime.sameDay")}
            />
          )}
          {local.type === "samedate" && (
            <>
              <div className="day-of-week-selector-wrapper">
                {WeekDays.map((day) => {
                  const dayStr = WeekDay[day];
                  return (
                    <div
                      className={[
                        "day-of-week-selector-wrapper--item",
                        local.selectedDays.includes(day) ? "active" : undefined,
                      ].join(" ")}
                      onClick={() => toggleSelectedDay(day)}
                      key={`day-of-week-selector-${day}`}
                    >
                      {t(`enum.dayOfWeekShort.${dayStr}`)}
                    </div>
                  );
                })}
              </div>

              <div className="time-wrapper">
                <div className="time-wrapper--item">
                  <InputComponent
                    value={local.sameDay[0].open}
                    onChange={(open) => {
                      const temp = [...local.sameDay];
                      temp[0] = { ...temp[0], open };
                      updateLocal({ sameDay: temp });
                    }}
                    type="time"
                    isFrameless
                  />
                  <div className="seperator">-</div>
                  <InputComponent
                    value={local.sameDay[0].close}
                    onChange={(close) => {
                      const temp = [...local.sameDay];
                      temp[0] = { ...temp[0], close };
                      updateLocal({ sameDay: temp });
                    }}
                    type="time"
                    isFrameless
                  />
                </div>
                <div
                  className={[
                    "time-wrapper--add",
                    local.sameDayBreaks ? "disabled" : undefined,
                  ].join(" ")}
                  onClick={() => {
                    if (!local.sameDayBreaks) {
                      const localSameDay = local.sameDay;
                      localSameDay[1] = { open: "", close: "" };
                      updateLocal({
                        sameDayBreaks: true,
                        sameDay: localSameDay,
                      });
                    }
                  }}
                  title={t("newSignup.addTooltip")}
                >
                  <AddIcon />
                </div>
              </div>

              {local.sameDayBreaks && (
                <div className="time-wrapper">
                  <div className="time-wrapper--item">
                    <InputComponent
                      value={local.sameDay[1].open}
                      onChange={(open) => {
                        const temp = [...local.sameDay];
                        temp[1] = { ...temp[1], open };
                        updateLocal({ sameDay: temp });
                      }}
                      type="time"
                      isFrameless
                    />
                    <div className="seperator">-</div>
                    <InputComponent
                      value={local.sameDay[1].close}
                      onChange={(close) => {
                        const temp = [...local.sameDay];
                        temp[1] = { ...temp[1], close };
                        updateLocal({ sameDay: temp });
                      }}
                      type="time"
                      isFrameless
                    />
                  </div>
                  <div
                    className="time-wrapper--add"
                    onClick={() => {
                      if (local.sameDayBreaks) {
                        const temp = [...local.sameDay];
                        temp.splice(1, 1);

                        updateLocal({ sameDayBreaks: false, sameDay: temp });
                      }
                    }}
                  >
                    <TrashIcon />
                  </div>
                </div>
              )}
            </>
          )}
          {local.type === "normal" && (
            <>
              {WeekDays.map((day) => {
                const dayStr = WeekDay[day];
                const openingDay = [
                  ...(local.weekDays[day] || [{ open: "", close: "" }]),
                ];

                return (
                  <div key={`every-day-is-different-${dayStr}`}>
                    <div className="different-day-wrapper--item">
                      <div
                        className={[
                          "different-day-wrapper--item--day",
                          local.selectedDays.includes(day)
                            ? "active"
                            : undefined,
                        ].join(" ")}
                        onClick={() => toggleSelectedDay(day)}
                      >
                        {t(`enum.dayOfWeekShort.${dayStr}`)}
                      </div>

                      <div className="time-wrapper">
                        <div className="time-wrapper--item">
                          <InputComponent
                            disabled={!local.selectedDays.includes(day)}
                            value={openingDay[0].open}
                            onChange={(open) => {
                              openingDay[0] = { ...openingDay[0], open };
                              const temp = [...local.weekDays];
                              temp[day] = openingDay;
                              updateLocal({ weekDays: temp });
                            }}
                            type="time"
                            isFrameless
                          />
                          <div className="seperator">-</div>
                          <InputComponent
                            disabled={!local.selectedDays.includes(day)}
                            value={openingDay[0].close}
                            onChange={(close) => {
                              openingDay[0] = { ...openingDay[0], close };
                              const temp = [...local.weekDays];
                              temp[day] = openingDay;
                              updateLocal({ weekDays: temp });
                            }}
                            type="time"
                            isFrameless
                          />
                        </div>
                      </div>

                      <div
                        className={[
                          "different-day-wrapper--item--add",
                          local.selectedDays.includes(day)
                            ? undefined
                            : "disabled",
                          local.weekDayBreaks.includes(day)
                            ? "disabled"
                            : undefined,
                        ].join(" ")}
                        onClick={() => {
                          if (!local.weekDayBreaks.includes(day)) {
                            openingDay[1] = { open: "", close: "" };
                            const temp = [...local.weekDays];
                            temp[day] = openingDay;

                            updateLocal({
                              weekDayBreaks: [...local.weekDayBreaks, day],
                              weekDays: temp,
                            });
                          }
                        }}
                        title={t("newSignup.addTooltip")}
                      >
                        <AddIcon />
                      </div>
                    </div>

                    {local.weekDayBreaks.includes(day) && (
                      <>
                        <div
                          className="time-wrapper"
                          style={{ marginLeft: "40px" }}
                        >
                          <div className="time-wrapper--item">
                            <InputComponent
                              disabled={
                                !local.selectedDays.includes(day) ||
                                !local.weekDayBreaks.includes(day)
                              }
                              value={openingDay[1].open}
                              onChange={(open) => {
                                openingDay[1] = { ...openingDay[1], open };
                                const temp = [...local.weekDays];
                                temp[day] = openingDay;
                                updateLocal({ weekDays: temp });
                              }}
                              type="time"
                              isFrameless
                            />
                            <div className="seperator">-</div>
                            <InputComponent
                              disabled={
                                !local.selectedDays.includes(day) ||
                                !local.weekDayBreaks.includes(day)
                              }
                              value={openingDay[1].close}
                              onChange={(close) => {
                                openingDay[1] = { ...openingDay[1], close };
                                const temp = [...local.weekDays];
                                temp[day] = openingDay;
                                updateLocal({ weekDays: temp });
                              }}
                              type="time"
                              isFrameless
                            />
                          </div>
                          <div
                            className={[
                              "different-day-wrapper--item--add",
                              local.selectedDays.includes(day)
                                ? undefined
                                : "disabled",
                            ].join(" ")}
                            onClick={() => {
                              const tempDay = openingDay;
                              tempDay.splice(1, 1);
                              const tempFrames = [...local.weekDays];
                              tempFrames[day] = tempDay;

                              const tempBreaks = [...local.weekDayBreaks];
                              const dayIndex = tempBreaks.indexOf(day);
                              if (dayIndex > -1) tempBreaks.splice(dayIndex, 1);

                              updateLocal({
                                weekDays: tempFrames,
                                weekDayBreaks: tempBreaks,
                              });
                            }}
                          >
                            <TrashIcon />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
            </>
          )}
        </>
      )}
    </>
  );
};

export default NewOpeningTimeBox;
