import {
  ButtonComponent,
  CheckboxComponent,
  FileInputComponent,
  InputComponent,
} from "deinestadtliebt-component-library";
import { useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  createEmptySlideshowItem,
  FileType,
  SlideshowItem,
} from "../../utils/appConfig/config.types";
import { SlideshowItemEditProps } from "./SlideshowItemEdit.types";
import "./SlideshowItemEdit.style.scss";
import { ReactComponent as ImageIcon } from "../../assets/icons/image.svg";
import { ReactComponent as PdfIcon } from "../../assets/icons/pdf.svg";
import { UserContext } from "../../pages/App";
import { UserRole } from "../../utils/user/User.types";
import { Link } from "react-router-dom";
import { generateSlideItems } from "../../utils/appConfig/configUtils";
import { convertToDateInputValue } from "../../utils/time/TimeUtils";
import { getProvidername } from "../../utils/user/UserUtils";
import { useAxios } from "../../utils/AxiosUtil";
import SlideShowComponent from "../SlideShowComponent/SlideShowComponent";

const SlideshowItemEdit: React.FC<SlideshowItemEditProps> = ({
  onSubmit,
  slideshowItemToEdit,
  onClose,
  onDelete,
  isLoading,
}) => {
  const axios = useAxios();
  const { t } = useTranslation();
  const [localSlideshowItemToEdit, setLocalSlideshowItemToEdit] =
    useState<SlideshowItem>(slideshowItemToEdit || createEmptySlideshowItem());
  const [currentFile, setCurrentFile] = useState<File>();
  const [currentMobileFile, setCurrentMobileFile] = useState<File>();
  const [hasText, toggleHasText] = useState<boolean>(true);
  const { user } = useContext(UserContext);
  const [currentFileBase64, setCurrentFileBase64] = useState<string>("");
  const [currentMobileFileBase64, setCurrentMobileFileBase64] =
    useState<string>("");
  const reader = new FileReader();
  const readerMobile = new FileReader();
  const [foundProviderName, setFoundProviderName] = useState<string>(
    slideshowItemToEdit?.providerId || ""
  );
  const [previewMobile, setPreviewMobile] = useState<boolean>();

  /**
   * this useeffect fetches the name of a provider if needed
   */
  useEffect(() => {
    if (!!axios && slideshowItemToEdit && !!slideshowItemToEdit.providerId)
      getProvidername(axios, localSlideshowItemToEdit.providerId!).then(
        (name) => setFoundProviderName(name)
      );
    // eslint-disable-next-line
  }, [slideshowItemToEdit?.providerId, axios]);

  /**
   * this useEffect checks if a slideshow to edit is provided and toggles hasText to false,if it has no text
   */
  useEffect(() => {
    if (
      !!slideshowItemToEdit?.id &&
      !slideshowItemToEdit?.header &&
      !slideshowItemToEdit?.subText
    )
      toggleHasText(false);
  }, [slideshowItemToEdit]);

  /**
   * this useeffect set the correct image base 64 if anything is set
   */
  useEffect(() => {
    if (currentFile) {
      reader.readAsDataURL(currentFile);
      reader.onload = () => setCurrentFileBase64(reader.result as string);
    }
    if (currentMobileFile) {
      readerMobile.readAsDataURL(currentMobileFile);
      readerMobile.onload = () =>
        setCurrentMobileFileBase64(readerMobile.result as string);
    }
    // eslint-disable-next-line
  }, [currentFile, currentMobileFile]);

  return (
    <>
      <div className="slideshow-item-to-edit-wrapper">
        <p>{t("slideshowEdit.preview")}</p>
        {slideshowItemToEdit?.id ? (
          <SlideShowComponent
            forceLayout={
              previewMobile !== undefined
                ? previewMobile
                  ? "mobile"
                  : "desktop"
                : undefined
            }
            items={generateSlideItems([localSlideshowItemToEdit])}
          />
        ) : (
          // TODO CLEANING
          <SlideShowComponent
            forceLayout={
              previewMobile !== undefined
                ? previewMobile
                  ? "mobile"
                  : "desktop"
                : undefined
            }
            items={[
              {
                text:
                  localSlideshowItemToEdit.header ||
                  localSlideshowItemToEdit.subText ||
                  localSlideshowItemToEdit.detailDescription ? (
                    <>
                      {localSlideshowItemToEdit.header && (
                        <h1>{localSlideshowItemToEdit.header}</h1>
                      )}
                      {localSlideshowItemToEdit.subText && (
                        <h2>{localSlideshowItemToEdit.subText}</h2>
                      )}
                      {localSlideshowItemToEdit.detailDescription && (
                        <h3>{localSlideshowItemToEdit.detailDescription}</h3>
                      )}
                    </>
                  ) : undefined,
                image: currentFileBase64,
                mobileImage: currentMobileFileBase64
                  ? currentMobileFileBase64
                  : undefined,
                reverseContentDirection: localSlideshowItemToEdit.direction,
              },
            ]}
          />
        )}
        <br />
        <div className="slideshow-item-to-edit-wrapper--flex-box">
          <div className="slideshow-item-to-edit-wrapper--flex-box--item">
            <CheckboxComponent
              checked={
                previewMobile === undefined
                  ? window.innerWidth >= 769
                    ? false
                    : true
                  : previewMobile
              }
              value={t("slideshowEdit.mobilePreview")}
              onCheck={setPreviewMobile}
            />
            <p>{t("slideshowEdit.nameText")}</p>
            <InputComponent
              isFrameless
              disabled={isLoading}
              value={localSlideshowItemToEdit.name || ""}
              onChange={(value) =>
                setLocalSlideshowItemToEdit({
                  ...localSlideshowItemToEdit,
                  name: value,
                })
              }
              signCap={20}
              placeholder={t("slideshowEdit.name")}
            />

            <CheckboxComponent
              checked={hasText}
              onCheck={() => {
                if (hasText) {
                  setLocalSlideshowItemToEdit({
                    ...localSlideshowItemToEdit,
                    header: "",
                    detailDescription: "",
                    subText: "",
                  });
                }
                toggleHasText(!hasText);
              }}
              value={t("slideshowEdit.text")}
            />

            <InputComponent
              isFrameless
              disabled={isLoading || !hasText}
              value={localSlideshowItemToEdit.header || ""}
              onChange={(value) =>
                setLocalSlideshowItemToEdit({
                  ...localSlideshowItemToEdit,
                  header: value,
                })
              }
              signCap={40}
              placeholder={t("slideshowEdit.title")}
            />
            <InputComponent
              isFrameless
              value={localSlideshowItemToEdit.subText || ""}
              disabled={isLoading || !hasText}
              signCap={50}
              onChange={(value) =>
                setLocalSlideshowItemToEdit({
                  ...localSlideshowItemToEdit,
                  subText: value,
                })
              }
              placeholder={t("slideshowEdit.description")}
            />
            <p className="a-little-bit-margin-top">
              {t("slideshowEdit.duration")}
            </p>
            <div className="slideshow-item-to-edit-wrapper--flex-box--item--flex-box">
              <InputComponent
                isFrameless
                type="date"
                disabled={isLoading}
                value={convertToDateInputValue(
                  localSlideshowItemToEdit.startDate
                )}
                onChange={(value) => {
                  setLocalSlideshowItemToEdit({
                    ...localSlideshowItemToEdit,
                    startDate: value ? new Date(value) : undefined,
                  });
                }}
                placeholder={t("slideshowEdit.startDate")}
              />
              <InputComponent
                isFrameless
                type="date"
                disabled={isLoading}
                value={convertToDateInputValue(
                  localSlideshowItemToEdit.endDate
                )}
                onChange={(value) =>
                  setLocalSlideshowItemToEdit({
                    ...localSlideshowItemToEdit,
                    endDate: value ? new Date(value) : undefined,
                  })
                }
                placeholder={t("slideshowEdit.endDate")}
              />
            </div>
            <p className="a-little-bit-margin-top">
              {t("slideshowEdit.linkText")}
            </p>
            <InputComponent
              isFrameless
              value={localSlideshowItemToEdit.detailDescription || ""}
              disabled={isLoading || !hasText}
              signCap={20}
              onChange={(value) =>
                setLocalSlideshowItemToEdit({
                  ...localSlideshowItemToEdit,
                  detailDescription: value,
                })
              }
              placeholder={t("slideshowEdit.detailDescription")}
            />
            <InputComponent
              isFrameless
              value={localSlideshowItemToEdit.link || ""}
              disabled={isLoading}
              onChange={(value) =>
                setLocalSlideshowItemToEdit({
                  ...localSlideshowItemToEdit,
                  link: value,
                })
              }
              placeholder={t("slideshowEdit.link")}
            />
            <p>{t("slideshowEdit.directionTitle")}</p>
            <CheckboxComponent
              disabled={isLoading || !hasText}
              checked={!!localSlideshowItemToEdit.direction}
              onCheck={() =>
                setLocalSlideshowItemToEdit({
                  ...localSlideshowItemToEdit,
                  direction: true,
                })
              }
              value={t("slideshowEdit.directionLeft")}
            />
            <CheckboxComponent
              disabled={isLoading || !hasText}
              checked={!localSlideshowItemToEdit.direction}
              onCheck={() =>
                setLocalSlideshowItemToEdit({
                  ...localSlideshowItemToEdit,
                  direction: false,
                })
              }
              value={t("slideshowEdit.directionRight")}
            />
            {user?.role === UserRole.ADMIN &&
              !!localSlideshowItemToEdit.providerId && (
                <>
                  <p>{t("slideshowEdit.activeProviderTitle")}</p>
                  <CheckboxComponent
                    disabled={isLoading}
                    checked={!!localSlideshowItemToEdit.active}
                    onCheck={() =>
                      setLocalSlideshowItemToEdit({
                        ...localSlideshowItemToEdit,
                        active: !localSlideshowItemToEdit.active,
                      })
                    }
                    value={t("slideshowEdit.activeProvider", {
                      replace: {
                        providerId: foundProviderName,
                      },
                    })}
                  />
                </>
              )}
          </div>

          <div className="slideshow-item-to-edit-wrapper--flex-box--item">
            <p>{t("slideshowEdit.mainImageTitle")}</p>
            <p>
              <small>{t("slideshowEdit.mainImageText")}</small>
            </p>
            <div className="download-template-wrapper">
              <div className="download-template">
                <a
                  href="/files/How_to_Slider_Grafikerstellung.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <PdfIcon />
                </a>
              </div>
              <div className="download-template">
                <a
                  href="/files/Vorlage_Grafikerstellung.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <PdfIcon />
                </a>
              </div>
              <div className="download-template">
                <a
                  href="/files/Vorlage_Grafikerstellung.ai"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ImageIcon />
                </a>
              </div>
            </div>
            <FileInputComponent
              onUpload={(file) => setCurrentFile(file)}
              fileName={slideshowItemToEdit?.fileName}
              type="image"
              fileType={FileType.SLIDESHOW_IMAGE}
              popupSaveTitle={t("providerEventsConfigurationPage.buttons.save")}
              translate={(value) => t(value)}
              autoCompress={false}
            />

            <p>{t("slideshowEdit.mobileImageTitle")}</p>
            <p>
              <small>{t("slideshowEdit.mobileImageText")}</small>
            </p>
            <div className="download-template-wrapper">
              <div className="download-template">
                <a
                  href="/files/How_to_Slider_Grafikerstellung.pdf"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <PdfIcon />
                </a>
              </div>
              <div className="download-template">
                <a
                  href="/files/Vorlage_Grafikerstellung.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <PdfIcon />
                </a>
              </div>
              <div className="download-template">
                <a
                  href="/files/Vorlage_Grafikerstellung.ai"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ImageIcon />
                </a>
              </div>
            </div>
            <FileInputComponent
              onUpload={(file) => setCurrentMobileFile(file)}
              fileName={slideshowItemToEdit?.mobileFileName}
              type="image"
              fileType={FileType.SLIDESHOW_IMAGE}
              popupSaveTitle={t("providerEventsConfigurationPage.buttons.save")}
              translate={(value) => t(value)}
              autoCompress={false}
            />
            {user?.role !== UserRole.ADMIN && (
              <p>
                <Trans
                  t={t}
                  i18nKey="slideshowEdit.needHelp"
                  components={[
                    <Link key={0} to="/mailbox">
                      there
                    </Link>,
                  ]}
                />
              </p>
            )}
          </div>
        </div>

        <div className="slideshow-item-to-edit-wrapper--flex-box">
          <ButtonComponent
            isLoading={isLoading}
            value={t("slideshowEdit.upload")}
            onClick={() =>
              onSubmit(localSlideshowItemToEdit, currentFile, currentMobileFile)
            }
            type="button"
          />
          {slideshowItemToEdit?.id && (
            <ButtonComponent
              isLoading={isLoading}
              value={t("slideshowEdit.delete")}
              onClick={() => onDelete?.(slideshowItemToEdit)}
              type="button"
            />
          )}
          {onClose && (
            <ButtonComponent
              isLoading={isLoading}
              value={t("slideshowEdit.cancel")}
              onClick={() => onClose()}
              type="button"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default SlideshowItemEdit;
