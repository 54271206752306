import { AxiosInstance } from "axios";
import { Tag } from "deinestadtliebt-component-library";
import i18next from "i18next";
import {
  getCorrectOpeningtimeDay,
  parseTimeStringToCompareableNumber,
} from "../time/TimeUtils";
import {
  Category,
  DayOfWeek,
  DetailType,
  MainType,
  OpeningTime,
  Provider,
  TradeAndServiceCategories,
} from "../user/User.types";

/**
 * API METHOD - to create a new Provider on the Server
 * @param axios axios Instance
 * @param newProvider provider object
 * @returns boolean
 */
export const createNewProviderOnServer = (
  axios: AxiosInstance,
  newProvider: Provider
): Promise<boolean> => {
  return axios
    .post("/user/provider/", newProvider)
    .then((providerCreationResp) => providerCreationResp.status === 201)
    .catch((exc) => {
      console.error("Error during creation of Provider!", exc);
      return false;
    });
};

/**
 * Returns tag-arrays depending on the category given
 * @param category typeof TradeAndServiceCategories
 * @returns Tag[]
 */
export const getFilteredTags = (category: TradeAndServiceCategories): Tag[] => {
  return convertEnumToTag(
    getTradeAndServiceCategories(TradeAndServiceCategories[category]),
    "DetailType"
  );
};

/**
 * Converts enum-array to tag-array and assigns value of enums to tag-title
 * @param currentEnum MainType[] | DetailType[]
 * @param enumType "MainType" | "DetailType" type of enum that determines the value that gets assigned to title of tag
 * @returns Tag[]
 * @tested
 */
export const convertEnumToTag = (
  currentEnum: MainType[] | DetailType[],
  enumType: "MainType" | "DetailType"
): Tag[] => {
  let returnTargetArray: Tag[] = [];
  Object.values(currentEnum).forEach((entry) => {
    returnTargetArray.push({
      value: entry.toString(),
      title: i18next.t(
        `enum.${enumType === "MainType" ? "mainType" : "detailType"}.${entry}`
      ),
    });
  });
  return returnTargetArray;
};

/**
 * Method to return the number of selectable tags for mainType depending on inputted category
 * @param category Category enum
 * @returns number
 * @tested
 */
export const getCorrectAmountOfMainTypesForCategory = (
  category: Category
): number => {
  switch (category) {
    case Category.EAT_DRINK:
      return Number.parseInt(process.env.REACT_APP_EAT_DRINK_MAIN_TAG_LIMIT!);
    case Category.EXPERIENCE:
      return Number.parseInt(process.env.REACT_APP_EXPERIENCE_MAIN_TAG_LIMIT!);
    case Category.SHOPPING:
      return Number.parseInt(process.env.REACT_APP_SHOPPING_MAIN_TAG_LIMIT!);
  }
};

/**
 * Method to return the number of selectable tags for detailType depending on inputted category
 * @returns number
 * @tested
 */
export const getCorrectAmountOfDetailTypesForCategory = (
  category: Category
): number => {
  switch (category) {
    case Category.EAT_DRINK:
      return Number.parseInt(process.env.REACT_APP_EAT_DRINK_DETAIL_TAG_LIMIT!);
    case Category.EXPERIENCE:
      return Number.parseInt(
        process.env.REACT_APP_EXPERIENCE_DETAIL_TAG_LIMIT!
      );
    case Category.SHOPPING:
      return Number.parseInt(process.env.REACT_APP_SHOPPING_DETAIL_TAG_LIMIT!);
  }
};

/**
 * Returns an array of MainType-enums depending on the used category
 * @param category Category enum
 * @returns MainType[]
 * @tested
 */
export const getCorrectMainTypeForCategory = (
  category: Category
): MainType[] => {
  switch (category) {
    case Category.EAT_DRINK:
      return [
        MainType.BAR,
        MainType.BACKERY,
        MainType.CAFE,
        MainType.ICE,
        MainType.SNACK,
        MainType.PUB,
        MainType.BUTCHER_SHOP,
        MainType.PIZZERIA,
        MainType.RESTAURANT,
        MainType.SPORTS_BAR,
        MainType.BEER_GARDEN,
        MainType.PASTRY_SHOP,
        MainType.IMBISS,
        MainType.TAVERN,
        MainType.CATERING,
        MainType.PARTY_SERVICE,
        MainType.PIZZA_SERVICE,
        MainType.VINEYARD,
      ];
    case Category.EXPERIENCE:
      return [
        MainType.ART,
        MainType.THEATRE,
        MainType.CONCERT,
        MainType.OPER,
        MainType.MUSICAL,
        MainType.COMEDY,
        MainType.KABARETT,
        MainType.VARIETE,
        MainType.EXHIBITION,
        MainType.CLUB,
        MainType.MUSEUM,
        MainType.GALERY,
        MainType.YOUTH_HOUSE,
        MainType.YOUTH_CENTER,
        MainType.CINEMA,
        MainType.DISCO,
        MainType.SWIMMING_POOL,
        MainType.SPORT,
        MainType.WELLNESS,
        MainType.TATTOO,
        MainType.SAUNA,
        MainType.PIERCING,
        MainType.OUTDOOR_POOL,
        MainType.PLAYGROUND,
        MainType.FOOTBALL_FIELD,
        MainType.FITNESS,
        MainType.ASSOCIATION,
        MainType.MASSAGE,
      ];
    case Category.SHOPPING:
      return [
        MainType.ELECTRO,
        MainType.FASHION,
        MainType.FOOD,
        MainType.OFFICE,
        MainType.STATIONERY,
        MainType.BOOK,
        MainType.DECORATIONS,
        MainType.HOUSEHOLD_GOODS,
        MainType.BUILDING_SUPPLIES,
        MainType.HOME_IMPROVEMENT,
        MainType.TOBACCO,
        MainType.PHOTO_STORE,
        MainType.HABERDASHERY,
        MainType.TOYS,
        MainType.FARM_STORE,
        MainType.ORGANIC_MARKET,
        MainType.CHILDREN_SUPPLIES,
        MainType.JEWELER,
        MainType.HEARING_ACOUSTICS,
        MainType.PURCHASE_SALE,
        MainType.TRAVEL_AGENCY,
        MainType.HAIRDRESSER,
        MainType.COSMETICS,
        MainType.BEAUTY,
        MainType.GROCERIES,
        MainType.DIYMARKET,
        MainType.OPTICIAN,
        MainType.HEALTH,
        MainType.SERVICE,
        MainType.CRAFT,
      ];
  }
};

/**
 * Returns an array of DetailType-enums depending on the used category
 * @param category Category enum
 * @returns DetailType[]
 * @tested
 */
export const getCorrectDetailTypeForCategory = (
  category: Category
): DetailType[] => {
  switch (category) {
    case Category.EAT_DRINK:
      return [
        DetailType.ALCOHOL_FREE,
        DetailType.ARABIAN,
        DetailType.ASIAN,
        DetailType.BEER,
        DetailType.ORGANIC,
        DetailType.ROAST,
        DetailType.BREAD,
        DetailType.BUNS,
        DetailType.BUFFET,
        DetailType.BUNDESLIGA,
        DetailType.BURGER,
        DetailType.CAPPUCCINO,
        DetailType.COCKTAIL,
        DetailType.DART,
        DetailType.DELICACIES,
        DetailType.GERMAN,
        DetailType.HOMEMADE,
        DetailType.DONER_KEBAB,
        DetailType.ICE,
        DetailType.ESPRESSO,
        DetailType.FISH,
        DetailType.FRAPPE,
        DetailType.BREAKFAST,
        DetailType.SOCCER,
        DetailType.DRINKS,
        DetailType.GREEK,
        DetailType.GRILL,
        DetailType.GYROS,
        DetailType.INTERNATIONAL,
        DetailType.ITALIAN,
        DetailType.CAKE,
        DetailType.LONGDRINK,
        DetailType.MENU,
        DetailType.PASTA,
        DetailType.PIZZA,
        DetailType.PORTUGUESE,
        DetailType.SALAD,
        DetailType.SCHNAPPS,
        DetailType.SCHNITZEL,
        DetailType.SWABIAN,
        DetailType.SKY,
        DetailType.SNACKS,
        DetailType.STEAK,
        DetailType.SWEETS,
        DetailType.TURKISH,
        DetailType.VEGAN,
        DetailType.VEGETARIAN,
        DetailType.WINE,
        DetailType.SAUSAGE,
        DetailType.LACTOSE_FREE,
        DetailType.GLUTEN_FREE,
        DetailType.SIRIAN,
        DetailType.KICKER,
        DetailType.BOARD_GAME,
        DetailType.GAME_TABLE,
      ];
    case Category.EXPERIENCE:
      return [
        DetailType.ACTING,
        DetailType.KIDS_THEATRE,
        DetailType.PUPPET_THEATRE,
        DetailType.IMPRO_THEATRE,
        DetailType.JAZZ,
        DetailType.METAL,
        DetailType.RAP,
        DetailType.POP,
        DetailType.ROCK,
        DetailType.CLASSIC,
        DetailType.ELECTRO,
        DetailType.HIPHOP,
        DetailType.ALTERNATIVE,
        DetailType.BASKETBALL,
        DetailType.BILLIARDS,
        DetailType.FOOTBALL,
        DetailType.SWIMMING,
        DetailType.TENNIS,
        DetailType.TABLETENNIS,
        DetailType.VOLLEYBALL,
        DetailType.BATMINTON,
        DetailType.SQUASH,
        DetailType.NINEPINS,
        DetailType.BOWLING,
        DetailType.RIDING,
        DetailType.GYMNASTICS,
        DetailType.YOGA,
        DetailType.ZUMBA,
        DetailType.BODEGA,
        DetailType.ATHLETICS,
        DetailType.WRESTLING,
        DetailType.AEROBIC,
        DetailType.JUDO,
        DetailType.SOCCER,
      ];
    case Category.SHOPPING:
      return [
        DetailType.PURCHASE_SALE,
        DetailType.ANTIQUES,
        DetailType.SUITS,
        DetailType.BABYWARES,
        DetailType.CRAFT_SUPPLIES,
        DetailType.BUILDING_SUPPLIES,
        DetailType.LIGHTING,
        DetailType.BIOMARKET,
        DetailType.FLORIST,
        DetailType.WEDDING_DRESSES,
        DetailType.GLASSES,
        DetailType.BOOKS,
        DetailType.OFFICE_SUPPLIES,
        DetailType.CAMPING_SUPPLIES,
        DetailType.CDS,
        DetailType.DECORATIONS,
        DetailType.IRONWARE,
        DetailType.EROTICSTORE,
        DetailType.BANNER,
        DetailType.FITNESSTOOLS,
        DetailType.TILES,
        DetailType.FOTOSTORE,
        DetailType.DRINKSTORE,
        DetailType.GLASSWARE,
        DetailType.MARKETGARDEN,
        DetailType.HANDCRAFT_SUPPLIES,
        DetailType.HANDYS,
        DetailType.HOUSEWARES,
        DetailType.DIYSTORE,
        DetailType.DIY,
        DetailType.HEARTH,
        DetailType.WEDDING_EQUIPMENT,
        DetailType.FARMSTORE,
        DetailType.HEARING_AID,
        DetailType.SHUTTERS,
        DetailType.JEWELER,
        DetailType.DEPARTMENT_STORE,
        DetailType.CHILDREN_SUPPLIES,
        DetailType.KIOSK,
        DetailType.GIFTS,
        DetailType.DRESSES,
        DetailType.CLOTHES,
        DetailType.SUITCASES,
        DetailType.DRYGOODS,
        DetailType.HYGIENE,
        DetailType.GROCERIES,
        DetailType.LEATHER_WARES,
        DetailType.LONGBOARD,
        DetailType.PAINTER_SUPPLIES,
        DetailType.FASHION,
        DetailType.MUSIC,
        DetailType.MUSICAL_INSTRUMENTS,
        DetailType.MUSICAL_SUPPLIES,
        DetailType.FURNITURE,
        DetailType.OPTICS,
        DetailType.PERFUMERY,
        DetailType.PORCELAIN_WARES,
        DetailType.CLEANING_SUPPLIES,
        DetailType.TRAVEL_AGENCY,
        DetailType.RECORDS,
        DetailType.PLATES,
        DetailType.LOCKING_SYSTEMS,
        DetailType.JEWELERY,
        DetailType.STATIONERY,
        DetailType.SHOES,
        DetailType.SKATEBOARD,
        DetailType.SKIS,
        DetailType.SNOWBOARD,
        DetailType.TOYS,
        DetailType.TOBACCO,
        DetailType.BAGS,
        DetailType.TECHNICAL_SUPPLIES,
        DetailType.PETFOOD,
        DetailType.WATCHES,
        DetailType.WATCH_ACCESSORIES,
        DetailType.CONSUMERMARKET,
        DetailType.TOOLS,
        DetailType.NEWSPAPER,
        DetailType.MAGAZINES,
        DetailType.PETSHOP,
        DetailType.STOVES,
        DetailType.COMBUSTIBLES,
        DetailType.FUELS,
        DetailType.HOUSEHOLD,
        DetailType.TV,
        DetailType.COMPUTER,
        DetailType.HIFI,
        DetailType.SAUSAGES,
        DetailType.MEAT,
        DetailType.BIO,
        DetailType.FAIRTRADE,
        DetailType.MADE_IN_EU,
        DetailType.MADE_IN_GERMANY,
        DetailType.ACCESSORIES,
        DetailType.SOUVENIRS,
        DetailType.GREETING_CARD,
        DetailType.PAPETERIE,
        DetailType.WOOLEN_FABRIC,
        DetailType.CONSTRUCTION_MACHINES,
        DetailType.CONSTRUCTION_EQUIPMENT,
        DetailType.HARDWARESTORE,
        DetailType.WINDOWS,
        DetailType.GARDENING_SUPPLIES,

        //HANDICRAFT
        DetailType.TILER,
        DetailType.ROOFER,
        DetailType.CARPENTER,
        DetailType.ELECTRICIAN,
        DetailType.PLUMBER,
        DetailType.ROOF,
        DetailType.ROOFING,
        DetailType.FACADE,
        DetailType.SCAFFOLDING,
        DetailType.SCAFFOLDER,
        DetailType.STAGING,
        DetailType.WINDOW_CONSTRUCTOR,
        DetailType.GARDENING,
        DetailType.LANDSCAPING,
        DetailType.INTERIORWORK,
        DetailType.SURVEYOFFICE,

        //SERVICES
        DetailType.IT_SERVICES,
        DetailType.GRAPHIC_DESIGN,
        DetailType.ADMNISTRATION,
        DetailType.WORKSHOP,
        DetailType.INSTALLATIONS,
        DetailType.SANITARY,
        DetailType.ELECTRONICS,
        DetailType.TIRE_SERVICE,
        DetailType.CAR,
        DetailType.BIKE,
        DetailType.BICYCLE,
        DetailType.HAIR_DRESSER,
        DetailType.COSMETICS,
        DetailType.BEAUTY,
        DetailType.TATTOO,
        DetailType.MASSAGE,
        DetailType.HAIR_REMOVAL,
        DetailType.FUNERAL,
        DetailType.BURIAL,
        DetailType.REGISTERED_AGENT,
        DetailType.CARETAKER_SERVICE,
        DetailType.CLEANING_COMPANY,
        DetailType.PRINTSHOP,
        DetailType.ENERGY_SUPPLY,
        DetailType.CLEARING_OUT,
        DetailType.HOUSE_CLEARANCE,
        DetailType.DRIVING_SCHOOL,
        DetailType.HAIRDRESSER_SHOP,
        DetailType.BARBER,
        DetailType.BARBERSHOP,
        DetailType.INTERNET_AGENCY,
        DetailType.MARKETING_AGENCY,
        DetailType.ONLINE_SERVICES,
        DetailType.PAKETSHOP,
        DetailType.PACKAGE_SERVICE,
        DetailType.PIERCING,
        DetailType.PIERCER,
        DetailType.CLEANERS,
        DetailType.PEST_CONTROL,
        DetailType.MOULD_REMOVAL,
        DetailType.LOCKSMITH,
        DetailType.TAILORING,
        DetailType.SECURITY_SERVICE,
        DetailType.SOLARIUM,
        DetailType.TATTOOS,
        DetailType.TRANSPORTATION,
        DetailType.ENTERTAINMENT_SERVICE,
        DetailType.VIDEO_PRODCTION,
        DetailType.COMMERCIALS,
        DetailType.ADVERTISING_AGENCY,
        DetailType.CONSTRUCTION_COMPANY,
        DetailType.ENERGY_CONSULTANT,
        DetailType.ARCHITECT,
        DetailType.AIRCONDITIONER,
        DetailType.DELIVERY,
        DetailType.MOUNTING_SERVICE,
        DetailType.INSURANCE,
        DetailType.VETERINARIAN,
        DetailType.BANK,
        DetailType.PROPERTY,
        DetailType.HEALTH_INSURANCE,
        DetailType.COMMERCIAL_AGENCY,
        DetailType.KEY_SERVICE,
        DetailType.ANIMAL_SHELTER,
        DetailType.HELPLINE,
        DetailType.POSTOFFICE,
        DetailType.ESTATE_AGENT,

        //MEDICINE
        DetailType.GENERAL_PRACTITIONER,
        DetailType.FAMILY_DOCTOR,
        DetailType.GYNECOLOGIST,
        DetailType.ORTHOPEDIST,
        DetailType.OTORHINOLARYNGOLOGIST,
        DetailType.DENTIST,
        DetailType.EMERGENCY_DOCTOR,
        DetailType.ACUPUNCTURE,
        DetailType.GERIATRIC_CARE,
        DetailType.PHARMACY,
        DetailType.OPTICIAN,
        DetailType.CHIROPRACTOR,
        DetailType.SURGERY,
        DetailType.OCCUPATIONAL_THERAPY,
        DetailType.CHIROPODY,
        DetailType.GYNECOLOGY,
        DetailType.MIDWIFE,
        DetailType.HEARING_AIDS,
        DetailType.ALTERNATIVE_PRACTITIONER,
        DetailType.THERAPEUTIC_PEDAGOGY,
        DetailType.INTERNIST,
        DetailType.PEDIATRIST,
        DetailType.JUGENDARZT,
        DetailType.NURSING,
        DetailType.NURSING_SERVICE,
        DetailType.LOGOPEDICS,
        DetailType.NATUROPATHY,
        DetailType.PHYSIOTHERAPY,
        DetailType.PSYCHOTHERAPY,
        DetailType.REHAB,
        DetailType.PAIN_THERAPY,
        DetailType.SPORTSMEDICINE,
        DetailType.UROLOGY,
        DetailType.MEDITATION,
      ];
  }
};

/**
 * Returns an array of DetailType-enums depending on the used category
 * @param category TradeAndServiceCategory enum
 * @returns DetailType[]
 * @tested
 */
export const getTradeAndServiceCategories = (
  category: TradeAndServiceCategories
): DetailType[] => {
  switch (category) {
    // defualt is needed, otherwise causes Error
    default:
      return [];
    case TradeAndServiceCategories.TRADE:
      return [
        DetailType.PURCHASE_SALE,
        DetailType.ANTIQUES,
        DetailType.SUITS,
        DetailType.BABYWARES,
        DetailType.CRAFT_SUPPLIES,
        DetailType.BUILDING_SUPPLIES,
        DetailType.LIGHTING,
        DetailType.BIOMARKET,
        DetailType.FLORIST,
        DetailType.WEDDING_DRESSES,
        DetailType.GLASSES,
        DetailType.BOOKS,
        DetailType.OFFICE_SUPPLIES,
        DetailType.CAMPING_SUPPLIES,
        DetailType.CDS,
        DetailType.DECORATIONS,
        DetailType.IRONWARE,
        DetailType.EROTICSTORE,
        DetailType.BANNER,
        DetailType.FITNESSTOOLS,
        DetailType.TILES,
        DetailType.FOTOSTORE,
        DetailType.DRINKSTORE,
        DetailType.GLASSWARE,
        DetailType.MARKETGARDEN,
        DetailType.HANDCRAFT_SUPPLIES,
        DetailType.HANDYS,
        DetailType.HOUSEWARES,
        DetailType.DIYSTORE,
        DetailType.DIY,
        DetailType.HEARTH,
        DetailType.WEDDING_EQUIPMENT,
        DetailType.FARMSTORE,
        DetailType.HEARING_AID,
        DetailType.SHUTTERS,
        DetailType.JEWELER,
        DetailType.DEPARTMENT_STORE,
        DetailType.CHILDREN_SUPPLIES,
        DetailType.KIOSK,
        DetailType.GIFTS,
        DetailType.DRESSES,
        DetailType.CLOTHES,
        DetailType.SUITCASES,
        DetailType.DRYGOODS,
        DetailType.HYGIENE,
        DetailType.GROCERIES,
        DetailType.LEATHER_WARES,
        DetailType.LONGBOARD,
        DetailType.PAINTER_SUPPLIES,
        DetailType.FASHION,
        DetailType.MUSIC,
        DetailType.MUSICAL_INSTRUMENTS,
        DetailType.MUSICAL_SUPPLIES,
        DetailType.FURNITURE,
        DetailType.OPTICS,
        DetailType.PERFUMERY,
        DetailType.PORCELAIN_WARES,
        DetailType.CLEANING_SUPPLIES,
        DetailType.TRAVEL_AGENCY,
        DetailType.RECORDS,
        DetailType.PLATES,
        DetailType.LOCKING_SYSTEMS,
        DetailType.JEWELERY,
        DetailType.STATIONERY,
        DetailType.SHOES,
        DetailType.SKATEBOARD,
        DetailType.SKIS,
        DetailType.SNOWBOARD,
        DetailType.TOYS,
        DetailType.TOBACCO,
        DetailType.BAGS,
        DetailType.TECHNICAL_SUPPLIES,
        DetailType.PETFOOD,
        DetailType.WATCHES,
        DetailType.WATCH_ACCESSORIES,
        DetailType.CONSUMERMARKET,
        DetailType.TOOLS,
        DetailType.NEWSPAPER,
        DetailType.MAGAZINES,
        DetailType.PETSHOP,
        DetailType.STOVES,
        DetailType.COMBUSTIBLES,
        DetailType.FUELS,
        DetailType.HOUSEHOLD,
        DetailType.TV,
        DetailType.COMPUTER,
        DetailType.HIFI,
        DetailType.SAUSAGES,
        DetailType.MEAT,
        DetailType.BIO,
        DetailType.FAIRTRADE,
        DetailType.MADE_IN_EU,
        DetailType.MADE_IN_GERMANY,
        DetailType.ACCESSORIES,
        DetailType.SOUVENIRS,
        DetailType.GREETING_CARD,
        DetailType.PAPETERIE,
        DetailType.WOOLEN_FABRIC,
        DetailType.CONSTRUCTION_MACHINES,
        DetailType.CONSTRUCTION_EQUIPMENT,
        DetailType.HARDWARESTORE,
        DetailType.WINDOWS,
        DetailType.GARDENING_SUPPLIES,
      ];

    case TradeAndServiceCategories.CRAFT:
      return [
        //HANDICRAFT
        DetailType.TILER,
        DetailType.ROOFER,
        DetailType.CARPENTER,
        DetailType.ELECTRICIAN,
        DetailType.PLUMBER,
        DetailType.ROOF,
        DetailType.ROOFING,
        DetailType.FACADE,
        DetailType.SCAFFOLDING,
        DetailType.SCAFFOLDER,
        DetailType.STAGING,
        DetailType.WINDOW_CONSTRUCTOR,
        DetailType.GARDENING,
        DetailType.LANDSCAPING,
        DetailType.INTERIORWORK,
        DetailType.SURVEYOFFICE,
      ];

    case TradeAndServiceCategories.SERVICE:
      return [
        //SERVICES
        DetailType.IT_SERVICES,
        DetailType.GRAPHIC_DESIGN,
        DetailType.ADMNISTRATION,
        DetailType.WORKSHOP,
        DetailType.INSTALLATIONS,
        DetailType.SANITARY,
        DetailType.ELECTRONICS,
        DetailType.TIRE_SERVICE,
        DetailType.CAR,
        DetailType.BIKE,
        DetailType.BICYCLE,
        DetailType.HAIR_DRESSER,
        DetailType.COSMETICS,
        DetailType.BEAUTY,
        DetailType.TATTOO,
        DetailType.MASSAGE,
        DetailType.HAIR_REMOVAL,
        DetailType.FUNERAL,
        DetailType.BURIAL,
        DetailType.REGISTERED_AGENT,
        DetailType.CARETAKER_SERVICE,
        DetailType.CLEANING_COMPANY,
        DetailType.PRINTSHOP,
        DetailType.ENERGY_SUPPLY,
        DetailType.CLEARING_OUT,
        DetailType.HOUSE_CLEARANCE,
        DetailType.DRIVING_SCHOOL,
        DetailType.HAIRDRESSER_SHOP,
        DetailType.BARBER,
        DetailType.BARBERSHOP,
        DetailType.INTERNET_AGENCY,
        DetailType.MARKETING_AGENCY,
        DetailType.ONLINE_SERVICES,
        DetailType.PAKETSHOP,
        DetailType.PACKAGE_SERVICE,
        DetailType.PIERCING,
        DetailType.PIERCER,
        DetailType.CLEANERS,
        DetailType.PEST_CONTROL,
        DetailType.MOULD_REMOVAL,
        DetailType.LOCKSMITH,
        DetailType.TAILORING,
        DetailType.SECURITY_SERVICE,
        DetailType.SOLARIUM,
        DetailType.TATTOOS,
        DetailType.TRANSPORTATION,
        DetailType.ENTERTAINMENT_SERVICE,
        DetailType.VIDEO_PRODCTION,
        DetailType.COMMERCIALS,
        DetailType.ADVERTISING_AGENCY,
        DetailType.CONSTRUCTION_COMPANY,
        DetailType.ENERGY_CONSULTANT,
        DetailType.ARCHITECT,
        DetailType.AIRCONDITIONER,
        DetailType.DELIVERY,
        DetailType.MOUNTING_SERVICE,
        DetailType.INSURANCE,
        DetailType.VETERINARIAN,
        DetailType.BANK,
        DetailType.PROPERTY,
        DetailType.HEALTH_INSURANCE,
        DetailType.COMMERCIAL_AGENCY,
        DetailType.KEY_SERVICE,
        DetailType.ANIMAL_SHELTER,
        DetailType.HELPLINE,
        DetailType.POSTOFFICE,
        DetailType.ESTATE_AGENT,
      ];

    case TradeAndServiceCategories.HEALTH:
      return [
        //MEDICINE
        DetailType.GENERAL_PRACTITIONER,
        DetailType.FAMILY_DOCTOR,
        DetailType.GYNECOLOGIST,
        DetailType.ORTHOPEDIST,
        DetailType.OTORHINOLARYNGOLOGIST,
        DetailType.DENTIST,
        DetailType.EMERGENCY_DOCTOR,
        DetailType.ACUPUNCTURE,
        DetailType.GERIATRIC_CARE,
        DetailType.PHARMACY,
        DetailType.OPTICIAN,
        DetailType.CHIROPRACTOR,
        DetailType.SURGERY,
        DetailType.OCCUPATIONAL_THERAPY,
        DetailType.CHIROPODY,
        DetailType.GYNECOLOGY,
        DetailType.MIDWIFE,
        DetailType.HEARING_AIDS,
        DetailType.ALTERNATIVE_PRACTITIONER,
        DetailType.THERAPEUTIC_PEDAGOGY,
        DetailType.INTERNIST,
        DetailType.PEDIATRIST,
        DetailType.JUGENDARZT,
        DetailType.NURSING,
        DetailType.NURSING_SERVICE,
        DetailType.LOGOPEDICS,
        DetailType.NATUROPATHY,
        DetailType.PHYSIOTHERAPY,
        DetailType.PSYCHOTHERAPY,
        DetailType.REHAB,
        DetailType.PAIN_THERAPY,
        DetailType.SPORTSMEDICINE,
        DetailType.UROLOGY,
        DetailType.MEDITATION,
      ];
  }
};

/**
 * This helper checks if the provided
 * opening times (from the new singup process)
 * are valid
 *
 * @return boolean if valid
 * @tested
 */
export const checkOpeningTimesValid = (
  openingType: "normal" | "samedate" | "none",
  provider: Pick<Provider, "noOpeningTimes" | "noOpeningTimesText">,
  openingTime: OpeningTime,
  selectedDayOfWeek: DayOfWeek[],
  selectedBreaks: Array<DayOfWeek | boolean>
): boolean => {
  switch (openingType) {
    case "none": {
      return provider.noOpeningTimes && !!provider.noOpeningTimesText;
    }

    case "samedate": {
      if (selectedDayOfWeek.length === 0) {
        return true;
      } else {
        const { startTime, endTime, startBreakTime, endBreakTime } =
          openingTime.mon;

        if (selectedBreaks.includes(true)) {
          return (
            parseTimeStringToCompareableNumber(startTime || "00:00", {
              fallback: 0,
            }) <
              parseTimeStringToCompareableNumber(endTime || "23:59", {
                fallback: 0,
              }) &&
            parseTimeStringToCompareableNumber(endTime || "23:59", {
              fallback: 0,
            }) <
              parseTimeStringToCompareableNumber(startBreakTime || "00:00", {
                fallback: 0,
              }) &&
            parseTimeStringToCompareableNumber(startBreakTime || "23:59", {
              fallback: 0,
            }) <
              parseTimeStringToCompareableNumber(endBreakTime || "00:00", {
                fallback: 0,
              })
          );
        } else {
          return (
            parseTimeStringToCompareableNumber(startTime || "23:59", {
              fallback: 0,
            }) <
            parseTimeStringToCompareableNumber(endTime || "00:00", {
              fallback: 0,
            })
          );
        }
      }
    }

    case "normal": {
      let openingTimesValid = true;
      for (const dayOfWeek of Object.values(DayOfWeek)) {
        const correctDay = getCorrectOpeningtimeDay(dayOfWeek);
        const currentDay = openingTime[correctDay];

        if (selectedBreaks.includes(dayOfWeek)) {
          if (
            (selectedDayOfWeek.includes(dayOfWeek) &&
              parseTimeStringToCompareableNumber(
                currentDay.startTime || "23:59",
                {
                  fallback: 0,
                }
              ) >=
                parseTimeStringToCompareableNumber(
                  currentDay.endTime || "00:00",
                  {
                    fallback: 0,
                  }
                )) ||
            parseTimeStringToCompareableNumber(currentDay.endTime || "00:00", {
              fallback: 0,
            }) >=
              parseTimeStringToCompareableNumber(
                currentDay.startBreakTime || "23:59",
                {
                  fallback: 0,
                }
              ) ||
            parseTimeStringToCompareableNumber(
              currentDay.startBreakTime || "23:59",
              {
                fallback: 0,
              }
            ) >=
              parseTimeStringToCompareableNumber(
                currentDay.endBreakTime || "00:00",
                {
                  fallback: 0,
                }
              )
          ) {
            openingTimesValid = false;
            break;
          }
        } else {
          if (
            selectedDayOfWeek.includes(dayOfWeek) &&
            parseTimeStringToCompareableNumber(
              currentDay.startTime || "23:59",
              {
                fallback: 0,
              }
            ) >=
              parseTimeStringToCompareableNumber(
                currentDay.endTime || "00:00",
                {
                  fallback: 0,
                }
              )
          ) {
            openingTimesValid = false;
            break;
          }
        }
      }

      return openingTimesValid;
    }
  }
};
