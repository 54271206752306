import { ButtonComponent } from "deinestadtliebt-component-library";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TimeFrameCollection } from "../../utils/openinghours/OpeningHours.types";
import NewOpeningTimeBox from "../newopeningtimebox/NewOpeningTimeBox";
import "./OpeningTimeEdit.styles.scss";
import { OpeningTimeEditProps } from "./OpeningTimeEdit.types";

const OpeningTimeEdit: React.FC<OpeningTimeEditProps> = ({
  isLoading,
  time,
  setTime,
  update,
}) => {
  const { t } = useTranslation();

  const [localTime, setLocalTime] = useState<TimeFrameCollection>(time);
  const [localTimeValid, setLocalTimeValid] = useState<boolean>(false);

  const isUpdate = useMemo(() => {
    return !!update;
  }, [update]);

  return (
    <>
      <div className="opening-time-box--scroll-limiter">
        <NewOpeningTimeBox
          timeCollection={localTime}
          setTimeCollection={setLocalTime}
          onValidChanged={setLocalTimeValid}
        />
      </div>
      <ButtonComponent
        value={t(
          `providerConfigurationPage.buttons.${isUpdate ? "update" : "add"}`
        )}
        isLoading={isLoading}
        disabled={!localTimeValid}
        onClick={() => {
          setTime(localTime);
        }}
      />
    </>
  );
};

export default OpeningTimeEdit;
