import {
  CheckboxComponent,
  InputComponent,
  RetractableComponent,
} from "deinestadtliebt-component-library";
import { useTranslation } from "react-i18next";
import { Allergens } from "../../utils/user/User.types";
import "./MenuItemEdit.styles.scss";
import { MenuItemEditProps } from "./MenuItemEdit.types";

const MenuItemEdit: React.FC<MenuItemEditProps> = ({
  currentMenuItem,
  setCurrentMenuItem,
}) => {
  const { t } = useTranslation();

  /**
   * Helper to toggle content of allergens list for menu
   * @param allergen enum to toggle
   */
  const toggleAllgensList = (allergen: Allergens): void => {
    if (currentMenuItem.allergens.includes(allergen)) {
      setCurrentMenuItem({
        ...currentMenuItem,
        allergens: currentMenuItem.allergens.filter((ag) => allergen !== ag),
      });
    } else {
      currentMenuItem.allergens.push(allergen);
      setCurrentMenuItem({
        ...currentMenuItem,
      });
    }
  };

  return (
    <div className="menu-item-wrapper">
      <InputComponent
        value={currentMenuItem.name}
        placeholder={t("providerConfigurationPage.inputs.menuName")}
        onChange={(value) =>
          setCurrentMenuItem({
            ...currentMenuItem,
            name: value,
          })
        }
      />
      <InputComponent
        value={currentMenuItem.description}
        placeholder={t("providerConfigurationPage.inputs.description")}
        type="multiLine"
        onChange={(value) =>
          setCurrentMenuItem({
            ...currentMenuItem,
            description: value,
          })
        }
      />
      <InputComponent
        value={currentMenuItem.price}
        type="number"
        step="0.01"
        onChange={(value) =>
          setCurrentMenuItem({
            ...currentMenuItem,
            price: parseFloat(value),
          })
        }
        decimals={2}
        placeholder={t("providerConfigurationPage.inputs.priceOptional")}
      />
      <RetractableComponent
        title={`${t("providerConfigurationPage.allergens")} (${
          currentMenuItem.allergens.length
        })`}
        type="border"
      >
        <div className="flex-it-wrap">
          {Object.values(Allergens).map((allergen, allergenIndex) => (
            <CheckboxComponent
              key={`allergen-check-${allergenIndex}`}
              checked={currentMenuItem.allergens.includes(allergen)}
              onCheck={() => toggleAllgensList(allergen)}
              value={t(`enum.allergens.${allergen}`)}
            />
          ))}
        </div>

        <p>{t("providerConfigurationPage.allergenNotFound")}</p>
        <InputComponent
          value={currentMenuItem.specialAllergen}
          placeholder={t(
            "providerConfigurationPage.inputs.allergenDescription"
          )}
          onChange={(value) =>
            setCurrentMenuItem({
              ...currentMenuItem,
              specialAllergen: value,
            })
          }
        />
      </RetractableComponent>
    </div>
  );
};

export default MenuItemEdit;
