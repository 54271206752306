import {
  ButtonComponent,
  DropdownOption,
  InfoComponent,
  InputComponent,
  SearchComponent,
  Tag,
  TagComponent,
} from "deinestadtliebt-component-library";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash.svg";
import { ReactComponent as LinkIcon } from "../../../assets/icons/externalLink.svg";
import { ConfigContext } from "../../../pages/App";
import {
  ActivityTag,
  ActivityType,
  DetailFilter,
  TargetAudience,
} from "../../../utils/activity/Activity.types";
import {
  checkCategoryForActivityTag,
  generateActivityDetailTagList,
  generateActivityTagList,
  generateSearchValueSuggestions,
  generateTargetAudienceTags,
  getActionTagList,
  getCorrectDetailTagListForMainTag,
  getEventTagList,
  getSeparateActivityLists,
  isActionTag,
  isEventTag,
} from "../../../utils/activity/ActivityUtils";
import { Category } from "../../../utils/user/User.types";
import { ActivityEditCompanion } from "../EventEditBoxComponent.types";

interface Step1Props {
  isDesktop: boolean;
  activityCompanion: ActivityEditCompanion;
  setactivityCompanion(activityCompanion: ActivityEditCompanion): void;
  stepCompletion?: boolean;
  isEdit?: boolean;
}

const ActivityStep1: React.FC<Step1Props> = ({
  isDesktop,
  activityCompanion,
  setactivityCompanion,
  stepCompletion,
  isEdit,
}) => {
  const { appConfig } = useContext(ConfigContext);
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState<string>("");
  const [customTagValue, setCustomTagValue] = useState<string>("");
  const combinedTags: Tag[] = generateActivityTagList([
    ...getSeparateActivityLists(ActivityType.ACTION),
    ...getSeparateActivityLists(ActivityType.EVENT),
  ]);
  const [searchValueSuggestions, setSearchValueSuggestions] = useState<
    DropdownOption[]
  >([]);
  const [availableSearchEntries, setAvailableSearchEntries] = useState<Tag[]>(
    []
  );

  /**
   * useEffect to initially set available entries for search-component
   */
  useEffect(() => {
    switch (true) {
      case isEdit && activityCompanion.activityType === ActivityType.ACTION:
        setAvailableSearchEntries(generateActivityTagList(getActionTagList()));
        break;

      case isEdit && activityCompanion.activityType === ActivityType.EVENT:
        setAvailableSearchEntries(generateActivityTagList(getEventTagList()));
        break;
      default:
        setAvailableSearchEntries(combinedTags);
    }

    if (!!activityCompanion.mainTag)
      setSearchValue(t(`enum.activityTags.${activityCompanion.mainTag}`));
    //eslint-disable-next-line
  }, []);

  /**
   * Trigger appropriate search or reset depending on current input in search field
   */
  useEffect(() => {
    if (searchValue.length < 1 || !searchValue) {
      setSearchValueSuggestions([]);
      setactivityCompanion({ ...activityCompanion, mainTag: undefined });
    } else {
      //Logic to create correct SearchSuggestions
      setSearchValueSuggestions(
        generateSearchValueSuggestions(searchValue, availableSearchEntries)
      );
    }
    //eslint-disable-next-line
  }, [searchValue]);

  /**
   * Helper to set activityType on activityCompanion when mainTag is selected
   */
  useEffect(() => {
    if (isActionTag(activityCompanion.mainTag!))
      setactivityCompanion({
        ...activityCompanion,
        activityType: ActivityType.ACTION,
      });
    else if (isEventTag(activityCompanion.mainTag!))
      setactivityCompanion({
        ...activityCompanion,
        activityType: ActivityType.EVENT,
      });
    else
      setactivityCompanion({ ...activityCompanion, activityType: undefined });
    //eslint-disable-next-line
  }, [activityCompanion.mainTag]);

  /**
   * Helper to handle tag-selection and assign correct ActivityTag on select
   * @param value value of tag selected
   */
  const handleTagSelect = (value?: string): void => {
    if (!value) return;
    let isSameCat: boolean =
      getCorrectDetailTagListForMainTag(value as ActivityTag) ===
      getCorrectDetailTagListForMainTag(activityCompanion.mainTag!);
    const fullList = getEventTagList().concat(getActionTagList());
    const selectedFullTag: ActivityTag = fullList.filter(
      (tag) => t(`enum.activityTags.${tag}`) === value
    )[0];
    setactivityCompanion({
      ...activityCompanion,
      mainTag: selectedFullTag,
      detailTags: isSameCat ? activityCompanion.detailTags : [],

      category: checkCategoryForActivityTag(selectedFullTag) as Category,
    });
  };

  /**
   * Helper to add customTags to customTags-array
   */
  const addCustomTag = (): void => {
    if (customTagValue.trim() === "" || !customTagValue) return;
    let localTagList = [...activityCompanion.customTags];
    localTagList.push(customTagValue.trim());
    setactivityCompanion({ ...activityCompanion, customTags: localTagList });
    setCustomTagValue("");
  };

  /**
   * Helper to delete customTags from customTags-array
   * @param tagIndex index of tag to be deleted
   */
  const removeCustomTag = (tagIndex: number): void => {
    let localTagList = [...activityCompanion.customTags];
    localTagList.splice(tagIndex, 1);
    setactivityCompanion({ ...activityCompanion, customTags: localTagList });
  };

  /**
   * Helper to generate entries for customTags
   * @returns JSX.Element containing name of customTag and Icon to delete entry
   */
  const generateCustomTagEntries = (): JSX.Element[] => {
    let createdEntries: JSX.Element[] = [];
    activityCompanion.customTags.forEach((cTag, tagIndex) => {
      createdEntries.push(
        <div className="table-tags">
          <p>{cTag}</p>
          <TrashIcon onClick={() => removeCustomTag(tagIndex)} />
        </div>
      );
    });
    return createdEntries;
  };

  return (
    <>
      {isDesktop && <h2>{t("adminEventPage.event.steps.step2Title")}</h2>}
      <div
        className={[
          "main-tag-selection",
          !stepCompletion ? "incomplete-flag" : undefined,
        ].join(" ")}
      >
        <div className="flex-it">
          <SearchComponent
            placeholder={t("adminEventPage.event.steps.step2.searchText")}
            value={searchValue}
            setValue={(value) => setSearchValue(value)}
            onClick={(value) => handleTagSelect(value)}
            dropdownSearch
            dropdownSearchSuggestions={searchValueSuggestions}
          />
          {appConfig?.categoryHelpLink && appConfig.categoryHelpLink !== "" && (
            <InfoComponent
              infoText={[
                <a
                  href={appConfig.categoryHelpLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("adminEventPage.event.steps.step2.helpLink")}{" "}
                  <LinkIcon width={15} />
                </a>,
              ]}
            />
          )}
        </div>
      </div>
      <div className="target-audience--selection">
        <h3>{t("adminEventPage.event.steps.step2.targetAudienceTitle")}</h3>
        <TagComponent
          selected={activityCompanion.targetAudiences}
          tags={generateTargetAudienceTags()}
          onChange={(tAs) =>
            setactivityCompanion({
              ...activityCompanion,
              targetAudiences: tAs as TargetAudience[],
            })
          }
        />
      </div>
      {getCorrectDetailTagListForMainTag(activityCompanion.mainTag!).length >
        0 && (
        <>
          <div
            className={"detail-tag--selection"}
            key={`tag-selection--${activityCompanion.mainTag}`}
          >
            <h3>{t("adminEventPage.event.steps.step2.detailTagSelect")}</h3>
            <TagComponent
              selected={activityCompanion.detailTags}
              tags={generateActivityDetailTagList(
                getCorrectDetailTagListForMainTag(activityCompanion.mainTag!)
              )}
              onChange={(selectedDetailTags) => {
                setactivityCompanion({
                  ...activityCompanion,
                  detailTags: selectedDetailTags as DetailFilter[],
                });
              }}
            />
          </div>
        </>
      )}
      <div className="no-detail-tag--selection">
        <h3>{t("adminEventPage.event.steps.step2.noFittingDetailTags")}</h3>
        <div className="selection">
          <InputComponent
            value={customTagValue}
            onChange={setCustomTagValue}
            placeholder={t(
              "adminEventPage.event.steps.step2.noFittingDetailTagsPlaceholder"
            )}
          />
          <ButtonComponent
            disabled={customTagValue.trim() === "" || !customTagValue}
            value={t("adminEventPage.event.steps.step4.add")}
            onClick={() => addCustomTag()}
          />
        </div>
        <div
          className="table"
          key={`custom-list--${activityCompanion.customTags.length ?? ""}`}
        >
          {generateCustomTagEntries()}
        </div>
      </div>
    </>
  );
};
export default ActivityStep1;
