import {
  ButtonComponent,
  InputComponent,
} from "deinestadtliebt-component-library";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PriceItem } from "../../utils/user/User.types";
import { PriceItemEditProps } from "./PriceItem.types";
import "./PriceItemEdit.styles.scss";

const PriceItemEdit: React.FC<PriceItemEditProps> = ({
  onSave,
  priceItemToEdit,
  isLoading,
}) => {
  const { t } = useTranslation();
  const [localPriceItemToEdit, setLocalPriceItemToEdit] =
    useState<PriceItem>(priceItemToEdit);

  return (
    <div className="price-category-wrapper">
      <p>{t("providerConfigurationPage.priceCategoryCreate")}</p>
      <InputComponent
        value={localPriceItemToEdit.name}
        onChange={(value) =>
          setLocalPriceItemToEdit({ ...localPriceItemToEdit, name: value })
        }
        placeholder={t("providerConfigurationPage.inputs.priceCategoryName")}
        isFrameless
        signCap={30}
      />
      <div className="price-input">
        <InputComponent
          value={
            localPriceItemToEdit.price !== 0 ? localPriceItemToEdit.price : ""
          }
          step="0.01"
          onChange={(value) =>
            setLocalPriceItemToEdit({
              ...localPriceItemToEdit,
              price: parseFloat(value),
            })
          }
          type="number"
          decimals={2}
          placeholder={t("providerConfigurationPage.inputs.price")}
          isFrameless
        />
        <p className="price-input-info">€</p>
      </div>

      <InputComponent
        value={localPriceItemToEdit.description}
        onChange={(value) =>
          setLocalPriceItemToEdit({
            ...localPriceItemToEdit,
            description: value,
          })
        }
        placeholder={t(
          "providerConfigurationPage.inputs.priceCategoryDescritpion"
        )}
        type="multiLine"
      />
      <ButtonComponent
        isLoading={isLoading}
        disabled={!localPriceItemToEdit.name}
        value={t("providerConfigurationPage.buttons.add")}
        onClick={() => onSave(localPriceItemToEdit)}
      />
    </div>
  );
};

export default PriceItemEdit;
