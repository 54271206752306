import {
  AdminLayoutComponent,
  RetractableComponent,
} from "deinestadtliebt-component-library";
import { CurrentPage } from "../../utils/navigation/Navigation.types";
import { useNavLayout } from "../../utils/navigation/NavigationUtils";
import { ReactComponent as ImagesIcon } from "../../assets/icons/images.svg";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { ConfigContext } from "../App";

interface AdminVersionPageProps {}

const AdminVersionPage: React.FC<AdminVersionPageProps> = () => {
  const { t } = useTranslation();
  const { appConfig } = useContext(ConfigContext);

  return (
    <AdminLayoutComponent {...useNavLayout(CurrentPage.ADMIN_VERSION)}>
      <div
        style={{ background: appConfig?.highlightColor }}
        className="default-page-headline"
      >
        <h1>Version</h1>
      </div>

      <div className="admin-configuration-wrapper default-page-wrapper">
        <RetractableComponent
          title={t("adminVersionPage.versionTitle")}
          type="border"
          icon={<ImagesIcon />}
        >
          <p>
            <b>{t("adminVersionPage.versionTitle")}:</b> {t("general.version")}
          </p>
        </RetractableComponent>
      </div>
    </AdminLayoutComponent>
  );
};

export default AdminVersionPage;
