import {
  ButtonComponent,
  CheckboxComponent,
  InputComponent,
} from "deinestadtliebt-component-library";
import { useTranslation } from "react-i18next";
import { SignupBoxComponentProps } from "./SignupComponent.types";

import { Category } from "../../utils/user/User.types";
import NewOpeningTimeBox from "../newopeningtimebox/NewOpeningTimeBox";
import { Link } from "react-router-dom";

const SignupBoxComponent: React.FC<SignupBoxComponentProps> = ({
  index,
  radioRangeNumber,
  newProvider,
  setNewProvider,
  localCategory,
  setLocalCategory,
  isAgbAccepted = false,
  toggleAgbAccepted,
  isLoading,
  isProviderReady,
  getProviderReadyAndCreate = () => {},
  toggleOpeningTimesValidState,
}) => {
  const isDesktop: boolean = radioRangeNumber !== 0 && !!radioRangeNumber;
  const { t } = useTranslation();

  return (
    <>
      {index === 1 && (
        <div className="selected-content">
          {isDesktop && (
            <p className="desktop-description-wrapper">
              {t("newSignup.nameAndLocation.description")}
            </p>
          )}
          <p>{t("newSignup.nameAndLocation.companyName")}</p>
          <InputComponent
            isFrameless
            value={newProvider.name}
            onChange={(value) =>
              setNewProvider({ ...newProvider, name: value })
            }
          />
          <p>{t("newSignup.nameAndLocation.street")}</p>
          <InputComponent
            isFrameless
            value={newProvider.location.street}
            onChange={(value) =>
              setNewProvider({
                ...newProvider,
                location: {
                  ...newProvider.location,
                  street: value,
                },
              })
            }
          />
          <div className="flex-it">
            <div className="split-width">
              <p>{t("newSignup.nameAndLocation.zipCode")}</p>
              <InputComponent
                isFrameless
                type="number"
                value={newProvider.location.zipCode}
                onChange={(value) =>
                  setNewProvider({
                    ...newProvider,
                    location: {
                      ...newProvider.location,
                      zipCode: value,
                    },
                  })
                }
              />
            </div>
            <div className="split-width">
              <p>{t("newSignup.nameAndLocation.city")}</p>
              <InputComponent
                isFrameless
                value={newProvider.location.city}
                onChange={(value) =>
                  setNewProvider({
                    ...newProvider,
                    location: {
                      ...newProvider.location,
                      city: value,
                    },
                  })
                }
              />
            </div>
          </div>
        </div>
      )}

      {index === 2 && (
        <div className="selected-content">
          {isDesktop && (
            <p className="desktop-description-wrapper">
              {t("newSignup.contact.description")}
            </p>
          )}

          <div className="flex-it">
            <div className="split-width">
              <p>{t("newSignup.contact.firstname")}</p>
              <InputComponent
                isFrameless
                value={newProvider.firstname}
                onChange={(value) =>
                  setNewProvider({
                    ...newProvider,
                    firstname: value,
                  })
                }
              />
            </div>
            <div className="split-width">
              <p>{t("newSignup.contact.lastname")}</p>
              <InputComponent
                isFrameless
                value={newProvider.lastname}
                onChange={(value) =>
                  setNewProvider({
                    ...newProvider,
                    lastname: value,
                  })
                }
              />
            </div>
          </div>

          <p>{t("newSignup.contact.mail")}</p>
          <InputComponent
            isFrameless
            value={newProvider.mail}
            onChange={(value) =>
              setNewProvider({
                ...newProvider,
                mail: value,
              })
            }
          />

          <p>{t("newSignup.contact.phone")}</p>
          <InputComponent
            isFrameless
            type="tel"
            value={newProvider.phoneNumber}
            onChange={(value) =>
              setNewProvider({
                ...newProvider,
                phoneNumber: value,
              })
            }
          />
        </div>
      )}

      {index === 3 && (
        <div className="selected-content">
          {isDesktop && (
            <p className="desktop-description-wrapper">
              {t("newSignup.openingTime.description")}
            </p>
          )}
          <NewOpeningTimeBox
            onValidChanged={toggleOpeningTimesValidState}
            register
            standard
            timeCollection={newProvider.openingHours?.standard}
            setTimeCollection={(standard) => {
              setNewProvider({
                ...newProvider,
                openingHours: { ...newProvider.openingHours, standard },
              });
            }}
          />
        </div>
      )}

      {index === 4 && (
        <div className="selected-content">
          {isDesktop && (
            <p className="desktop-description-wrapper">
              {t("newSignup.category.description")}
            </p>
          )}

          {Object.values(Category).map((category) => (
            <div key={`${category}`} className="category-selector-wrapper">
              <div className="category-selector-wrapper--input">
                <input
                  type="radio"
                  checked={localCategory === category}
                  name={`category-selector-${radioRangeNumber}`}
                  onClick={() => setLocalCategory(category)}
                />
              </div>
              <div className="category-selector-wrapper--text">
                <div className="category-selector-wrapper--text-header">
                  {t(`enum.category.${category}`)}
                </div>
                <div className="category-selector-wrapper--text-text">
                  {t(`enum.categoryDescription.${category}`)}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {index === 5 && (
        <div className="selected-content">
          {isDesktop && (
            <p className="desktop-description-wrapper">
              {t("newSignup.wrap.description")}
            </p>
          )}
          <div className="agb-wrapper">
            <CheckboxComponent
              checked={isAgbAccepted}
              onCheck={() => {
                toggleAgbAccepted(!isAgbAccepted);
              }}
              value=""
            />
            <p>
              <Link target="_blank" to="/agb">
                {t("newSignup.acceptAGB")}
              </Link>
            </p>
          </div>
          <ButtonComponent
            value={t("newSignup.finish")}
            isLoading={isLoading}
            disabled={!isAgbAccepted || !isProviderReady}
            type="button"
            onClick={getProviderReadyAndCreate}
            colors={{ default: { bgColor: "black", fontColor: "white" } }}
          />
        </div>
      )}
    </>
  );
};

export default SignupBoxComponent;
