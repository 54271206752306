import { AxiosInstance } from "axios";
import {
  generateNotification,
  NotificationTypes,
  TableRow,
} from "deinestadtliebt-component-library";
import { Action } from "./Action.types";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import QRCode from "react-qr-code";
import i18n from "../../i18n";
import { saveBlobInFile } from "../user/UserUtils";
import { renderToString } from "react-dom/server";
import { generateTimeStringForDate } from "../time/TimeUtils";
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";
import { ReactComponent as CopyIcon } from "../../assets/icons/copy.svg";
import { IdName } from "../user/User.types";

/**
 * API METHOD - to fetch all actions by a given provider id
 * @param axios network instance
 * @param providerId id to fetch all actions for
 * @returns list of all found actions or an empty array
 */
export const fetchAllActionsForProvider = async (
  axios: AxiosInstance,
  providerId: string
): Promise<Action[]> => {
  return axios
    .get("/event/action/provider/all/", { params: { id: providerId } })
    .then((actionResp) => actionResp.data)
    .catch((exc) => {
      console.error("Error during loading all actions for provider!", exc);
      return [];
    });
};

/**
 * API METHOD - to fetch all actions
 * @param axios network instance
 * @returns list of all found actions or an empty array
 */
export const fetchAllActions = async (
  axios: AxiosInstance
): Promise<Action[]> => {
  return axios
    .get("/event/action/all/")
    .then((actionResp) => actionResp.data)
    .catch((exc) => {
      console.error("Error during loading all actions!", exc);
      return [];
    });
};

/**
 * API METHOD - loads the current Action with the given slugId from the server
 *
 * @param slugId slugId of the requested Action
 * @returns Action
 */
export const getActionBySlugId = (
  slugId: string,
  axios: AxiosInstance
): Promise<Action> => {
  return axios
    .get(`/event/action/id/`, {
      params: { id: slugId },
    })
    .then((loadedAction) => loadedAction.data)
    .catch(() => {
      console.error("error during fetch of Action");
      return null;
    });
};

/**
 * API METHOD - to create an action with optional image
 * @param axios network instance
 * @param action action to create on the backend
 * @param actionImage optional image for uploading
 * @param actionFlyer optional flyer for uploading
 * @returns created action or null otherwise
 */
export const createAction = async (
  axios: AxiosInstance,
  action: Action,
  actionImage?: File,
  flyerFile?: File,
  currentImagePreviewFile?: File
): Promise<Action> => {
  let uploadFormData = new FormData();
  const eventBlob = new Blob([JSON.stringify(action)], {
    type: "application/json",
  });
  uploadFormData.append("newAction", eventBlob);
  if (actionImage) uploadFormData.append("fileData", actionImage);
  if (flyerFile) uploadFormData.append("flyerData", flyerFile);
  if (currentImagePreviewFile)
    uploadFormData.append("previewImage", currentImagePreviewFile);
  return axios
    .post("/event/action/", uploadFormData)
    .then((eventResp) => eventResp.data)
    .catch((exc) => {
      console.error("Error during uploading/creating new action!", exc);

      switch (true) {
        case exc.response && exc.response.status === 412:
          generateNotification(
            NotificationTypes.WARNING,
            i18n.t("notification.title.warning.licence"),
            i18n.t("notification.content.warning.licence")
          );
          break;

        default:
          generateNotification(
            NotificationTypes.ERROR,
            i18n.t("notification.title.error.error"),
            i18n.t("notification.content.error.actionCreate")
          );
          break;
      }

      return null;
    });
};

/**
 * API METHOD - to update an action with optional image
 * @param axios network instance
 * @param action action to create on the backend
 * @param actionImage optional image for uploading
 * @param actionFlyer optional flyer for uploading
 * @returns created action or null otherwise
 */
export const updateAction = async (
  axios: AxiosInstance,
  action: Action,
  actionImage?: File,
  flyerFile?: File,
  currentImagePreviewFile?: File
): Promise<Action> => {
  let uploadFormData = new FormData();
  const eventBlob = new Blob([JSON.stringify(action)], {
    type: "application/json",
  });
  uploadFormData.append("updateAction", eventBlob);
  if (actionImage) uploadFormData.append("fileData", actionImage);
  if (flyerFile) uploadFormData.append("flyerData", flyerFile);
  if (currentImagePreviewFile)
    uploadFormData.append("previewImage", currentImagePreviewFile);
  return axios
    .post("/event/action/update/", uploadFormData)
    .then((eventResp) => eventResp.data)
    .catch((exc) => {
      console.error("Error during updating of action!", exc);
      return null;
    });
};

/**
 * API METHOD - to copy an {@link Action} on the backend
 * @param axios network instance
 * @param action data to copy
 * @returns null in case of error and copyied {@link Action} in body on success
 */
export const copyAction = async (
  axios: AxiosInstance,
  action: Action
): Promise<Action> => {
  return axios
    .post("/event/action/copy/", action)
    .then((opyResp) => opyResp.data)
    .catch((exc) => {
      console.error("Error during copying of action!", exc);
      return null;
    });
};

/**
 * API METHOD - deletes an action by idon the backend
 * @param axios network instance
 * @param actionId id to delete
 * @returns boolean is returned if status code 200 is recieved
 */
export const deleteAction = async (
  axios: AxiosInstance,
  actionId: string
): Promise<boolean> => {
  return axios
    .post("/event/action/delete/", actionId)
    .then((actionResp) => actionResp.status === 200)
    .catch((exc) => {
      console.error("Error during deleting action!", exc);
      return false;
    });
};

/**
 * Helper to extract the correct provider name from a
 * id name map. Empty string for provider login.
 * @param mapIdAndName source map of all needed data
 * @param id id of provider
 * @returns found name in array
 * @tested
 */
export const getProviderNameFroIdNameMap = (
  mapIdAndName: IdName[],
  id: string
): string => {
  let currentProviderName: string = "";
  if (!!mapIdAndName) {
    let filteredList: IdName[] = mapIdAndName.filter(
      (entry) => entry.id === id
    );
    if (filteredList.length === 1) currentProviderName = filteredList[0].name;
    else currentProviderName = "-";
  }
  return currentProviderName;
};

/**
 *
 * @param actions actionlist to work on
 * @param onDelete method to delete the current action from backend and local
 * @param showOld boolean to determine if old actions should be displayed
 * @returns created table row entries
 * @tested
 */
export const generateActionTableRows = (
  actions: Action[],
  onDelete: Function,
  onDetail: Function,
  onCopy: Function,
  showOld: boolean,
  isAdmin: boolean,
  mapIdAndName?: IdName[]
): TableRow[] => {
  let currentRows: TableRow[] = [];
  actions
    .filter((item) => {
      if (showOld) return true;
      else if (item.endDate)
        return (
          new Date(item.endDate) >=
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate(),
            0,
            0,
            0,
            0
          )
        );
      // the item is not old or start date is not set
      else return false;
    })
    .forEach((action) => {
      let currentProviderName: string = "";
      if (!!mapIdAndName) {
        currentProviderName = getProviderNameFroIdNameMap(
          mapIdAndName,
          action.providerId
        );
      }
      let convertedProviderName: string[] = !!currentProviderName
        ? [currentProviderName]
        : [];

      currentRows.push({
        content: [
          ...convertedProviderName,
          ...[
            action.name,
            `${generateTimeStringForDate(
              action.startDate
            )} - ${generateTimeStringForDate(action.endDate)}`,
            `${action.startTime} - ${action.endTime}`,
            generateTimeStringForDate(
              action.showDate,
              i18n.t("providerActionsConfigurationPage.showDateNow")
            ),
            <div
              className="trash-wrapper black-color"
              onClick={() =>
                saveBlobInFile(
                  new Blob(
                    [
                      renderToString(
                        <QRCode
                          value={`${process.env.REACT_APP_CLIENT_URL!}/${
                            action.providerId
                          }/action/${action.slugId}`}
                        />
                      ),
                    ],
                    { type: "image/svg+xml" }
                  ),
                  `${action.slugId}.svg`
                )
              }
            >
              <QRCode
                size={20}
                value={`${process.env.REACT_APP_CLIENT_URL!}/${action.slugId}`}
              />
            </div>,
            <div className="flex-it-center">
              <div
                className={["trash-wrapper", "little-bit-margin-right"].join(
                  " "
                )}
                onClick={() => onDetail(action)}
              >
                <EditIcon />
              </div>
              {isAdmin || (
                <div
                  className={["trash-wrapper", "little-bit-margin-right"].join(
                    " "
                  )}
                  onClick={() => onCopy(action)}
                >
                  <CopyIcon />
                </div>
              )}
              <div
                className="trash-wrapper"
                onClick={() => onDelete(action.id)}
              >
                <TrashIcon />
              </div>
            </div>,
          ],
        ],
        id: action.id!,
      });
    });
  return currentRows;
};
