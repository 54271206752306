import { AxiosInstance } from "axios";
import {
  ButtonComponent,
  CheckboxComponent,
  generateNotification,
  NotificationTypes,
  TableRow,
} from "deinestadtliebt-component-library";
import i18next from "i18next";
import { SlideItem } from "../../components/SlideShowComponent/SlideShowComponent.types";
import i18n from "../../i18n";
import { validateUrl } from "../InputUtils";
import { Category, Provider } from "../user/User.types";
import {
  AppConfig,
  FileType,
  GoodToKnow,
  GoodToKnowType,
  SlideshowItem,
  SocialMedia,
} from "./config.types";

/**
 * API METHOD - fetches UserConfig from server
 *
 * @param axios
 * @returns UserConfig from Server
 */
export const getAppConfig = async (
  axios: AxiosInstance
): Promise<AppConfig> => {
  return axios
    .get("/config/admin/")
    .then((appConfig) => appConfig.data)
    .catch((error) => console.error("Error during fetching AppConfig!", error));
};

/**
 * API Method - to create new custom good to know item
 * @param axios  - axios instance
 * @param newGoodToKnowItem - the new custom good to know item
 * @returns
 */
export const createCustomGoodToKnowItem = (
  axios: AxiosInstance,
  newGoodToKnowItem: GoodToKnow
): Promise<boolean> => {
  return axios
    .post("/config/gtk/new/", newGoodToKnowItem)
    .then((gtkResponse) => gtkResponse.status === 201)
    .catch((exc) => {
      console.error("Error during adding new custom good to know item!", exc);
      return false;
    });
};

/**
 * API METHOD - generates a slidwshow item on the server
 *
 * @param axios network instance
 * @param slideshowItem item to generate
 * @param slideshowImage image to append
 * @returns uploaded data or null
 */
export const createSlideshowItem = async (
  axios: AxiosInstance,
  slideshowItem: SlideshowItem,
  slideshowImage: File,
  mobileSlideshowImage?: File
): Promise<SlideshowItem> => {
  let uploadFormData = new FormData();
  const slideshowItemBlob = new Blob([JSON.stringify(slideshowItem)], {
    type: "application/json",
  });
  uploadFormData.append("slideshowItem", slideshowItemBlob);
  uploadFormData.append("fileData", slideshowImage);
  if (mobileSlideshowImage)
    uploadFormData.append("mobileFileData", mobileSlideshowImage);
  return axios
    .post("/config/slideshow/add/", uploadFormData)
    .then((imageUpload) => imageUpload.data)
    .catch((exc) => {
      console.error("Error during uploading/creating new slideshow item!", exc);
      return null;
    });
};

/**
 * API METHOD - to update the positions of the slideshow items
 * @param axios network instance
 * @param slideshowItemList list of the current slideshow items
 * @returns success state true if 200 is received false otherwise
 */
export const updateSlideshowPositions = async (
  axios: AxiosInstance,
  slideshowItemList: SlideshowItem[]
): Promise<boolean> => {
  return axios
    .post("/config/slideshow/update/position/", slideshowItemList)
    .then((slideShowResp) => slideShowResp.status === 200)
    .catch((exc) => {
      console.error("Error during updating position for slideshow items!", exc);
      return false;
    });
};

/**
 * API METHOD - to update slide show item on the server
 *
 * @param axios network instance
 * @param slideshowItem to update on the server
 * @param slideshowImage image to update if it is new
 * @param mobileSlideshowImage mobile image to update if it is new
 * @returns true if status code 200 is received
 */
export const updateSlideshowItem = async (
  axios: AxiosInstance,
  slideshowItem: SlideshowItem,
  slideshowImage?: File,
  mobileSlideshowImage?: File
): Promise<boolean> => {
  let uploadFormData = new FormData();
  const slideshowItemBlob = new Blob([JSON.stringify(slideshowItem)], {
    type: "application/json",
  });
  uploadFormData.append("slideshowItem", slideshowItemBlob);
  if (slideshowImage) uploadFormData.append("fileData", slideshowImage);
  if (mobileSlideshowImage)
    uploadFormData.append("mobileFileData", mobileSlideshowImage);
  return axios
    .post("/config/slideshow/update/", uploadFormData)
    .then((imageUpload) => imageUpload.status === 200)
    .catch((exc) => {
      console.error("Error during uploading/creating new slideshow item!", exc);
      return false;
    });
};

/**
 * API METHOD - to delete a slideshow item in the config
 * @param axios network instance
 * @param slideshowItem to delete on the server
 * @returns success for status code 200
 */
export const deleteSlideshowItem = async (
  axios: AxiosInstance,
  slideshowItem: SlideshowItem
): Promise<SlideshowItem[]> => {
  return axios
    .post("/config/slideshow/delete/", slideshowItem)
    .then((imageUpload) => imageUpload.data)
    .catch((exc) => {
      console.error("Error during deleting slideshow item!", exc);
      generateNotification(
        NotificationTypes.ERROR,
        i18next.t("notification.title.error.error"),
        i18next.t("notification.content.error.slideshowDeletion")
      );
      return [];
    });
};

/**
 * API METHOD - to update social media links for app
 *
 * @param axios
 * @param appConfig
 * @returns boolean for success
 */
export const updateSocialMedia = async (
  axios: AxiosInstance,
  appConfig: AppConfig
): Promise<boolean> => {
  return axios
    .post("/config/update/social/", appConfig)
    .then((socialConfigUpdate) => socialConfigUpdate.status === 200)
    .catch((exc) => {
      console.error("Error during updating social media links!", exc);
      return false;
    });
};

/**
 * API METHOD - to update given config
 *
 * @param axios network instance for communitcation
 * @param appConfig config to update on the server
 * @returns true if status 200 is recivied, false otherwise
 */
export const updateConfig = async (
  axios: AxiosInstance,
  appConfig: AppConfig
): Promise<boolean> => {
  return axios
    .post("/config/update/", appConfig)
    .then((configUpdate) => configUpdate.status === 200)
    .catch((exc) => {
      console.error("Error during updating config!", exc);
      return false;
    });
};

/**
 * Helper to determine if all given social media inputs are valid
 *
 * @param socialMedia items for an admin
 * @returns boolean
 * @tested
 */
export const validateSocialMedia = (socialMedia: SocialMedia): boolean => {
  return (
    (!!socialMedia.facebook && validateUrl(socialMedia.facebook)) ||
    (!!socialMedia.instagram && validateUrl(socialMedia.instagram)) ||
    (!!socialMedia.linkedIn && validateUrl(socialMedia.linkedIn)) ||
    (!!socialMedia.whatsapp && validateUrl(socialMedia.whatsapp)) ||
    (!!socialMedia.youtube && validateUrl(socialMedia.youtube))
  );
};

/**
 * Helper to generate SlideItems for Index slideshow
 *
 * @param items array of SlideShowItem
 * @returns generated list for SlideItems in Slideshow
 * @tested
 */
export const generateSlideItems = (items: SlideshowItem[]): SlideItem[] => {
  let returnArray: SlideItem[] = [];
  items.forEach((slideshowItem) =>
    returnArray.push({
      text:
        slideshowItem.header ||
        slideshowItem.subText ||
        slideshowItem.detailDescription ? (
          <>
            {slideshowItem.header && (
              <h1 className="slideshow-headline-1">{slideshowItem.header}</h1>
            )}
            {slideshowItem.subText && (
              <h2 className="slideshow-headline-2">{slideshowItem.subText}</h2>
            )}
            {slideshowItem.detailDescription && (
              <ButtonComponent
                className="slideshow-item-buttton-wrapper"
                onClick={() => {
                  if (typeof window !== "undefined")
                    window.open(slideshowItem.link || "#", "_blank")!.focus();
                }}
                isFrameless
                value={slideshowItem.detailDescription}
              />
            )}
          </>
        ) : undefined,
      image: `${process.env.REACT_APP_SERVICE_URL}/config/file/?fileName=${slideshowItem.fileName}&type=${FileType.SLIDESHOW_IMAGE}`,
      mobileImage: slideshowItem.mobileFileName
        ? `${process.env.REACT_APP_SERVICE_URL}/config/file/?fileName=${slideshowItem.mobileFileName}&type=${FileType.SLIDESHOW_IMAGE}`
        : undefined,
      reverseContentDirection: slideshowItem.direction,
    })
  );
  return returnArray;
};

/**
 * Helper to generate the selection table for a provider
 *
 * @param appConfig config to validate
 * @param provider optional if not admin
 * @param toggleGoodToKnowItem Function to toggle onclick for provider
 * @returns generated table row list
 * @tested
 */
export const generateGoodToKnowTable = (
  appConfig: AppConfig,
  provider?: Provider,
  toggleGoodToKnowItem?: Function
): TableRow[] => {
  let returnTarget: TableRow[] = [];
  let toUseGoodToKnowList: GoodToKnowType[] = [];
  if (provider)
    toUseGoodToKnowList = getCorrectListOfGoodToKnowItems(provider.category);
  appConfig.goodToKnow
    ?.filter((item) => {
      let additionBoolean: boolean = true;
      if (item.type === GoodToKnowType.CUSTOM && !item.name) return false;
      if (provider) {
        additionBoolean =
          !!item.name ||
          (!!item.type && toUseGoodToKnowList.includes(item.type));
        return item.active && additionBoolean;
      }
      return true;
    })
    .forEach((item) => {
      let additionalProviderAdd: (string | JSX.Element)[] = [];
      if (!!provider)
        additionalProviderAdd.push(
          <CheckboxComponent
            checked={provider?.goodToKnowItems.includes(item.id!)}
            onCheck={() => toggleGoodToKnowItem?.(item.id!)}
            value=""
          />
        );

      returnTarget.push({
        id: item.id!,
        content: [
          !!item.type && item.type !== GoodToKnowType.CUSTOM
            ? i18n.t(`enum.goodToKnow.${item.type}`)
            : item.name || "-",
          item.active
            ? i18n.t("providerConfigurationPage.active")
            : i18n.t("providerConfigurationPage.inactive"),
          item.fixed ? i18n.t("providerConfigurationPage.fixed") : "-",
          ...additionalProviderAdd,
        ],
      });
    });
  return returnTarget;
};

/**
 * Helper to return which enums elements a provider is allowed to see
 * @param category main category for provider
 * @returns array of valid enums elements
 * @tested
 */
export const getCorrectListOfGoodToKnowItems = (
  category: Category
): GoodToKnowType[] => {
  switch (category) {
    case Category.EAT_DRINK:
      return [
        GoodToKnowType.RESERVATION,
        GoodToKnowType.SMOKER,
        GoodToKnowType.NON_SMOKER,
        GoodToKnowType.ANIMALS,
        GoodToKnowType.PICKUP,
        GoodToKnowType.MEETING_ROOMS,
        GoodToKnowType.RENT,
        GoodToKnowType.OUTSIDE_SITTING,
        GoodToKnowType.DELIVERY,
        GoodToKnowType.BARRIER_FREE,
        GoodToKnowType.CUSTOMER_TOILET,
        GoodToKnowType.CHANGING_TABLE,
        GoodToKnowType.WIFI,
        GoodToKnowType.SHOPPING_VOUCHER,
        GoodToKnowType.CREDIT_CARD,
        GoodToKnowType.GIROCARD,
        GoodToKnowType.CUSTOMER_PARKING_SPOT,
        GoodToKnowType.CUSTOM,
      ];
    case Category.EXPERIENCE:
      return [
        GoodToKnowType.RESERVATION,
        GoodToKnowType.SMOKER,
        GoodToKnowType.NON_SMOKER,
        GoodToKnowType.ANIMALS,
        GoodToKnowType.BARRIER_FREE,
        GoodToKnowType.CUSTOMER_TOILET,
        GoodToKnowType.CHANGING_TABLE,
        GoodToKnowType.WIFI,
        GoodToKnowType.SHOPPING_VOUCHER,
        GoodToKnowType.CREDIT_CARD,
        GoodToKnowType.GIROCARD,
        GoodToKnowType.CUSTOMER_PARKING_SPOT,
        GoodToKnowType.CUSTOM,
      ];
    case Category.SHOPPING:
      return [
        GoodToKnowType.DELIVERY,
        GoodToKnowType.ORGANIC,
        GoodToKnowType.BARRIER_FREE,
        GoodToKnowType.CUSTOMER_TOILET,
        GoodToKnowType.CHANGING_TABLE,
        GoodToKnowType.WIFI,
        GoodToKnowType.SHOPPING_VOUCHER,
        GoodToKnowType.CREDIT_CARD,
        GoodToKnowType.GIROCARD,
        GoodToKnowType.CUSTOMER_PARKING_SPOT,
        GoodToKnowType.CUSTOM,
      ];
  }
};
