import { ClickBoxProps } from "./ClickBox.types";
import "./ClickBoxStyles.scss";
import { ReactComponent as ChevronRight } from "../../assets/icons/chevron-right.svg";

const ClickBox: React.FC<ClickBoxProps> = ({ onClick, text, icon }) => {
  return (
    <div className="click-box-wrapper" onClick={onClick}>
      {icon && (
        <div className="click-box-wrapper--item click-box-wrapper--item-first-icon">
          {icon}
        </div>
      )}
      <div className="click-box-wrapper--item click-box-wrapper--item-text">
        {text}
      </div>
      <div className="click-box-wrapper--item click-box-wrapper--item-chevron">
        <ChevronRight />
      </div>
    </div>
  );
};

export default ClickBox;
