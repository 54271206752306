import { AxiosInstance } from "axios";
import { createEmptyLegal, Legal } from "./Legal.types";

/**
 * Fetch latest adminclient terms from backend
 *
 * @param axios the axios instance used to send the request
 * @returns admin client terms
 */
export const fetchAdminClientTerms = (
  axios: AxiosInstance
): Promise<Legal["termsAdminClient"]> => {
  return axios
    .get("/config/legal/terms/", { params: { client: false } })
    .then((res) => res.data)
    .catch((error) =>
      console.error("Error during fetching adminclient terms!", error)
    );
};

/**
 * Fetch latest client terms from backend
 *
 * @param axios the axios instance used to send the request
 * @returns client terms
 */
export const fetchClientTerms = (
  axios: AxiosInstance
): Promise<Legal["termsClient"]> => {
  return axios
    .get("/config/legal/terms/", { params: { client: true } })
    .then((res) => res.data)
    .catch((error) =>
      console.error("Error during fetching client terms!", error)
    );
};

/**
 * Update client terms on backend
 *
 * @param content new terms to update
 * @param axios the axios instance used to send the request
 * @returns true if successful
 */
export const updateClientTerms = (
  content: Legal["termsClient"],
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/config/legal/terms/", {
      content,
      client: true,
    })
    .then((res) => res.status === 200)
    .catch((error) => {
      console.error("Error during updating client terms!", error);
      return false;
    });
};

/**
 * Update admin client terms on backend
 *
 * @param content new terms to update
 * @param axios the axios instance used to send the request
 * @returns true if successful
 */
export const updateAdminClientTerms = (
  content: Legal["termsAdminClient"],
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/config/legal/terms/", {
      content,
      client: false,
    })
    .then((res) => res.status === 200)
    .catch((error) => {
      console.error("Error during updating admin client terms!", error);
      return false;
    });
};

/**
 * Fetch latest adminclient privacy from backend
 *
 * @param axios the axios instance used to send the request
 * @returns admin client privacy
 */
export const fetchAdminClientPrivacy = (
  axios: AxiosInstance
): Promise<Legal["privacyAdminClient"]> => {
  return axios
    .get("/config/legal/privacy/", { params: { client: false } })
    .then((res) => res.data)
    .catch((error) =>
      console.error("Error during fetching adminclient privacy!", error)
    );
};

/**
 * Fetch latest client privacy from backend
 *
 * @param axios the axios instance used to send the request
 * @returns client privacy
 */
export const fetchClientPrivacy = (
  axios: AxiosInstance
): Promise<Legal["privacyClient"]> => {
  return axios
    .get("/config/legal/privacy/", { params: { client: true } })
    .then((res) => res.data)
    .catch((error) =>
      console.error("Error during fetching client privacy!", error)
    );
};

/**
 * Update adminclient privacy on backend
 *
 * @param legal new privacy to update
 * @param axios the axios instance used to send the request
 * @returns true if successful
 */
export const updateAdminClientPrivacy = (
  content: Legal["privacyAdminClient"],
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/config/legal/privacy/", {
      content,
      client: false,
    })
    .then((res) => res.status === 200)
    .catch((error) => {
      console.error("Error during updating adminclient privacy!", error);
      return false;
    });
};

/**
 * Update client privacy on backend
 *
 * @param content new privacy to update
 * @param axios the axios instance used to send the request
 * @returns true if successful
 */
export const updateClientPrivacy = (
  content: Legal["privacyClient"],
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/config/legal/privacy/", {
      content,
      client: true,
    })
    .then((res) => res.status === 200)
    .catch((error) => {
      console.error("Error during updating client privacy!", error);
      return false;
    });
};

/**
 * Fetch latest legals of client and adminclient from backend
 *
 * @param axios the axios instance used to send the request
 * @return {@link Legal} object with legals of client and adminclient
 */
export const fetchAllLegal = (axios: AxiosInstance): Promise<Legal> => {
  return axios
    .get("/config/legal/all/")
    .then((res) => {
      return {
        ...createEmptyLegal(),
        ...{
          termsClient: res.data.termsClient,
          termsAdminClient: res.data.termsAdminClient,
          privacyClient: res.data.privacyClient,
          privacyAdminClient: res.data.privacyAdminClient,
        },
      };
    })
    .catch((error) => {
      console.error("Error during fetching all legal!", error);
      return createEmptyLegal();
    });
};
