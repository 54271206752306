import "./JobEdit.styles.scss";
import { useTranslation } from "react-i18next";
import { JobEditProps } from "./JobEdit.types";
import { useState } from "react";
import { Job } from "../../utils/job/job.types";
import {
  ButtonComponent,
  FileInputComponent,
  InputComponent,
} from "deinestadtliebt-component-library";
import { FileType } from "../../utils/appConfig/config.types";
import WysiwygComponent from "../wysiwyg/WysiwygComponent";
import { convertToDateInputValue } from "../../utils/time/TimeUtils";

const JobEdit: React.FC<JobEditProps> = ({
  jobToEdit,
  onSave,
  providerId,
  providerSlugId,
}) => {
  const { t } = useTranslation();
  const [localJobToEdit, setLocalJobToEdit] = useState<Job>(jobToEdit);
  const [currentJobFile, setCurrentJobFile] = useState<File>();

  return (
    <>
      <p>{t("providerAdvertisementConfigurationPage.jobsInfo")}</p>
      <div className="flex-it-between configuration-data-wrapper">
        <div className="half-the-width">
          <InputComponent
            isFrameless
            fontSize="13px"
            placeholder={t(
              "providerAdvertisementConfigurationPage.inputs.title"
            )}
            value={localJobToEdit.name}
            onChange={(value) =>
              setLocalJobToEdit({ ...localJobToEdit, name: value })
            }
          />
          <InputComponent
            isFrameless
            fontSize="13px"
            placeholder={t(
              "providerAdvertisementConfigurationPage.inputs.mail"
            )}
            type="email"
            value={localJobToEdit.mail}
            onChange={(value) =>
              setLocalJobToEdit({ ...localJobToEdit, mail: value })
            }
          />
          <InputComponent
            isFrameless
            fontSize="13px"
            placeholder={t(
              "providerAdvertisementConfigurationPage.inputs.phone"
            )}
            type="tel"
            value={localJobToEdit.phone}
            onChange={(value) =>
              setLocalJobToEdit({ ...localJobToEdit, phone: value })
            }
          />
          <InputComponent
            fontSize="13px"
            placeholder={t(
              "providerAdvertisementConfigurationPage.inputs.link"
            )}
            value={localJobToEdit.link}
            onChange={(value) =>
              setLocalJobToEdit({ ...localJobToEdit, link: value })
            }
            isFrameless
          />
          <div className="flex-it">
            <InputComponent
              fontSize="13px"
              isFrameless
              type="date"
              placeholder={t(
                "providerAdvertisementConfigurationPage.inputs.start"
              )}
              value={convertToDateInputValue(localJobToEdit.startDate)}
              onChange={(value) =>
                value
                  ? setLocalJobToEdit({
                      ...localJobToEdit,
                      startDate: new Date(value),
                    })
                  : ""
              }
            />
            <InputComponent
              fontSize="13px"
              isFrameless
              type="date"
              placeholder={t(
                "providerAdvertisementConfigurationPage.inputs.end"
              )}
              value={convertToDateInputValue(localJobToEdit.endDate)}
              onChange={(value) =>
                value
                  ? setLocalJobToEdit({
                      ...localJobToEdit,
                      endDate: new Date(value),
                    })
                  : ""
              }
            />
          </div>
        </div>
        <div className="half-the-width">
          <p>{t("providerAdvertisementConfigurationPage.jobsImageInfo")}</p>
          <FileInputComponent
            cropTopText={t(
              "providerAdvertisementConfigurationPage.ratioDescription"
            )}
            aspectHeight={300}
            aspectWidth={400}
            fileType={FileType.JOB}
            type={"crop"}
            fileName={localJobToEdit.fileItem?.filename}
            initialFile={currentJobFile}
            onUpload={(image) => setCurrentJobFile(image)}
            popupSaveTitle={t(
              "providerAdvertisementConfigurationPage.buttons.send"
            )}
          />
        </div>
      </div>
      <WysiwygComponent
        providerId={providerId}
        signCap={2000}
        value={localJobToEdit.description}
        onChange={(value) =>
          setLocalJobToEdit({ ...localJobToEdit, description: value })
        }
        placeholder={t(
          "providerAdvertisementConfigurationPage.inputs.description"
        )}
      />
      <ButtonComponent
        disabled={!localJobToEdit.name}
        onClick={() =>
          onSave(
            { ...localJobToEdit, providerId: providerSlugId },
            currentJobFile
          )
        }
        value={t("providerAdvertisementConfigurationPage.buttons.send")}
      />
    </>
  );
};

export default JobEdit;
