import {
  CheckboxComponent,
  InputComponent,
  TableRow,
} from "deinestadtliebt-component-library";
import { Action } from "../action/Action.types";
import i18n from "../../i18n";
import { DayOfWeekArray, Event } from "../event/Event.types";
import { DateState } from "../activity/Activity.types";

/**
 * Helper to generate correct tableRows for delaying multiple dates
 * @param activity containg all required dates
 * @param adjustDateItemEntry method that is executed on Change
 * @returns TableRow[] containing all inputs and data
 * @tested
 */
export const generateDelayTableRows = (
  activity: Event | Action,
  adjustDateItemEntry: Function
): TableRow[] => {
  let generatedRows: TableRow[] = [];

  activity?.activityDates?.forEach((activityDate, index) => {
    generatedRows.push({
      content: [
        <div className="activity-date">
          {i18n
            .t(
              `enum.dayOfWeek.${
                DayOfWeekArray[new Date(activityDate.date).getDay()]
              }`
            )
            .slice(0, 2)}
          <InputComponent
            value={
              new Date(activityDate.date || new Date())?.toISOString() || ""
            }
            type="date"
            isFrameless
            onChange={(value) => {
              adjustDateItemEntry(value, index, "date");
            }}
          />
        </div>,
        <div className="activity-time">
          <InputComponent
            value={activityDate?.startTime || "00:00"}
            isFrameless
            type="time"
            onChange={(value) => {
              adjustDateItemEntry(value, index, "startTime");
            }}
          />
          -
          <InputComponent
            value={activityDate?.endTime || "00:00"}
            isFrameless
            type="time"
            onChange={(value) => {
              adjustDateItemEntry(value, index, "endTime");
            }}
          />
        </div>,
      ] as JSX.Element[],
      id: index.toString(),
    });
  });
  return generatedRows;
};

/**
 * Helper to generate correct tableRows for canceling multiple dates
 * @param activity containg all required dates
 * @param handleDateStateChange method that is executed on Change
 * @returns TableRow[] containing all inputs and data
 * @tested
 */
export const generateCancelTableRows = (
  activity: Event | Action,
  handleDateStateChange: Function
): TableRow[] => {
  let generatedRows: TableRow[] = [];
  activity?.activityDates?.forEach((activityDate, index) => {
    generatedRows.push({
      content: [
        <p>
          {i18n
            .t(
              `enum.dayOfWeek.${
                DayOfWeekArray[new Date(activityDate.date).getDay()]
              }`
            )
            .slice(0, 2)}
          {", "}
          {new Date(activityDate.date).toLocaleDateString("de")}
        </p>,
        <CheckboxComponent
          value={""}
          checked={
            !!activity?.activityDates &&
            activity.activityDates[index].state === DateState.CANCELLED
          }
          onCheck={() => {
            handleDateStateChange(index, DateState.CANCELLED);
          }}
        />,
      ],
      id: index.toString(),
    });
  });

  return generatedRows;
};

/**
 * Helper to generate correct tableRows for marking multiple dates as sold out
 * @param activity containg all required dates
 * @param handleDateStateChange method that is executed on Change
 * @returns TableRow[] containing all inputs and data
 * @tested
 */
export const generateSoldOutTableRows = (
  activity: Event | Action,
  handleDateStateChange: Function
): TableRow[] => {
  let generatedRows: TableRow[] = [];

  activity?.activityDates?.forEach((activityDate, index) => {
    generatedRows.push({
      content: [
        <p>
          {i18n
            .t(
              `enum.dayOfWeek.${
                DayOfWeekArray[new Date(activityDate.date).getDay()]
              }`
            )
            .slice(0, 2)}
          {", "}
          {new Date(activityDate.date).toLocaleDateString("de")}
        </p>,
        <CheckboxComponent
          value={""}
          checked={activity.activityDates[index].state === DateState.SOLD_OUT}
          onCheck={() => {
            handleDateStateChange(index, DateState.SOLD_OUT);
          }}
        />,
      ],
      id: index.toString(),
    });
  });
  return generatedRows;
};
