import { useContext, useEffect, useState } from "react";
import {
  createEmptyOpeningTime,
  createEmptyProvider,
  Provider,
  UserRole,
} from "../../utils/user/User.types";
import {
  AdminLayoutComponent,
  ButtonComponent,
  generateNotification,
  LoaderComponent,
  NotificationTypes,
} from "deinestadtliebt-component-library";
import { useNavLayout } from "../../utils/navigation/NavigationUtils";
import { CurrentPage } from "../../utils/navigation/Navigation.types";
import { useTranslation } from "react-i18next";
import {
  checkSocialMediaStatus,
  assignCoordinatesForAddress,
  fetchUserById,
  updateProviderProfileData,
  isProviderReadyForUpdate,
} from "../../utils/user/UserUtils";
import "../../styles/ProviderConfigPageStyles.scss";
import { useAxios } from "../../utils/AxiosUtil";
import { ConfigContext, UserContext } from "../App";
import { ReactComponent as CheckMarkIcon } from "../../assets/icons/check.svg";
import { ReactComponent as ExclamationMarkIcon } from "../../assets/icons/exclamation.svg";
import ProviderProfileConfig from "../../components/providerprofileconfig/ProviderProfileConfig";

interface ProviderConfigPageProps {}

const ProviderConfigPage: React.FC<ProviderConfigPageProps> = () => {
  const axios = useAxios();
  const { user } = useContext(UserContext);
  const { appConfig } = useContext(ConfigContext);
  const [localProvider, setLocalProvider] = useState<Provider>(
    createEmptyProvider(false)
  );
  const [localChangedProvider, setLocalChangedProvider] = useState<Provider>(
    createEmptyProvider(false)
  );
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const [isLoadingProvider, toogleLoadingProvider] = useState<boolean>(true);
  const [wysiwygKey, setWysiwygKey] = useState<number>(0);
  const { t } = useTranslation();

  /**
   * Applies the changed data to the Provider-Object and sends a post-request to server to overwrite provider-data
   * @returns no return, but sends alert to user whether it was successfull or not
   */
  const adjustCurrentProvider = async (): Promise<void> => {
    if (!isProviderReadyForUpdate(localChangedProvider)) {
      generateNotification(
        NotificationTypes.WARNING,
        t("notification.title.warning.missingFields"),
        t("notification.content.warning.missingFields")
      );
    }
    toggleLoading(true);

    assignCoordinatesForAddress(axios, localChangedProvider).then(
      (provider) => {
        setLocalChangedProvider(provider);

        updateProviderProfileData(axios, provider).then((success) => {
          if (success)
            generateNotification(
              NotificationTypes.SUCCESS,
              t("notification.title.success.changesSuccessful"),
              t("notification.content.success.providerUpdate")
            );
          else
            generateNotification(
              NotificationTypes.ERROR,
              t("notification.title.error.changesFailed"),
              t("notification.content.error.providerUpdate")
            );
          toggleLoading(false);
        });
      }
    );
  };

  /**
   * this useEffect sets the provider by fetching it with the given user-id
   */
  useEffect(() => {
    if (!!axios && !!user)
      fetchUserById(axios, user.id || "", UserRole.PROVIDER).then(
        (loadedprovider) => {
          if (
            localChangedProvider.openingTimes.findIndex(
              (item) => item.defaultTime
            ) === -1
          ) {
            (loadedprovider as Provider).openingTimes.push(
              createEmptyOpeningTime(true)
            );

            setLocalProvider(loadedprovider as Provider);
            setLocalChangedProvider({ ...(loadedprovider as Provider) });
          } else {
            setLocalProvider(loadedprovider as Provider);
            setLocalChangedProvider(loadedprovider as Provider);
          }
          toogleLoadingProvider(false);
          setWysiwygKey(wysiwygKey + 1);
        }
      );
    // eslint-disable-next-line
  }, [axios, user]);

  return (
    <>
      <AdminLayoutComponent
        {...useNavLayout(CurrentPage.PROVIDER_DETAIL_CONFIGURATION)}
      >
        <div
          style={{ background: appConfig?.highlightColor }}
          className="default-page-headline headline-profile-configuration"
        >
          <h1>{t("dashboard.provider.config.helper.title")}</h1>
          <div className="provider-config-mobile-buttons">
            <ButtonComponent
              isFrameless
              disabled={isLoading}
              type="button"
              value={t("signup.buttons.save")}
              onClick={() => adjustCurrentProvider()}
            />
            <ButtonComponent
              isFrameless
              type="button"
              disabled={isLoading}
              value={t("signup.buttons.abort")}
              onClick={() => {
                setLocalChangedProvider({ ...localProvider });
                setWysiwygKey(wysiwygKey + 1);
              }}
            />
          </div>
        </div>
        <div className="provider-config">
          <div className="provider-config--aligner">
            <div className="provider-config--status">
              <div className="provider-config--status-title">
                {t("dashboard.provider.config.helper.title")}
              </div>
              <div className="provider-config--status-description">
                {t("dashboard.provider.config.helper.description")}
              </div>
              <div className="provider-config--status-progress">
                <div className="provider-config--status-progress-description">
                  <div className="provider-config--status-progress-description-text">
                    {t("dashboard.provider.config.helper.progress.description")}
                  </div>
                  <div className="provider-config--status-progress-description-check">
                    {localChangedProvider.description ? (
                      <CheckMarkIcon className="check-mark-icon" />
                    ) : (
                      <ExclamationMarkIcon className="exclamation-mark-icon" />
                    )}
                  </div>
                </div>
                <div className="provider-config--status-progress-displayDescription">
                  <div className="provider-config--status-progress-displayDescription-text">
                    {t(
                      "dashboard.provider.config.helper.progress.displayDescription"
                    )}
                  </div>
                  <div className="provider-config--status-progress-displayDescription-check">
                    {localChangedProvider.displayDescription?.length > 10 ? (
                      <CheckMarkIcon className="check-mark-icon" />
                    ) : (
                      <ExclamationMarkIcon className="exclamation-mark-icon" />
                    )}
                  </div>
                </div>
                <div className="provider-config--status-progress-openingTimes">
                  <div className="provider-config--status-progress-openingTimes-text">
                    {t(
                      "dashboard.provider.config.helper.progress.openingTimes"
                    )}
                  </div>
                  <div className="provider-config--status-progress-openingTimes-check">
                    {localChangedProvider.openingTimes.filter(
                      (item) => item.defaultTime
                    )[0] ? (
                      <CheckMarkIcon className="check-mark-icon" />
                    ) : (
                      <ExclamationMarkIcon className="exclamation-mark-icon" />
                    )}
                  </div>
                </div>
                <div className="provider-config--status-progress-socialMedia">
                  <div className="provider-config--status-progress-socialMedia-text">
                    {t("dashboard.provider.config.helper.progress.socialMedia")}
                  </div>
                  <div className="provider-config--status-progress-socialMedia-check">
                    {checkSocialMediaStatus(localChangedProvider) ? (
                      <CheckMarkIcon className="check-mark-icon" />
                    ) : (
                      <ExclamationMarkIcon className="exclamation-mark-icon" />
                    )}
                  </div>
                </div>
              </div>
              <div className="provider-config--status-buttons">
                <ButtonComponent
                  type="button"
                  isLoading={isLoading}
                  value={t("signup.buttons.save")}
                  onClick={() => adjustCurrentProvider()}
                />
                <ButtonComponent
                  type="button"
                  isLoading={isLoading}
                  value={t("signup.buttons.abort")}
                  onClick={() => {
                    setLocalChangedProvider({ ...localProvider });
                    setWysiwygKey(wysiwygKey + 1);
                  }}
                />
                <div className="provider-config--status-buttons-preview">
                  <a
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                    className="preview-text"
                    href={[
                      process.env.REACT_APP_CLIENT_URL!,
                      localProvider.slugid,
                    ].join("")}
                  >
                    {t("dashboard.provider.config.helper.toPreview")}
                  </a>
                </div>
              </div>
            </div>

            {isLoadingProvider ? (
              <LoaderComponent />
            ) : (
              <ProviderProfileConfig
                isLoading={isLoading}
                key={wysiwygKey}
                provider={localChangedProvider}
                setProvider={setLocalChangedProvider}
              />
            )}
          </div>
        </div>
      </AdminLayoutComponent>
    </>
  );
};

export default ProviderConfigPage;
