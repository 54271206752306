import { useContext, useEffect, useState } from "react";
import { User, UserRole } from "../utils/user/User.types";
import {
  AdminLayoutComponent,
  ButtonComponent,
  generateNotification,
  InputComponent,
  NotificationTypes,
} from "deinestadtliebt-component-library";
import { useNavLayout } from "../utils/navigation/NavigationUtils";
import { CurrentPage } from "../utils/navigation/Navigation.types";
import { useTranslation } from "react-i18next";
import {
  stringsMatch,
  updateProfileLoginCredentials,
} from "../utils/user/UserUtils";
import "../styles/ProfilePageStyle.scss";
import { useAxios } from "../utils/AxiosUtil";
import { UserContext } from "./App";
import { useKeycloak } from "@react-keycloak/web";

interface ProfilePageProps {}

const ProfilePage: React.FC<ProfilePageProps> = () => {
  const axios = useAxios();
  const { user, setUser } = useContext(UserContext);
  const [localUser, setLocalUser] = useState<User>();
  const { t } = useTranslation();
  const [password, setPassword] = useState<string>("");
  const [passwordRepeat, setPasswordRepeat] = useState<string>("");
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const { keycloak } = useKeycloak();

  /**
   * sets the user if it changes
   */
  useEffect(() => {
    setLocalUser(user);
  }, [user]);

  /**
   * Applies the changed data to the Provider-Object and sends a
   * post-request to server to overwrite provider-data.
   * Sends alert to user whether it was successfull or not
   */
  const adjustCurrentProfile = (): void => {
    if (!localUser) return;
    toggleLoading(true);
    if (stringsMatch(password, passwordRepeat)) {
      localUser.password = password;
    } else {
      generateNotification(
        NotificationTypes.WARNING,
        t("notification.title.error.error"),
        t("notification.content.error.passwordDontMatch")
      );
      toggleLoading(false);
      return;
    }

    if (localUser.username.length <= 0) {
      generateNotification(
        NotificationTypes.ERROR,
        t("notification.title.error.error"),
        t("notification.content.error.usernameToShort")
      );
      toggleLoading(false);
      return;
    }
    updateProfileLoginCredentials(axios, localUser).then((success) => {
      //TODO: Notification for response
      if (success) {
        setUser(localUser as any);
        generateNotification(
          NotificationTypes.SUCCESS,
          t("notification.title.success.changesSuccessful"),
          t("notification.content.success.providerUpdate")
        );
      } else {
        generateNotification(
          NotificationTypes.ERROR,
          t("notification.title.error.error"),
          t("notification.content.error.providerUpdate")
        );
      }
      toggleLoading(false);
    });
  };

  return (
    <AdminLayoutComponent
      {...useNavLayout(
        localUser?.role === UserRole.ADMIN
          ? CurrentPage.ADMIN_PROFILE_PAGE
          : CurrentPage.PROVIDER_PROFILE_PAGE
      )}
    >
      <div className="profile-body">
        <div className="profile-body--content">
          <h1>{t("dashboard.provider.config.profileData")}</h1>
          <div className="profile-body--content-inputs">
            <InputComponent
              isFrameless
              label={t("dashboard.provider.config.placeholder.username")}
              value={localUser?.username || ""}
              placeholder={t(
                "dashboard.provider.config.helper.progress.username"
              )}
              onChange={(value) =>
                setLocalUser({
                  ...localUser!,
                  username: value,
                })
              }
            />
            <InputComponent
              isFrameless
              label={t("dashboard.provider.config.placeholder.mail")}
              value={localUser?.mail || ""}
              placeholder={t("dashboard.provider.config.placeholder.mail")}
              onChange={(value) =>
                setLocalUser({
                  ...localUser!,
                  mail: value,
                })
              }
            />
            <InputComponent
              isFrameless
              label={t("dashboard.provider.config.placeholder.password")}
              placeholder={t("dashboard.provider.config.placeholder.password")}
              value={password}
              type="password"
              onChange={(newPassword) => setPassword(newPassword)}
            />
            <InputComponent
              isFrameless
              label={t("dashboard.provider.config.placeholder.passwordRepeat")}
              placeholder={t(
                "dashboard.provider.config.placeholder.passwordRepeat"
              )}
              value={passwordRepeat}
              type="password"
              onChange={(newPassword) => setPasswordRepeat(newPassword)}
            />
          </div>

          <div className="profile-body--content--buttons">
            <ButtonComponent
              type="button"
              isLoading={isLoading}
              value={t("profile.buttons.save")}
              onClick={() => adjustCurrentProfile()}
            />
            <ButtonComponent
              isLoading={isLoading}
              value={t("profile.buttons.abort")}
              type="button"
              onClick={() => {
                setPassword("");
                setPasswordRepeat("");
                setLocalUser({ ...user! });
              }}
            />
            <ButtonComponent
              type="button"
              value={t("profile.buttons.logout")}
              onClick={() => keycloak.logout()}
            />
          </div>
        </div>
      </div>
    </AdminLayoutComponent>
  );
};

export default ProfilePage;
