import {
  ButtonComponent,
  DropdownComponent,
  InputComponent,
} from "deinestadtliebt-component-library";
import { MailboxCreateProps } from "./MailboxCreate.types";
import { fetchAllProvider } from "../../../utils/user/UserUtils";
import { useEffect, useState } from "react";
import { Provider, UserRole } from "../../../utils/user/User.types";
import { useAxios } from "../../../utils/AxiosUtil";
import { useTranslation } from "react-i18next";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";

export const MailboxCreate: React.FC<MailboxCreateProps> = ({
  onSend,
  onChange,
  onClose,
  thread,
  role,
  type,
}) => {
  const { t } = useTranslation();
  const axios = useAxios();
  const [allProvider, setAllProvider] = useState<Provider[]>();

  /**
   * This effects load the latest provider list if the user is an admin.
   * Because an admin can choice the other message partner.
   */
  useEffect(() => {
    if (role === UserRole.ADMIN && axios) {
      fetchAllProvider(axios).then(setAllProvider);
    }
  }, [role, axios]);

  /**
   * This helper gets a provider name from a provided user/provider id.
   *
   * @param id the id of the provider
   * @returns provider name as string. undefined if not found
   */
  const getNameFromId = (id: string): string | undefined => {
    if (allProvider) {
      return allProvider.find((prov) => prov.id === id)?.name;
    }
  };

  return (
    <div className="mailbox-create">
      {type === "new" && (
        <div className="mailbox-create-header">
          <div className="mailbox-create-header-title">
            {role === UserRole.ADMIN
              ? t("mailbox.header.newProvider")
              : t("mailbox.header.newAdmin")}
          </div>
          <div className="mailbox-create-header-close" onClick={onClose}>
            <CloseIcon />
          </div>
        </div>
      )}
      {role === UserRole.ADMIN && type === "new" && (
        <DropdownComponent
          dropdownOptions={
            allProvider
              ? allProvider.map((provider) => ({
                  label: provider.name,
                  value: provider.id!,
                }))
              : []
          }
          onChange={(id) =>
            onChange({
              ...thread,
              ...{ members: [{ id, name: getNameFromId(id) }] },
            })
          }
          selectedOption={thread.members?.[0]?.id || ""}
          searchable
        />
      )}

      {type === "new" && (
        <InputComponent
          value={thread.subject}
          placeholder={t("mailbox.create.subject")}
          onChange={(subject) => {
            onChange({ ...thread, ...{ subject } });
          }}
          isFrameless
          signCap={40}
          type={"text"}
          fontSize="1em"
          signCapText="Zeichen"
        />
      )}

      <InputComponent
        placeholder={t("mailbox.create.message")}
        type="multiLine"
        value={thread.messages[0].content}
        onChange={(content) =>
          onChange({
            ...thread,
            ...{ messages: [{ ...thread.messages[0], ...{ content } }] },
          })
        }
      />
      <ButtonComponent value={t("mailbox.create.submit")} onClick={onSend} />
    </div>
  );
};
