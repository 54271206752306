import {
  AdminLayoutComponent,
  DropdownOption,
  generateNotification,
  NotificationTypes,
  PopupComponent,
  RetractableComponent,
  TableComponent,
} from "deinestadtliebt-component-library";
import { CurrentPage } from "../../utils/navigation/Navigation.types";
import { useNavLayout } from "../../utils/navigation/NavigationUtils";
import { useTranslation } from "react-i18next";
import { ReactComponent as ImagesIcon } from "../../assets/icons/images.svg";
import { useContext, useEffect, useState } from "react";
import { useAxios } from "../../utils/AxiosUtil";
import { ConfigContext, UserContext } from "../App";
import {
  fetchUserById,
  generatePriceItemsTableRows,
  updateProviderCustomPriceDataForActivity,
} from "../../utils/user/UserUtils";
import {
  createEmptyPriceItem,
  createEmptyProvider,
  PriceItem,
  Provider,
  UserRole,
} from "../../utils/user/User.types";
import "../../styles/ProviderEventsPageStyle.scss";
import { getCalendarWeeks } from "../../utils/time/TimeUtils";
import { CalendarWeeks } from "../../utils/time/Time.types";
import { fetchAllEventsForProvider } from "../../utils/event/EventUtils";
import { EventLocationEdit } from "../../components/eventlocationedit/EventLocationEdit";
import PriceItemEdit from "../../components/priceitemedit/PriceItemEdit";

interface ProviderEventConfigurationPageProps {}

const ProviderEventConfigurationPage: React.FC<
  ProviderEventConfigurationPageProps
> = () => {
  const { t } = useTranslation();
  const axios = useAxios();
  const { user, setUser } = useContext(UserContext);
  const { appConfig } = useContext(ConfigContext);
  const [localProvider, setLocalProvider] = useState<Provider>(
    createEmptyProvider(false)
  );
  const [isLoading, toggleLoading] = useState<boolean>(false);

  const [dropdownCalendarWeeks, setDropdownCalendarWeeks] = useState<
    DropdownOption[]
  >([]);
  const currentCalendarWeeks: CalendarWeeks[] = getCalendarWeeks();

  const [currentPriceItem, setCurrentPriceItem] = useState<PriceItem>(
    createEmptyPriceItem()
  );
  const [keyPriceItemUpdate, setKeyPriceItemUpdate] = useState<number>(0);
  const [priceItemToUpdate, setPriceItemToUpdate] = useState<PriceItem>();

  /**
   * this useEffect creates the needed calendar week entries in dropdown
   */
  useEffect(() => {
    currentCalendarWeeks.forEach((item) =>
      dropdownCalendarWeeks.push({
        label: `${t("providerEventsConfigurationPage.calendarWeeksShort")} ${
          item.name
        } (${new Date(item.startDate).toLocaleDateString("de")} - ${new Date(
          item.endDate
        ).toLocaleDateString("de")})`,
        value: item.name,
      })
    );
    setDropdownCalendarWeeks([...dropdownCalendarWeeks]);
    // eslint-disable-next-line
  }, []);

  /**
   * this useEffect sets the provider by fetching it with the given user-id
   */
  useEffect(() => {
    if (!axios || !user) return;
    fetchUserById(axios, user.id!, UserRole.PROVIDER).then((loadedprovider) => {
      setLocalProvider(loadedprovider as Provider);
      fetchAllEventsForProvider(axios, (loadedprovider as Provider).slugid);
    });
    // eslint-disable-next-line
  }, [axios, user]);

  /**
   * Helper to modify the content of the price for a provider
   * @param id if something has to be removed
   * @param type which code should be executed
   * @param item price item to update
   */
  const addCustomPriceItemForActivity = async (
    type: "create" | "delete" | "update",
    item: PriceItem,
    id: string
  ): Promise<void> => {
    toggleLoading(true);
    let localPrice: PriceItem[] = [
      ...localProvider.customPriceItemsForActivity,
    ];
    switch (type) {
      case "create":
        localPrice.push(item!);
        break;
      case "delete":
        localPrice = localPrice.filter((item) => item.id !== id);
        break;
      case "update":
        let foundIndex: number = localPrice.findIndex(
          (item) => item.id === item?.id
        );
        if (foundIndex === -1) {
          toggleLoading(false);
          return;
        }
        localPrice[foundIndex] = item!;
        break;
    }
    const dataToUpdate = {
      ...localProvider,
      customPriceItemsForActivity: localPrice,
    };

    updateProviderCustomPriceDataForActivity(axios, dataToUpdate).then(
      (success) => {
        if (success) {
          setLocalProvider(dataToUpdate);
          setUser(dataToUpdate);
          setCurrentPriceItem(createEmptyPriceItem());
          setPriceItemToUpdate(undefined);
          setKeyPriceItemUpdate(keyPriceItemUpdate + 1);
          generateNotification(
            NotificationTypes.SUCCESS,
            t(
              `notification.title.success.${
                !id ? "creationSuccessful" : "changesSuccessful"
              }`
            ),
            t(`notification.content.success.providerUpdate`)
          );
        } else
          generateNotification(
            NotificationTypes.ERROR,
            t(`notification.title.success.error`),
            t(`notification.content.success.deletion`)
          );
        toggleLoading(false);
      }
    );
  };

  return (
    <AdminLayoutComponent
      {...useNavLayout(CurrentPage.PROVIDER_DETAIL_CONFIGURATION)}
    >
      <div
        style={{ background: appConfig?.highlightColor }}
        className="default-page-headline"
      >
        <h1>{t("providerEventsConfigurationPage.title")}</h1>
      </div>

      <div
        id="provider-events-configuration-wrapper"
        className="default-page-wrapper"
      >
        <RetractableComponent
          title={t("providerEventsConfigurationPage.location")}
          type="border"
          icon={<ImagesIcon />}
        >
          <EventLocationEdit
            type="event"
            isLoading={isLoading}
            provider={localProvider}
            setProvider={setLocalProvider}
            toggleLoading={toggleLoading}
          />
        </RetractableComponent>

        <RetractableComponent
          title={t("providerEventsConfigurationPage.bookingOffices")}
          type="border"
          icon={<ImagesIcon />}
        >
          <p className="description-text-retractable">
            {t("providerEventsConfigurationPage.bookingOfficesDescription")}
          </p>
          <EventLocationEdit
            type="booking"
            isLoading={isLoading}
            provider={localProvider}
            setProvider={setLocalProvider}
            toggleLoading={toggleLoading}
          />
        </RetractableComponent>

        <RetractableComponent
          title={t("providerEventsConfigurationPage.ticketPricing")}
          type="border"
          icon={<ImagesIcon />}
        >
          <p className="description-text-retractable">
            {t("providerEventsConfigurationPage.ticketPricingDescription")}
          </p>
          <PriceItemEdit
            key={keyPriceItemUpdate}
            onSave={(item) => addCustomPriceItemForActivity("create", item, "")}
            priceItemToEdit={currentPriceItem}
            isLoading={isLoading}
          />
          <TableComponent
            header={t("providerConfigurationPage.tableHeaderPrice", {
              returnObjects: true,
            })}
            rows={generatePriceItemsTableRows(
              localProvider.customPriceItemsForActivity ?? [],
              addCustomPriceItemForActivity,
              setPriceItemToUpdate
            )}
            maxPageAmount={20}
          />
          <div className="popup-max-width">
            <PopupComponent
              open={!!priceItemToUpdate}
              toggleOpen={() => setPriceItemToUpdate(undefined)}
            >
              <h2>{t("providerEventsConfigurationPage.updateTitle")}</h2>
              <PriceItemEdit
                key={keyPriceItemUpdate}
                priceItemToEdit={priceItemToUpdate!}
                isLoading={isLoading}
                onSave={(priceItem) =>
                  addCustomPriceItemForActivity("update", priceItem, "")
                }
              />
            </PopupComponent>
          </div>
        </RetractableComponent>
      </div>
    </AdminLayoutComponent>
  );
};

export default ProviderEventConfigurationPage;
