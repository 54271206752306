import {
  ButtonComponent,
  generateNotification,
  InputComponent,
  NotificationTypes,
  PopupComponent,
  RetractableComponent,
  TableComponent,
} from "deinestadtliebt-component-library";
import { ReactComponent as ImagesIcon } from "../../assets/icons/images.svg";
import { useTranslation } from "react-i18next/";
import {
  createEmptySpecialNote,
  Provider,
  SpecialNote,
} from "../../utils/user/User.types";
import {
  deleteSpecialNote,
  generateSpecialNoteRows,
  updateProviderProfileData,
} from "../../utils/user/UserUtils";
import { useState } from "react";
import ExampleImage from "../../assets/images/quick-note-example.png";
import "./SpecialNoteEdit.style.scss";
import { useAxios } from "../../utils/AxiosUtil";

interface SpecialNoteEditProps {
  localProvider: Provider;
  setLocalProvider: (provider: Provider) => void;
}
const SpecialNoteEdit: React.FC<SpecialNoteEditProps> = ({
  localProvider,
  setLocalProvider,
}) => {
  const { t } = useTranslation();
  const [specialNoteToggle, toggleSpecialNoteToggle] = useState<boolean>(false);
  const [currentSpecialNote, setCurrentSpecialNote] = useState<SpecialNote>(
    createEmptySpecialNote()
  );
  const axios = useAxios();

  /**
   * Helperto handle deletion of a special note and update provider
   * @param id id of specialNote to be deleted
   */
  const handleDelete = async (id: string): Promise<void> => {
    const adjustedProvider = {
      ...localProvider,
      specialNotes: deleteSpecialNote(id, localProvider.specialNotes),
    };

    await updateProviderProfileData(axios, adjustedProvider).then((success) => {
      if (success) {
        setLocalProvider(adjustedProvider);
        generateNotification(
          NotificationTypes.SUCCESS,
          t(`notification.title.success.changesSuccessful`),
          t(`notification.content.success.providerUpdate`)
        );
      } else
        generateNotification(
          NotificationTypes.ERROR,
          t(`notification.title.success.error`),
          t(`notification.content.success.deletion`)
        );
    });
  };

  /**
   * Helper to modify specialNotes and update them
   */
  const handleAddOrEdit = async (): Promise<void> => {
    const adjustedProvider = {
      ...localProvider,
      specialNotes: addOrEditSpecialNote(
        currentSpecialNote,
        localProvider.specialNotes
      ),
    };

    await updateProviderProfileData(axios, adjustedProvider).then((success) => {
      if (success) {
        setLocalProvider(adjustedProvider);
        generateNotification(
          NotificationTypes.SUCCESS,
          t(`notification.title.success.changesSuccessful`),
          t(`notification.content.success.providerUpdate`)
        );
      } else
        generateNotification(
          NotificationTypes.ERROR,
          t(`notification.title.success.error`),
          t(`notification.content.success.deletion`)
        );
    });
  };

  /**
   * Helper to add new specialNote to given SpecialNote[] or edit existing one
   * @param newSpecialNote SpecialNote to be added or adjusted
   * @param newSpecialNoteArray Array to be adjusted
   * @returns SpecialNote[] containing new or adjusted entries
   */
  const addOrEditSpecialNote = (
    newSpecialNote: SpecialNote,
    specialNoteArray: SpecialNote[]
  ): SpecialNote[] => {
    let localSpecialNotes = [...(specialNoteArray || [])];
    if (
      !!newSpecialNote.title &&
      !!newSpecialNote.text &&
      !!newSpecialNote.startDate &&
      !!newSpecialNote.endDate
    ) {
      let givenNoteIndex = localSpecialNotes.findIndex((note) => {
        return note.id === newSpecialNote.id;
      });
      if (givenNoteIndex === -1) {
        localSpecialNotes.push(newSpecialNote);
      } else {
        localSpecialNotes[givenNoteIndex] = newSpecialNote;
      }
      toggleSpecialNoteToggle(false);
    } else {
      generateNotification(
        "WARNING",
        t("notification.title.warning.missingFields"),
        t("adminConfigurationPage.missingNoteDescription")
      );
    }
    return localSpecialNotes;
  };

  return (
    <div className="special-note-edit">
      <PopupComponent
        open={specialNoteToggle}
        toggleOpen={() => toggleSpecialNoteToggle(false)}
        title={t("adminConfigurationPage.newSpecialNote")}
        bottomButtons={[
          {
            value: t("adminConfigurationPage.buttons.save"),
            onClick: () => {
              handleAddOrEdit();
            },
          },
        ]}
      >
        <h3>{t("adminConfigurationPage.noteTitle")}</h3>
        <InputComponent
          isFrameless
          signCap={30}
          type="text"
          placeholder={t("adminConfigurationPage.noteTitle")}
          value={currentSpecialNote?.title || ""}
          onChange={(value) =>
            setCurrentSpecialNote({
              ...currentSpecialNote,
              title: value,
            })
          }
        />
        <h3>{t("adminProviderOverviewPage.description")}</h3>
        <InputComponent
          type="multiLine"
          placeholder={t("adminProviderOverviewPage.description")}
          signCap={150}
          value={currentSpecialNote?.text || ""}
          onChange={(value) =>
            setCurrentSpecialNote({
              ...currentSpecialNote,
              text: value ? value : currentSpecialNote.title,
            })
          }
        />
        <h3>{t("adminConfigurationPage.displayFrame")}</h3>
        <div className="time-frame-edit">
          <InputComponent
            isFrameless
            label={t("adminConfigurationPage.startDate")}
            type="date"
            value={
              new Date(currentSpecialNote?.startDate)
                .toISOString()
                .split("T")[0]
            }
            onChange={(value) =>
              setCurrentSpecialNote({
                ...currentSpecialNote,
                startDate: new Date(
                  value ? value : currentSpecialNote.startDate
                ),
              })
            }
          />
          <InputComponent
            isFrameless
            label={t("adminConfigurationPage.endDate")}
            type="date"
            value={
              new Date(currentSpecialNote?.endDate).toISOString().split("T")[0]
            }
            onChange={(value) =>
              setCurrentSpecialNote({
                ...currentSpecialNote,
                endDate: new Date(value ? value : currentSpecialNote.endDate),
              })
            }
          />
        </div>
      </PopupComponent>
      <RetractableComponent
        title={t("adminConfigurationPage.specialNotes")}
        type="border"
        icon={<ImagesIcon />}
      >
        <div className="special-note-edit-wrapper">
          <div className="special-note-edit--description">
            <div className="special-note-edit--description-text">
              {t("adminConfigurationPage.specialNoteDescription")}
            </div>
            <div className="special-note-edit--description-image">
              <img src={ExampleImage} alt="Example Note" />
            </div>
          </div>
          <ButtonComponent
            value={t("adminConfigurationPage.newSpecialNote")}
            onClick={() => {
              setCurrentSpecialNote(createEmptySpecialNote());
              toggleSpecialNoteToggle(true);
            }}
          />
          {localProvider.specialNotes?.length > 0 && (
            <TableComponent
              maxPageAmount={5}
              header={t("providerConfigurationPage.tableHeaderNotes", {
                returnObjects: true,
              })}
              rows={generateSpecialNoteRows(
                localProvider,
                toggleSpecialNoteToggle,
                setCurrentSpecialNote,
                handleDelete
              )}
            />
          )}
        </div>
      </RetractableComponent>
    </div>
  );
};

export default SpecialNoteEdit;
