import {
  AdminLayoutComponent,
  RetractableComponent,
} from "deinestadtliebt-component-library";
import { CurrentPage } from "../../utils/navigation/Navigation.types";
import { useNavLayout } from "../../utils/navigation/NavigationUtils";
import { useTranslation } from "react-i18next";
import { ReactComponent as ImagesIcon } from "../../assets/icons/images.svg";
import { useContext, useEffect, useState } from "react";
import { useAxios } from "../../utils/AxiosUtil";
import { ConfigContext, UserContext } from "../App";
import { fetchUserById } from "../../utils/user/UserUtils";
import {
  createEmptyProvider,
  Provider,
  UserRole,
} from "../../utils/user/User.types";
import { Link } from "react-router-dom";

interface ProviderEvaluationConfigurationPageProps {}

const ProviderEvaluationConfigurationPage: React.FC<
  ProviderEvaluationConfigurationPageProps
> = () => {
  const { t } = useTranslation();
  const { appConfig } = useContext(ConfigContext);
  const axios = useAxios();
  const { user } = useContext(UserContext);
  // eslint-disable-next-line
  const [localProvider, setLocalProvider] = useState<Provider>(
    createEmptyProvider(false)
  );

  /**
   * this useEffect sets the provider by fetching it with the given user-id
   */
  useEffect(() => {
    if (!axios || !user) return;
    fetchUserById(axios, user.id!, UserRole.PROVIDER).then((loadedprovider) => {
      setLocalProvider(loadedprovider as Provider);
    });
    // eslint-disable-next-line
  }, [axios, user]);

  return (
    <AdminLayoutComponent
      {...useNavLayout(CurrentPage.PROVIDER_DETAIL_CONFIGURATION)}
    >
      <div
        style={{ background: appConfig?.highlightColor }}
        className="default-page-headline"
      >
        <h1>{t("providerEvaluationPage.title")}</h1>
      </div>

      <div
        className="default-page-wrapper"
        id="provider-evaluation-configuration-wrapper"
      >
        <RetractableComponent
          title={t("providerEvaluationPage.access")}
          type="border"
          icon={<ImagesIcon />}
        >
          <p className="access-amount">{localProvider.trafficCounter}x</p>
          <p>{t("providerEvaluationPage.accessInfo")}</p>
          <p>
            Einfach das{" "}
            <Link to="/config/profile">Profil vervollständigen</Link> oder
            nochmal über die <Link to="/config">Tags</Link> nachdenken.
          </p>
        </RetractableComponent>
      </div>
    </AdminLayoutComponent>
  );
};

export default ProviderEvaluationConfigurationPage;
