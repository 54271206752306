import {
  ButtonComponent,
  CheckboxComponent,
  DropdownComponent,
  generateNotification,
  InputComponent,
  NotificationTypes,
  TableComponent,
} from "deinestadtliebt-component-library";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAxios } from "../../utils/AxiosUtil";
import {
  fetchHolidaysForCountry,
  generateHolidayTimeTableRows,
} from "../../utils/time/TimeUtils";
import { HolidayTime, Provider } from "../../utils/user/User.types";
import { updateProviderHolidayData } from "../../utils/user/UserUtils";

interface HolidayConfigurationProps {
  provider: Provider;
  setProvider: (provider: Provider) => void;
}

const HolidayConfiguration: React.FC<HolidayConfigurationProps> = ({
  provider,
  setProvider,
}) => {
  const { t } = useTranslation();
  const axios = useAxios();
  const [currentHolidayTimeList, setCurrentHolidayTimeList] = useState<
    HolidayTime[]
  >([]);
  const [selectedBundesland, setSelectedBundesland] = useState<string>("BW");
  const [isHolidayLoaded, toggleHolidayLoaded] = useState<boolean>(false);

  /**
   * Helper to update or create the holiday data for a loaded provider
   * @param givenProvider Provider that will be used to overwrite current providerData
   */
  const updateOrCreateHolidayDataForProvider = (
    givenProvider: Provider
  ): void => {
    let localHolidayItems: HolidayTime[] = [...givenProvider.holidayTimes];
    currentHolidayTimeList.forEach((currentHoliday) => {
      let foundIndex: number = localHolidayItems.findIndex(
        (item) =>
          new Date(item.holidayDate).toLocaleDateString("de") ===
          new Date(currentHoliday.holidayDate).toLocaleDateString("de")
      );
      if (foundIndex === -1) localHolidayItems.push(currentHoliday);
      else localHolidayItems[foundIndex] = currentHoliday;
    });
    updateProviderHolidayData(axios, {
      ...givenProvider,
      holidayTimes: localHolidayItems,
    }).then((success) => {
      if (success) {
        setProvider({
          ...givenProvider,
          holidayTimes: localHolidayItems,
        });
        setCurrentHolidayTimeList([]);
        generateNotification(
          NotificationTypes.SUCCESS,
          t("notification.title.success.changesSuccessful"),
          t("notification.content.success.specialOpeningTimes")
        );
      } else
        generateNotification(
          NotificationTypes.ERROR,
          t("notification.title.error.error"),
          t("notification.content.error.providerUpdate")
        );
    });
  };

  /**
   * Method to deactive/delete holiday entry from list and trigger providerUpdate
   * @param index number of entry to be deleted
   */
  const deactivateHoliday = (index: number): void => {
    let localHolidayItems = [...provider.holidayTimes];
    localHolidayItems[index].open = false;
    updateOrCreateHolidayDataForProvider({
      ...provider,
      holidayTimes: localHolidayItems,
    });
  };

  /**
   * Helper to generate correct string for holiday-entries
   * @param currentHolidayItem HolidayTime that contains all necessary data
   * @returns String containing name and date of holiday
   */
  const generateCorrectHolidayString = (
    currentHolidayItem: HolidayTime
  ): string => {
    return `${currentHolidayItem.name} (${new Date(
      currentHolidayItem.holidayDate
    ).toLocaleDateString("de")})`;
  };

  return (
    <>
      <p className="padding-down">
        {t("providerConfigurationPage.holidaysInfo")}
      </p>
      <div className="flex-it-center configuration-data-wrapper padding-down flex-space-between">
        <div className="half-the-width">
          <DropdownComponent
            dropdownOptions={t(
              "providerConfigurationPage.holidayDropdownConfig",
              {
                returnObjects: true,
              }
            )}
            onChange={(value) => setSelectedBundesland(value)}
            selectedOption={selectedBundesland}
          />
        </div>
        <div className="half-the-width">
          <ButtonComponent
            value={t("providerConfigurationPage.buttons.load")}
            onClick={() =>
              fetchHolidaysForCountry(
                selectedBundesland,
                setCurrentHolidayTimeList,
                toggleHolidayLoaded
              )
            }
          />
        </div>
      </div>
      <div className="flex-it-wrap padding-down holiday-list">
        {currentHolidayTimeList.map(
          (currentHolidayItem, currentHolidayItemIndex) => (
            <div
              key={`loaded-holiday-${currentHolidayItemIndex}`}
              className="flex-it holiday-item"
            >
              <CheckboxComponent
                checked={currentHolidayItem.open}
                onCheck={() => {
                  currentHolidayTimeList[currentHolidayItemIndex].open =
                    !currentHolidayTimeList[currentHolidayItemIndex].open;
                  setCurrentHolidayTimeList([...currentHolidayTimeList]);
                }}
                value={generateCorrectHolidayString(currentHolidayItem)}
              />
              {currentHolidayItem.open && (
                <div>
                  <p>{t("providerConfigurationPage.holidaysOpenInfo")}</p>
                  <div className="flex-it">
                    <InputComponent
                      type="time"
                      value={currentHolidayItem.startTime}
                      onChange={(value) => {
                        currentHolidayTimeList[
                          currentHolidayItemIndex
                        ].startTime = value;
                        setCurrentHolidayTimeList([...currentHolidayTimeList]);
                      }}
                      placeholder={t("providerConfigurationPage.inputs.start")}
                    />
                    <InputComponent
                      type="time"
                      value={currentHolidayItem.endTime}
                      onChange={(value) => {
                        currentHolidayTimeList[
                          currentHolidayItemIndex
                        ].endTime = value;
                        setCurrentHolidayTimeList([...currentHolidayTimeList]);
                      }}
                      placeholder={t("providerConfigurationPage.inputs.end")}
                    />
                  </div>
                </div>
              )}
            </div>
          )
        )}
      </div>
      <ButtonComponent
        disabled={!isHolidayLoaded}
        value={t("providerConfigurationPage.buttons.save")}
        onClick={() => updateOrCreateHolidayDataForProvider(provider)}
      />
      <TableComponent
        header={t("providerConfigurationPage.tableHeaderHolidays", {
          returnObjects: true,
        })}
        rows={generateHolidayTimeTableRows(
          provider.holidayTimes,
          deactivateHoliday
        )}
        maxPageAmount={20}
      />
    </>
  );
};

export default HolidayConfiguration;
