import { useEffect, useState } from "react";
import { createEmptyProvider, Provider } from "../utils/user/User.types";
import { useHistory, useLocation } from "react-router";
import { ButtonComponent } from "deinestadtliebt-component-library";
import "../styles/SignupSuccessPageStyle.scss";
import { useTranslation } from "react-i18next";

interface SignupSuccessPageProps {}

const SignupSuccessPage: React.FC<SignupSuccessPageProps> = () => {
  const [localProvider, setLocalProvider] = useState<Provider>(
    createEmptyProvider(false)
  );
  const location = useLocation<{ provider: Provider }>();
  const { t } = useTranslation();
  const history = useHistory();

  /**
   * this useEffect sets the provider given by the location
   */
  useEffect(() => {
    if (!location.state?.provider) return;
    setLocalProvider(location.state?.provider);
  }, [location]);

  return (
    <>
      <div className="success-box">
        <div className="success-box--image"></div>
        <div className="success-box--text">
          <div className="success-box--text-header">
            <h1>
              {t("signup.success.header", {
                replace: { name: localProvider.name },
              })}
            </h1>
          </div>
          <div className="success-box--text-content">
            <div>
              {t("signup.success.text1", {
                replace: { email: localProvider.mail },
              })}
            </div>
            <div>{t("signup.success.text2")}</div>
          </div>
          <div className="success-box--text-buttons">
            <ButtonComponent
              className="dashboard-button"
              value={t("signup.success.buttonLogin")}
              onClick={() => history.push("/dashboard")}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SignupSuccessPage;
