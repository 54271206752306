// Generated with util/create-component.js
import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./SlideShowComponent.scss";

import { SlideShowComponentProps } from "./SlideShowComponent.types";
import LeftArrowIcon from "./assets/left.svg";
import RightArrowIcon from "./assets/right.svg";
import Slider from "react-slick";

const SlideShowComponent: React.FC<SlideShowComponentProps> = ({
  items,
  intervall = 5,
  autoPlay = false,
  dots = false,
  backgroundColor = "#80a7ce",
  forceLayout,
}) => {
  return (
    <div
      className={[
        "slick-slideshow-component",
        forceLayout ? `preview-${forceLayout}` : "",
      ].join(" ")}
      style={{ background: backgroundColor }}
    >
      <Slider
        infinite={false}
        dots={dots}
        arrows
        autoplay={autoPlay}
        autoplaySpeed={intervall * 1000}
        accessibility
        prevArrow={<LeftArrowIcon />}
        nextArrow={<RightArrowIcon />}
        pauseOnFocus
        pauseOnHover
      >
        {items.map((item, itemIndex) => (
          <div
            key={`slide-show-item-${itemIndex}`}
            className={[
              "slick-slideshow-component--content-item",
              (item.reverseContentDirection && "content-reverse") ||
                "content-normal",
              item.text
                ? undefined
                : "slick-slideshow-component--content-item-image-standalone",
            ].join(" ")}
          >
            <div
              key={`slide-show-item-image-desktop${itemIndex}`}
              className="slick-slideshow-component--content-item-image-desktop"
            >
              <img src={item.image} alt={`Slider ${itemIndex}`} />
            </div>
            <div
              key={`slide-show-item-image-mobile${itemIndex}`}
              className="slick-slideshow-component--content-item-image-mobile"
            >
              <img
                src={item.mobileImage ? item.mobileImage : item.image}
                alt={`Mobile Slider ${itemIndex}`}
              />
            </div>
            {item.text && (
              <div className="slick-slideshow-component--content-item-text">
                {item.text}
              </div>
            )}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SlideShowComponent;
