import { AxiosInstance } from "axios";
import {
  DropdownOption,
  generateNotification,
  NotificationTypes,
  TableRow,
} from "deinestadtliebt-component-library";
import { Licence } from "./Licence.types";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";
import i18n from "../../i18n";

/**
 * API METHOD - to create a {@link Licence} on the backend
 * @param axios network instance
 * @param licence data to store
 * @returns created licence or null
 */
export const createLicence = (
  axios: AxiosInstance,
  licence: Licence
): Promise<Licence> => {
  return axios
    .post("/user/licence/", licence)
    .then((res) => res.data)
    .catch((error) => {
      console.error("Error during creating licence!", error);
      generateNotification(
        NotificationTypes.ERROR,
        i18n.t("notification.title.error.error"),
        i18n.t("notification.content.error.licenceSave")
      );
      return null;
    });
};

/**
 * API METHOD - to update {@link Licence} on the server
 * @param axios network instance
 * @param licence data to update
 * @returns updated licence or null
 */
export const updateLicence = (
  axios: AxiosInstance,
  licence: Licence
): Promise<Licence> => {
  return axios
    .post("/user/licence/update/", licence)
    .then((res) => res.data)
    .catch((error) => {
      console.error("Error during updating licence!", error);
      generateNotification(
        NotificationTypes.ERROR,
        i18n.t("notification.title.error.error"),
        i18n.t("notification.content.error.licenceUpdate")
      );
      return null;
    });
};

/**
 * API METHOD - to delete a {@link Licence} on the server
 * @param axios network instance
 * @param licence data to delete
 * @returns true if statuscode 200 is recieved, false otherwise
 */
export const deleteLicence = (
  axios: AxiosInstance,
  licence: Licence
): Promise<boolean> => {
  return axios
    .post("/user/licence/delete/", licence.id!)
    .then((res) => res.status === 200)
    .catch((error) => {
      console.error("Error during deleting licence!", error);
      generateNotification(
        NotificationTypes.ERROR,
        i18n.t("notification.title.error.error"),
        i18n.t("notification.content.error.licenceDelete")
      );
      return false;
    });
};

/**
 * API METHOD - to fetch all {@link Licence}s from the server
 * @param axios network instance
 * @returns all found {@link Licence}s or an empty array
 */
export const getAllLicence = (axios: AxiosInstance): Promise<Licence[]> => {
  return axios
    .get("/user/licence/all/")
    .then((res) => res.data)
    .catch((error) => {
      console.error("Error during fetching all licences!", error);
      generateNotification(
        NotificationTypes.ERROR,
        "Fehler",
        "Lizenzen konnten nicht geladen werden"
      );
      return [];
    });
};

/**
 * Helper to generate a {@link TabelRow} of an array of {@link Licence}
 * @param licences array of data to convert
 * @param onEdit function which is called for editing
 * @param onDelete function which is called for deleting
 * @returns generated {@link TableRow}s
 */
export const generateLicencesTableRows = (
  licences: Licence[],
  onEdit: Function,
  onDelete: Function
): TableRow[] => {
  const localTableRows: TableRow[] = [];
  licences.forEach((lic) =>
    localTableRows.push({
      id: lic.id!,
      content: [
        lic.name,
        lic.eventCreation ? "X" : "-",
        lic.actionCreation ? "X" : "-",
        lic.menuCreation ? "X" : "-",
        lic.jobCreation ? "X" : "-",
        <div className="flex-it-center">
          <div
            className={["trash-wrapper", "little-bit-margin-right"].join(" ")}
            onClick={() => onEdit(lic)}
          >
            <EditIcon />
          </div>
          <div
            className={["trash-wrapper"].join(" ")}
            onClick={() => onDelete(lic)}
          >
            <TrashIcon />
          </div>
        </div>,
      ],
    })
  );
  return localTableRows;
};

/**
 * Helper to gnerate list of {@link DropdownOption}s for {@link Licence}s
 * @param licences data to convert into options
 * @returns generated options
 */
export const generateDropdownItemsForLicences = (
  licences: Licence[],
  disabledId?: string
): DropdownOption[] => {
  let items: DropdownOption[] = [];
  licences.forEach((lic) =>
    items.push({
      label: lic.name,
      value: lic.id!,
      disabled: lic.id === disabledId,
    })
  );
  return items;
};
