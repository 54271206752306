import { AdminLayoutComponent } from "deinestadtliebt-component-library";
import { useEffect, useState } from "react";
import "../styles/AgbPageStyle.scss";
import { useAxios } from "../utils/AxiosUtil";
import { fetchAdminClientTerms } from "../utils/legal/LegalUtils";
import { CurrentPage } from "../utils/navigation/Navigation.types";
import { useNavLayout } from "../utils/navigation/NavigationUtils";
import DOMPurify from "isomorphic-dompurify";

interface AgbPageProps {}

const AgbPage: React.FC<AgbPageProps> = () => {
  const axios = useAxios();
  const [termsAdminClient, setTermsAdminClient] = useState<string>("");

  /**
   * This effect fetch the latest terms from backend and change the state
   */
  useEffect(() => {
    if (axios) {
      fetchAdminClientTerms(axios).then(setTermsAdminClient);
    }
  }, [axios]);

  return (
    <AdminLayoutComponent {...useNavLayout(CurrentPage.AGB)}>
      <div
        className="legal-page-wrapper"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(termsAdminClient),
        }}
      ></div>
    </AdminLayoutComponent>
  );
};

export default AgbPage;
