import { useContext, useEffect, useState } from "react";
import { ConfigContext } from "./App";
import {
  ButtonComponent,
  generateNotification,
  LoaderComponent,
  NotificationTypes,
} from "deinestadtliebt-component-library";
import { useTranslation } from "react-i18next";
import "../styles/NewSignupPageStyle.scss";
import { ReactComponent as ThumpsUpImage } from "../assets/icons/thumbsUpMan.svg";
import { ReactComponent as CheckIcon } from "../assets/icons/simple-check.svg";
import { Link, useHistory } from "react-router-dom";
import {
  Category,
  createEmptyProvider,
  Provider,
} from "../utils/user/User.types";
import { validateMail, validateZipCode } from "../utils/InputUtils";
import { createNewProviderOnServer } from "../utils/signup/SignupUtils";
import { useAxios } from "../utils/AxiosUtil";
import SignupBoxComponent from "../components/signup/SignupBoxComponent";
import { getCoordinatesForLocation } from "../utils/user/UserUtils";
import { TimeEditor } from "../utils/openinghours/OpeningHoursUtils";
import { WeekDay, WeekDays } from "../utils/openinghours/OpeningHours.types";

interface SignupPageProps {}

const NewSignupPage: React.FC<SignupPageProps> = () => {
  const axios = useAxios();
  const history = useHistory();
  const { appConfig } = useContext(ConfigContext);
  const { t } = useTranslation();
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const [currentBoxIndex, setCurrentBoxIndex] = useState<number>(0);
  const [newProvider, setNewProvider] = useState<Provider>(
    createEmptyProvider(true) as Provider
  );

  const [isNameLocationValid, toggleNameLocationValid] =
    useState<boolean>(false);
  const [isContactValid, toggleContactValid] = useState<boolean>(false);
  const [isCategoryValid, toggleCategoryValid] = useState<boolean>(false);
  const [isProviderReady, toggleProviderReady] = useState<boolean>(false);
  const [isCategoryOpened, toggleCategoryOpened] = useState<boolean>(false);
  const [isOpeningtimesOpened, toggleOpeningtimesOpened] =
    useState<boolean>(false);
  const [isOpeningTimesValidFromBox, toggleOpeningTimesValidFromBox] =
    useState<boolean>(false);
  const [isAgbAccepted, toggleAgbAccepted] = useState<boolean>(false);
  const [isOpeningTimesValid, toggleOpeningTimesValid] =
    useState<boolean>(false);
  const [isDesktopVisible, toggleDesktopVisible] = useState<boolean>(false);
  const [localCategory, setLocalCategory] = useState<Category>();

  /**
   * Helper to determine the current width from the window to toggle a
   * boolean for input display
   */
  useEffect(() => {
    const handleResize = () => {
      if (typeof window !== "undefined" && window.document) {
        let desktopWrap: HTMLElement | null =
          document.getElementById("desktop-wrapper");
        if (desktopWrap === null) return;
        toggleDesktopVisible(
          window.getComputedStyle(desktopWrap).display !== "none"
        );
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  /**
   * this useeffect listen to the opened boxes to set needed data
   */
  useEffect(() => {
    if (currentBoxIndex === 3) toggleOpeningtimesOpened(true);
    if (currentBoxIndex === 4) toggleCategoryOpened(true);
  }, [currentBoxIndex]);

  /**
   * this useeffect checks the content of the boxes
   * if everything is good it sets a boolean for green border
   */
  useEffect(() => {
    toggleNameLocationValid(
      !!newProvider.name &&
        !!newProvider.location.street &&
        !!newProvider.location.city &&
        validateZipCode(newProvider.location.zipCode)
    );
    toggleContactValid(
      !!newProvider.phoneNumber &&
        !!newProvider.firstname &&
        !!newProvider.lastname &&
        validateMail(newProvider.mail)
    );
    toggleCategoryValid(!!localCategory && isCategoryOpened);
    // set category if it is set now
    if (!!localCategory && localCategory !== newProvider.category)
      setNewProvider({ ...newProvider, category: localCategory });
    toggleOpeningTimesValid(isOpeningtimesOpened && isOpeningTimesValidFromBox);
    // eslint-disable-next-line
  }, [
    newProvider,
    isCategoryOpened,
    isOpeningtimesOpened,
    localCategory,
    isOpeningTimesValidFromBox,
  ]);

  /**
   * this useeffect checks if all needed things are setup for provider creation
   */
  useEffect(() => {
    toggleProviderReady(
      isNameLocationValid &&
        isContactValid &&
        isCategoryValid &&
        isOpeningTimesValid
    );
  }, [
    isNameLocationValid,
    isContactValid,
    isCategoryValid,
    isOpeningTimesValid,
  ]);

  /**
   * generate Provider on the backend
   */
  const getProviderReadyAndCreate = async (): Promise<void> => {
    toggleLoading(true);
    const loadedCoords: [lng: number, lat: number] =
      await getCoordinatesForLocation(axios, newProvider.location);
    newProvider.location.lng = loadedCoords[0];
    newProvider.location.lat = loadedCoords[1];
    // create Provider
    createNewProviderOnServer(axios, newProvider).then((success) => {
      if (success) {
        history.push("/signup/success", { provider: newProvider });
      } else {
        generateNotification(
          NotificationTypes.ERROR,
          t("notification.title.error.creationFailed"),
          t("notification.content.error.providerCreation")
        );
      }
      toggleLoading(false);
    });
  };

  /**
   * Helper to generate unselected content for opening hours
   *
   * @returns generate JSX.Element
   */
  const generateOpeningHoursEntry = (): JSX.Element => {
    const editor = TimeEditor.from(newProvider.openingHours.standard);

    if (editor.isEmpty()) {
      if (editor.getEmptyContact().length === 0) {
        return <p>{t("newSignup.openingTime.noOpeningtime")}</p>;
      } else {
        return (
          <>
            {editor.getEmptyContact().map((contactType) => {
              return <p>{t(`newSignup.emptyContact.${contactType}`)}</p>;
            })}
          </>
        );
      }
    } else {
      return (
        <div>
          {Object.values(WeekDays).map((dayIndex) => {
            const dayString = WeekDay[dayIndex];
            const dayFrames = editor.getDay(dayIndex);

            return (
              <div
                className="openingtimes-result-entry"
                key={`unselect-show-openingtimes-${dayIndex}`}
              >
                <p>{t(`enum.dayOfWeekShort.${dayString}`)}</p>
                <p className="value-item">
                  {dayFrames.length !== 0
                    ? `${dayFrames[0].open} - ${dayFrames[0].close}${
                        dayFrames[1]
                          ? ` / ${dayFrames[1].open} - ${dayFrames[1].close}`
                          : ""
                      }`
                    : t("newSignup.closed")}
                </p>
              </div>
            );
          })}
        </div>
      );
    }
  };

  return (
    <>
      {isLoading && <LoaderComponent isFullscreen />}

      <div className="new-signup-content-wrapper">
        <div className="new-signup-content-wrapper--top">
          <div className="mobile-wrapper">
            {isDesktopVisible || (
              <div className="header">
                <h1 className="header--header">{appConfig?.platformName}</h1>
                <ThumpsUpImage />
                <p className="header--title">
                  {t("newSignup.welcome", {
                    replace: { platformName: appConfig?.platformName },
                  })}
                </p>
                <p className="header--text">{t("newSignup.text")}</p>
                <p className="header--text">{t("newSignup.text2")}</p>
              </div>
            )}

            <div
              className="content"
              style={{ background: appConfig?.mainColor }}
            >
              <div
                className={"signup-box"}
                onClick={() => setCurrentBoxIndex(1)}
              >
                <div
                  className={[
                    "signup-box--counter",
                    isNameLocationValid ? "everything-good" : undefined,
                    currentBoxIndex === 1 && !isNameLocationValid
                      ? "selected-sgnup-box"
                      : undefined,
                  ].join(" ")}
                >
                  {isNameLocationValid ? <CheckIcon /> : 1}
                </div>
                <div
                  className={[
                    "signup-box--content",
                    isNameLocationValid ? "everything-good" : undefined,
                  ].join(" ")}
                >
                  <div className="unselected-content">
                    <h2>{t("newSignup.nameAndLocation.title")}</h2>
                    {isNameLocationValid && currentBoxIndex !== 1 ? (
                      <p>
                        {newProvider.name}
                        <br />
                        {newProvider.location.street}
                        <br />
                        {newProvider.location.zipCode}{" "}
                        {newProvider.location.city}
                      </p>
                    ) : (
                      <p>{t("newSignup.nameAndLocation.description")}</p>
                    )}
                  </div>
                  {currentBoxIndex === 1 && !isDesktopVisible && (
                    <SignupBoxComponent
                      localCategory={localCategory}
                      setLocalCategory={setLocalCategory}
                      index={1}
                      newProvider={newProvider}
                      setNewProvider={setNewProvider}
                      toggleAgbAccepted={toggleAgbAccepted}
                    />
                  )}
                </div>
              </div>

              <div className="signup-box" onClick={() => setCurrentBoxIndex(2)}>
                <div
                  className={[
                    "signup-box--counter",
                    isContactValid ? "everything-good" : undefined,
                    currentBoxIndex === 2 && !isNameLocationValid
                      ? "selected-sgnup-box"
                      : undefined,
                  ].join(" ")}
                >
                  {isContactValid ? <CheckIcon /> : 2}
                </div>
                <div
                  className={[
                    "signup-box--content",
                    isContactValid ? "everything-good" : undefined,
                  ].join(" ")}
                >
                  <div className="unselected-content">
                    <h2>{t("newSignup.contact.title")}</h2>
                    {isContactValid && currentBoxIndex !== 2 ? (
                      <p>
                        {newProvider.firstname} {newProvider.lastname}
                        <br />
                        {newProvider.mail}
                        <br />
                        {newProvider.phoneNumber}
                      </p>
                    ) : (
                      <p>{t("newSignup.contact.description")}</p>
                    )}
                  </div>
                  {currentBoxIndex === 2 && !isDesktopVisible && (
                    <SignupBoxComponent
                      localCategory={localCategory}
                      setLocalCategory={setLocalCategory}
                      index={2}
                      newProvider={newProvider}
                      setNewProvider={setNewProvider}
                      toggleAgbAccepted={toggleAgbAccepted}
                    />
                  )}
                </div>
              </div>

              <div className="signup-box" onClick={() => setCurrentBoxIndex(3)}>
                <div
                  className={[
                    "signup-box--counter",
                    isOpeningTimesValid ? "everything-good" : undefined,
                    currentBoxIndex === 3 && !isNameLocationValid
                      ? "selected-sgnup-box"
                      : undefined,
                  ].join(" ")}
                >
                  {isOpeningTimesValid ? <CheckIcon /> : 3}
                </div>
                <div
                  className={[
                    "signup-box--content",
                    isOpeningTimesValid ? "everything-good" : undefined,
                  ].join(" ")}
                >
                  <div className="unselected-content">
                    <h2>{t("newSignup.openingTime.title")}</h2>
                    {isOpeningTimesValid &&
                    isOpeningtimesOpened &&
                    currentBoxIndex !== 3 ? (
                      generateOpeningHoursEntry()
                    ) : (
                      <p>{t("newSignup.openingTime.description")}</p>
                    )}
                  </div>
                  {currentBoxIndex === 3 && !isDesktopVisible && (
                    <SignupBoxComponent
                      localCategory={localCategory}
                      setLocalCategory={setLocalCategory}
                      index={3}
                      newProvider={newProvider}
                      setNewProvider={setNewProvider}
                      toggleAgbAccepted={toggleAgbAccepted}
                      toggleOpeningTimesValidState={
                        toggleOpeningTimesValidFromBox
                      }
                    />
                  )}
                </div>
              </div>

              <div className="signup-box" onClick={() => setCurrentBoxIndex(4)}>
                <div
                  className={[
                    "signup-box--counter",
                    isCategoryValid ? "everything-good" : undefined,
                    currentBoxIndex === 4 && !isNameLocationValid
                      ? "selected-sgnup-box"
                      : undefined,
                  ].join(" ")}
                >
                  {isCategoryValid ? <CheckIcon /> : 4}
                </div>
                <div
                  className={[
                    "signup-box--content",
                    isCategoryValid ? "everything-good" : undefined,
                  ].join(" ")}
                >
                  <div className="unselected-content">
                    <h2>{t("newSignup.category.title")}</h2>
                    {isCategoryValid && currentBoxIndex !== 4 ? (
                      <p>{t(`enum.category.${newProvider.category}`)}</p>
                    ) : (
                      <p>{t("newSignup.category.description")}</p>
                    )}
                  </div>
                  {currentBoxIndex === 4 && !isDesktopVisible && (
                    <SignupBoxComponent
                      localCategory={localCategory}
                      setLocalCategory={setLocalCategory}
                      index={4}
                      newProvider={newProvider}
                      setNewProvider={setNewProvider}
                      toggleAgbAccepted={toggleAgbAccepted}
                    />
                  )}
                </div>
              </div>

              <div className="signup-box" onClick={() => setCurrentBoxIndex(5)}>
                <div
                  className={[
                    "signup-box--counter",
                    isAgbAccepted ? "everything-good" : undefined,
                    currentBoxIndex === 5 && !isNameLocationValid
                      ? "selected-sgnup-box"
                      : undefined,
                  ].join(" ")}
                >
                  {isAgbAccepted ? <CheckIcon /> : 5}
                </div>
                <div
                  className={[
                    "signup-box--content",
                    isAgbAccepted ? "everything-good" : undefined,
                  ].join(" ")}
                >
                  <div className="unselected-content">
                    <h2>{t("newSignup.wrap.title")}</h2>

                    <p>{t("newSignup.wrap.description")}</p>
                  </div>
                  {currentBoxIndex === 5 && !isDesktopVisible && (
                    <SignupBoxComponent
                      localCategory={localCategory}
                      setLocalCategory={setLocalCategory}
                      index={5}
                      newProvider={newProvider}
                      setNewProvider={setNewProvider}
                      isAgbAccepted={isAgbAccepted}
                      toggleAgbAccepted={toggleAgbAccepted}
                      isLoading={isLoading}
                      isProviderReady={isProviderReady}
                      getProviderReadyAndCreate={() =>
                        getProviderReadyAndCreate()
                      }
                    />
                  )}
                </div>
              </div>

              <ButtonComponent
                value={t("newSignup.finish")}
                isLoading={isLoading}
                disabled={!isProviderReady || !isAgbAccepted}
                type="button"
                onClick={getProviderReadyAndCreate}
                colors={{ default: { bgColor: "black", fontColor: "white" } }}
              />
            </div>
          </div>

          <div className="desktop-wrapper" id="desktop-wrapper">
            <div className="desktop-wrapper--content">
              <div className="desktop-menu-button-wrapper">
                <ButtonComponent
                  value={t("newSignup.previous")}
                  onClick={() => setCurrentBoxIndex(currentBoxIndex - 1)}
                  type="button"
                  disabled={currentBoxIndex === 0}
                />
                <ButtonComponent
                  value={t("newSignup.next")}
                  onClick={() => setCurrentBoxIndex(currentBoxIndex + 1)}
                  type="button"
                  disabled={currentBoxIndex === 5}
                />
              </div>
              {currentBoxIndex === 0 && (
                <div className="header">
                  <h1 className="header--header">{appConfig?.platformName}</h1>
                  <ThumpsUpImage />
                  <p className="header--title">
                    {t("newSignup.welcome", {
                      replace: { platformName: appConfig?.platformName },
                    })}
                  </p>
                  <p className="header--text">{t("newSignup.text")}</p>
                  <p className="header--text">{t("newSignup.text2")}</p>
                </div>
              )}

              <SignupBoxComponent
                localCategory={localCategory}
                setLocalCategory={setLocalCategory}
                index={currentBoxIndex}
                newProvider={newProvider}
                setNewProvider={setNewProvider}
                radioRangeNumber={2}
                isAgbAccepted={isAgbAccepted}
                toggleAgbAccepted={toggleAgbAccepted}
                isProviderReady={isProviderReady}
                getProviderReadyAndCreate={() => getProviderReadyAndCreate()}
                toggleOpeningTimesValidState={toggleOpeningTimesValidFromBox}
              />
            </div>
          </div>
        </div>

        <div className="footer">
          <div className="footer--item">
            <div>&copy; Copyright {new Date().getFullYear()}</div>
            <div>
              {t("newSignup.brand", {
                replace: { cityName: appConfig?.cityName },
              })}
            </div>
          </div>
          <div className="footer--item footer--link">
            <div>
              <Link to="/privacy">{t("newSignup.privacy")}</Link>
            </div>
            <div>
              <Link to="/imprint">{t("newSignup.imprint")}</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewSignupPage;
