import {
  AdminLayoutComponent,
  generateNotification,
  NotificationTypes,
  PopupComponent,
  RetractableComponent,
  TableComponent,
} from "deinestadtliebt-component-library";
import { CurrentPage } from "../../utils/navigation/Navigation.types";
import { useNavLayout } from "../../utils/navigation/NavigationUtils";
import "../../styles/ProviderConfiguration.style.scss";
import { useTranslation } from "react-i18next";
import { ReactComponent as ImagesIcon } from "../../assets/icons/images.svg";
import { useContext, useEffect, useState } from "react";
import { useAxios } from "../../utils/AxiosUtil";
import { ConfigContext, UserContext } from "../App";
import { fetchUserById } from "../../utils/user/UserUtils";
import {
  createEmptyProvider,
  Provider,
  UserRole,
} from "../../utils/user/User.types";
import SlideshowItemEdit from "../../components/slideshowitemedit/SlideshowItemEdit";
import { createEmptyJob, Job } from "../../utils/job/job.types";
import {
  createEmptySlideshowItem,
  SlideshowItem,
} from "../../utils/appConfig/config.types";
import {
  createJobOnBackend,
  deleteJobById,
  fetchAllJobsForProvider,
  generateJobTableRows,
  updateJobOnBackend,
} from "../../utils/job/JobUtils";
import { createSlideshowItem } from "../../utils/appConfig/configUtils";
import JobEdit from "../../components/jobedit/JobEdit";

interface ProviderAdvertisementConfigurationPageProps {}

const ProviderAdvertisementConfigurationPage: React.FC<
  ProviderAdvertisementConfigurationPageProps
> = () => {
  const { t } = useTranslation();
  const { appConfig } = useContext(ConfigContext);
  const axios = useAxios();
  const { user } = useContext(UserContext);
  // eslint-disable-next-line
  const [localProvider, setLocalProvider] = useState<Provider>(
    createEmptyProvider(false)
  );
  const [currentJob, setCurrentJob] = useState<Job>(createEmptyJob(""));
  const [slideshowItemKey, setSlideshowItemKey] = useState<number>(0);
  const [loadedJobs, setLoadedJobs] = useState<Job[]>([]);
  const [currentSlideShowItem, setCurrentSlideShowItem] =
    useState<SlideshowItem>(createEmptySlideshowItem());
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const [keyJobCreation, setKeyJobCreation] = useState<number>(0);
  const [jobToUpdate, setJobToUpdate] = useState<Job>();

  /**
   * this useEffect sets the provider by fetching it with the given user-id
   */
  useEffect(() => {
    if (!axios || !user) return;

    Promise.all([
      fetchUserById(axios, user.id!, UserRole.PROVIDER),
      fetchAllJobsForProvider(axios, (user as Provider).slugid),
    ]).then(([loadedProvider, loadedJobs]) => {
      setLocalProvider(loadedProvider as Provider);
      setCurrentJob(createEmptyJob((loadedProvider as Provider).slugid!));
      setKeyJobCreation(keyJobCreation + 1);
      setLoadedJobs(loadedJobs);
    });
    // eslint-disable-next-line
  }, [axios, user]);

  /**
   * Helper to create job on the backend
   */
  const createJob = async (
    job: Job,
    jobImage: File | undefined
  ): Promise<void> => {
    createJobOnBackend(axios, job, jobImage).then((newJob) => {
      if (newJob) {
        setCurrentJob(createEmptyJob(localProvider.slugid!));
        loadedJobs.push(newJob);
        setLoadedJobs([...loadedJobs]);
        setKeyJobCreation(keyJobCreation + 1);
        generateNotification(
          NotificationTypes.SUCCESS,
          t("notification.title.success.creationSuccessful"),
          t("notification.content.success.jobCreation")
        );
      } else
        generateNotification(
          NotificationTypes.ERROR,
          t("notification.title.error.error"),
          t("notification.content.error.jobCreation")
        );
    });
  };

  /**
   * Helper to update a local job on the backend and update the local state too
   * @param job data to update
   * @param jobImage optiona file data to append
   */
  const updateJob = async (
    job: Job,
    jobImage: File | undefined
  ): Promise<void> => {
    updateJobOnBackend(axios, job, jobImage).then((updatedJob) => {
      if (updatedJob) {
        setJobToUpdate(undefined);
        let foundIndex: number = loadedJobs.findIndex(
          (item) => item.id === updatedJob.id
        );
        if (foundIndex === -1) {
          generateNotification(
            NotificationTypes.ERROR,
            t("notification.title.error.error"),
            t("notification.content.error.jobUpdate")
          );
          return;
        }
        loadedJobs[foundIndex] = updatedJob;
        setLoadedJobs([...loadedJobs]);
        setKeyJobCreation(keyJobCreation + 1);
        generateNotification(
          NotificationTypes.SUCCESS,
          t("notification.title.success.changesSuccessful"),
          t("notification.content.success.jobUpdate")
        );
      } else
        generateNotification(
          NotificationTypes.ERROR,
          t("notification.title.error.error"),
          t("notification.content.error.jobUpdate")
        );
    });
  };

  /**
   * Helper to delete a job from the sevrer and the list
   * @param id of the job to delete
   */
  const deleteJobOnTheBackend = (id: string): void => {
    deleteJobById(axios, id).then((success) => {
      if (success) {
        setLoadedJobs([...loadedJobs.filter((item) => item.id! !== id)]);
        generateNotification(
          NotificationTypes.SUCCESS,
          t("notification.title.success.deleteSuccessful"),
          t("notification.content.success.deleteSuccessful")
        );
      } else
        generateNotification(
          NotificationTypes.ERROR,
          t("notification.title.error.error"),
          t("notification.content.error.deletion")
        );
    });
  };

  /**
   * Helper to create a slideshow for admin by provider
   * @param slideshowItem to create
   * @param slideshowImage to add it
   * @param mobileSlideshowImage optional
   */
  const createProviderSlideShowItem = (
    slideshowItem: SlideshowItem,
    slideshowImage: File,
    mobileSlideshowImage?: File
  ): void => {
    toggleLoading(true);
    createSlideshowItem(
      axios,
      { ...slideshowItem, providerId: localProvider.id! },
      slideshowImage,
      mobileSlideshowImage
    ).then((success) => {
      if (success) {
        setCurrentSlideShowItem(createEmptySlideshowItem());
        setSlideshowItemKey(slideshowItemKey + 1);
        generateNotification(
          NotificationTypes.SUCCESS,
          t("notification.title.success.creationSuccessful"),
          t("notification.content.success.slideshowUpdate")
        );
      } else
        generateNotification(
          NotificationTypes.ERROR,
          t("notification.title.error.error"),
          t("notification.content.error.slideshowUpdate")
        );
      toggleLoading(false);
    });
  };

  return (
    <AdminLayoutComponent
      {...useNavLayout(CurrentPage.PROVIDER_DETAIL_CONFIGURATION)}
    >
      <div
        style={{ background: appConfig?.highlightColor }}
        className="default-page-headline"
      >
        <h1>{t("providerAdvertisementConfigurationPage.title")}</h1>
      </div>

      <div
        id="provider-advertisement-configuration-wrapper"
        className="default-page-wrapper"
      >
        <RetractableComponent
          title={t("providerAdvertisementConfigurationPage.create")}
          type="border"
          icon={<ImagesIcon />}
        >
          <p>{t("providerAdvertisementConfigurationPage.createInfo")}</p>
          <SlideshowItemEdit
            key={slideshowItemKey}
            onSubmit={createProviderSlideShowItem}
            slideshowItemToEdit={currentSlideShowItem}
            isLoading={isLoading}
          />
        </RetractableComponent>

        <RetractableComponent
          title={t("providerAdvertisementConfigurationPage.jobs")}
          type="border"
          icon={<ImagesIcon />}
        >
          <JobEdit
            providerSlugId={localProvider.slugid!}
            providerId={localProvider.id!}
            jobToEdit={currentJob}
            key={keyJobCreation}
            onSave={(job, jobImage) => createJob(job, jobImage)}
          />

          <TableComponent
            header={t("providerAdvertisementConfigurationPage.tableHeader", {
              returnObjects: true,
            })}
            rows={generateJobTableRows(
              loadedJobs,
              deleteJobOnTheBackend,
              setJobToUpdate
            )}
            maxPageAmount={20}
          />
          <PopupComponent
            open={!!jobToUpdate}
            toggleOpen={() => setJobToUpdate(undefined)}
          >
            <h2>{t("providerAdvertisementConfigurationPage.updateTitle")}</h2>
            <JobEdit
              providerSlugId={localProvider.slugid!}
              providerId={localProvider.id!}
              jobToEdit={jobToUpdate!}
              key={keyJobCreation}
              onSave={(job, jobImage) => updateJob(job, jobImage)}
            />
          </PopupComponent>
        </RetractableComponent>

        {false && (
          <RetractableComponent
            title={"Social Media Beitrag erstellen"}
            type="border"
            icon={<ImagesIcon />}
          >
            TODO saved for future (said by Jochen)
          </RetractableComponent>
        )}
      </div>
    </AdminLayoutComponent>
  );
};

export default ProviderAdvertisementConfigurationPage;
