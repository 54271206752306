import { FileItem } from "../user/User.types";

/**
 * model for a job
 */
export interface Job {
  id?: string;
  providerId: string;
  createDate?: Date;
  active: boolean;
  name: string;
  mail: string;
  phone: string;
  link: string;
  startDate: Date;
  endDate: Date;
  description: string;
  fileItem?: FileItem;
}

/**
 * Helper to generate an empty Job
 * @param providerId to create it for
 * @returns empty job
 */
export const createEmptyJob = (providerId: string): Job => ({
  active: true,
  description: "",
  startDate: new Date(),
  endDate: new Date(new Date().setDate(new Date().getDate() + 14)),
  link: "",
  mail: "",
  name: "",
  phone: "",
  providerId: providerId,
});
