import { useContext } from "react";
import AdminDashboard from "../components/dashboard/admin/AdminDashboard";
import ProviderDashboard from "../components/dashboard/provider/ProviderDashboard";
import { Admin, Provider, UserRole } from "../utils/user/User.types";
import { UserContext } from "./App";

interface DashboardPageProps {}

const DashboardPage: React.FC<DashboardPageProps> = () => {
  const { user } = useContext(UserContext);

  /**
   * Helper to render the correct dashboard
   *
   * @returns correct JSX.Element for dashboard
   */
  const renderCorrectDashboard = (): JSX.Element => {
    switch (user?.role) {
      case UserRole.ADMIN:
        return <AdminDashboard user={user as Admin} />;
      case UserRole.PROVIDER:
        return <ProviderDashboard user={user as Provider} />;
      default:
        return <></>;
    }
  };

  return <>{renderCorrectDashboard()}</>;
};

export default DashboardPage;
