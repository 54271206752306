//
import {
  EmptyContact,
  LocalModel,
  OpeningHours,
  OpeningType,
  TimeFrame,
  TimeFrameCollection,
  TimeFrameInput,
  WeekDay,
  WeekDays,
} from "./OpeningHours.types";
import dayjs, { Dayjs, ConfigType as DayjsInput } from "dayjs";
import dayjsUtc from "dayjs/plugin/utc";
import { v4 } from "uuid";
import { isEqual } from "lodash";
import { Optional } from "../types/TypeUtils";

dayjs.extend(dayjsUtc);

const WEEK_REFERENCE = dayjs("1995-01-01");
const INPUT_REGEX = /^([0-1]?[0-9]|2[0-3]):[0-5]?[0-9]$/;

/**
 * Helper to create empty opening hours.
 *
 * @tested
 * @param empty if true the returned opening hours has setted "standard" as undefined
 * @returns empty OpeningHours object (if empty is true then stardard is undefined)s
 */
export const createEmptyOpeningHours = <E extends boolean>(
  empty: E = false as E
): E extends true ? Optional<OpeningHours, "standard"> : OpeningHours =>
  ({
    standard: empty ? undefined : createEmptyTimeFrameCollection(null),
    custom: [],
  } as unknown as E extends true
    ? Optional<OpeningHours, "standard">
    : OpeningHours);

/**
 * Helper to create a empty time frame collection
 *
 * @tested
 * @param id optional id. Can be null and if its an empty string then an uuid is generated.
 * @param start optional start date
 * @param stop optional stop date
 * @returns new empty time frame collection
 */
export const createEmptyTimeFrameCollection = (
  id: string | null = "",
  start?: Date,
  stop?: Date
): TimeFrameCollection => ({
  id: id === null ? undefined : id || v4(),
  start,
  stop,
  empty: false,
  frames: [],
  emptyContact: [],
});

/**
 *  Helper class to manage and modify time frame collections
 */
export class TimeEditor {
  constructor(public tfc: TimeFrameCollection) {
    tfc.start = tfc.start ? new Date(tfc.start) : undefined;
    tfc.stop = tfc.stop ? new Date(tfc.stop) : undefined;
  }

  /**
   * Check if the time frame collection is marked as empty (formely noOpeningTimes)
   *
   * @tested
   * @returns true if is empty (-> noOpeningTimes)
   */
  isEmpty(): boolean {
    return this.tfc.empty;
  }

  /**
   * Get selected ways to contact the provider if marked as empty
   *
   * @tested
   * @returns list of {@link EmptyContact}s
   */
  getEmptyContact(): EmptyContact[] {
    return this.tfc.emptyContact;
  }

  /**
   * Get opening frames of a specific week day.
   *
   * @tested
   * @param day the week day from {@link WeekDay}
   * @returns list of Time frames for given day
   */
  getDay(day: WeekDay): TimeFrameInput[] {
    return this.getRelativeDay(this.generateDayOffset(day));
  }

  /**
   * Get opening frames of a specific day which is relative to the start date.
   *
   * Example: Imagine start date is Tuesday then
   *
   * day = 0: return Tuesday
   *
   * day = 3: return Friday
   *
   * day = 7: return Tuesday a week later
   *
   * @tested
   * @param day the relative day
   * @returns list of Time frames for given day
   */
  getRelativeDay(day: number): TimeFrameInput[] {
    const timeFrames = this.getTimeFramesForDayOffset(day);

    const result: TimeFrameInput[] = [];

    for (const timeFrame of timeFrames) {
      const dateOpen = dayjs(timeFrame.open * 60000 + +this.getReference());
      const dateClose = dayjs(timeFrame.close * 60000 + +this.getReference());

      result.push({
        open: `${dateOpen.hour().toString().padStart(2, "0")}:${dateOpen
          .minute()
          .toString()
          .padStart(2, "0")}`,
        close: `${dateClose.hour().toString().padStart(2, "0")}:${dateClose
          .minute()
          .toString()
          .padStart(2, "0")}`,
      });
    }

    return result;
  }

  /**
   * Set empty contacts which are showing for the provider (notice: empty need to be enabled)
   *
   * @param emptyContact the empty contacts which the provider selected
   */
  setEmptyContact(emptyContact: EmptyContact[]): void {
    this.tfc.emptyContact = emptyContact;
  }

  /**
   * Set the collection to empty. Then it only shows the emptyContacts instead of opening times.
   *
   * @param isEmpty true if empty
   */
  setEmpty(isEmpty: boolean) {
    this.tfc.empty = isEmpty;
  }

  /**
   * Set the internal name for this collection (custom opening hours only)
   *
   * @param name the internal name
   */
  setName(name?: string) {
    this.tfc.name = name;
  }

  /**
   * Set time frames for a given weekday.
   * Notice: This overwrites existing time frames for the given day
   *
   * @tested
   * @param day the weekday to set
   * @param times a list of time frames
   */
  setDay(day: WeekDay, ...times: TimeFrameInput[]): void {
    return this.setRelativeDay(this.generateDayOffset(day), ...times);
  }

  /**
   * Set time frames for a given relative day. (same as getRelativeDay)
   * Notice: This overwrites existing time frames for the given day
   *
   * @tested
   * @param day the relative day
   * @param times
   */
  setRelativeDay(day: WeekDay | number, ...times: TimeFrameInput[]) {
    const timeFrames: TimeFrame[] = [];

    for (const { open, close } of times) {
      if (!INPUT_REGEX.test(open) || !INPUT_REGEX.test(close))
        throw new Error("Time input invalid format");
      const [openHour, openMinute] = open
        .split(":")
        .map((obj) => Number.parseInt(obj));
      const [closeHour, closeMinute] = close
        .split(":")
        .map((obj) => Number.parseInt(obj));

      const openDiff = Math.floor(
        this.getReference()
          .add(day, "day")
          .hour(openHour)
          .minute(openMinute)
          .diff(this.getReference()) / 60000
      );
      const closeDiff = Math.floor(
        this.getReference()
          .add(day, "day")
          .hour(closeHour)
          .minute(closeMinute)
          .diff(this.getReference()) / 60000
      );

      // if open is higher then close then close is at next day (= add 1440 minutes (1 day) to close)
      if (closeDiff <= openDiff) {
        timeFrames.push({ open: openDiff, close: closeDiff + 1440 });
      } else {
        timeFrames.push({ open: openDiff, close: closeDiff });
      }
    }

    // Clear all belongs to the given day
    this.clearDays(day);

    this.tfc.frames.push(...timeFrames);
  }

  /**
   * Set timeframes for mutiple days at once.
   * This is used for the same date option when modifying
   * opening hoursin admin client.
   *
   * Notice: Any weekday that is not set here will be cleared.
   *
   * @tested
   * @param time list of timeframes to set
   * @param days list of weekday to set
   */
  setDays(time: TimeFrameInput[], days: WeekDay[]): void {
    this.clearDays();
    for (const day of days) {
      this.setDay(day, ...time);
    }
  }

  /**
   * Clear given relative days.
   *
   * @tested
   * @param days list of relative days to clear.
   */
  clearDays(...days: number[]): void {
    if (days.length !== 0) {
      for (const day of days) {
        this.tfc.frames = this.tfc.frames.filter(
          ({ open }) => !(day * 1440 <= open && open < (day + 1) * 1440)
        );
      }
    } else {
      this.tfc.frames = [];
    }
  }

  /**
   * Helper method to automatically fill collection until collection defined stop date.
   *
   * @tested
   */
  setupToStop(): void {
    if (!this.tfc.stop || !this.tfc.start) return;

    const days = Math.floor(
      dayjs(this.tfc.stop).diff(this.tfc.start) / 86400000
    );

    if (days > 6) {
      for (let i = 7; i <= days; i++) {
        this.setRelativeDay(i, ...this.getRelativeDay(i % 7));
      }
    } else if (days < 6) {
      for (let i = days + 1; i <= 6; i++) {
        this.setRelativeDay(i);
      }
    }
  }

  /**
   * Export the timeframecollection
   *
   * @return cloned TimeFrameCollection
   */
  export(): TimeFrameCollection {
    return { ...this.tfc };
  }

  /**
   * Helper to get the reference date (start date)
   *
   * @returns a dayjs object of referance date
   */
  private getReference(): Dayjs {
    return dayjs(this.tfc.start || WEEK_REFERENCE);
  }

  /**
   * Helper to calculate the relative day from a week day.
   *
   * @param day a week day
   * @returns relative day (relative to the reference date)
   */
  private generateDayOffset(day: WeekDay) {
    // day = 3; refDay = 4

    let offset = day - this.getReference().day();

    if (offset < 0) {
      offset += 7;
    }

    return offset;
  }

  /**
   * Helper to timeframes for a relative day
   *
   * @param day relative day
   * @returns list of timeframes
   */
  private getTimeFramesForDayOffset(day: number): TimeFrame[] {
    return this.tfc.frames.filter(
      ({ open }) => day * 1440 <= open && open < (day + 1) * 1440
    );
  }

  /**
   * Helper to simply create a new TimeEditor instance
   *
   * @param tfc the timeframecollection
   * @returns new TimeEditor
   */
  static from(tfc: TimeFrameCollection): TimeEditor {
    return new TimeEditor(tfc);
  }
}

/**
 * Helper to convert a date to the date input of a html input element.
 *
 * @tested
 * @param date to convert
 * @returns formatted date string for html input element
 * @tested
 */
export const convertDateToDateInput = (date: DayjsInput): string => {
  return dayjs(date).format("YYYY-MM-DD").padStart(10, "0");
};

/**
 * Helper to convert back a html date element input to a date
 *
 * @tested
 * @param dateValueInput the date string to convert back
 * @returns a date object from the date string
 * @tested
 */
export const convertDateInputToDate = (dateValueInput: string): Date => {
  const [year, month, day] = dateValueInput
    .split("-")
    .map((obj) => Number.parseInt(obj));

  return dayjs()
    .year(year)
    .month(month - 1)
    .date(day)
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0)
    .toDate();
};

/**
 * Helper to convert local used model for opening time defining to the model in database
 *
 * @tested
 * @param model the local used model
 * @param standard true if the converted model for database is the standard opening hour instead of a custom opening hour
 * @returns the converted TimeFrameCollection
 */
export const convertToDatabaseModel = (
  model: LocalModel,
  standard: boolean
): TimeFrameCollection => {
  const tfc = createEmptyTimeFrameCollection(
    standard ? null : model.id,
    standard
      ? undefined
      : model.start
      ? convertDateInputToDate(model.start)
      : undefined,
    standard
      ? undefined
      : model.stop
      ? convertDateInputToDate(model.stop)
      : undefined
  );
  const editor = TimeEditor.from(tfc);

  editor.setEmpty(model.empty);
  editor.setEmptyContact(model.emptyContact);

  if (model.name) editor.setName(model.name);

  if (model.type === "samedate") {
    editor.setDays(model.sameDay, model.selectedDays);
  } else if (model.type === "normal") {
    for (const day of model.selectedDays) {
      editor.setDay(day, ...model.weekDays[day]);
    }
  }

  editor.setupToStop();

  return editor.export();
};

/**
 * Helper to convert database/provider opening hour model to local model used for defining opening hours.
 *
 * @tested
 * @param model the database/provider model
 * @param init true if setting default settings (like mo-fr selected by default) [default = false]
 * @returns local model for use in opening hours component
 */
export const convertToLocalModel = (
  model: TimeFrameCollection,
  init = false
): LocalModel => {
  const local: Partial<LocalModel> = {};
  const editor = TimeEditor.from(model);
  const type = calculateOpeningType(editor);

  local.id = model.id;
  local.name = model.name;

  local.start = model.start ? convertDateToDateInput(model.start) : "";
  local.stop = model.stop ? convertDateToDateInput(model.stop) : "";

  local.type = type;

  local.empty = model.empty;
  local.emptyContact = model.emptyContact || [];

  local.selectedDays = [];

  local.sameDayBreaks = false;
  local.sameDay = [];

  local.weekDayBreaks = [];
  local.weekDays = [];

  if (type === "samedate") {
    const localSelectedDays: WeekDay[] = [];
    let localBreak: boolean = false;
    let localSameDayFrames: TimeFrameInput[] = [];
    const localWeekDayFrames: TimeFrameInput[][] = [];
    const localWeekDayBreaks: WeekDay[] = [];
    for (const day of WeekDays) {
      const dayFrames = editor.getDay(day);
      if (dayFrames.length !== 0) {
        if (dayFrames.length === 2) {
          localBreak = true;
          localWeekDayBreaks.push(day);
        }
        localSelectedDays.push(day);
        if (localSameDayFrames.length === 0) localSameDayFrames = dayFrames;
      }
      localWeekDayFrames[day] = dayFrames;
    }
    local.sameDayBreaks = localBreak;
    local.selectedDays = localSelectedDays;
    local.sameDay = localSameDayFrames;
    local.weekDays = localWeekDayFrames;
    local.weekDayBreaks = localWeekDayBreaks;
  } else if (type === "normal") {
    const localWeekDayFrames: TimeFrameInput[][] = [];
    const localSelectedDays: WeekDay[] = [];
    const localSelectedBreaks: WeekDay[] = [];
    for (const day of WeekDays) {
      const weekDayFrames = editor.getDay(day);
      localWeekDayFrames[day] = weekDayFrames;
      if (weekDayFrames.length > 0) localSelectedDays.push(day);
      if (weekDayFrames.length === 2) localSelectedBreaks.push(day);
    }
    local.weekDays = localWeekDayFrames;
    local.selectedDays = localSelectedDays;
    local.weekDayBreaks = localSelectedBreaks;
  }

  if (init && local.selectedDays.length === 0) {
    local.selectedDays = [1, 2, 3, 4, 5];
  }

  if (local.sameDay.length === 0) {
    local.sameDay[0] = { open: "", close: "" };
  }

  local.weekDays.forEach((weekDay, index) => {
    if (!weekDay || (weekDay && weekDay.length === 0)) {
      local.weekDays![index] = [{ open: "", close: "" }];
    }
  });

  return { ...local } as LocalModel;
};

/**
 * Helper to automatically detect the opening type from a TimeEditor. (empty, samedate or normal)
 *
 * @tested
 * @param editor the timeeditor to use
 * @returns a OpeningType
 */
export const calculateOpeningType = (editor: TimeEditor): OpeningType => {
  if (editor.isEmpty()) {
    return "empty";
  } else {
    let sameDate = true;
    let latestFrames = undefined;

    for (const day of Object.values(WeekDays)) {
      const frames = editor.getDay(day);

      if (frames.length !== 0) {
        if (latestFrames && !isEqual(latestFrames, frames)) {
          sameDate = false;
          break;
        }
        latestFrames = frames;
      }
    }

    return sameDate ? "samedate" : "normal";
  }
};
