import {
  AdminLayoutComponent,
  ButtonComponent,
  CheckboxComponent,
  RetractableComponent,
} from "deinestadtliebt-component-library";
import { CurrentPage } from "../../utils/navigation/Navigation.types";
import { useNavLayout } from "../../utils/navigation/NavigationUtils";
import "../../styles/ProviderConfiguration.style.scss";
import { useTranslation } from "react-i18next";
import { ReactComponent as ImagesIcon } from "../../assets/icons/images.svg";
import { useContext, useEffect, useState } from "react";
import { useAxios } from "../../utils/AxiosUtil";
import { ConfigContext, UserContext } from "../App";
import { fetchUserById } from "../../utils/user/UserUtils";
import {
  createEmptyProvider,
  Provider,
  UserRole,
} from "../../utils/user/User.types";
import { useKeycloak } from "@react-keycloak/web";

interface ProviderAboConfigurationPageProps {}

const ProviderAboConfigurationPage: React.FC<
  ProviderAboConfigurationPageProps
> = () => {
  const { t } = useTranslation();
  const { appConfig } = useContext(ConfigContext);
  const axios = useAxios();
  const { user } = useContext(UserContext);
  const { keycloak } = useKeycloak();
  // eslint-disable-next-line
  const [localProvider, setLocalProvider] = useState<Provider>(
    createEmptyProvider(false)
  );
  const [isSureForTermination, toggleSureForTermination] =
    useState<boolean>(false);
  /**
   * this useEffect sets the provider by fetching it with the given user-id
   */
  useEffect(() => {
    if (!axios || !user) return;
    fetchUserById(axios, user.id!, UserRole.PROVIDER).then((loadedprovider) => {
      setLocalProvider(loadedprovider as Provider);
    });
    // eslint-disable-next-line
  }, [axios, user]);

  /**
   * Helper to terminate a provider from the platform
   */
  const terminateProvider = (): void => {
    // TODO terminate for sure
    keycloak.logout();
  };

  return (
    <AdminLayoutComponent
      {...useNavLayout(CurrentPage.PROVIDER_DETAIL_CONFIGURATION)}
    >
      <div
        style={{ background: appConfig?.highlightColor }}
        className="default-page-headline"
      >
        <h1>{t("providerAboConfigurationPage.title")}</h1>
      </div>

      <div
        className="default-page-wrapper"
        id="provider-abo-configuration-wrapper"
      >
        {false && (
          <RetractableComponent
            title={t("providerAboConfigurationPage.overview")}
            type="border"
            icon={<ImagesIcon />}
          >
            <p>TODO: Inhalt (Preisübersicht/Grafiken)</p>
          </RetractableComponent>
        )}

        <RetractableComponent
          title={t("providerAboConfigurationPage.terminate")}
          type="border"
          icon={<ImagesIcon />}
        >
          <CheckboxComponent
            checked={isSureForTermination}
            onCheck={() => toggleSureForTermination(!isSureForTermination)}
            value={t("providerAboConfigurationPage.terminateForSure")}
          />
          <ButtonComponent
            disabled={!isSureForTermination}
            value={t("providerAboConfigurationPage.terminate")}
            onClick={terminateProvider}
            colors={{
              default: {
                bgColor: "#ff3737",
                borderColor: "#ff3737",
                fontColor: "white",
              },
              hover: {
                bgColor: "#f81919",
                borderColor: "#f81919",
                fontColor: "white",
              },
            }}
          />
        </RetractableComponent>
      </div>
    </AdminLayoutComponent>
  );
};

export default ProviderAboConfigurationPage;
