import { useMemo } from "react";
import {
  convertInRelativeTimeString,
  getNameFromMember,
} from "../../../utils/mailbox/MailboxUtils";
import { MailboxItemProps } from "./MailboxItem.types";

export const MailboxItem: React.FC<MailboxItemProps> = ({
  thread,
  odd,
  open,
  userId,
  onClick,
}) => {
  /**
   * Helper to get opposite message partner to always display
   * the opposite instead of the current user.
   *
   * Notice: admin user as id = null
   *
   * @returns id of the opposite or undefined if nothing found.
   */
  const getOppositeId = (): string | null | undefined => {
    return thread.members.find((member) => member.id !== userId)?.id;
  };

  /**
   * This memo set true if message is unreaded. It detects if the message status was changed.
   */
  const isUnreaded = useMemo(() => {
    return (
      thread.messages.filter((message) => !message.seenBy.includes(userId))
        .length > 0
    );
  }, [thread, userId]);

  /**
   * This memo always points to the latest message.
   */
  const latestMessage = useMemo(
    () =>
      thread.messages ? thread.messages[thread.messages.length - 1] : undefined,
    [thread]
  );

  return (
    <div
      className={["mailbox-item", odd ? "odd" : "", isUnreaded ? "unread" : ""]
        .join(" ")
        .trim()}
      onClick={onClick}
    >
      {open && <div className="mailbox-item-open" />}
      <div className="mailbox-item-header">
        {isUnreaded && <div className="mailbox-item-status" />}
        <div className="mailbox-item-opposite">
          {getNameFromMember(getOppositeId(), thread)}
        </div>
        <div className="mailbox-item-time">
          {convertInRelativeTimeString(new Date(latestMessage!.createdAt))}
        </div>
      </div>
      <div className="mailbox-item-subject">{thread.subject}</div>
      <div className="mailbox-item-message">{latestMessage?.content}</div>
    </div>
  );
};
