import {
  InputComponent,
  TableComponent,
} from "deinestadtliebt-component-library";
import { useTranslation } from "react-i18next/";
import { Action } from "../../../utils/action/Action.types";
import { DateState } from "../../../utils/activity/Activity.types";
import { Event } from "../../../utils/event/Event.types";
import { generateDelayTableRows } from "../../../utils/state/StateUtils";

interface DelayComponentProps {
  activityToEdit: Event | Action | undefined;
  setActivityToEdit: Function;
}

const DelayComponent: React.FC<DelayComponentProps> = ({
  activityToEdit,
  setActivityToEdit,
}) => {
  const { t } = useTranslation();

  /**
   * Method to handle value changes for delay-component
   * @param newValue new value to be assigned
   * @param index index of entry to be adjusted (not use for start-/endDate)
   * @param valueToChange determines which property will be changed
   */
  const handleDelayChange = (
    newValue: string,
    index: number,
    valueToChange:
      | "date"
      | "state"
      | "startTime"
      | "endTime"
      | "status"
      | "startDate"
      | "endDate"
  ): void => {
    let localActivityToEdit: Event | Action = {
      ...(activityToEdit as Event | Action),
    };
    switch (valueToChange) {
      case "date":
        localActivityToEdit.activityDates[index].date = newValue
          ? new Date(newValue)
          : new Date(activityToEdit?.activityDates?.[index].date || new Date());
        break;
      case "state":
        localActivityToEdit.activityDates[index].state =
          (newValue as DateState) || DateState.READY;
        break;
      case "startTime":
        localActivityToEdit.activityDates[index].startTime = newValue
          ? newValue
          : localActivityToEdit.activityDates?.[index].startTime || "";
        localActivityToEdit.startTime = newValue
          ? newValue
          : localActivityToEdit?.startTime || "";
        break;
      case "endTime":
        localActivityToEdit.activityDates[index].endTime = newValue
          ? newValue
          : localActivityToEdit.activityDates?.[index].endTime || "";
        localActivityToEdit.endTime = newValue
          ? newValue
          : localActivityToEdit?.endTime || "";
        break;
      case "status":
        localActivityToEdit.activityDates[index].statusDetail = newValue
          ? newValue
          : localActivityToEdit.activityDates[index].statusDetail || "";
        break;
      case "startDate":
        localActivityToEdit.startDate = newValue
          ? new Date(newValue)
          : new Date(activityToEdit?.startDate || new Date());
        break;
      case "endDate":
        localActivityToEdit.endDate = newValue
          ? new Date(newValue)
          : new Date(activityToEdit?.endDate || new Date());
        break;
      default:
        return;
    }
    localActivityToEdit.activityDates[index].state = DateState.DELAYED;
    setActivityToEdit(localActivityToEdit);
  };

  return (
    <>
      <div className="delay-wrapper">
        {!!activityToEdit?.startDate ? (
          <>
            <div className="activity-date">
              <InputComponent
                value={
                  new Date(
                    activityToEdit?.startDate || new Date()
                  )?.toISOString() || ""
                }
                type="date"
                isFrameless
                onChange={(value) => handleDelayChange(value, 0, "startDate")}
              />
              {!!activityToEdit?.endDate ? (
                <>
                  {" - "}
                  <InputComponent
                    value={
                      new Date(
                        activityToEdit?.endDate || new Date()
                      )?.toISOString() || ""
                    }
                    type="date"
                    isFrameless
                    onChange={(value) => handleDelayChange(value, 0, "endDate")}
                  />
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="activity-time">
              <InputComponent
                value={activityToEdit?.startTime || "00:00"}
                isFrameless
                type="time"
                onChange={(value) => handleDelayChange(value, 0, "startTime")}
              />
              -
              <InputComponent
                value={activityToEdit?.endTime || "00:00"}
                isFrameless
                type="time"
                onChange={(value) => handleDelayChange(value, 0, "endTime")}
              />
            </div>
          </>
        ) : (
          <div className="delay-wrapper--table">
            <TableComponent
              header={t("providerEventsConfigurationPage.delayTableHeader", {
                returnObjects: true,
              })}
              rows={generateDelayTableRows(activityToEdit!, handleDelayChange)}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default DelayComponent;
