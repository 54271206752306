import {
  AdminLayoutComponent,
  ButtonComponent,
  generateNotification,
  InputComponent,
  NotificationTypes,
  RetractableComponent,
  TableComponent,
  TagComponent,
} from "deinestadtliebt-component-library";
import { CurrentPage } from "../../utils/navigation/Navigation.types";
import { useNavLayout } from "../../utils/navigation/NavigationUtils";
import "../../styles/ProviderConfiguration.style.scss";
import { useTranslation } from "react-i18next";
import { ReactComponent as ImagesIcon } from "../../assets/icons/images.svg";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAxios } from "../../utils/AxiosUtil";
import { ConfigContext, UserContext } from "../App";
import {
  createCustomTagOnBackend,
  fetchUserById,
  generateCustomTagsTableRows,
  updateProviderProfileData,
} from "../../utils/user/UserUtils";
import {
  Category,
  createEmptyCustomTag,
  createEmptyProvider,
  CustomTag,
  DetailType,
  MainType,
  Provider,
  TradeAndServiceCategories,
  UserRole,
} from "../../utils/user/User.types";
import {
  convertEnumToTag,
  getCorrectAmountOfDetailTypesForCategory,
  getCorrectAmountOfMainTypesForCategory,
  getCorrectDetailTypeForCategory,
  getCorrectMainTypeForCategory,
  getFilteredTags,
} from "../../utils/signup/SignupUtils";
import { generateGoodToKnowTable } from "../../utils/appConfig/configUtils";
import MenuEdit from "../../components/menu/MenuEdit";
import PriceListEdit from "../../components/pricelist/PriceListEdit";
import SpecialOpeningTimeEdit from "../../components/specialopeningtimeedit/SpecialOpeningTimeEdit";
import HolidayConfiguration from "../../components/holidayEdit/HolidayEdit";
import SpecialNoteEdit from "../../components/specialnoteedit/SpecialNoteEdit";

interface ProviderConfigurationPageProps {}

const ProviderConfigurationPage: React.FC<
  ProviderConfigurationPageProps
> = () => {
  const { t } = useTranslation();
  const { appConfig } = useContext(ConfigContext);
  const axios = useAxios();
  const { user } = useContext(UserContext);
  const { hash: locationHash } = useLocation();
  const [localProvider, setLocalProvider] = useState<Provider>(
    createEmptyProvider(false)
  );
  const [currentCustomTag, setCurrentCustomTag] = useState<CustomTag>(
    createEmptyCustomTag()
  );
  const [isLoading, toggleIsLoading] = useState<boolean>(false);
  const [tradeTags, setTradeTags] = useState<DetailType[]>([]);
  const [serviceTags, setServiceTags] = useState<DetailType[]>([]);
  const [healthTags, setHealthTags] = useState<DetailType[]>([]);
  const [craftTags, setCraftTags] = useState<DetailType[]>([]);
  const [tradeAndServiceCat, setTradeAndServiceCat] =
    useState<TradeAndServiceCategories>(TradeAndServiceCategories.TRADE);
  const specialOpeningTimesElement = useRef<HTMLParagraphElement>(null);

  /**
   * this useEffect sets the provider by fetching it with the given user-id
   */
  useEffect(() => {
    if (!axios || !user) return;
    fetchUserById(axios, user.id!, UserRole.PROVIDER).then((loadedprovider) => {
      setLocalProvider(loadedprovider as Provider);
    });
    // eslint-disable-next-line
  }, [axios, user]);

  /**
   *  This effect handles the scroll behavior when the user
   *  was redirected to the special opening times.
   *  It shows the special opening times centered on the viewport.
   */
  useEffect(() => {
    if (
      specialOpeningTimesElement.current &&
      locationHash === "#specialOpeningTimes"
    ) {
      specialOpeningTimesElement.current.scrollIntoView({ block: "center" });
    }
  }, [locationHash, specialOpeningTimesElement]);

  /**
   * Creates array for selected detail-tags and assigns them to provider-object
   * @param category enum of type Category
   * @param tags  array of tag-objects to be assigned into provider-object
   */
  const setDetailTypesAndCategory = (
    category: Category,
    tags: DetailType[]
  ): void => {
    let selectedDetailTypes: DetailType[] = [];
    tags.forEach((tag) => selectedDetailTypes.push(tag));
    setLocalProvider({
      ...localProvider,
      detailTypes: selectedDetailTypes,
      category: category,
    });
  };

  /**
   *  Saves currently selected tags into respective useState and then lines them up and saves them into provider
   * @param category type of Categories, determines the switch case
   * @param tags Array of DetailTypes that will be saved
   */
  const setTradeAndServiceTags = (
    category: TradeAndServiceCategories,
    tags: DetailType[]
  ): void => {
    const tagList: DetailType[] = [];
    switch (category) {
      case TradeAndServiceCategories.TRADE:
        setTradeTags(tags);
        break;
      case TradeAndServiceCategories.SERVICE:
        setServiceTags(tags);
        break;
      case TradeAndServiceCategories.HEALTH:
        setHealthTags(tags);
        break;
      case TradeAndServiceCategories.CRAFT:
        setCraftTags(tags);
        break;
    }
    setDetailTypesAndCategory(
      Category.SHOPPING,
      tagList.concat(tradeTags, serviceTags, healthTags, craftTags)
    );
  };

  /**
   * Helper to create a custom tag on the server
   */
  const createAndOrderCustomTag = (): void => {
    toggleIsLoading(true);
    createCustomTagOnBackend(axios, currentCustomTag, localProvider.id!).then(
      (success) => {
        if (success) {
          let localCustomTags: CustomTag[] = [...localProvider.customTags];
          localCustomTags.push(currentCustomTag);
          setLocalProvider({
            ...localProvider,
            customTags: localCustomTags,
          });
          setCurrentCustomTag(createEmptyCustomTag());
        } else
          generateNotification(
            NotificationTypes.ERROR,
            t("notification.title.error.error"),
            t("notification.content.error.customTag")
          );
        toggleIsLoading(false);
      }
    );
  };

  /**
   * Helper to update the profile data of a provider on the backend
   */
  const updateProviderProfileDataOnClick = (): void => {
    toggleIsLoading(true);
    updateProviderProfileData(axios, { ...localProvider }).then((success) => {
      if (success) {
        generateNotification(
          NotificationTypes.SUCCESS,
          t("notification.title.success.changesSuccessful"),
          t("notification.content.success.configChanges")
        );
      } else
        generateNotification(
          NotificationTypes.SUCCESS,
          t("notification.title.error.creationFailed"),
          t("notification.content.error.configChanges")
        );
      toggleIsLoading(false);
    });
  };

  /**
   * Helper to toggle the selectionof a good to know item
   * @param goodToKnowId id of element to toggle
   */
  const toggleGoodToKnowElement = (goodToKnowId: string): void => {
    if (localProvider.goodToKnowItems.includes(goodToKnowId)) {
      setLocalProvider({
        ...localProvider,
        goodToKnowItems: localProvider.goodToKnowItems.filter(
          (item) => item !== goodToKnowId
        ),
      });
    } else {
      localProvider.goodToKnowItems.push(goodToKnowId);
      setLocalProvider({ ...localProvider });
    }
    updateProviderProfileDataOnClick();
  };

  return (
    <AdminLayoutComponent
      {...useNavLayout(CurrentPage.PROVIDER_DETAIL_CONFIGURATION)}
    >
      <div
        style={{ background: appConfig?.highlightColor }}
        className="default-page-headline"
      >
        <h1>{t("providerConfigurationPage.title")}</h1>
      </div>
      <div id="provider-configuration-wrapper" className="default-page-wrapper">
        <RetractableComponent
          title={t("providerConfigurationPage.tagsTitle")}
          type="border"
          icon={<ImagesIcon />}
        >
          {Object.values(Category).map(
            (currentCategory, currentCategoryIndex) => (
              <div
                key={`category-rectangle-index-${currentCategoryIndex}-${localProvider.category}`}
              >
                <p>{t(`enum.category.${currentCategory}`)}</p>
                <TagComponent
                  maxSelectAmount={getCorrectAmountOfMainTypesForCategory(
                    currentCategory
                  )}
                  selected={localProvider.mainTypes}
                  tags={convertEnumToTag(
                    getCorrectMainTypeForCategory(Category[currentCategory]),
                    "MainType"
                  )}
                  onChange={(tags) => {
                    setLocalProvider({
                      ...localProvider,
                      mainTypes: [...(tags as MainType[])],
                      category: currentCategory,
                    });
                  }}
                />
              </div>
            )
          )}
          <ButtonComponent
            value={t("providerConfigurationPage.buttons.save")}
            type="button"
            isLoading={isLoading}
            key={localProvider.category}
            onClick={() => updateProviderProfileDataOnClick()}
          />
        </RetractableComponent>

        <RetractableComponent
          title={t("providerConfigurationPage.detailTags")}
          type="border"
          icon={<ImagesIcon />}
        >
          {localProvider.category === Category.SHOPPING ? (
            <div className="signup-component--right-content--detailTags">
              <div className="signup-component--right-content--detailTags-buttons">
                <ButtonComponent
                  className={
                    tradeAndServiceCat === TradeAndServiceCategories.TRADE
                      ? "signup-component--right-content--detailTags-buttons-selected"
                      : ""
                  }
                  onClick={() =>
                    setTradeAndServiceCat(TradeAndServiceCategories.TRADE)
                  }
                  value={t("enum.tradeService.TRADE")}
                />
                <ButtonComponent
                  className={
                    tradeAndServiceCat === TradeAndServiceCategories.SERVICE
                      ? "signup-component--right-content--detailTags-buttons-selected"
                      : ""
                  }
                  onClick={() =>
                    setTradeAndServiceCat(TradeAndServiceCategories.SERVICE)
                  }
                  value={t("enum.tradeService.SERVICE")}
                />
                <ButtonComponent
                  className={
                    tradeAndServiceCat === TradeAndServiceCategories.HEALTH
                      ? "signup-component--right-content--detailTags-buttons-selected"
                      : ""
                  }
                  onClick={() =>
                    setTradeAndServiceCat(TradeAndServiceCategories.HEALTH)
                  }
                  value={t("enum.tradeService.HEALTH")}
                />
                <ButtonComponent
                  className={
                    tradeAndServiceCat === TradeAndServiceCategories.CRAFT
                      ? "signup-component--right-content--detailTags-buttons-selected"
                      : ""
                  }
                  onClick={() =>
                    setTradeAndServiceCat(TradeAndServiceCategories.CRAFT)
                  }
                  value={t("enum.tradeService.CRAFT")}
                />
              </div>
              <div className="signup-component--right-content--detailTags-tags">
                <TagComponent
                  key={tradeAndServiceCat}
                  maxSelectAmount={getCorrectAmountOfDetailTypesForCategory(
                    localProvider.category
                  )}
                  selected={localProvider.detailTypes}
                  tags={getFilteredTags(tradeAndServiceCat)}
                  onChange={(tags) => {
                    setTradeAndServiceTags(
                      tradeAndServiceCat,
                      tags as DetailType[]
                    );
                  }}
                />
              </div>
            </div>
          ) : (
            <TagComponent
              maxSelectAmount={getCorrectAmountOfDetailTypesForCategory(
                localProvider.category
              )}
              tags={convertEnumToTag(
                getCorrectDetailTypeForCategory(localProvider.category),
                "DetailType"
              )}
              selected={localProvider.detailTypes}
              onChange={(tags) =>
                setDetailTypesAndCategory(
                  localProvider.category,
                  tags as DetailType[]
                )
              }
            />
          )}
          <ButtonComponent
            value={t("providerConfigurationPage.buttons.save")}
            type="button"
            onClick={() => {
              updateProviderProfileData(axios, localProvider).then(
                (success) => {
                  if (success)
                    generateNotification(
                      NotificationTypes.SUCCESS,
                      t("notification.title.success.changesSuccessful"),
                      t("notification.content.success.configChanges")
                    );
                  else
                    generateNotification(
                      NotificationTypes.SUCCESS,
                      t("notification.title.error.creationFailed"),
                      t("notification.content.error.configChanges")
                    );
                }
              );
            }}
          />
        </RetractableComponent>

        <RetractableComponent
          title={t("providerConfigurationPage.customTags")}
          type="border"
          icon={<ImagesIcon />}
        >
          <p>{t("providerConfigurationPage.detailTagsOrder")}</p>
          <InputComponent
            placeholder={t("providerConfigurationPage.inputs.name")}
            value={currentCustomTag.text}
            onChange={(value) =>
              setCurrentCustomTag({ ...currentCustomTag, text: value })
            }
          />
          <ButtonComponent
            value={t("providerConfigurationPage.buttons.save")}
            type="button"
            isLoading={isLoading}
            onClick={() => createAndOrderCustomTag()}
          />
          <div>
            <TableComponent
              maxPageAmount={20}
              header={t("providerConfigurationPage.tableHeaderCustomTag", {
                returnObjects: true,
              })}
              rows={generateCustomTagsTableRows(localProvider.customTags)}
            />
          </div>
        </RetractableComponent>

        {localProvider.category === Category.EAT_DRINK ? (
          <MenuEdit
            selectedProvider={localProvider}
            setSelectedProvider={setLocalProvider}
            automaticStore
          />
        ) : (
          <PriceListEdit
            selectedProvider={localProvider}
            setSelectedProvider={setLocalProvider}
            automaticStore
          />
        )}
        <SpecialOpeningTimeEdit
          selectedProvider={localProvider}
          setSelectedProvider={setLocalProvider}
        />

        <SpecialNoteEdit
          localProvider={localProvider}
          setLocalProvider={setLocalProvider}
        />

        <RetractableComponent
          title={t("providerConfigurationPage.holidays")}
          type="border"
          icon={<ImagesIcon />}
        >
          <HolidayConfiguration
            provider={localProvider}
            setProvider={setLocalProvider}
          />
        </RetractableComponent>

        <RetractableComponent
          title={t("providerConfigurationPage.titleGoodToKnow")}
          type="border"
          icon={<ImagesIcon />}
        >
          <TableComponent
            maxPageAmount={20}
            header={t("providerConfigurationPage.tableHeaderGoodToKnow", {
              returnObjects: true,
            })}
            rows={generateGoodToKnowTable(
              appConfig!,
              localProvider,
              toggleGoodToKnowElement
            )}
          />
        </RetractableComponent>
      </div>
    </AdminLayoutComponent>
  );
};

export default ProviderConfigurationPage;
