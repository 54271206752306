/**
 * Validates an inputed email string via reg-ex
 * @param input string
 * @returns boolean
 * @tested
 */
export const validateMail = (input: string): boolean => {
  const re =
    // eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return (
    input !== undefined &&
    input !== null &&
    input.length > 0 &&
    re.test(String(input).toLowerCase())
  );
};

/**
 * Validates an inputed url string via reg-ex
 * @param url as a string
 * @returns boolean
 * @tested
 */
export const validateUrl = (url?: string): boolean => {
  if (!url) return false;
  const re =
    // eslint-disable-next-line
    /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;
  return re.test(String(url).toLowerCase());
};

/**
 * Validates an inputed zip code string via reg-ex
 * @param url as a string
 * @returns boolean
 * @tested
 */
export const validateZipCode = (zipCode?: string): boolean => {
  if (!zipCode) return false;
  const re =
    // eslint-disable-next-line
    /^(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})$/;
  return re.test(String(zipCode).toLowerCase());
};

/**
 * Helper to generate correct url, if it is a youtube url create
 * embedded url for it
 * @param url strin to check and convert
 * @returns converted url
 * @tested
 */
export const generateCorrectYoutubeUrl = (url: string): string => {
  let urlToUse: string = "";
  if (validateUrl(url)) {
    // check if url is youtube
    if (getYoutubeIdFromUrl(url) !== "") {
      urlToUse = `https://www.youtube.com/embed/${getYoutubeIdFromUrl(
        url
      )}?autoplay=1&origin=${process.env.REACT_APP_BASE_CLIENT_URL}`;
    } else urlToUse = url;
  } else
    urlToUse = `https://www.youtube.com/embed/${url}?autoplay=1&origin=${process.env.REACT_APP_BASE_CLIENT_URL}`;
  return urlToUse;
};

/**
 * Helper to extract the youtube id from a valid youtube url string
 * @param url given youtube url
 * @returns found id or empty string
 * @tested
 */
export const getYoutubeIdFromUrl = (url: string): string => {
  if (url !== undefined || url !== "") {
    var regExp =
      // eslint-disable-next-line
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
    var match = url.match(regExp);
    if (match && match[2].length === 11) {
      return match[2];
    }
  }
  return "";
};
