import {
  DropdownComponent,
  DropdownOption,
  FileInputComponent,
  InputComponent,
} from "deinestadtliebt-component-library";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  RequiredCompanionProps,
  checkCertainPropOfCompanion,
  getCorrectImageDataForActivityType,
} from "../../../utils/activity/ActivityUtils";
import { FileType } from "../../../utils/appConfig/config.types";
import {
  getBase64ForFile,
  transformDataURLtoFile,
} from "../../../utils/event/EventUtils";
import {
  EventLocation,
  Location,
  Provider,
} from "../../../utils/user/User.types";
import StockImage from "../../stockimage/StockImage";
import WysiwygComponent from "../../wysiwyg/WysiwygComponent";
import { ActivityEditCompanion } from "../EventEditBoxComponent.types";

interface Step2Props {
  isDesktop: boolean;
  isLoading: boolean;
  provider: Provider;
  activityCompanion: ActivityEditCompanion;
  setactivityCompanion(event: ActivityEditCompanion): void;
  stepCompletion?: boolean;
}

const ActivityStep2: React.FC<Step2Props> = ({
  isDesktop,
  isLoading,
  provider,
  activityCompanion,
  setactivityCompanion,
  stepCompletion,
}) => {
  const { t } = useTranslation();
  const [dropdownEventLocations, setDropdownEventLocations] = useState<
    DropdownOption[]
  >([]);
  const [selectedEventLocationOption, setSelectedEventLocationOption] =
    useState<string>("-1");
  const [currentEventFlyerFile, setCurrentEventFlyerFile] = useState<File>();
  const [localStorageFlyerFileString, setLocalStorageFlyerFileString] =
    useState<string>();
  const [currentEventImageFile, setCurrentEventImageFile] = useState<File>();
  const [currentEventImagePreviewFile, setCurrentEventImagePreviewFile] =
    useState<File>();
  const [localStorageImageFileString, setLocalStorageImageFileString] =
    useState<string>();
  const [
    localStorageImagePreviewFileString,
    setLocalStorageImagePreviewFileString,
  ] = useState<string>();

  /**
   * useEffect used to assign frame-data from currentEventImageFile to activityCompanion.event.image
   * or currentEventImagePreviewFile to activityCompanion.event.previewImage
   */
  useEffect(() => {
    let localActivityCompanion: ActivityEditCompanion = {
      ...activityCompanion,
    };
    if (currentEventImageFile !== undefined) {
      getBase64ForFile(currentEventImageFile!, setLocalStorageImageFileString);
      localActivityCompanion = {
        ...localActivityCompanion,
        currentImageFile: currentEventImageFile,
        image: {
          filename: currentEventImageFile?.name || "",
          id: currentEventImageFile?.name || "",
          type: FileType.IMAGE,
          originalFilename: currentEventImageFile?.name || "",
          mainimage: false,
        },
      };
    }
    if (currentEventImagePreviewFile !== undefined) {
      getBase64ForFile(
        currentEventImagePreviewFile!,
        setLocalStorageImagePreviewFileString
      );
      localActivityCompanion = {
        ...localActivityCompanion,
        currentImagePreviewFile: currentEventImagePreviewFile,
        previewImage: {
          filename: currentEventImagePreviewFile?.name || "",
          id: currentEventImagePreviewFile?.name || "",
          type: FileType.IMAGE,
          originalFilename: currentEventImagePreviewFile?.name || "",
          mainimage: false,
        },
      };
    }
    setactivityCompanion(localActivityCompanion);
    // eslint-disable-next-line
  }, [currentEventImageFile, currentEventImagePreviewFile]);
  /**
   * useEffect used to assign frame-data from currentEventFlyerFile to activityCompanion.event.flyer
   */
  useEffect(() => {
    getBase64ForFile(currentEventFlyerFile!, setLocalStorageFlyerFileString);
    setactivityCompanion({
      ...activityCompanion,
      currentFlyerFile: currentEventFlyerFile,
      flyer: {
        filename: currentEventFlyerFile?.name || "",
        id: currentEventFlyerFile?.name || "",
        type: FileType.DOCUMENT,
        originalFilename: currentEventFlyerFile?.name || "",
        mainimage: false,
      },
    });
    // eslint-disable-next-line
  }, [currentEventFlyerFile]);

  /**
   * useEffect used to assign generated base64String to activityCompanion.image or previewImage
   */
  useEffect(() => {
    if (localStorageImageFileString !== undefined)
      setactivityCompanion({
        ...activityCompanion,
        imageString: localStorageImageFileString,
      });
    if (localStorageImagePreviewFileString !== undefined)
      setactivityCompanion({
        ...activityCompanion,
        previewImageString: localStorageImagePreviewFileString,
      });
    // eslint-disable-next-line
  }, [localStorageImageFileString, localStorageImagePreviewFileString]);

  /**
   * useEffect used to assign generated base64String to activityCompanion.flyer
   */
  useEffect(() => {
    setactivityCompanion({
      ...activityCompanion,
      flyerString: localStorageFlyerFileString,
    });
    // eslint-disable-next-line
  }, [localStorageFlyerFileString]);

  /**
   * useEffect used to assign saved base64String to imageUpload onLoad if available
   */
  useEffect(() => {
    if (activityCompanion.imageString) {
      setCurrentEventImageFile(
        transformDataURLtoFile(
          activityCompanion.imageString,
          activityCompanion.image?.filename || ""
        )
      );
    }
    if (activityCompanion.previewImageString) {
      setCurrentEventImagePreviewFile(
        transformDataURLtoFile(
          activityCompanion.previewImageString,
          activityCompanion.previewImage?.filename || ""
        )
      );
    }
    if (activityCompanion.flyerString) {
      setCurrentEventFlyerFile(
        transformDataURLtoFile(
          activityCompanion.flyerString,
          activityCompanion.event.flyer?.filename || ""
        )
      );
    }
    // eslint-disable-next-line
  }, []);

  /**
   * useEffect to set values of found provider-eventLocation to event.location on change of dropdown value
   */
  useEffect(() => {
    if (selectedEventLocationOption === "-2" && !!provider.location) {
      setactivityCompanion({
        ...activityCompanion,
        event: { ...activityCompanion.event, location: provider.location },
      });
      return;
    }
    if (selectedEventLocationOption !== "-1") {
      const foundLocation: Location = provider.eventLocationItems.filter(
        (location: EventLocation) => location.id === selectedEventLocationOption
      )[0].location;
      setactivityCompanion({
        ...activityCompanion,
        event: { ...activityCompanion.event, location: foundLocation },
      });
    }
    // eslint-disable-next-line
  }, [selectedEventLocationOption]);

  /**
   * this useEffect generates the event location dropdown contents.
   */
  useEffect(() => {
    let localDropdownLocations: DropdownOption[] = [];
    localDropdownLocations.push({
      label: `${t("providerEventsConfigurationPage.providerAddress")} (${
        provider.location.street
      }, ${provider.location.zipCode} ${provider.location.city})`,
      value: "-2",
    });
    if (provider?.eventLocationItems.length > 0) {
      provider.eventLocationItems.forEach((item) =>
        localDropdownLocations.push({
          label: `${item.name} (${item.location.street}, ${item.location.zipCode} ${item.location.city})`,
          value: item.id,
        })
      );
    }
    localDropdownLocations.push({
      label: t("providerEventsConfigurationPage.dropdownDefaultEntry"),
      value: "-1",
    });
    setDropdownEventLocations([...localDropdownLocations]);
    if (
      !activityCompanion.event.location.city ||
      !activityCompanion.event.location.street ||
      !activityCompanion.event.location.zipCode
    )
      setSelectedEventLocationOption("-2");
    // eslint-disable-next-line
  }, [provider]);

  return (
    <>
      {isDesktop && <h2>{t("adminEventPage.event.steps.step1Title")}</h2>}
      <div className="input-label">
        {t("adminEventPage.event.steps.step1.titleLabel")}
        <div className="required">
          {t("adminEventPage.event.requiredField")}
        </div>
      </div>
      <div className="name-input">
        <InputComponent
          isValid={
            stepCompletion ||
            checkCertainPropOfCompanion(
              activityCompanion,
              RequiredCompanionProps.NAME
            )
          }
          value={activityCompanion.event.name ?? ""}
          placeholder={t("adminEventPage.event.steps.step1.eventName")}
          isFrameless
          signCap={40}
          onChange={(value) =>
            setactivityCompanion({
              ...activityCompanion,
              event: { ...activityCompanion.event, name: value },
            })
          }
        />
      </div>
      <div className="input-label">
        {t("adminEventPage.event.steps.step1.descriptionLabel")}
        <div className="required">
          {t("adminEventPage.event.requiredField")}
        </div>
      </div>
      <div
        className={[
          "description-input",
          !(
            stepCompletion ||
            checkCertainPropOfCompanion(
              activityCompanion,
              RequiredCompanionProps.DESCRIPTION
            )
          )
            ? "incomplete-flag"
            : undefined,
        ].join(" ")}
      >
        <WysiwygComponent
          providerId={provider.id}
          disabled={isLoading}
          key={`${isLoading}`}
          signCap={2000}
          value={activityCompanion.event.description ?? ""}
          onChange={(value) =>
            setactivityCompanion({
              ...activityCompanion,
              event: { ...activityCompanion.event, description: value },
            })
          }
        />
      </div>
      <div className="input-label">
        {t("adminEventPage.event.steps.step1.imageLabel")}
      </div>
      <div className="selected-content--image-select">
        <div className="image-upload">
          <p>{t("adminEventPage.event.steps.step1.imageText1")}</p>
          <FileInputComponent
            text={t("adminEventPage.event.steps.step1.infoImageUpload")}
            cropTopText={t(
              "providerEventsConfigurationPage.ratioDescription1to1"
            )}
            aspectHeight={400}
            aspectWidth={400}
            key={localStorageImageFileString}
            initialFile={currentEventImageFile}
            fileName={
              getCorrectImageDataForActivityType(false, activityCompanion)
                .fileName
            }
            onUpload={setCurrentEventImageFile}
            fileType={
              getCorrectImageDataForActivityType(false, activityCompanion)
                .fileType
            }
            type="crop"
            popupSaveTitle={t("providerEventsConfigurationPage.buttons.save")}
          />
        </div>
        <h3>{t("adminEventPage.event.steps.step1.or")}</h3>
        <div className="stock-image">
          <p>{t("adminEventPage.event.steps.step1.imageText2")}</p>
          <StockImage
            setSelectedFile={setCurrentEventImageFile}
            setSelectedPreviewFile={setCurrentEventImagePreviewFile}
            category={activityCompanion.category}
            activityType={activityCompanion.activityType}
          />
        </div>
      </div>

      <div className="selected-content--image-select">
        <div className="image-upload">
          <p>{t("adminEventPage.event.steps.step1.imageText3")}</p>
          <FileInputComponent
            cropTopText={t(
              "providerEventsConfigurationPage.ratioDescription4to3"
            )}
            height={200}
            aspectHeight={300}
            aspectWidth={400}
            key={localStorageImagePreviewFileString}
            initialFile={currentEventImagePreviewFile}
            fileName={
              getCorrectImageDataForActivityType(true, activityCompanion)
                .fileName
            }
            onUpload={setCurrentEventImagePreviewFile}
            fileType={
              getCorrectImageDataForActivityType(true, activityCompanion)
                .fileType
            }
            type="crop"
            popupSaveTitle={t("providerEventsConfigurationPage.buttons.save")}
          />
        </div>
      </div>

      <div className="selected-content--downloads">
        <div className="input-label">{t("adminEventPage.downloads")}</div>
        <div className="selected-content--downloads-upload">
          <FileInputComponent
            button={{ value: "+" }}
            type="file"
            fileType={FileType.DOCUMENT}
            key={activityCompanion.flyerString}
            initialFile={currentEventFlyerFile}
            fileName={activityCompanion.event.flyer?.filename ?? ""}
            accept="application/pdf"
            onFileListChange={(file) =>
              setCurrentEventFlyerFile(file.length > 0 ? file[0] : undefined)
            }
          />
          {currentEventFlyerFile ? (
            <p>{t("adminEventPage.event.steps.step1.deleteFile")}</p>
          ) : (
            <p>{t("adminEventPage.event.steps.step1.uploadFlyer")}</p>
          )}
        </div>
      </div>
      <div className="selected-content--location">
        <div className="input-label">
          {t("adminEventPage.event.steps.step1.locationLabel")}
          <div className="required">
            {t("adminEventPage.event.requiredField")}
          </div>
        </div>
        <DropdownComponent
          type="rounded"
          hideClearIcon
          dropdownOptions={dropdownEventLocations}
          selectedOption={selectedEventLocationOption ?? ""}
          onChange={(value) =>
            setSelectedEventLocationOption(
              value ? value : selectedEventLocationOption
            )
          }
        />

        {selectedEventLocationOption === "-1" && (
          <div className="selected-content--location-manualInput">
            <InputComponent
              isValid={
                stepCompletion ||
                checkCertainPropOfCompanion(
                  activityCompanion,
                  RequiredCompanionProps.STREET
                )
              }
              disabled={isLoading}
              isFrameless
              value={activityCompanion.event?.location.street ?? ""}
              onChange={(value) =>
                setactivityCompanion({
                  ...activityCompanion,
                  event: {
                    ...activityCompanion.event,
                    location: {
                      ...activityCompanion.event.location,
                      street: value,
                    },
                  },
                })
              }
              placeholder={t("providerEventsConfigurationPage.inputs.street")}
            />
            <div className="flex-it">
              <InputComponent
                isValid={
                  stepCompletion ||
                  checkCertainPropOfCompanion(
                    activityCompanion,
                    RequiredCompanionProps.ZIPCODE
                  )
                }
                isFrameless
                disabled={isLoading}
                value={activityCompanion.event?.location.zipCode ?? ""}
                onChange={(value) =>
                  setactivityCompanion({
                    ...activityCompanion,
                    event: {
                      ...activityCompanion.event,
                      location: {
                        ...activityCompanion.event.location,
                        zipCode: value,
                      },
                    },
                  })
                }
                placeholder={t(
                  "providerEventsConfigurationPage.inputs.zipCode"
                )}
              />
              <InputComponent
                isValid={
                  stepCompletion ||
                  checkCertainPropOfCompanion(
                    activityCompanion,
                    RequiredCompanionProps.CITY
                  )
                }
                isFrameless
                disabled={isLoading}
                value={activityCompanion.event?.location.city ?? ""}
                onChange={(value) =>
                  setactivityCompanion({
                    ...activityCompanion,
                    event: {
                      ...activityCompanion.event,
                      location: {
                        ...activityCompanion.event.location,
                        city: value,
                      },
                    },
                  })
                }
                placeholder={t("providerEventsConfigurationPage.inputs.city")}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ActivityStep2;
