import {
  ButtonComponent,
  PopupComponent,
} from "deinestadtliebt-component-library";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../pages/App";
import { useAxios } from "../../utils/AxiosUtil";
import { MailboxThread } from "../../utils/mailbox/Mailbox.types";
import {
  addMessageToThread,
  archiveThread,
  createEmptyThread,
  createNewThread,
  deleteThread,
  fetchAllThreads,
  getLastMessageTime,
  markThreadReaded,
} from "../../utils/mailbox/MailboxUtils";
import { Provider, UserRole } from "../../utils/user/User.types";
import { MailboxContent } from "./content/MailboxContent";
import { MailboxCreate } from "./create/MailboxCreate";
import { MailboxItem } from "./item/MailboxItem";
import "./MailboxComponent.styles.scss";
import { MailboxComponentProps } from "./MailboxComponent.types";

export const MailboxComponent: React.FC<MailboxComponentProps> = ({ role }) => {
  const { user } = useContext(UserContext);
  const axios = useAxios();
  const { t } = useTranslation();
  const [page, setPage] = useState<"new" | "inbox" | "archive">("inbox");
  const [openThread, setOpenThread] = useState<MailboxThread>();
  const [newThread, setNewThread] = useState<MailboxThread>(
    createEmptyThread()
  );
  const [replyThread, setReplyThread] = useState<MailboxThread>();
  const [allThreads, setAllThreads] = useState<MailboxThread[]>();
  const [allArchiveThreads, setAllArchiveThreads] = useState<MailboxThread[]>();

  /**
   * This memo saves the current userId.
   * If role is admin then instead null is saved.
   */
  const userId = useMemo(() => {
    if (user) {
      return user.role === UserRole.ADMIN ? null : user.id!;
    }
  }, [user]);

  /**
   * This effect is loading non-archived threads dirctly on page load.
   */
  useEffect(() => {
    if (userId !== undefined && axios && !allThreads) {
      fetchAllThreads(axios, userId, false).then(setAllThreads);
    }
  }, [axios, userId, allThreads]);

  /**
   * This effect is loading archived threads on demand when user is opening the archive page.
   */
  useEffect(() => {
    if (
      userId !== undefined &&
      axios &&
      page === "archive" &&
      !allArchiveThreads
    ) {
      fetchAllThreads(axios, userId, true).then(setAllArchiveThreads);
    }
  }, [axios, userId, page, allArchiveThreads]);

  /**
   * This memo filter the correct threads depending on opened page
   */
  const threads = useMemo(() => {
    let currentThreads: MailboxThread[] = [];
    switch (page) {
      case "inbox": {
        currentThreads = [...(allThreads || [])];
        break;
      }
      case "archive": {
        currentThreads = [...(allArchiveThreads || [])];
        break;
      }
    }

    return currentThreads.sort(
      (a, b) => getLastMessageTime(b) - getLastMessageTime(a)
    );
  }, [page, allThreads, allArchiveThreads]);

  /**
   * Helper to update local thread state. It is used to update threads.
   *
   * from: null  -> to: <Obj> = add thread
   * from: <Obj> -> to: null  = remove thread
   * from: <Obj> -> to: <Obj> = replace thread
   *
   * @param from the from object (also can be null)
   * @param to the to object (also can be null)
   * @param archive if true, uses archive state
   */
  const updateLocalThreads = (
    from: MailboxThread | null,
    to: MailboxThread | null,
    archive: boolean = false
  ): void => {
    const [state, setState] = archive
      ? [allArchiveThreads, setAllArchiveThreads]
      : [allThreads, setAllThreads];
    if (archive && !state) return;

    let local = [...(state || [])];

    if (from === null) {
      if (to !== null) {
        local.push(to);
      }
    } else {
      const index = local.findIndex((t) => t.id === from.id);

      if (index >= 0) {
        if (to === null) {
          local.splice(index, 1);
        } else {
          local[index] = to;
        }
      }
    }
    setState(local);
  };

  /**
   * Helper which create a new thread. Used newThread state
   */
  const createThread = (): void => {
    if (newThread && userId !== undefined && axios) {
      const threadToSend = { ...newThread };
      if (role === UserRole.PROVIDER) {
        threadToSend.members = [
          { id: userId, name: (user as Provider).name },
          { id: null },
        ];
      } else {
        threadToSend.members.push({ id: null });
      }

      threadToSend.messages[0].sender = userId;
      threadToSend.messages[0].seenBy = [userId];
      threadToSend.messages[0].createdAt = Date.now();

      createNewThread(axios, threadToSend).then((savedThread) => {
        updateLocalThreads(null, savedThread);
        setPage("inbox");
        setOpenThread(savedThread);
        setNewThread(createEmptyThread());
      });
    }
  };

  /**
   * Helper which marks the opened thread as readed.
   */
  const readOpenThread = (): void => {
    if (openThread && axios && userId !== undefined) {
      markThreadReaded(axios, openThread, userId).then(() => {
        const updatedThread = { ...openThread };
        updatedThread.messages = updatedThread.messages.map((value) => {
          if (!value.seenBy.includes(userId)) {
            value.seenBy.push(userId);
          }
          return value;
        });
        updateLocalThreads(openThread, updatedThread, page === "archive");
      });
    }
  };

  /**
   * Helper which delete the opened thread.
   */
  const deleteOpenThread = (): void => {
    if (openThread && axios && userId !== undefined) {
      deleteThread(axios, openThread.id, userId).then(() => {
        updateLocalThreads(
          openThread,
          null,
          openThread.archivedBy.includes(userId)
        );
        setOpenThread(undefined);
      });
    }
  };

  /**
   * Helper which archive the opened thread.
   */
  const archiveOpenThread = (): void => {
    if (openThread && axios && userId !== undefined) {
      archiveThread(axios, openThread.id, userId).then((success) => {
        if (success) {
          updateLocalThreads(openThread, null);
          const archivedThread = { ...openThread };
          archivedThread.archivedBy.push(userId);
          updateLocalThreads(null, archivedThread, true);
          setOpenThread(undefined);
        }
      });
    }
  };

  /**
   * Helper which opens the reply popup and set a inital thread.
   */
  const replyOpenThread = (): void => {
    if (openThread) {
      setReplyThread(createEmptyThread({ id: openThread.id }));
    }
  };

  /**
   * Helper to send reply to backend
   */
  const sendOpenThreadReply = (): void => {
    if (!!axios && userId !== undefined && replyThread && openThread) {
      const message = replyThread.messages[0];
      message.createdAt = Date.now();
      message.sender = userId;
      message.seenBy = [userId];
      addMessageToThread(axios, replyThread.id, message).then((success) => {
        if (success) {
          const replyedThread = { ...openThread };
          replyedThread.messages!.push(message);
          updateLocalThreads(openThread, replyedThread);
          setReplyThread(undefined);
        }
      });
    }
  };

  return (
    <div id="mailbox-wrapper" className="default-page-wrapper">
      <div className="mailbox-header">
        <ButtonComponent
          value={
            role === UserRole.ADMIN
              ? t("mailbox.header.newProvider")
              : t("mailbox.header.newAdmin")
          }
          onClick={() => setPage("new")}
        />
        <div
          className={[
            ...["mailbox-header-link"],
            ...(page === "inbox" ? ["active"] : []),
          ].join(" ")}
          onClick={() => {
            setPage("inbox");
            setOpenThread(undefined);
          }}
        >
          {t("mailbox.header.inbox")}
        </div>
        <div
          className={[
            ...["mailbox-header-link"],
            ...(page === "archive" ? ["active"] : []),
          ].join(" ")}
          onClick={() => {
            setPage("archive");
            setOpenThread(undefined);
          }}
        >
          {t("mailbox.header.archive")}
        </div>
      </div>
      <div className="mailbox-panel">
        {(page === "inbox" || page === "archive") && (
          <div
            className="mailbox-list"
            style={{
              backgroundColor: threads.length % 2 === 0 ? "#f8f8f8" : "#efefef",
            }}
          >
            {userId !== undefined &&
              threads.map((thread, index) => (
                <MailboxItem
                  thread={thread}
                  odd={index % 2 === 1}
                  open={openThread?.id === thread.id}
                  onClick={() => setOpenThread(thread)}
                  userId={userId}
                  key={thread.id}
                />
              ))}
          </div>
        )}

        {(page === "inbox" || page === "archive") && userId !== undefined && (
          <MailboxContent
            thread={openThread}
            onReply={page === "inbox" ? replyOpenThread : undefined}
            onArchive={page === "inbox" ? archiveOpenThread : undefined}
            onDelete={deleteOpenThread}
            onRead={readOpenThread}
            userId={userId}
          />
        )}
        {user && newThread && page === "new" && (
          <MailboxCreate
            role={user.role}
            type="new"
            onSend={createThread}
            onClose={() => setPage("inbox")}
            thread={newThread}
            onChange={setNewThread}
          />
        )}
      </div>
      {user && (
        <PopupComponent
          open={replyThread !== undefined}
          toggleOpen={() => setReplyThread(undefined)}
          title={
            role === UserRole.ADMIN
              ? t("mailbox.header.replyProvider")
              : t("mailbox.header.replyAdmin")
          }
        >
          <MailboxCreate
            thread={replyThread!}
            type="reply"
            role={user!.role}
            onChange={setReplyThread}
            onClose={() => setReplyThread(undefined)}
            onSend={sendOpenThreadReply}
          />
        </PopupComponent>
      )}
    </div>
  );
};
