import {
  ButtonComponent,
  FileInputComponent,
  generateNotification,
  LoaderComponent,
  NotificationTypes,
  PopupComponent,
  RetractableComponent,
  TableComponent,
} from "deinestadtliebt-component-library";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FileType } from "../../utils/appConfig/config.types";
import { useAxios } from "../../utils/AxiosUtil";
import {
  createEmptyMenuItem,
  FileItem,
  MenuItem,
} from "../../utils/user/User.types";
import {
  createProviderFile,
  deleteProviderFile,
  generateMenuItemsTableRows,
  updateProviderMenuData,
} from "../../utils/user/UserUtils";
import { MenuEditProps } from "./MenuEdit.types";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import { ReactComponent as ImagesIcon } from "../../assets/icons/images.svg";
import MenuItemEdit from "../menuitemedit/MenuItemEdit";
import { downloadFile } from "../../utils/file/fileUtils";

const MenuEdit: React.FC<MenuEditProps> = ({
  selectedProvider,
  setSelectedProvider,
  automaticStore = false,
}) => {
  const { t } = useTranslation();
  const [menuItemToUpdate, setMenuItemToUpdate] = useState<MenuItem>();
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const [isFileUpload, toggleFileUpload] = useState<boolean>(false);
  const [currentMenuItem, setCurrentMenuItem] = useState<MenuItem>(
    createEmptyMenuItem()
  );
  const axios = useAxios();

  /**
   * Helper to delete a file from the storage service
   * @param fileItem to delete
   */
  const deleteFile = (fileItem: FileItem): void => {
    toggleFileUpload(true);
    deleteProviderFile(
      axios,
      selectedProvider!.id!,
      fileItem.filename,
      fileItem.type
    ).then((success) => {
      if (success) {
        setSelectedProvider({
          ...selectedProvider!,
          files: selectedProvider!.files.filter(
            (files) => files.filename !== fileItem.filename
          ),
        });
        generateNotification(
          NotificationTypes.SUCCESS,
          t("notification.title.success.deleteSuccessful"),
          t("notification.content.success.deleteSuccessful")
        );
      } else
        generateNotification(
          NotificationTypes.ERROR,
          t("notification.title.error.error"),
          t("notification.content.error.deletion")
        );
      toggleFileUpload(false);
    });
  };

  /**
   * Helper to generate all nedded file inputs for menuItems.
   *
   * @returns JSX.Element for file handling of a price/menu
   */
  const renderMenuItemsFileUpload = (type: FileType): JSX.Element => {
    if (
      selectedProvider!.files.filter((item) => item.type === type).length === 0
    ) {
      return (
        <FileInputComponent
          button={{
            value: t("providerConfigurationPage.buttons.upload"),
          }}
          type="file"
          accept="application/pdf"
          onFileListChange={(files) => {
            if (files.length === 1) {
              toggleFileUpload(true);
              createProviderFile(
                axios,
                selectedProvider?.id!,
                files[0],
                type
              ).then((updatedProvider) => {
                setSelectedProvider(updatedProvider);
                toggleFileUpload(false);
              });
            }
          }}
        />
      );
    } else
      return (
        <div className="download-image-wrapper">
          <div
            className="download-image-wrapper-item remove"
            onClick={() =>
              deleteFile(
                selectedProvider!.files.filter((item) => item.type === type)[0]
              )
            }
          >
            <TrashIcon />
          </div>
          <div
            className="download-image-wrapper-item"
            onClick={() =>
              downloadFile(
                selectedProvider!.files.filter((item) => item.type === type)[0],
                axios
              )
            }
          >
            <ImagesIcon />
          </div>
          <div className="download-image-wrapper-item">
            {
              selectedProvider!.files.filter((item) => item.type === type)[0]
                .originalFilename
            }
          </div>
        </div>
      );
  };

  /**
   * Helper to modify the content of the menu for a provider
   * @param type which function should be called
   * @param id if something has to be removed
   */
  const changeMenuItemToProvider = (
    type: "create" | "delete" | "update",
    id: string
  ): void => {
    toggleLoading(true);

    let localMenu: MenuItem[] = [...selectedProvider!.menuItems];
    switch (type) {
      case "create":
        localMenu.push(currentMenuItem);
        break;
      case "delete":
        localMenu = localMenu.filter((item) => item.id !== id);
        break;
      case "update":
        let foundIndex: number = localMenu.findIndex(
          (item) => item.id === menuItemToUpdate?.id
        );
        if (foundIndex === -1) return;
        localMenu[foundIndex] = menuItemToUpdate!;
        break;
    }
    if (automaticStore)
      updateProviderMenuData(axios, {
        ...selectedProvider!,
        menuItems: localMenu,
      }).then((success) => {
        if (success) {
          setSelectedProvider({ ...selectedProvider!, menuItems: localMenu });
          setCurrentMenuItem(createEmptyMenuItem());
          setMenuItemToUpdate(undefined);
          generateNotification(
            NotificationTypes.SUCCESS,
            t(
              `notification.title.success.${
                !id ? "creationSuccessful" : "changesSuccessful"
              }`
            ),
            t(`notification.content.success.providerUpdate`)
          );
        } else
          generateNotification(
            NotificationTypes.ERROR,
            t(`notification.title.success.error`),
            t(`notification.content.success.deletion`)
          );
        toggleLoading(false);
      });
    else {
      setSelectedProvider({ ...selectedProvider!, menuItems: localMenu });
      setCurrentMenuItem(createEmptyMenuItem());
      setMenuItemToUpdate(undefined);
      toggleLoading(false);
    }
  };

  return (
    <RetractableComponent
      title={t("providerConfigurationPage.menu")}
      type="border"
      icon={<ImagesIcon />}
    >
      {isFileUpload && <LoaderComponent isFullscreen />}
      <div className="flex-it-between configuration-data-wrapper">
        <div className="half-the-width">
          <p>{t("providerConfigurationPage.menuUploadInfo")}</p>
          {renderMenuItemsFileUpload(FileType.MENU)}
        </div>

        <div className="half-the-width">
          <p>{t("providerConfigurationPage.menuCreate")}</p>
          <MenuItemEdit
            currentMenuItem={currentMenuItem}
            setCurrentMenuItem={setCurrentMenuItem}
          />
          <ButtonComponent
            value={t("providerConfigurationPage.buttons.add")}
            disabled={!currentMenuItem.name}
            isLoading={isLoading}
            onClick={() => changeMenuItemToProvider("create", "")}
          />
        </div>
      </div>
      <p>
        <TableComponent
          rows={generateMenuItemsTableRows(
            selectedProvider.menuItems,
            changeMenuItemToProvider,
            setMenuItemToUpdate
          )}
        />
        <PopupComponent
          open={!!menuItemToUpdate}
          toggleOpen={() => setMenuItemToUpdate(undefined)}
          bottomButtons={[
            {
              value: t(`providerEventsConfigurationPage.buttons.update`),
              onClick: () =>
                changeMenuItemToProvider("update", menuItemToUpdate?.id!),
            },
          ]}
        >
          <h2>{t("providerEventsConfigurationPage.updateTitle")}</h2>
          <MenuItemEdit
            currentMenuItem={menuItemToUpdate!}
            setCurrentMenuItem={setMenuItemToUpdate}
          />
        </PopupComponent>
      </p>
    </RetractableComponent>
  );
};

export default MenuEdit;
