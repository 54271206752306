import {
  CheckboxComponent,
  TableComponent,
} from "deinestadtliebt-component-library";
import { useTranslation } from "react-i18next/";
import { Action } from "../../../utils/action/Action.types";
import { DateState } from "../../../utils/activity/Activity.types";
import { Event } from "../../../utils/event/Event.types";
import { generateCancelTableRows } from "../../../utils/state/StateUtils";

interface StatusComponentProps {
  activityToEdit: Event | Action | undefined;
  handleDateStateChange: Function;
}

const StatusComponent: React.FC<StatusComponentProps> = ({
  activityToEdit,
  handleDateStateChange,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <h4>{t("adminEventPage.status.multiCancel")}</h4>
      <div className="cancel-wrapper">
        {!!activityToEdit?.startDate ? (
          <>
            <div className="activity-date">
              {new Date(
                activityToEdit.startDate || new Date()
              ).toLocaleDateString("de")}
              {!!activityToEdit?.endDate ? (
                <>
                  {" - "}
                  {new Date(
                    activityToEdit.endDate || new Date()
                  ).toLocaleDateString("de")}
                </>
              ) : (
                <></>
              )}
            </div>
            <CheckboxComponent
              value=""
              checked={
                !!activityToEdit?.activityDates &&
                activityToEdit?.activityDates?.[0]?.state ===
                  DateState.CANCELLED
              }
              onCheck={() => {
                handleDateStateChange(0, DateState.CANCELLED);
              }}
            />
          </>
        ) : (
          <div className="cancel-wrapper--table">
            <TableComponent
              rows={generateCancelTableRows(
                activityToEdit!,
                handleDateStateChange
              )}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default StatusComponent;
