/**
 * The Mailbox thread model.
 * The archivedBy and deletedBy can also have null as value
 * instead of a string to indicates an admin
 */
export interface MailboxThread {
  id: string;
  type: ThreadType;
  subject: string;
  members: Member[];
  messages: Message[];
  archivedBy: Member["id"][];
  deletedBy: Member["id"][];
}

/**
 * The Member model for Mailbox.
 * The id property can be null if the member is an admin
 */
export interface Member {
  id: string | null;
  name?: string;
}

/**
 * The Message model for Mailbox
 */
export interface Message {
  content: string;
  sender: string | null;
  createdAt: number;
  seenBy: Member["id"][];
}
/**
 * The Model for Thread types
 */
export enum ThreadType {
  NORMAL,
  SYSTEM,
}

/**
 * The mailbox add message request model
 */
export interface MailboxAddMessageRequest {
  threadId: string;
  message: Message;
}
