import {
  FileInputComponent,
  FullImageSlideShowComponent,
  generateNotification,
  InfoComponent,
  LoaderComponent,
  NotificationTypes,
} from "deinestadtliebt-component-library";
import { FileType } from "../../utils/appConfig/config.types";
import "./ProviderImageEdit.styles.scss";
import { ProviderImageEditProps } from "./ProviderImageEdit.types";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import { useState } from "react";
import {
  createProviderFile,
  deleteProviderFile,
  updateProviderMainImage,
} from "../../utils/user/UserUtils";
import { useAxios } from "../../utils/AxiosUtil";
import { useTranslation } from "react-i18next";

const ProviderImageEdit: React.FC<ProviderImageEditProps> = ({
  provider,
  setProvider,
}) => {
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const axios = useAxios();
  const { t } = useTranslation();

  /**
   * Helper to generate list in slideshow
   *
   * @returns dgenerated image list
   */
  const generateImageListForSlideshow = (): { original: string }[] => {
    let imageGalleryList: { original: string }[] = [];
    provider.files
      .filter((file) => file.type === FileType.IMAGE)
      .forEach((file) => {
        imageGalleryList.push({
          original: `${process.env.REACT_APP_SERVICE_URL}/config/file/?fileName=${file.filename}&type=${FileType.IMAGE}`,
        });
      });
    return imageGalleryList;
  };

  /**
   * Helper to upload image to server for provider
   * @param newFile to add
   */
  const uploadNewImage = (newFile: File): void => {
    if (!newFile) return;
    toggleLoading(true);
    createProviderFile(axios, provider.id!, newFile, FileType.IMAGE).then(
      (updatedProvider) => {
        if (updatedProvider == null) {
          generateNotification(
            NotificationTypes.ERROR,
            t("notification.title.error.error"),
            t("notification.content.error.imgUpload")
          );
        } else setProvider({ ...provider, files: updatedProvider.files });
        toggleLoading(false);
      }
    );
  };

  /**
   * Util method that checks if a image is in landscape format and uploads it
   * if it is in partrait format, a notification is generated
   * @param file file to check and upload
   */
  const uploadLandScapeImage = (file: File): void => {
    const img: HTMLImageElement = new Image();
    const url: string = URL.createObjectURL(file);
    img.src = url;
    img.onload = () => {
      img.width > img.height
        ? uploadNewImage(file)
        : generateNotification(
            NotificationTypes.ERROR,
            t("notificaion.title.error"),
            t("notification.content.error.imageDimension")
          );
    };
  };
  /**
   * Helper to upload main image to server for provider
   * @param newFile new main image
   */
  const handleMainImageUpload = (newFile: File): void => {
    if (!newFile) return;
    toggleLoading(true);
    if (
      provider.files.filter((file) => file.type === FileType.MAIN_IMAGE)
        .length > 0
    ) {
      updateProviderMainImage(axios, provider.id!, newFile).then(
        (updatedProvider) => {
          if (updatedProvider == null) {
            generateNotification(
              NotificationTypes.ERROR,
              t("notification.title.error.error"),
              t("notification.content.error.imgUpload")
            );
          } else setProvider({ ...provider, files: updatedProvider.files });
          toggleLoading(false);
        }
      );
    } else
      createProviderFile(
        axios,
        provider.id!,
        newFile,
        FileType.MAIN_IMAGE
      ).then((updatedProvider) => {
        if (updatedProvider == null) {
          generateNotification(
            NotificationTypes.ERROR,
            t("notification.title.error.error"),
            t("notification.content.error.imgUpload")
          );
        } else setProvider({ ...provider, files: updatedProvider.files });
        toggleLoading(false);
      });
  };

  /**
   * Helper to delete image and update provider
   *
   * @param filename to remove from image list
   */
  const deleteImageFromProvider = (filename: string): void => {
    toggleLoading(true);
    deleteProviderFile(axios, provider.id!, filename, FileType.IMAGE).then(
      (success) => {
        if (success) {
          setProvider({
            ...provider,
            files: provider.files.filter(
              (files) => files.filename !== filename
            ),
          });
          generateNotification(
            NotificationTypes.SUCCESS,
            t("notification.title.success.deleteSuccessful"),
            t("notification.content.success.deleteSuccessful")
          );
        } else
          generateNotification(
            NotificationTypes.ERROR,
            t("notification.title.error.error"),
            t("notification.content.error.deletion")
          );
        toggleLoading(false);
      }
    );
  };

  return (
    <div className="provider-image-administration">
      {isLoading && <LoaderComponent isFullscreen />}

      <div className="provider-image-administration--slideshow">
        <FullImageSlideShowComponent
          items={generateImageListForSlideshow()}
          height={350}
        />
      </div>

      <div className="provider-image-administration--images">
        {provider.files
          .filter((file) => file.type === FileType.IMAGE)
          .map((file, fileIndex) => (
            <div
              key={`provider-image-list-${fileIndex}`}
              className="provider-image-administration--images--list-item"
            >
              <div className="name">{file.originalFilename}</div>
              <div className="icon">
                <TrashIcon
                  onClick={() => deleteImageFromProvider(file.filename)}
                />
              </div>
            </div>
          ))}
        <div className="provider-image-administration--provider-image--header">
          <p className="p-headline">{t("providerImageEdit.generalUpload")}</p>
          <InfoComponent infoText={t("providerImageEdit.profileImageText")} />
        </div>
        {provider.files.filter((file) => file.type === FileType.IMAGE)
          .length === 0 && <p>{t("providerImageEdit.upload")}</p>}
        {provider.files.filter((file) => file.type === FileType.IMAGE).length <
          4 && (
          <FileInputComponent
            type={"image"}
            onUpload={uploadLandScapeImage}
            noPreview
            disabled={isLoading}
          />
        )}
        <div className="provider-image-administration--list-image--header">
          <p className="p-headline">
            {t("providerImageEdit.searchEntryImage")}
          </p>
          <InfoComponent
            infoText={t("providerImageEdit.listImageText")}
            aligendLeft
          />
        </div>
        <FileInputComponent
          fileType={FileType.MAIN_IMAGE}
          fileName={
            provider.files.filter(
              (file) => file.type === FileType.MAIN_IMAGE
            )[0]?.filename || undefined
          }
          type={"crop"}
          onUpload={(image) => handleMainImageUpload(image)}
          popupSaveTitle={t("providerImageEdit.save")}
          cropTopText={t("providerImageEdit.description")}
          aspectHeight={300}
          aspectWidth={400}
          disabled={isLoading}
        />
      </div>
    </div>
  );
};

export default ProviderImageEdit;
