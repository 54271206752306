import {
  generateNotification,
  NotificationTypes,
} from "deinestadtliebt-component-library";
import { ContentState, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { useEffect, useState } from "react";
import { renderToString } from "react-dom/server";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useTranslation } from "react-i18next";
import { ReactComponent as ExternalLink } from "../../assets/icons/external-link.svg";
import { FileType } from "../../utils/appConfig/config.types";
import { useAxios } from "../../utils/AxiosUtil";
import { generateCorrectYoutubeUrl } from "../../utils/InputUtils";
import { FileItem } from "../../utils/user/User.types";
import { createProviderFile } from "../../utils/user/UserUtils";
import "./WysiwygComponent.styles.scss";
import { WysiwygComponentProps } from "./WysiwygComponent.types";

const WysiwygComponent: React.FC<WysiwygComponentProps> = ({
  placeholder,
  disabled,
  required,
  value,
  onChange,
  label,
  signCap,
  signCapText = "",
  isFrameless,
  backgroundColor = "white",
  providerId,
}) => {
  const { t } = useTranslation();
  const axios = useAxios();
  const placeholderText: string = placeholder
    ? `${placeholder}${required ? " *" : ""}`
    : "";
  const labelText: string = label ? `${label}${required ? " *" : ""}` : "";
  const [currentLeftChars, setCurrentLeftChars] = useState<number>(signCap);
  const [editorState, setEditorState] = useState<EditorState>();

  /**
   * useEffect to set Editor State so it is not undefined when the component gets rendered.
   * The initial value gets cut, if its length exceeds the sign cap.
   */
  useEffect(() => {
    const currentInitialValue: string = value || "";
    let currentInitialHtml: string = currentInitialValue || "<p></p>";
    const contentBlock = htmlToDraft(currentInitialHtml);
    setEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(contentBlock.contentBlocks)
      )
    );
    // eslint-disable-next-line
  }, []);

  /**
   * useEffect that writes the length of the current content of the editor into variable currentLength and calculates the remaining chars
   */
  useEffect(() => {
    let currentInitialValue: string = value || "";
    let currentLength: number = currentInitialValue.length;
    if (editorState) {
      currentLength = editorState.getCurrentContent().getPlainText().length;
    }
    setCurrentLeftChars(signCap - currentLength);
    // eslint-disable-next-line
  }, [value, editorState]);

  /**
   * Helper to upload and display file in editor
   * @param file data to upload and append to provider
   * @returns generated link
   */
  const uploadImageCallBack = async (
    file: File
  ): Promise<{ data: { link: string } }> => {
    const updatedProvider = await createProviderFile(
      axios,
      providerId!,
      file,
      FileType.DESCRIPTION_IMAGE
    );
    if (updatedProvider === null) {
      generateNotification(
        NotificationTypes.ERROR,
        t("notification.title.error.error"),
        t("notification.content.error.imgUpload")
      );
      return Promise.resolve({
        data: {
          link: `${process.env.REACT_APP_BASE_CLIENT_URL}/logo.png`,
        },
      });
    }
    let newestFileItemWithFilename: FileItem = updatedProvider.files.filter(
      (item) => item.originalFilename === file.name
    )[
      updatedProvider.files.filter(
        (item) => item.originalFilename === file.name
      ).length - 1
    ];
    return Promise.resolve({
      data: {
        link: `${process.env.REACT_APP_SERVICE_URL}/config/file/?fileName=${newestFileItemWithFilename.filename}&type=${FileType.DESCRIPTION_IMAGE}`,
      },
    });
  };

  /**
   * Helper method to replace utls with an a tag
   * @param match the matched url
   * @returns replaced element of the string
   */
  const replacer = (match: string): string => {
    if (match.slice(0, 4) !== "http") {
      match = "https://" + match;
    }
    return `<a href="${match}" target="_blank" rel="noopener noreferrer">Externer Link ${renderToString(
      <ExternalLink width={15} />
    )} </a>`;
  };

  /**
   * Regex to detect all urls that are beginning with http, https or www
   */
  const regExp: RegExp =
    /(?<!["])(https?:\/\/|www\.)[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_+.~#?&\/=]*)(?!["])/g;

  return (
    <div className="wysiwyg-component">
      {labelText && value.length > 0 && (
        <div className="wysiwyg-component--label">{labelText}</div>
      )}
      {!!axios && (
        <Editor
          handlePastedText={() => false}
          toolbarCustomButtons={[
            <div className="wysiwyg-component--input-signCounter">
              {currentLeftChars}/{signCap} {signCapText}
            </div>,
          ]}
          localization={{
            locale: "de",
          }}
          editorStyle={{
            backgroundColor: backgroundColor,
          }}
          wrapperClassName="wysiwyg-component--input"
          toolbarClassName={[
            isFrameless ? "toolbar-frameless" : "toolbar-default",
            "wysiwyg-component--input-toolbar",
          ].join(" ")}
          editorClassName={[
            isFrameless ? "editor-frameless" : "editor-default",
            "wysiwyg-component--input-content",
          ].join(" ")}
          placeholder={placeholderText}
          toolbar={{
            // attributes for customization available at: https://jpuri.github.io/react-draft-wysiwyg/#/docs?_k=jjqinp
            options: ["list", "inline", "image", "embedded", "link"],
            inline: { options: ["bold", "italic", "underline"] },
            embedded: {
              embedCallback: generateCorrectYoutubeUrl,
            },
            image: {
              urlEnabled: false,
              uploadEnabled: !!providerId,
              alignmentEnabled: false,
              uploadCallback: providerId ? uploadImageCallBack : undefined,
              previewImage: true,
              inputAccept: "image/jpeg,image/jpg,image/png",
              alt: { present: false, mandatory: false },
            },
          }}
          onChange={(event) =>
            onChange?.(draftToHtml(event).replace(regExp, replacer))
          }
          onEditorStateChange={(newEditorState) => {
            if (
              (!disabled &&
                newEditorState.getCurrentContent().getPlainText().length <
                  signCap + 1) ||
              !signCap
            )
              setEditorState(newEditorState);
          }}
          editorState={editorState}
        />
      )}
    </div>
  );
};

export default WysiwygComponent;
