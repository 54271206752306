import dayjs from "dayjs";
import {
  ButtonComponent,
  CheckboxComponent,
  InputComponent,
} from "deinestadtliebt-component-library";
import { useTranslation } from "react-i18next";
import "./LicenceEdit.styles.scss";
import { LicenceEditProps } from "./LicenceEdit.types";

const LicenceEdit: React.FC<LicenceEditProps> = ({
  licence,
  setLicence,
  onSave,
}) => {
  const { t } = useTranslation();

  return (
    <form
      onSubmit={(evt) => {
        evt.stopPropagation();
        evt.preventDefault();
        onSave?.();
      }}
    >
      <p>
        {!!licence.id
          ? t("licenceEdit.updateTtitle")
          : t("licenceEdit.createTitle")}
      </p>
      <InputComponent
        value={licence.name}
        onChange={(value) => setLicence({ ...licence, name: value })}
        placeholder={t("licenceEdit.name")}
        isFrameless
      />
      {licence.createDate && (
        <p>{dayjs(licence.createDate).format("DD.MM.YYYY")}</p>
      )}
      <CheckboxComponent
        checked={licence.eventCreation}
        onCheck={() =>
          setLicence({ ...licence, eventCreation: !licence.eventCreation })
        }
        value={t("licenceEdit.events")}
      />
      <CheckboxComponent
        checked={licence.actionCreation}
        onCheck={() =>
          setLicence({ ...licence, actionCreation: !licence.actionCreation })
        }
        value={t("licenceEdit.actions")}
      />
      <CheckboxComponent
        checked={licence.jobCreation}
        onCheck={() =>
          setLicence({ ...licence, jobCreation: !licence.jobCreation })
        }
        value={t("licenceEdit.jobs")}
      />
      <CheckboxComponent
        checked={licence.menuCreation}
        onCheck={() =>
          setLicence({ ...licence, menuCreation: !licence.menuCreation })
        }
        value={t("licenceEdit.menu")}
      />
      <ButtonComponent type="submit" value={t("licenceEdit.save")} />
    </form>
  );
};

export default LicenceEdit;
