import { AxiosInstance } from "axios";
import i18n from "../../i18n";
import {
  Member,
  MailboxThread,
  ThreadType,
  Message,
  MailboxAddMessageRequest,
} from "./Mailbox.types";

/**
 * Helper to create an empty thread.
 * You can also add a pertial-typed thread to overwrite specific properties.
 *
 * @param thread a optional partial-typed thread object to overwrite specific properties.
 * @returns empty thread (with optional {@link thread} included)
 */
export const createEmptyThread = (
  thread?: Partial<MailboxThread>
): MailboxThread => ({
  ...{
    id: "",
    subject: "",
    members: [],
    messages: [{ content: "", sender: "", createdAt: 0, seenBy: [] }],
    type: ThreadType.NORMAL,
    archivedBy: [],
    deletedBy: [],
  },
  ...thread,
});

/**
 * Helper to get a name of a specific userId (can be null for admin).
 * A thread provides members with there id and name.
 *
 * @param userId the userId for which the name is needed
 * @param thread the thread which included the member list.
 * @returns a name as string or undefined if not found.
 * @tested
 */
export const getNameFromMember = (
  userId: Member["id"] | undefined,
  thread: MailboxThread
): string | undefined => {
  if (userId === null) {
    return "Admin";
  } else {
    return (
      thread.members.find(({ id }) => userId === id)?.name ||
      `Unknown (${userId})`
    );
  }
};

/**
 * Helper to fetch all relevant threads for a specific user.
 *
 * @param axios the axios instance
 * @param member the member/user for which the threads are needed.
 * @param archive if true, fetch the archive instead of the normal inbox.
 * @returns a list of {@link MailboxThread}
 */
export const fetchAllThreads = (
  axios: AxiosInstance,
  member: string | null,
  archive = false
): Promise<MailboxThread[]> => {
  return axios
    .get("/user/mailbox/", { params: { id: member, archive } })
    .then((response) => response.data);
};

/**
 * Helper to create a new thread on the backend.
 *
 * @param axios the axios instance
 * @param thread the thread object
 * @returns the promised thread from backend
 */
export const createNewThread = (
  axios: AxiosInstance,
  thread: MailboxThread
): Promise<MailboxThread> => {
  return axios.post("/user/mailbox/", thread).then((response) => response.data);
};

/**
 * Helper to mark a thread (or single message) as read.
 *
 * @param axios the axios instance
 * @param thread the thread which was readed
 * @param memberId the member/user which readed the thread (null for admin)
 * @param index the message index which was readed. Use -1 to read all unreaded messages automaticlly
 * @returns promised boolean if success
 */
export const markThreadReaded = (
  axios: AxiosInstance,
  thread: MailboxThread,
  memberId: Member["id"],
  index: number = -1
): Promise<boolean> => {
  return axios
    .post("/user/mailbox/read/", null, {
      params: {
        id: thread.id,
        memberId,
        index, // marks all as ready -> >0 only specific message
      },
    })
    .then((response) => response.status === 200)
    .catch((error) => {
      console.error("Error while marking message as read", error);
      return false;
    });
};

/**
 * Helper to add a message to a thread.
 *
 * @param axios the axios instance
 * @param threadId the threadId which a message should be appended
 * @param message the actual message to be appended
 * @returns promised boolean if success
 */
export const addMessageToThread = (
  axios: AxiosInstance,
  threadId: string,
  message: Message
): Promise<boolean> => {
  return axios
    .post("/user/mailbox/add", {
      threadId,
      message,
    } as MailboxAddMessageRequest)
    .then((response) => response.status === 201)
    .catch((error) => {
      console.error("Error while adding message to thread", error);
      return false;
    });
};

/**
 * Helper to archive a thread for a specific member/user
 *
 * @param axios the axios instance
 * @param threadId the thread id which should be archived
 * @param memberId the member/user id which wants to archive the thread (null for admin)
 * @returns promised boolean if success
 */
export const archiveThread = (
  axios: AxiosInstance,
  threadId: string,
  memberId: Member["id"]
): Promise<boolean> => {
  return axios
    .post("/user/mailbox/archive/", null, {
      params: { id: threadId, memberId },
    })
    .then((response) => response.status === 200)
    .catch((error) => {
      console.error("Error while archiving thread", error);
      return false;
    });
};

/**
 * Helper to delete a thread for a specific member/user.
 *
 * @param axios the axios instance
 * @param threadId the thread id to be deleted
 * @param memberId the member/user id which wants to delete the thread (null for admin)
 * @returns promised boolean if success
 */
export const deleteThread = (
  axios: AxiosInstance,
  threadId: string,
  memberId: Member["id"]
): Promise<boolean> => {
  return axios
    .post("/user/mailbox/delete/", null, { params: { id: threadId, memberId } })
    .then((response) => response.status === 200)
    .catch((error) => {
      console.error("Error while deleting thread", error);
      return false;
    });
};

/**
 * Helper to get the unreaded thread count.
 *
 * @param axios the axios instance
 * @param memberId the member/user id which the count is needed (null for admin)
 * @returns promised number of unreaded threads
 */
export const getUnreadedThreadCount = (
  axios: AxiosInstance,
  memberId: string | null
): Promise<number> => {
  return axios
    .get("/user/mailbox/unreaded/", { params: { id: memberId } })
    .then((response) => (isNaN(response.data) ? 0 : response.data))
    .catch((error) => {
      console.error("Error while requesting unreaded thread count", error);
      return 0;
    });
};

/**
 * Helper to convert a {@link Date} object in a date string (including 'today' and 'tomorrow').
 *
 * @param date the date object to be converted
 * @returns a date string
 * @tested
 */
export const convertInRelativeTimeString = (date: Date): string => {
  const now = new Date();

  if (date.toDateString() === now.toDateString()) {
    return i18n.t("mailbox.content.today");
  } else if (
    date.toDateString() === new Date(now.getTime() - 86400000).toDateString()
  ) {
    return i18n.t("mailbox.content.yesterday");
  } else {
    return date.toLocaleDateString();
  }
};

/**
 * Helper to get the time (in millis since unix epoch)
 * of the last message in a given message thread.
 *
 * @param thread the given thread to check
 * @returns the time in millis since unix epoch of the last message
 * @tested
 */
export const getLastMessageTime = (thread: MailboxThread): number => {
  let time = 0;
  thread.messages.forEach((m) =>
    m.createdAt > time ? (time = m.createdAt) : void 0
  );
  return time;
};
