import { AxiosInstance } from "axios";
import { TableRow } from "deinestadtliebt-component-library";
import { Job } from "./job.types";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import { FileType } from "../appConfig/config.types";
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";

/**
 * API METHOD - to create a job with optional image
 * @param axios network instance
 * @param job with all new data
 * @param jobImage optional file to stored it right away
 * @returns newly created job or null
 */
export const createJobOnBackend = async (
  axios: AxiosInstance,
  job: Job,
  jobImage?: File
): Promise<Job> => {
  let uploadFormData = new FormData();
  const newJobBlob = new Blob([JSON.stringify(job)], {
    type: "application/json",
  });
  uploadFormData.append("newJob", newJobBlob);
  if (!!jobImage) uploadFormData.append("fileData", jobImage);
  return axios
    .post("/user/job/", uploadFormData)
    .then((jobResp) => jobResp.data)
    .catch((exc) => {
      console.error("Error during creating of a job!", exc);
      return null;
    });
};

/**
 * API METHOD - to update a loaded job on the backend
 * @param axios network instance
 * @param job object with new data
 * @param jobImage optional file for an image
 * @returns updated job or ull in case of failure
 */
export const updateJobOnBackend = async (
  axios: AxiosInstance,
  job: Job,
  jobImage?: File
): Promise<Job> => {
  let uploadFormData = new FormData();
  const newJobBlob = new Blob([JSON.stringify(job)], {
    type: "application/json",
  });
  uploadFormData.append("updatedJob", newJobBlob);
  if (!!jobImage) uploadFormData.append("fileData", jobImage);
  return axios
    .post("/user/job/update/", uploadFormData)
    .then((jobResp) => jobResp.data)
    .catch((exc) => {
      console.error("Error during updating of a job!", exc);
      return null;
    });
};

/**
 * API METHOD - to load all jobs from the server by provider id
 * @param axios network instance
 * @param providerId to load jobs for
 * @returns all found jobs or an empty array
 */
export const fetchAllJobsForProvider = async (
  axios: AxiosInstance,
  providerId: string
): Promise<Job[]> => {
  return axios
    .get("/user/job/provider/", { params: { id: providerId } })
    .then((jobResp) => jobResp.data)
    .catch((exc) => {
      console.error("Error during fetching all jobs for a provider!", exc);
      return [];
    });
};

/**
 * API METHOD - to load all jobs from the server
 * @param axios network instance
 * @returns all found jobs or an empty array
 */
export const fetchAllJobs = async (axios: AxiosInstance): Promise<Job[]> => {
  return axios
    .get("/user/job/all/")
    .then((jobResp) => jobResp.data)
    .catch((exc) => {
      console.error("Error during fetching all jobs!", exc);
      return [];
    });
};

/**
 * API METHOD - to delete a job from the server
 * @param axios network instance
 * @param jobId to delete
 * @returns if status code 200 was received
 */
export const deleteJobById = async (
  axios: AxiosInstance,
  jobId: string
): Promise<boolean> => {
  return axios
    .post("/user/job/delete/", jobId)
    .then((jobResp) => jobResp.status === 200)
    .catch((exc) => {
      console.error("Error during deleting job !", exc);
      return false;
    });
};

/**
 * Helper to generate table rows for jobs items
 * @param jobs list of jobs items for provider
 * @param onDelete function which deletes the current row
 * @returns generated table rows
 * @tested
 */
export const generateJobTableRows = (
  jobs: Job[],
  onDelete: Function,
  onDetail: Function
): TableRow[] => {
  let currentRows: TableRow[] = [];
  jobs.forEach((job) => {
    currentRows.push({
      content: [
        job.name,
        job.mail,
        `${new Date(job.startDate).toLocaleDateString("de")} -
                ${new Date(job.endDate).toLocaleDateString("de")}`,
        <div>
          {job.fileItem && (
            <img
              style={{ height: "50px" }}
              src={`${process.env.REACT_APP_SERVICE_URL}/config/file/?fileName=${job.fileItem?.filename}&type=${FileType.JOB}`}
              alt={job.fileItem?.filename}
            />
          )}
        </div>,
        <div className="flex-it-center">
          <div
            className={["trash-wrapper", "little-bit-margin-right"].join(" ")}
            onClick={() => onDetail(job)}
          >
            <EditIcon />
          </div>
          <div className="trash-wrapper" onClick={() => onDelete(job.id)}>
            <TrashIcon />
          </div>
        </div>,
      ],
      id: job.id!,
    });
  });
  return currentRows;
};
