import {
  ButtonComponent,
  FileInputComponent,
  generateNotification,
  InputComponent,
  LoaderComponent,
  NotificationTypes,
  PopupComponent,
  RetractableComponent,
  TableComponent,
} from "deinestadtliebt-component-library";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FileType } from "../../utils/appConfig/config.types";
import { useAxios } from "../../utils/AxiosUtil";
import {
  createEmptyPriceItem,
  FileItem,
  PriceItem,
} from "../../utils/user/User.types";
import {
  createProviderFile,
  deleteProviderFile,
  generatePriceItemsTableRows,
  updateProviderPriceData,
  updateProviderProfileData,
} from "../../utils/user/UserUtils";
import { PriceListEditProps } from "./PriceListEdit.types";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import { ReactComponent as ImagesIcon } from "../../assets/icons/images.svg";
import PriceItemEdit from "../priceitemedit/PriceItemEdit";
import { downloadFile } from "../../utils/file/fileUtils";

const PriceListEdit: React.FC<PriceListEditProps> = ({
  selectedProvider,
  setSelectedProvider,
  automaticStore = false,
}) => {
  const { t } = useTranslation();
  const [priceItemToUpdate, setPriceItemToUpdate] = useState<PriceItem>();
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const [isFileUpload, toggleFileUpload] = useState<boolean>(false);
  const [currentPriceItem, setCurrentPriceItem] = useState<PriceItem>(
    createEmptyPriceItem()
  );
  const [keyPriceItemUpdate, setKeyPriceItemUpdate] = useState<number>(0);
  const axios = useAxios();

  /**
   * Helper to delete a file from the storage service
   * @param fileItem to delete
   */
  const deleteFile = (fileItem: FileItem): void => {
    toggleFileUpload(true);
    deleteProviderFile(
      axios,
      selectedProvider.id!,
      fileItem.filename,
      fileItem.type
    ).then((success) => {
      if (success) {
        setSelectedProvider({
          ...selectedProvider,
          files: selectedProvider.files.filter(
            (files) => files.filename !== fileItem.filename
          ),
        });
        generateNotification(
          NotificationTypes.SUCCESS,
          t("notification.title.success.deleteSuccessful"),
          t("notification.content.success.deleteSuccessful")
        );
      } else
        generateNotification(
          NotificationTypes.ERROR,
          t("notification.title.error.error"),
          t("notification.content.error.deletion")
        );
      toggleFileUpload(false);
    });
  };

  /**
   * Helper to update the profile data of a provider on the backend
   */
  const updateProviderProfileDataOnClick = (): void => {
    toggleLoading(true);
    updateProviderProfileData(axios, { ...selectedProvider }).then(
      (success) => {
        if (success) {
          generateNotification(
            NotificationTypes.SUCCESS,
            t("notification.title.success.changesSuccessful"),
            t("notification.content.success.configChanges")
          );
        } else
          generateNotification(
            NotificationTypes.SUCCESS,
            t("notification.title.error.creationFailed"),
            t("notification.content.error.configChanges")
          );
        toggleLoading(false);
      }
    );
  };

  /**
   * Helper to modify the content of the price for a provider
   * @param id if something has to be removed
   */
  const changePriceItemToProvider = async (
    type: "create" | "delete" | "update",
    priceItem: PriceItem | undefined,
    id: string
  ): Promise<void> => {
    toggleLoading(true);
    let localPrice: PriceItem[] = [...selectedProvider.priceItems];
    switch (type) {
      case "create":
        localPrice.push(priceItem!);
        break;
      case "delete":
        localPrice = localPrice.filter((item) => item.id !== id);
        break;
      case "update":
        let foundIndex: number = localPrice.findIndex(
          (item) => item.id === priceItem?.id
        );
        if (foundIndex === -1) return;
        localPrice[foundIndex] = priceItem!;
        break;
    }
    if (automaticStore)
      updateProviderPriceData(axios, {
        ...selectedProvider,
        priceItems: localPrice,
      }).then((success) => {
        if (success) {
          setSelectedProvider({ ...selectedProvider, priceItems: localPrice });
          setCurrentPriceItem(createEmptyPriceItem());
          setPriceItemToUpdate(undefined);
          setKeyPriceItemUpdate(keyPriceItemUpdate + 1);
          generateNotification(
            NotificationTypes.SUCCESS,
            t(
              `notification.title.success.${
                !id ? "creationSuccessful" : "changesSuccessful"
              }`
            ),
            t(`notification.content.success.providerUpdate`)
          );
        } else
          generateNotification(
            NotificationTypes.ERROR,
            t(`notification.title.success.error`),
            t(`notification.content.success.deletion`)
          );
        toggleLoading(false);
      });
    else {
      setSelectedProvider({ ...selectedProvider, priceItems: localPrice });
      setCurrentPriceItem(createEmptyPriceItem());
      setPriceItemToUpdate(undefined);
      setKeyPriceItemUpdate(keyPriceItemUpdate + 1);
      toggleLoading(false);
    }
  };

  /**
   * Helper to generate all nedded file inputs for menuItems.
   *
   * @returns JSX.Element for file handling of a price/menu
   */
  const renderMenuItemsFileUpload = (type: FileType): JSX.Element => {
    if (
      selectedProvider.files.filter((item) => item.type === type).length === 0
    ) {
      return (
        <FileInputComponent
          button={{
            value: t("providerConfigurationPage.buttons.upload"),
          }}
          type="file"
          accept="application/pdf"
          onFileListChange={(files) => {
            if (files.length === 1) {
              toggleFileUpload(true);
              createProviderFile(
                axios,
                selectedProvider.id!,
                files[0],
                type
              ).then((updatedProvider) => {
                setSelectedProvider(updatedProvider);
                toggleFileUpload(false);
              });
            }
          }}
        />
      );
    } else
      return (
        <div className="download-image-wrapper">
          <div
            className="download-image-wrapper-item remove"
            onClick={() =>
              deleteFile(
                selectedProvider.files.filter((item) => item.type === type)[0]
              )
            }
          >
            <TrashIcon />
          </div>
          <div
            className="download-image-wrapper-item"
            onClick={() =>
              downloadFile(
                selectedProvider.files.filter((item) => item.type === type)[0],
                axios
              )
            }
          >
            <ImagesIcon />
          </div>
          <div className="download-image-wrapper-item">
            {
              selectedProvider.files.filter((item) => item.type === type)[0]
                .originalFilename
            }
          </div>
        </div>
      );
  };

  return (
    <RetractableComponent
      title={t("providerConfigurationPage.priceList")}
      type="border"
      icon={<ImagesIcon />}
    >
      {isFileUpload && <LoaderComponent isFullscreen />}
      <div className="flex-it-between configuration-data-wrapper">
        <div className="half-the-width">
          <p>{t("providerConfigurationPage.priceListPdf")}</p>
          {renderMenuItemsFileUpload(FileType.PRICE)}
          <p className="padding-up">
            {t("providerConfigurationPage.priceListWebShop")}
          </p>
          <InputComponent
            type="url"
            value={selectedProvider.webshopLink}
            onChange={(value) =>
              setSelectedProvider({ ...selectedProvider, webshopLink: value })
            }
            placeholder={t("providerConfigurationPage.inputs.link")}
          />
          <ButtonComponent
            value={t("providerConfigurationPage.buttons.save")}
            type="button"
            isLoading={isLoading}
            key={selectedProvider.webshopLink}
            onClick={() => updateProviderProfileDataOnClick}
          />
        </div>

        <div className="half-the-width">
          <PriceItemEdit
            key={keyPriceItemUpdate}
            priceItemToEdit={currentPriceItem}
            isLoading={isLoading}
            onSave={(priceItem) =>
              changePriceItemToProvider("create", priceItem, "")
            }
          />
        </div>
      </div>

      <TableComponent
        header={t("providerConfigurationPage.tableHeaderPrice", {
          returnObjects: true,
        })}
        rows={generatePriceItemsTableRows(
          selectedProvider.priceItems,
          changePriceItemToProvider,
          setPriceItemToUpdate
        )}
        maxPageAmount={20}
      />
      <div className="popup-max-width">
        <PopupComponent
          open={!!priceItemToUpdate}
          toggleOpen={() => setPriceItemToUpdate(undefined)}
        >
          <h2>{t("providerEventsConfigurationPage.updateTitle")}</h2>
          <PriceItemEdit
            key={keyPriceItemUpdate}
            priceItemToEdit={priceItemToUpdate!}
            isLoading={isLoading}
            onSave={(priceItem) =>
              changePriceItemToProvider("update", priceItem, "")
            }
          />
        </PopupComponent>
      </div>
    </RetractableComponent>
  );
};

export default PriceListEdit;
