import {
  ButtonComponent,
  CheckboxComponent,
  InfoComponent,
  InputComponent,
  PopupComponent,
  RetractableComponent,
  TableComponent,
  TableHeader,
  TableRow,
} from "deinestadtliebt-component-library";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArchiveIcon } from "../../assets/icons/archive.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/contact-edit.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/simple-check.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/close.svg";
import { ReactComponent as OverviewIcon } from "../../assets/icons/editmulti.svg";
import {
  convertLunchIntoTableEntries,
  convertMealsIntoTableEntries,
  sortArrayOfDays,
  updateLunchItems,
} from "../../utils/lunch/LunchUtils";
import {
  Allergens,
  DayOfWeek,
  FoodTags,
  Lunch,
  MenuItem,
  Provider,
  UserRole,
  createEmptyMenuItem,
} from "../../utils/user/User.types";
import { useHistory } from "react-router-dom";
import { useAxios } from "../../utils/AxiosUtil";
import { ConfigContext, UserContext } from "../../pages/App";

export interface LunchEditStep2Props {
  lunch: Lunch;
  setLunch: React.Dispatch<React.SetStateAction<Lunch>>;
  provider: Provider;
  setActiveStep: React.Dispatch<React.SetStateAction<number | undefined>>;
  type: "create" | "delete" | "update" | "copy";
}

export const LunchEditStep2: React.FC<LunchEditStep2Props> = ({
  lunch,
  setLunch,
  provider,
  setActiveStep,
  type,
}) => {
  const axios = useAxios();
  const { user, setUser } = useContext(UserContext);
  const { appConfig } = useContext(ConfigContext);
  const containerWrapperRef = useRef<HTMLDivElement>(null);

  const [rows, setRows] = useState<TableRow[]>([]);
  const [overviewRows, setOverviewRows] = useState<TableRow[]>([]);
  const [newItem, setNewItem] = useState<MenuItem>(
    createEmptyMenuItem({ days: lunch.days })
  );
  const [popupType, setPopupType] = useState<"allergenes" | "tags" | "">();
  const [selectedAllergenTags, setSelectedAllergenTags] = useState<Allergens[]>(
    newItem.allergens
  );
  const [selectedCategoryTags, setSelectedCategoryTags] = useState<FoodTags[]>(
    newItem.tags
  );
  const history = useHistory();
  const [mealToDelete, setMealToDelete] = useState<string>();

  //useEffect hook to convert older lunches into tableEntries
  useEffect(() => {
    setRows(convertLunchIntoTableEntries(provider, setLunch, lunch.days));
  }, [provider, setLunch, lunch.days]);

  const { t } = useTranslation();

  /**
   * Helper method to to setItem and focus the edit wrapper onClick onto the edit meal symbol
   */
  const handleEdit = useCallback(
    (meal: MenuItem): void => {
      setNewItem(meal);
      setSelectedAllergenTags(meal.allergens);
      setSelectedCategoryTags(meal.tags);
      if (!containerWrapperRef.current) return;
      containerWrapperRef.current.scrollIntoView(false);
      containerWrapperRef.current.style.backgroundColor =
        appConfig?.highlightColor || "";
      setTimeout(() => {
        containerWrapperRef.current!.style.backgroundColor = "white";
      }, 500);
    },
    [appConfig?.highlightColor]
  );
  /**
   * helper method to remove days from a meal
   * @param meal menuItem to update
   * @param day day to remove
   */
  const toogleDayInArray = useCallback(
    (meal: MenuItem, day: DayOfWeek): void =>
      setLunch({
        ...lunch,
        menuItems: lunch.menuItems.map((item) =>
          item.id === meal.id
            ? {
                ...meal,
                days: meal.days?.includes(day)
                  ? meal.days?.filter((dayToCheck) => dayToCheck !== day)
                  : [...(meal.days ?? []), day],
              }
            : item
        ),
      }),
    [lunch, setLunch]
  );
  //useEffect to set the rows of the overview Table
  useEffect(() => {
    setOverviewRows(
      convertMealsIntoTableEntries(
        lunch,
        setLunch,
        setMealToDelete,
        handleEdit,
        toogleDayInArray,
        setSelectedAllergenTags,
        setSelectedCategoryTags
      )
    );
  }, [
    appConfig?.highlightColor,
    handleEdit,
    lunch,
    setLunch,
    toogleDayInArray,
  ]);

  /**
   *Helper function to update selected Days for a new meal
   * @param day to add or remove on the item
   */
  const handleNewItemDays = (day: DayOfWeek): void => {
    if (newItem.days?.includes(day))
      setNewItem({
        ...newItem,
        days: newItem.days.filter((lunchDay) => lunchDay !== day),
      });
    else
      setNewItem({
        ...newItem,
        days: newItem.days ? [...newItem.days, day] : [day],
      });
  };

  /**
   * Helper to generate tags to select based on the selected popup type
   */
  const generateTags = (): JSX.Element[] => {
    if (popupType === "allergenes") {
      return Object.values(Allergens).map((allergen) => (
        <div
          key={`allergen-${allergen}`}
          className={[
            "lunch-edit-step-2--tag",
            selectedAllergenTags.includes(allergen) ? "selected" : "",
          ].join(" ")}
          onClick={() =>
            selectedAllergenTags.includes(allergen)
              ? setSelectedAllergenTags(
                  selectedAllergenTags.filter(
                    (currAllergen) => currAllergen !== allergen
                  )
                )
              : setSelectedAllergenTags([...selectedAllergenTags, allergen])
          }
        >
          {t(`enum.allergens.${allergen}`)}
        </div>
      ));
    } else {
      return Object.values(FoodTags).map((category) => (
        <div
          key={`category-${category}`}
          className={[
            "lunch-edit-step-2--tag",
            selectedCategoryTags.includes(category) ? "selected" : "",
          ].join(" ")}
          onClick={() =>
            selectedCategoryTags.includes(category)
              ? setSelectedCategoryTags(
                  selectedCategoryTags.filter((currTag) => currTag !== category)
                )
              : setSelectedCategoryTags([...selectedCategoryTags, category])
          }
        >
          {t(`enum.foodTags.${category}`)}
        </div>
      ));
    }
  };

  /**
   * Helper method to save tags into the newItem
   */
  const saveTags = (): void => {
    if (popupType === "allergenes")
      setNewItem({ ...newItem, allergens: selectedAllergenTags });
    else setNewItem({ ...newItem, tags: selectedCategoryTags });
    setPopupType(undefined);
  };

  return (
    <div className="lunch-edit-step-2--wrapper">
      <PopupComponent
        open={!!popupType}
        toggleOpen={() => setPopupType(undefined)}
        bottomButtons={[
          {
            value:
              popupType === "allergenes"
                ? t("lunchEdit.step.2.saveAllergenes")
                : t("lunchEdit.step.2.saveTags"),
            onClick: saveTags,
          },
        ]}
      >
        <div className="lunch-edit-step-2--popup--tag-wrapper">
          {generateTags()}
        </div>
      </PopupComponent>
      <PopupComponent
        open={!!mealToDelete}
        toggleOpen={() => setMealToDelete(undefined)}
        bottomButtons={[
          {
            value: t("lunchEdit.step.2.deletePopupAbort"),
            onClick: () => setMealToDelete(undefined),
          },
          {
            value: t("lunchEdit.step.2.deletePopupConfirm"),
            onClick: () => {
              setLunch({
                ...lunch,
                menuItems: lunch.menuItems.filter(
                  (item) => item.id !== mealToDelete
                ),
              });
              setMealToDelete(undefined);
            },
          },
        ]}
      >
        <p>{t("lunchEdit.step.2.deletePopupText")}</p>
      </PopupComponent>
      <div className="lunch-edit-step-2--archive">
        <div className="lunch-edit-step-2--headline--wrapper">
          <p className="lunch-edit-step-2--headline--text">
            {t("lunchEdit.step.2.archiveHeadline")}
          </p>
          <InfoComponent
            aligendLeft
            infoText={t("lunchEdit.step.2.archiveText")}
          />
        </div>
        <RetractableComponent
          type="border"
          title={
            <div className="lunch-edit-step-2--archive-headline-wrapper">
              <p className="lunch-edit-step-2--description">
                {t("lunchEdit.step.2.archive")}
              </p>
              <InfoComponent
                infoText={t("lunchEdit.step.2.archiveTextTable")}
                aligendLeft
              />
            </div>
          }
          icon={<ArchiveIcon width={40} />}
        >
          <TableComponent
            maxPageAmount={5}
            rows={rows}
            header={
              t("lunchEdit.step.2.tableHeaders", {
                returnObjects: true,
              }) as TableHeader[]
            }
          />
        </RetractableComponent>
      </div>

      <div className="lunch-edit-step-2--add-meal" ref={containerWrapperRef}>
        <div className="lunch-edit-step-2--add-meal--headline">
          <div className="lunch-edit-step-2--add-meal--headline">
            <EditIcon width={30} />
            <p>{t("lunchEdit.step.2.addMealHeadline")}</p>
          </div>
        </div>
        <div className="lunch-edit-step-2--name-input-wrapper--input">
          <InputComponent
            type="text"
            value={newItem.name}
            onChange={(name) => setNewItem({ ...newItem, name })}
            placeholder={t("lunchEdit.step.2.newItemName")}
            isFrameless
            maxWidth={250}
            fontSize="1rem"
          />
        </div>
        <div className="lunch-edit-step-2--description-input-wrapper--input">
          <InputComponent
            type="text"
            value={newItem.description}
            onChange={(description) => setNewItem({ ...newItem, description })}
            placeholder={t("lunchEdit.step.2.newItemDescription")}
            isFrameless
            maxWidth={500}
            signCap={100}
            signCapText={t("lunchEdit.step.2.signCap")}
            fontSize="1rem"
          />
        </div>
        <div className="lunch-edit-step-2--price-input-wrapper--input">
          <InputComponent
            decimals={2}
            type="number"
            value={newItem.price === 0 ? "" : newItem.price}
            onChange={(price) =>
              setNewItem({ ...newItem, price: Number(price) })
            }
            placeholder={t("lunchEdit.step.2.newItemPricePlaceholder")}
            isFrameless
            maxWidth={250}
            fontSize="1rem"
          />
          <p>{t("lunchEdit.step.1.price")}</p>
        </div>
        <div className="lunch-edit-step-2--day-select-wrapper">
          {sortArrayOfDays(lunch.days).map((day) => (
            <div className="lunch-edit-step-2--day-select-wrapper--day">
              <p className="lunch-edit-step-2--day-select-wrapper--day--label">
                {t(`enum.dayOfWeekShort.${day}`)}
              </p>
              <CheckboxComponent
                checked={newItem.days?.includes(day) || false}
                onCheck={() => handleNewItemDays(day)}
                value=""
              />
            </div>
          ))}
          <div className="lunch-edit-step-2--day-select-wrapper--day">
            <p className="lunch-edit-step-2--day-select-wrapper--day--label">
              {t("lunchEdit.step.2.daily")}
            </p>
            <CheckboxComponent
              checked={newItem.days?.length === lunch.days.length}
              onCheck={() =>
                newItem.days?.length === lunch.days.length
                  ? setNewItem({ ...newItem, days: [] })
                  : setNewItem({ ...newItem, days: lunch.days })
              }
            />
          </div>
          <InfoComponent
            infoText={t("lunchEdit.step.2.daysInfo")}
            aligendLeft
          />
        </div>
        <div className="lunch-edit-step-2--tag-wrapper">
          <div className="lunch-edit-step-2--tag-wrapper--list-wrapper">
            <ButtonComponent
              value={t("lunchEdit.step.2.selectAllergenes")}
              onClick={() => setPopupType("allergenes")}
            />
            <div
              className={[
                "lunch-edit-step-2--tag-wrapper--list-wrapper--entry-wrapper",
                selectedAllergenTags.length >= 10 ? "big" : "small",
              ].join(" ")}
            >
              {selectedAllergenTags.map((allergen) => (
                <div
                  key={`allergen-${allergen}`}
                  className="tag-component--tag-selected  tag-component--tag meal-tag"
                  onClick={() =>
                    setSelectedAllergenTags(
                      selectedAllergenTags.filter(
                        (currAllergen) => currAllergen !== allergen
                      )
                    )
                  }
                >
                  {t(`enum.allergens.${allergen}`)}
                  <div className="tag-component--crossbody">
                    <DeleteIcon className="tag-component--crossmark" />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="lunch-edit-step-2--tag-wrapper--list-wrapper">
            <ButtonComponent
              value={t("lunchEdit.step.2.selectCategory")}
              onClick={() => setPopupType("tags")}
            />
            <div
              className={[
                "lunch-edit-step-2--tag-wrapper--list-wrapper--entry-wrapper",
                selectedCategoryTags.length >= 10 ? "big" : "small",
              ].join(" ")}
            >
              {selectedCategoryTags.map((category) => (
                <div
                  key={`category-${category}`}
                  className="tag-component--tag-selected  tag-component--tag meal-tag"
                  onClick={() =>
                    setSelectedCategoryTags(
                      selectedCategoryTags.filter(
                        (currTag) => currTag !== category
                      )
                    )
                  }
                >
                  {t(`enum.foodTags.${category}`)}
                  <div className="tag-component--crossbody">
                    <DeleteIcon className="tag-component--crossmark" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="lunch-edit-step-2--add-meal--icons">
          <CheckIcon
            className="lunch-edit-step-2--add-meal--icons--check-icon"
            onClick={() => {
              if (lunch.menuItems.some((item) => item.id === newItem.id))
                setLunch({
                  ...lunch,
                  menuItems: lunch.menuItems.map((item) =>
                    item.id === newItem.id ? newItem : item
                  ),
                });
              else
                setLunch({
                  ...lunch,
                  menuItems: [...lunch.menuItems, newItem],
                });
              setNewItem(createEmptyMenuItem({ days: lunch.days }));
              setSelectedAllergenTags([]);
              setSelectedCategoryTags([]);
            }}
          />
          <DeleteIcon
            className="lunch-edit-step-2--add-meal--icons--delete-icon"
            onClick={() => setNewItem(createEmptyMenuItem())}
          />
        </div>
      </div>
      <div className="lunch-edit-step-2--meal-overview">
        <div className="lunch-edit-step-2--meal-overview--headline-wrapper">
          <OverviewIcon width={30} />
          <p className="lunch-edit-step-2--meal-overview--headline-wrapper--headline">
            {t("lunchEdit.step.2.mealOverviewHeadline")}
          </p>
          <InfoComponent
            aligendLeft
            infoText={t("lunchEdit.step.2.overviewInfo")}
          />
        </div>
        <TableComponent
          rows={overviewRows}
          header={
            t("lunchEdit.step.2.overviewTableHeaders", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </div>
      <div className="lunch-edit-page--content--content-wrapper--nav-button-wrapper">
        <ButtonComponent
          value={t("lunchEdit.oneStepBack")}
          onClick={() => setActiveStep(1)}
        />
        <ButtonComponent
          value={t("lunchEdit.done")}
          onClick={() => {
            updateLunchItems(
              axios,
              type,
              lunch,
              provider,
              setUser,
              user?.role || UserRole.NONE,
              (path: string) => history.push(path)
            );
          }}
        />
      </div>
    </div>
  );
};
