export const updateList = <T extends { id?: string | number }>(
  item: T,
  list: T[],
  type: "create" | "delete" | "update"
): T[] => {
  let localList = [...list];
  switch (type) {
    case "create":
      localList.push(item);
      break;
    case "delete":
      localList = localList.filter((obj) => obj.id !== item.id);
      break;
    case "update":
      const foundIndex: number = localList.findIndex(
        (obj) => obj.id === item.id
      );
      if (foundIndex === -1) return localList;
      localList[foundIndex] = item;
      break;
  }
  return localList;
};
