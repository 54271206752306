import { AdminLayoutComponentProps } from "deinestadtliebt-component-library";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { ConfigContext, UserContext } from "../../pages/App";
import { useAxios } from "../AxiosUtil";
import { ConsentStatus } from "../cookies/Cookie.types";
import {
  getCookieConsentValue,
  setCookieConsentValue,
} from "../cookies/CookieUtils";
import { getUnreadedThreadCount } from "../mailbox/MailboxUtils";
import { UserRole } from "../user/User.types";
import { CurrentPage } from "./Navigation.types";

/**
 * Helper to generate a admin layout configuration for a specific page
 *
 * @param currentPage enum of the current page
 * @returns admin layout configuration
 */
export const useNavLayout = (
  currentPage: CurrentPage
): AdminLayoutComponentProps => {
  const history = useHistory();
  const axios = useAxios();
  const { t } = useTranslation();
  const { appConfig } = useContext(ConfigContext);
  const { user } = useContext(UserContext);
  const [currentBagdeNumberForUser, setCurrentBagdeNumberForUser] =
    useState<number>(0);
  const [showCookieBanner, setShowCookieBanner] = useState(false);

  // this useEffect starts localstorage operations when the browser is ready
  // otherwise it fails because it is ssr
  useEffect(() => {
    if (localStorage)
      setShowCookieBanner(getCookieConsentValue() === ConsentStatus.PENDING);
  }, []);

  /**
   * this useeffect fetches the amount of unread threads for the current user
   * to display it in the layout
   */
  useEffect(() => {
    if (axios && user) {
      getUnreadedThreadCount(
        axios,
        user.role === UserRole.ADMIN ? null : user.id!
      ).then((count) => setCurrentBagdeNumberForUser(count));
    }
  }, [user, axios]);

  /**
   * Helper for handling navigation. It can detect the protocol mailto and use
   * a different way to navigate to work correctly in browsers.
   *
   * @param url the url to navigateTo
   */
  const navigateTo = (url: string): void => {
    const urlSplit = url.split(":");
    if (urlSplit.length >= 2 && ["mailto"].includes(urlSplit[0])) {
      window.location.href = url;
    } else {
      history.push(url);
    }
  };

  // adding additional configuration for layout
  let additionalConfig = {};
  switch (currentPage) {
    case CurrentPage.ADMIN_VERSION:
    case CurrentPage.ADMIN_EVENT:
    case CurrentPage.ADMIN_ACTION:
    case CurrentPage.ADMIN_ACTIVITY:
    case CurrentPage.ADMIN_LUNCHOVERVIEW:
    case CurrentPage.ADMIN_LUNCHEDIT:
    case CurrentPage.ADMIN_PROFILE_PAGE:
    case CurrentPage.CONFIGURATION_ADMIN:
    case CurrentPage.DASHBOARD_ADMIN_HOME:
    case CurrentPage.MAILBOX_ADMIN:
    case CurrentPage.CONFIGURATION_ADMIN_PROVIDER:
      additionalConfig = {
        bgColor: appConfig?.highlightColor,
        headerLinks: [
          {
            title: t("header.home"),
            active: currentPage === CurrentPage.DASHBOARD_ADMIN_HOME,
            url: "/",
          },
          {
            title: t("header.menu"),
            active:
              currentPage === CurrentPage.CONFIGURATION_ADMIN ||
              currentPage === CurrentPage.ADMIN_VERSION,
            subLinks: [
              {
                title: t("header.config"),
                url: "/admin/config",
                description: t("header.configDescription"),
              },

              {
                title: t("general.versionTitle"),
                url: "/admin/version",
                description: t("general.versionDescription"),
              },
            ],
          },
          {
            title: t("adminProviderOverviewPage.providerConfig"),
            active:
              currentPage === CurrentPage.CONFIGURATION_ADMIN_PROVIDER ||
              currentPage === CurrentPage.ADMIN_EVENT ||
              currentPage === CurrentPage.ADMIN_ACTIVITY ||
              currentPage === CurrentPage.ADMIN_ACTION,
            subLinks: [
              {
                title: t("adminProviderOverviewPage.overviewTitle"),
                url: "/config/provider",
                description: t("adminProviderOverviewPage.overviewText"),
              },
              {
                title: t("adminConfigPage.title"),
                url: "/admin/acitivityConfig",
              },
              {
                title: t("adminEventPage.title"),
                url: "/admin/activity",
              },
              {
                title: t("adminLunchOverviewPage.title"),
                url: "/admin/lunch",
              },
              {
                title: t("providerAdminJobPage.title"),
                url: "/admin/advertisement",
              },
            ],
          },
          {
            title: t("header.mailbox"),
            url: "/mailbox",
            badgeNumber:
              currentBagdeNumberForUser === 0
                ? undefined
                : currentBagdeNumberForUser,
            active: CurrentPage.MAILBOX_ADMIN === currentPage,
          },
        ],
      };
      break;

    case CurrentPage.PROVIDER_PROFILE_PAGE:
    case CurrentPage.DASHBOARD_PROVIDER_HOME:
    case CurrentPage.PROVIDER_DETAIL_CONFIGURATION:
    case CurrentPage.MAILBOX_PROVIDER:
    case CurrentPage.PROVIDER_ACTIVITY:
    case CurrentPage.PROVIDER_LUNCHOVERVIEW:
    case CurrentPage.PROVIDER_LUNCHEDIT:
      additionalConfig = {
        bgColor: appConfig?.highlightColor,
        headerLinks: [
          {
            title: t("header.home"),
            active: CurrentPage.DASHBOARD_PROVIDER_HOME === currentPage,
            url: "/",
          },
          {
            title: t("header.menu"),
            active: CurrentPage.PROVIDER_DETAIL_CONFIGURATION === currentPage,
            subLinks: [
              {
                title: t("navigation.config"),
                url: "/config/events",
                description: t("navigation.configText"),
              },
              {
                title: t("navigation.providerActivityConfig"),
                url: "/config/activity",
                description: t("navigation.providerActivityConfigText"),
              },
              {
                title: t("adminLunchOverviewPage.title"),
                url: "/config/lunch",
                description: t("navigation.providerLunchConfigText"),
              },
              {
                title: t("navigation.providerConfigProfile"),
                url: "/config/profile",
                description: t("navigation.providerConfigProfileText"),
              },
              {
                title: t("navigation.providerConfig"),
                url: "/config",
                description: t("navigation.providerConfigText"),
              },
              {
                title: t("navigation.providerAdvertisment"),
                url: "/config/advertisement",
                description: t("navigation.providerAdvertismentText"),
              },
              {
                title: t("navigation.providerEvaluation"),
                url: "/config/evaluation",
                description: t("navigation.providerEvaluationText"),
              },
              {
                title: t("navigation.providerAboConfig"),
                url: "/config/abo",
                description: t("navigation.providerAboConfigText"),
              },
            ],
          },
          {
            title: t("header.mailbox"),
            url: "/mailbox",
            badgeNumber:
              currentBagdeNumberForUser === 0
                ? undefined
                : currentBagdeNumberForUser,
            active: CurrentPage.MAILBOX_PROVIDER === currentPage,
          },
        ],
      };
      break;

    case CurrentPage.AGB:
    case CurrentPage.Imprint:
    case CurrentPage.Privacy:
    default:
      additionalConfig = {
        bgColor: appConfig?.highlightColor,
        headerLinks: [
          {
            title: t("header.home"),
            url: "/",
          },
        ],
      };
      break;
  }

  return {
    ...additionalConfig,
    navigateTo,
    pageTitle: t("general.title", {
      replace: { cityName: appConfig?.platformName },
    }),
    profileLink: "/profile",
    profileTitle: t("profile.title"),
    footerLinks: [
      { title: "AGB", url: "/agb" },
      {
        title: t("footer.contact"),
        url: `mailto:${process.env.REACT_APP_CONTACT_MAIL}`,
      },
      { title: t("footer.imprint"), url: "/imprint" },
      { title: t("footer.privacy"), url: "/privacy" },
    ],
    cookieBannerProps: {
      text: <p>{t("cookie.text")}</p>,
      declineButtonText: t("cookie.decline"),
      acceptButtonText: t("cookie.accept"),
      onAccept: () => {
        setShowCookieBanner(false);
        setCookieConsentValue(ConsentStatus.ACCEPTED);
      },
      onDecline: () => {
        setShowCookieBanner(false);
        setCookieConsentValue(ConsentStatus.DECLINED);
      },
    },
    showCookieBanner: showCookieBanner,
  };
};
