import {
  DropdownComponent,
  PopupComponent,
} from "deinestadtliebt-component-library";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Action } from "../../utils/action/Action.types";
import { updateAction } from "../../utils/action/ActionUtils";
import { DateItem, DateState } from "../../utils/activity/Activity.types";
import { isAction, isEvent } from "../../utils/activity/ActivityUtils";
import { useAxios } from "../../utils/AxiosUtil";
import { Event } from "../../utils/event/Event.types";
import { updateEventOnBackend } from "../../utils/event/EventUtils";
import WysiwygComponent from "../wysiwyg/WysiwygComponent";
import { StatusChangeType } from "./DateStatusEdit.types";
import "./DateStatusEditStyles.scss";
import StatusComponent from "./subcomponents/StatusCancelComponent";
import DelayComponent from "./subcomponents/StatusDelayComponent";
import SoldOutComponent from "./subcomponents/StatusSoldOutComponent";

interface DateStatusEditProps {
  activityToEdit: Action | Event | undefined;
  setActivityToEdit(activity: Action | Event | undefined): void;
}

const DateStatusEdit: React.FC<DateStatusEditProps> = ({
  activityToEdit,
  setActivityToEdit,
}) => {
  const [delayCancelType, setDelayCancelType] = useState<StatusChangeType>();
  const { t } = useTranslation();
  const axios = useAxios();

  /**
   * Method to handle change of cancel-status
   * @param index index of date to be changed
   * @param newValue value that will be assigned or checked
   */
  const handleDateStateChange = (index: number, newValue: DateState) => {
    let localDateStateArray: DateItem[] = [...activityToEdit!.activityDates];
    if (localDateStateArray[index].state === newValue)
      localDateStateArray[index].state = DateState.READY;
    else {
      localDateStateArray[index].state = newValue;
    }
    setActivityToEdit({
      ...(activityToEdit as Action | Event),
      activityDates: localDateStateArray,
    });
  };

  /**
   * Helper to adjust specific values of activityDate
   * @param newValue value to be changed to
   * @param index index of the entry that will be changed
   * @param valueToChange vlaue that will be changed
   */
  const adjustDateItemEntry = (
    newValue: string,
    index: number,
    valueToChange: "date" | "state" | "startTime" | "endTime" | "status"
  ): void => {
    let localActivityToEdit: Event | Action = {
      ...(activityToEdit as Event | Action),
    };
    switch (valueToChange) {
      case "date":
        localActivityToEdit.activityDates[index].date = newValue
          ? new Date(newValue)
          : new Date(activityToEdit?.activityDates?.[index].date || new Date());
        break;
      case "state":
        localActivityToEdit.activityDates[index].state =
          (newValue as DateState) || DateState.READY;
        break;
      case "startTime":
        localActivityToEdit.activityDates[index].startTime = newValue
          ? newValue
          : localActivityToEdit.activityDates?.[index].startTime || "";
        break;
      case "endTime":
        localActivityToEdit.activityDates[index].endTime = newValue
          ? newValue
          : localActivityToEdit.activityDates?.[index].endTime || "";
        break;
      case "status":
        localActivityToEdit.activityDates[index].statusDetail = newValue
          ? newValue
          : localActivityToEdit.activityDates[index].statusDetail || "";
        break;
      default:
        return;
    }
    setActivityToEdit(localActivityToEdit);
  };

  /**
   * Helper to save adjusted activity
   */
  const updateActivity = (): void => {
    switch (true) {
      case isAction(activityToEdit!):
        updateAction(axios, activityToEdit as Action).then(() => {
          setActivityToEdit(undefined);
        });
        break;
      case isEvent(activityToEdit!):
        updateEventOnBackend(axios, activityToEdit as Event).then(() => {
          setActivityToEdit(undefined);
        });
        break;
      default:
        return;
    }
  };

  /**
   * Helper to get correct content depending on StatusChangeType
   * @param statusType value to determine what content will be returned
   * @returns JSX.Element containing different inputs depending on statusChangeType
   */
  const getCorrectStatusTypeContent = (
    statusType: StatusChangeType | undefined
  ): JSX.Element => {
    let correctContent: JSX.Element = <></>;
    switch (statusType) {
      case StatusChangeType.DELAY:
        correctContent = (
          <DelayComponent
            activityToEdit={activityToEdit}
            setActivityToEdit={setActivityToEdit}
          />
        );
        break;
      case StatusChangeType.CANCEL:
        correctContent = (
          <StatusComponent
            activityToEdit={activityToEdit}
            handleDateStateChange={handleDateStateChange}
          />
        );
        break;
      case StatusChangeType.SOLD_OUT:
        correctContent = (
          <SoldOutComponent
            activityToEdit={activityToEdit}
            handleDateStateChange={handleDateStateChange}
          />
        );
        break;
      default:
        return <></>;
    }
    return (
      <>
        {correctContent}
        <div>
          <h3>{t("adminEventPage.status.detail")}</h3>
          <WysiwygComponent
            signCap={200}
            value={activityToEdit?.activityDates[0]?.statusDetail || ""}
            onChange={(value) => adjustDateItemEntry(value, 0, "status")}
          />
        </div>
      </>
    );
  };

  return (
    <div className="date-status-edit">
      <PopupComponent
        title={t("adminEventPage.status.title")}
        open={!!activityToEdit}
        toggleOpen={() => {
          setActivityToEdit(undefined);
        }}
        bottomButtons={[
          { value: t("adminEventPage.status.save"), onClick: updateActivity },
        ]}
      >
        <div className="delay-cancel--wrapper">
          <DropdownComponent
            dropdownOptions={[
              {
                label: t("adminEventPage.status.delay"),
                value: StatusChangeType.DELAY,
              },
              {
                label: t("adminEventPage.status.cancel"),
                value: StatusChangeType.CANCEL,
              },
              {
                label: t("adminEventPage.status.soldOut"),
                value: StatusChangeType.SOLD_OUT,
              },
            ]}
            onChange={(value) => setDelayCancelType(value as StatusChangeType)}
            selectedOption={delayCancelType || ""}
            hideClearIcon
          />
          {getCorrectStatusTypeContent(delayCancelType)}
        </div>
      </PopupComponent>
    </div>
  );
};

export default DateStatusEdit;
